import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container, Button, Col, Row } from 'react-bootstrap';
import CountUp from 'react-countup';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import VisibilitySensor from "react-visibility-sensor";
function Limelight() {
    window.scrollTo(0, 0);
    const [focus, setFocus] = React.useState(false);
    return (
        <div>
            <Helmet>
                 <title>780% ROI via Digital, scaled non existent e-commerce</title>
                <meta name="description" content="" />
                <link rel="canonical" href="https://www.aandb.xyz/case-studies/ecommerce-advertising-case-study.html" />
            </Helmet>
            {/* first fold */}
            <div className="casestudy">
                <section className="box padmmdt padmmdb"> 
                    <Container>

                        <p className="text-left p1 pdl10"> <span class="spcase">Li</span>melight</p>
                    </Container>

                    <Container fluid className="padbsmt padbsmb">
                        <Row>
                            <Col lg="2"></Col>
                            <Col lg="8">
                                <div className="circleboxfirst  padssmb">
                                       <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="roundbx" width="30%" height="30%" alt="" />

                                    <div className="boxcentered">
                                        <h4>  <span className="cuspn"> 780%</span><br /> ROI via Digital, scaled non existent e-commerce.</h4>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="2"></Col>
                        </Row>
                    </Container>
                </section>
            </div>
            {/* The problem  and build fold */}
            <div className="lgbox  problem ">
               {/* <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/novakid/1.jpeg`} className="d-block d-sm-none marmsmb " width="100%" height="100%"  /> */}
                <Container fluid className="pdr0 ">
                    <Row>

                        <Col lg="10" md="10" sm="8" xs="12" className=" marmsmb padmmdt mbpt0 casebox offset-lg-1 ">
                            <p className="text-left p1 marmsmb mbb50"> <span class="spcase">THE</span> <strong>Brand</strong> </p>
                            <p className="p2">
                                <span>Limelight  Diamonds</span> is India’s leading sustainable diamond luxury brand offering lab grown diamond-studded jewelry with a mission to redefine luxury in a conscious effort toward sustainability. Their eco-friendly diamonds help preserve a diamond's legacy and protect the planet for a brighter future. 
                            </p>
                            <p className="text-left p1 mbt50 marbmdt  marmsmb mbb50"> <span class="spcase">THE</span> <strong>Problem</strong> </p>
                            <p className="p2">
                            Demand Generation
                            </p>
                            <p className="p2">
                                <ol>
                                    <li>Digital Discovery -  For the thousands of people wanting to know about Lab Grown Diamonds, the brand was not getting discovered.</li>
                                    <li>E-commerce sales were negligible.</li>
                                    <li>Website lacked e-commerce features leading to poor buying experience..</li> 
                                    <li>Online trust, critical in the category, was missing. </li>
                                </ol>
                            </p>

                        </Col>

                        {/* <Col lg={{ span: 7, order: 'last' }} md="6" sm={{ span: 6, order: 'last' }} xs={{ span: 12, order: 'first' }} className="pdr0 mbppr0 marmsmt marmsmb mdpdt80 d-none d-sm-block">

                               <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/novakid/1.jpeg`} className="" width="100%" height="100%"  />
                        </Col> */}

                    </Row>
                </Container>
            </div>
            {/* Opportunities  */}

            <Container fluid className=" box padbmdt padbmdb">
                <div className="seomatters ">
                    <Row>
                        <Col lg="11" className="casebox offset-lg-1">
                            <p className="text-left p1 marmsmb "> <span class="spcase">THE</span> <strong>Opportunities</strong></p>
                        </Col>
                    </Row>
                    <Container>
                        <Row className=" mbpdt30 marketing"> 
                              
                            <p className='p2 text-left'><strong>1. Digital discovery - Captured high intent audience looking/wanting to learn about Lab Grown Diamond</strong></p>  
                           
                            <p className='p2'>About <strong>87,220</strong> people on an average look for Lab Grown/CVD Diamond in India in a month, we got Limelight in the forefront for these searches.</p>
                            

                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/limelight/1image.png`} className=" mx-auto img-fluid marmxsb mgbtom30 " />
                            <br /> <br />
                            <p className='p2'><strong>Impact</strong>- The brands discovery went up by +120% & +81% YOY change</p>
                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/limelight/2image.png`} className=" mx-auto img-fluid marmxsb mgbtom30 " />
                            <br /><br />
                            <p className='p2 text-left'><strong>2. E-commerce sales - </strong></p>  
                            <p className='p2 text-left'>Lab Grown being a new category & for high order value products in the Jewelry industry, direct purchase from the website without speaking to the sales team is a hindrance for the users. <br />
                            It is important that the users can speak to Limelight’s sales team, get their queries answered & then be confident to move ahead with their purchase. Hence we enabled Personalized Assistance on WhatsApp </p>  
                            <p className='p2 text-left'><strong>Impact- </strong></p> 
                             
                            <p className='p2 text-left'>
                            <ul><br />
                                <li>Overall Revenue went via digital went up by <strong>+503%</strong></li>
                                <li>With A&B, Limelight Diamond achieved an ROI of <strong>780%</strong>. Via Digital</li>
                            </ul>
                            </p>  
                            <p className='p2 text-left'>
                                <strong>3.  E-commerce website was not fully fledged with all features. </strong>
                                <br />  <br />
                                <strong>Website </strong>
                            </p>
                          
                            <p className='p2 text-left'>
                            The Limelight website was built well but needed optimisation and more performance factors to push users in the purchase flow. 
                            </p>
                            
                            <p className='p2 text-left'>
                                <strong>1. Page Speed </strong> 
                            </p>
                           
                            <Col lg="5" md="5" sm="5" >
                            <p className='p2 text-left'>
                            <br /> <br />
                                <strong>Before</strong> 
                                <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/limelight/3image.png`} className=" mx-auto img-fluid mgbtom30 " />
                            </p>
                            </Col>
                            <Col lg="5" md="5" sm="5" >
                            <p className='p2 text-left'>
                            <br /> <br />
                                <strong>After</strong> 
                                <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/limelight/4image.png`} className=" mx-auto img-fluid mgbtom30 " />
                            </p>
                            </Col>
                            <p className='p2 text-left'>
                            <br /> <br />
                            The website speed was slightly above 20 and optimized to boost. After optimization, the score increased by 309%.
                            </p>
                            <p className='p2 text-left'>
                            <strong>2. Product page conversion rate optimization </strong>
                            <br /> 
                            Before - The product page design was good, however, it was missing details like 
                            </p>
                            <ol className='text-left'>
                                <li>Shipping & Returns</li>
                                <li>Customer Reviews </li>
                                <li>Details about Limelight exchange/buy back, </li>
                                <li>Trust Factors </li>
                                <li>Reviews</li> 
                            </ol>
                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/limelight/5image.png`} className=" mx-auto img-fluid mgbtom30 " />
                            <p className='p2 text-left'><br />
                            <strong>New Page Design - <a href="https://limelightdiamonds.com/product/ller-62/" target='blank'>Link</a></strong>
                          
                            <br /><br /> <strong> Impact </strong> 
                            <br /><br />
                            Product pageview increased by 60%, and bounce rate decreased by 73%.</p>
                            <Col lg="5" md="5" sm="5" >
                            <p className='p2 text-left'> 
                                <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/limelight/6image.png`} className=" mx-auto img-fluid mgbtom30 " />
                            </p>
                            </Col>
                            <Col lg="5" md="5" sm="5" >
                            <p className='p2 text-left'> 
                                <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/limelight/7image.png`} className=" mx-auto img-fluid mgbtom30 " />
                            </p>
                            </Col>
                            <p className='p2 text-left'> 
                            <br/><strong>3.  Online trust was a challenge.</strong> <br/> 
                            Limelight Diamond being a new brand in a new category lacked trust, hence it was important to bring out the trust factors. <br/>
                               We added Celebrity fold, influencers collab section & customer testimonial on the homepage and all product pages. 
                            </p>
                            <p className='p2 text-left'> 
                                <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/limelight/8image.png`} className=" mx-auto img-fluid mgbtom30 " /> <br /><br />
                                <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/limelight/9image.png`} className=" mx-auto img-fluid mgbtom30 " />
                            </p>
                        </Row>
                    </Container>

                </div>

            </Container>
              
            <div className="lgbox build endbox">
                <Container fluid className="pdlr0">
                    <Row className="text-left  mglr0">


                        <Col lg="6" sm="6" xs="12" className=" offset-lg-0 bgbl ">
                            <Link to={`${process.env.PUBLIC_URL}/case-studies/content-marketing-case-study-increase-in-pageviews.html`}>
                                <Row className="marssmt marssmb pdlr0 mglr0">
                                    <Col lg="3" sm="3" xs="3" >
                                           <img src={`${process.env.PUBLIC_URL}/img/casestudy/footerlogo/blabel.png`} width="97" height="97" className="mx-auto img-fluid text-right" />
                                    </Col>
                                    <Col lg="4" sm="6" xs="7">
                                        
                                        <h4 className="tcgold">Blabel </h4>
                                        <p>1043% increase in unique blogs pageviews </p>
                                    </Col>
                                    <Col lg="2" sm="3" xs="2" className="marbxst">
                                           <img src={`${process.env.PUBLIC_URL}/img/righticon.png`} width="34" height="34" className=" text-right" />
                                    </Col>
                                </Row>
                            </Link>
                        </Col>
                        
                        <Col lg="6" sm="6" xs="12" >
                          
                        <Link to={`${process.env.PUBLIC_URL}/case-studies/social-media-case-study-evok-furnitures.html`}>
                            <Row className="marssmt marssmb pdlr0 mglr0">
                                <Col lg="3" sm="3" xs="3" >
                                      <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/footerlogo/evok.png`} className="mx-auto img-fluid text-right" />
                                </Col>
                                <Col lg="4" sm="6" xs="7">
                                    {/* <p>Casestudy 9</p> */}
                                    <h4 className="tcgold">Evok Social Media Case Study</h4>
                                    <p> 1.47 Million Targeted Users Engaged</p>
                                </Col>
                                <Col lg="2" sm="3" xs="2" className="marbxst">
                                      <img src={`${process.env.PUBLIC_URL}/img/righticon.png`} width="40%" className=" text-right" />
                                </Col>
                            </Row>
                            </Link> 
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>

    )
}
export default Limelight;