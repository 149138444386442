import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Nav, Container, Accordion, Card, Button, Col, Row, Tab, Carousel } from 'react-bootstrap';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Slider from "react-slick";
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";
import Services from './Services';

function Contentmarketing() {
    window.scrollTo(0, 0);
    const [focus, setFocus] = React.useState(false);

    return (

        <div>
            <Helmet>

                <title>Best Content Marketing Agency for *Luxury* Brands | A&B</title>
                <meta name="description" content="Luxury brands who use content marketing see 40% higher ROI." />
                <link rel="canonical" href="https://www.aandb.xyz/content-marketing-agency-luxury.html" /> 
            </Helmet>
            <div className="firstfoldseo ">
                <div className="box  padmmdb">
                    {/* first fold */}
                    <Container fluid className="padbsmt padbsmb">
                        <Row>
                            <Col lg="2"></Col>
                            <Col lg="8">
                                <div className="circleboxfirst  padsmdt padsmdb">
                                    <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="roundbx"  width="30%" alt="" />

                                    <div className="boxcentered">
                                        <h4>
                                            <span>Luxury brands</span> who use content marketing<br /> see <span className="cuspn">  
                                            
                                            <CountUp start={focus ? 0 : null} end={40} redraw={true}>
                                            {({ countUpRef }) => (
                                                <VisibilitySensor onChange={(isVisible) => {
                                                    if (isVisible) { setFocus(true); }
                                                }}>
                                                    <span ref={countUpRef} />
                                                </VisibilitySensor>
                                            )}
                                        </CountUp>%
                                             </span><span> higher ROI.</span>

                                        </h4>

                                    </div>

                                </div>
                                <AnchorLink href="#conmarketing" className="btn btn-seo" >Show me how?</AnchorLink>
                            </Col>
                            <Col lg="2"></Col>

                        </Row>
                    </Container>
                </div>
            </div>
            {/* Content Marketing */}
            <div id="conmarketing" className="seomatters  bgb">
                <Container className="padbmdt padbmdb">

                    <h3 className="text-center txtcolor  padsmdb mgb50">Why <span>Content Marketing</span> is Important ?</h3>
                    <Row className="padsmdb">
                        <Col lg="4" md="4" sm="4">
                            <div className="circleboxfirst  padssmb pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="servicescirbox" width="40%" alt="" />
                                <div className="boxcentered">

                                    <h1>200</h1>
                                </div>
                            </div>
                            <p>million people now use ad blockers, show them content, not ads.</p><br />
                        </Col>
                        <Col lg="4" md="4" sm="4">
                            <div className="circleboxfirst  padssmb pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="servicescirbox" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1>70%
                                    </h1>
                                </div>
                            </div>

                            <p>of people say that an article is more trustworthy than an ad.</p><br />

                        </Col>
                        <Col lg="4" md="4" sm="4">
                            <div className="circleboxfirst  padssmb pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="servicescirbox" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1>60%
                                    </h1>
                                </div>
                            </div>

                            <p>of marketers claim content marketing generates better results than advertising.</p>

                        </Col>
                    </Row>
                    <AnchorLink href="#approach" className="btn btn-seo" >Let's do it!</AnchorLink>
                </Container>
            </div>
            {/* our approach seo fold */}
            <div id="approach" className="seoapproach bgbl padmmdt padmmdb ">
                <Container>
                    <div className="appbox  padssmb d-none d-sm-block">
                        <img src={`${process.env.PUBLIC_URL}/img/approach2.png`} className="imgwidt img-fluid" alt="" />
                        <div className="top-left">
                            <h4><strong>Content Pillars,</strong><br /> <i>not Blog Posts </i></h4>
                            <p>We don't publish thin blog posts. Each piece is heavily researched and mightier than 10 posts.</p>
                        </div>

                        <div className="top-right">
                            <h4><strong>Users,</strong><br /> <i>not Google</i></h4>
                            <p>We write with the user in mind; if it works for the user, it will for Social and Search.</p>
                        </div>

                        <div className="bottom-left">
                            <h4><strong>Value,</strong><br /> <i>not Volume</i></h4>
                            <p>We focus on the value brought by each content piece rather than the number of total pieces.</p>
                        </div>


                        <div className="bottom-right"> <h4><strong>Action,</strong><br /> <i> not just creation</i></h4>
                            <p>We make sure that your content performs; meaning generates traffic and revenue.</p>
                        </div>
                        <div className="centered"><h1>OUR <span>APPROACH</span> <br />TO Content Marketing</h1></div>
                    </div>
                    <div className="appbox  padssmb d-block d-sm-none">
                        <h1>OUR <span>APPROACH</span> <br />TO Content Marketing</h1> <br />

                        <h4><strong>Content Pillars,</strong><br /> <i>not Blog Posts </i></h4>
                        <p>We don't publish thin blog posts. Each piece is heavily researched and mightier than 10 posts.</p>
                        <img src={`${process.env.PUBLIC_URL}/img/straightline.png`} className="img-fluid" />
                        <h4><strong>Users,</strong><br /> <i>not Google</i></h4>
                        <p>We write with the user in mind; if it works for the user, it will for Social and Search.</p>

                        <img src={`${process.env.PUBLIC_URL}/img/straightline.png`} className="img-fluid" />
                        <h4><strong>Value,</strong><br /> <i>not Volume</i></h4>
                        <p>We focus on the value brought by each content piece rather than the number of total pieces.</p>
                        <img src={`${process.env.PUBLIC_URL}/img/straightline.png`} className="img-fluid" />
                        <h4><strong>Action,</strong><br /> <i>not just creation</i></h4>
                        <p>We make sure that your content performs; meaning generates traffic and revenue.</p>


                    </div>

                    <Accordion defaultActiveKey="0" className="seoapproach1 marbxst">

                        <Accordion.Toggle as={Button} variant="read" eventKey="1">
                            Our approach in detail
                            {/* &nbsp; <i className="fa fa-chevron-circle-down"></i> */}
                        </Accordion.Toggle>

                        <Accordion.Collapse eventKey="1">
                            <Card.Body>
                                <h3 className="text-center txtcolor  padsmdb"> <span>SERVICES</span></h3>
                                <Row>
                                    <ol>
                                        <li>Blog - <br /> <br />

                                            <ol type="a">
                                                <li>
                                                    Why blogs are important: No matter what type of website you have if you want people to discover you, you have to show up in search results. For that, you should provide useful content on your website in the form of blogs. To a greater degree, you optimize content for SERP higher will be rankings and indeed results in higher traffic. <br /><br />
                                                </li>
                                                <li>
                                                    Our Detailed Approach: <br /><br />
                                                    <ol type="i">
                                                        <li> Objective - Our primary goal is to understand the business, not merely writing a blog to rank higher in SERP. It is extremely vital to write quality information to users. <br /><br /></li>
                                                        <li> Audit - We perform an audit of the existing content and analyze how much traffic each blog is getting and how can we improve the blog. We also take into consideration the SERP page while writing a new blog post to understand what is ranking and how we can write a blog that will outrank other websites. <br /><br /></li>
                                                        <li> Execution - After understanding the Google SERP and what users are searching for we plan a structure of our blog. Writing an excellent quality is not enough, promoting your blog is extremely important. We make sure to include social posts for promoting our blog. Also, reach out to industry leaders to build a backlink profile of the blog. <br /><br /></li>
                                                    </ol>
                                                </li>
                                                <li>
                                                    Blog Impact for our client: By focusing on content marketing via the blog, One of our clients received significant traffic & Rankings.
                                                </li>
                                            </ol>
                                        </li>
                                        <br /> <br />
                                        <li>Video - <br /> <br />

                                            <ol type="a">
                                                <li>
                                                    Why video is important: More than 75% of people watch videos on the mobile. Therefore if you want to capture the gigantic market of mobile videos is the way to go. To crack the social media game videos are really important as videos get 1200% more shares than other images or posts. <br /><br />
                                                </li>
                                                <li>
                                                    Our Detailed Approach: <br /><br />
                                                    <ol type="i">
                                                        <li> Objective - Our core objective behind creating a video is to build engagement and improve brand image. It does not make any scene to create video content that does not build engagement. <br /><br /></li>
                                                        <li> Audit - We have our social media experts who keep track of the latest developments in the platform, most recent trends, etc. They keep you updated on what new and different we can implement for clients that will stand out. <br /><br /></li>
                                                        <li> Execution - We have in-house video production that builds and decides on the overall look and feel of the video. <br /><br /></li>
                                                    </ol>
                                                </li>
                                                <li>
                                                    Video Impact for our client: Tiranga Dabba for A&B
                                                </li>
                                            </ol>
                                        </li>
                                        <br /> <br />
                                        <li>Social Media Posts - <br /> <br />

                                            <ol type="a">
                                                <li>
                                                    Search Engine Algorithms Hit:<br /><br />
                                                    Why social media posts are important: According to research, the average time invested on social media per day is 2 hours 24 minutes. So, It doesn’t matter if you run a small local shop or a big company being present & active on social media is essential in today's world.
                                                </li>
                                                <li>
                                                    Our Detailed Approach: <br /><br />
                                                    <ol type="i">
                                                        <li> Objective - The main goal of social media is to broaden awareness and create curiosity in the minds of users. <br /><br /></li>
                                                        <li> Audit - We start with understanding our audiences and which platforms they use and focus on those platforms. We create SMART goals (specific, Measurable, Achievable, Relevant, Time-bound). <br /><br /></li>
                                                        <li> Execution - We make sure all strategies are ready before the actual event or festival post. We typically share our plan with clients months prior if they receive suggestions they can suggest right away and there is no end moment back and forth. <br /><br /></li>

                                                    </ol>
                                                </li>
                                                <li>
                                                    Social Media Posts Impact for our client:
                                                </li>
                                            </ol>
                                        </li>

                                        <br /> <br />
                                        <li>Influencers - <br /> <br />

                                            Do you know you can track Facebook ads cost in Google Analytics?<br /><br />
                                            <ol type="a">
                                                <li>
                                                    Why influencers important:
                                                    People are consuming a lot of content from social media that is why It is extremely difficult to build trust & a long-term relationship with the audience. 92% of people trust recommendations from Influencers. So the influencers can help us to bridge the gap between the brand & customers.
                                                </li>
                                                <li>
                                                    Our Detailed Approach: <br /><br />
                                                    <ol type="i">
                                                        <li> Objective - The main objective is to understand the brand's requirement and reach out to the influencer whose audience is appropriate for the brand. <br /><br /></li>
                                                        <li> Audit -   <br /><br /></li>
                                                        <li> Execution - <br /><br /></li>
                                                    </ol>
                                                </li>
                                                <li>
                                                    Influencers Impact on our client:
                                                </li> <br />
                                            </ol>
                                        </li>

                                        <br /> <br />
                                        <li>GIFs and Memes - <br /> <br />
                                            <ol type="a">
                                                <li>
                                                    Why GIFs and Memes are important: GIFs and memes are immensely important if you want to make content that is relatable to the audience which indeed leads to good engagement. GIFs and memes are effective and impactful communication ways to build content in terms of emotions or feelings.
                                                </li>
                                                <li>
                                                    Our Detailed Approach: <br /><br />
                                                    <ol type="i">
                                                        <li> Objective - The goal behind creating GIF or MEME is to express the personality of the brand. MEME also helps in showcasing a fun & friendly side of the brand to the customers.<br /><br /></li>
                                                        <li> Audit -  We pay close attention to trends while planning for gifs.<br /><br /></li>
                                                        <li> Execution - <br /><br /></li>
                                                    </ol>
                                                </li>
                                                <li>
                                                    GIFs and Memes Impact for our client: A&B
                                                </li> <br />

                                            </ol>
                                        </li>

                                    </ol>

                                </Row>
                            </Card.Body>
                        </Accordion.Collapse>

                    </Accordion>
                </Container>
                {/* <img src={`${process.env.PUBLIC_URL}/img/approach.png`} className="img-fluid" /> */}
            </div>
            {/* services */}
            {/* <div className="seoservices  bgb">
                <Container className="padmmdt  ">

                <h3 className="text-center txtcolor  padsmdb pdtb20">SUB <span>SERVICES</span></h3>
                    <Row className="text-center padsmdb" xs={12}>
                        <Col lg="4" md="4" sm="4" xs="6" >
                            <div className="circleboxfirst  padssmb pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw" width="40%" alt="" />
                                <div className="boxenter">
                                    <h1>Blog  </h1><br />
                                </div>
                            </div>
                            <p>We write astonishing content that will help impress Google and customers at the same time</p>
                        </Col>
                        <Col lg="4" md="4" sm="4" xs="6" >
                            <div className="circleboxfirst  padssmb pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw" width="40%" alt="" />
                                <div className="boxenter">
                                    <h1>Video </h1><br />

                                </div>
                            </div>

                            <p>Video sees an average increase of 157% in organic traffic. We build your brand story in a video which can reach over a Billion people on YouTube.</p>

                        </Col>
                        <Col lg="4" md="4" sm="4" xs="6" >
                            <div className="circleboxfirst  padssmb pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw" width="40%" alt="" />
                                <div className="boxenter">
                                    <h1>Social Media<br />Posts</h1>
                                </div>
                            </div>

                            <p>We know you never want to miss out on social media which has over 3.2 billion users currently. Our social media experts will help to win the social media game.</p>

                        </Col>
                   
                        <Col lg="4" md="4" sm="4" xs="6" >
                            <div className="circleboxfirst  padssmb pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw" width="40%" alt="" />
                                <div className="boxenter">
                                    <h1>Influencers<br /></h1>
                                </div>
                            </div>
                            <p>From food, fashion, lifestyle and travel we have top influencers across all categories to build your brand. </p>
                        </Col>
                        <Col lg="4" md="4" sm="4" xs="6" >
                            <div className="circleboxfirst  padssmb pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw" width="40%" alt="" />
                                <div className="boxenter">
                                    <h1>GIFs and <br /> Memes</h1>
                                </div>
                            </div>

                            <p>Extremely popular content these days is MEME. We have the best creators if you are looking for viral content. </p>

                        </Col>

                    </Row>

                </Container>
            </div> */}
            {/* case study */}

            <div className="bgfooter whyus padsmdt padbmdb">
                <Col lg="8" className="offset-lg-1" >
                    <h3 className=" txtcolor"><span className="spund">CASE</span> STUDY</h3>
                </Col>
                <Casestudy />
            </div>

            {/* 3 Reasons to choose */}

            <div className="bgb casestudy ">

                <Reasons />
            </div>

            {/* our services */}
            <Services />
        </div>
    );
}


export default Contentmarketing;
function Casestudy() {
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,

        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    adaptiveHeight: true,
                }
            },]
    };
    const next = () => {
        Slider.current.slickNext();
    }
    const previous = () => {
        Slider.current.slickPrev();
    }
    return (
        <Container fluid className="padmmdt casestudy ">
            <Row>
                <Col lg="10" className="offset-lg-1">
                    <Slider {...settings}>

                        <div>
                            <Row>
                                <Col className="offset-lg-1" lg={{ span: 4, order: 'first' }} md="6" sm={{ span: 6, order: 'last' }} xs={{ span: 12, order: 'first' }}><img src={`${process.env.PUBLIC_URL}/img/Blabelcasestudy.png`} width="100%" className=" mx-auto d-block" /></Col>
                                <Col lg={{ span: 6, order: 'first' }} sm={{ span: 6, order: 'first' }} md="6" xs={{ span: 12, order: 'last' }} className="offset-lg-1 padbsmb mbtxtcenter">
                                    <h1 className="padslgt padbxsb">1043%</h1>
                                    <h2 className="padbxsb">  increase in unique <strong><span>blogs pageviews</span></strong></h2>
                                    {/* <a href="" className="btn btn-primary">Read Case Study</a> */}
                                    <Link to={`${process.env.PUBLIC_URL}/case-studies/content-marketing-case-study-increase-in-pageviews.html`} className="btn btn-seo">Read Case Study</Link> 
                                </Col>

                            </Row>

                        </div>

                    </Slider>
                </Col>
            </Row>
        </Container>
    );
}

// reasons
function Reasons() {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        adaptiveHeight: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false,
                    adaptiveHeight: true,
                }
            },],
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    adaptiveHeight: false,
                }
            },]
    };
    const next = () => {
        Slider.current.slickNext();
    }
    const previous = () => {
        Slider.current.slickPrev();
    }
    return (
        <Container fluid className="reasons padslgt padslgb casestudy ">
            <Row>
                <Col lg="10" className="offset-lg-1">
                    <Slider {...settings}>
                        <div>
                            <Row>
                                <Col className="offset-lg-1 offset-sm-1" lg={{ span: 4, order: 'first' }} md="4" sm={{ span: 3, order: 'first' }} xs={{ span: 12, order: 'first' }}><img src={`${process.env.PUBLIC_URL}/img/reasons3.png`} className="custmt80 padt130 padmmdt mimgw padmsmb marbsmt img-fluid mx-auto d-block" /></Col>
                                <Col className="marmlgt mt0" lg={{ span: 7, order: 'first' }} sm={{ span: 6, order: 'last' }} md="7" xs={{ span: 12, order: 'last' }}  >

                                    <h2 className="marmlgt"> Reasons to choose A&B for<br/><strong> Digital Lead Generation</strong></h2> <br />

                                </Col>

                            </Row>

                        </div>
                        <div>
                            <Row>

                                <Col className="offset-lg-1 offset-sm-1" lg={{ span: 5, order: 'first' }} sm={{ span: 6, order: 'first' }} xs={{ span: 12, order: 'last' }} >

                                <h2 className="padt190"><span>A&B Commitment</span></h2>
                                    <h3 className=" padmsmb">  If we don't generate <strong>growth</strong> in 3 months, <strong><i>we fire ourselves.</i></strong><br />There are 9 more weird things about us, <br />read them here.</h3>

                                </Col>
                                <Col className="offset-lg-1 mgm90" lg={{ span: 4, order: 'first' }} sm={{ span: 4, order: 'last' }} xs={{ span: 12, order: 'first' }}>
                                    <img src={`${process.env.PUBLIC_URL}/img/commitment.png`} className="custmt80  padslg125 mimgw img-fluid mx-auto d-block" />
                                </Col>

                            </Row>

                        </div>
                        <div>
                            <Row>

                                <Col className="offset-lg-1 offset-sm-1" lg={{ span: 5, order: 'first' }} sm={{ span: 5, order: 'first' }} xs={{ span: 12, order: 'last' }} >

                                    <h3 className="marblgt padmsmb padslg125"> Specialised content marketing agency trusted by global brands</h3>

                                </Col>
                                <Col className=" mgm90" lg={{ span: 5, order: 'first' }} sm={{ span: 5, order: 'last' }} xs={{ span: 12, order: 'first' }}>
                                    <img src={`${process.env.PUBLIC_URL}/img/worldmap.png`} className="custmt80 padslg125 padbsmb img-fluid mx-auto d-block" />
                                    <img src={`${process.env.PUBLIC_URL}/img/clientslogo.png`} className=" img-fluid mx-auto d-block" />
                                </Col>

                            </Row>

                        </div>
                        
                        <div>
                            <Row>
                                <Col className="offset-lg-1 offset-md-0" lg={{ span: 10, order: 'first' }} md={{ span: 12, order: 'first' }} sm={{ span: 8, order: 'last' }} xs={{ span: 12, order: 'last' }}>
                                    <h3 className="text-center htitle"><span>Don't believe us?</span></h3>
                                    <p className="text-center custml ">  Hear it from our partners.</p>
                                    <div className="feedbackbox marmsmt padmsmt padmsmb">
                                        <img src={`${process.env.PUBLIC_URL}/img/leftcoma.png`} className="mgt40 img-fluid mx-auto d-block" />
                                        <br />
                                        {/* <img src={`${process.env.PUBLIC_URL}/img/paytminsiderlogo.png`} className="img-fluid mx-auto d-block padmsmt padmsmb" /> */}
                                        <h4 className="">We never knew that our category has so many informational searches. We also did not believe that a premium fashion label made of hemp can get transactions out of content marketing. But glad we were wrong!
                                        </h4>
                                        {/* <img src={`${process.env.PUBLIC_URL}/img/1seagallery.png`} className="rounded-circle img-fluid mx-auto d-block" /> */}
                                        {/* <img src={`${process.env.PUBLIC_URL}/img/rightcoma.png`}  className="flr" /> */}
                                    </div>
                                    <div className="clientname">
                                        <img src={`${process.env.PUBLIC_URL}/img/clients/Blabel_roundedlogo.png`} className="img-fluid mx-auto" width="160px" /> <br />
                                        <p className="text-center"> <i>- Chirag Tekchandaney, Founder, B Label by BOHECO</i></p>
                                    </div>
                                </Col>

                            </Row>


                        </div>
                    </Slider>
                </Col>
            </Row>
        </Container>
    );
}