import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { Navbar, Nav, NavItem } from 'react-bootstrap';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { HashLink } from 'react-router-hash-link';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'; 
export default class Navbars extends Component {
  constructor(props) {
    super(props);

    this.state = {
      navExpanded: false
    };
  }

  setNavExpanded = (expanded) => {
    this.setState({ navExpanded: expanded });
  }

  setNavClose = () => {
    this.setState({ navExpanded: false });
  }

  render() {
  
  return (

    <div>
      
      <Navbar className="navbar-dark" expand="lg" onToggle={this.setNavExpanded} expanded={this.state.navExpanded}>
        <Navbar.Brand as={Link} to={`${process.env.PUBLIC_URL}/`}><LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/logo.png`} alt="" width="120" height="100%" className="img-responsive mblogos" /></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav navbar-right">
          <Nav className="mr-auto">
            <NavItem>   </NavItem>

          </Nav>

          <Nav  onClick={this.setNavClose}>

            {/* <NavLink  as={Link}  to="/Aboutme">About</NavLink>  */}
            {/* <AnchorLink  href="#services" className="nav-link">SERVICES</AnchorLink> */}
            <HashLink to="/#services" className="nav-link">
            SERVICES
            </HashLink>
            <HashLink to="/#footer" className="nav-link">CONTACT US</HashLink>
            {/* <AnchorLink href="#footer" className="nav-link">CONTACT US</AnchorLink> */}
            <Link  to={`${process.env.PUBLIC_URL}/work.html`} className="nav-link">WORK</Link>
            <Link  to={`${process.env.PUBLIC_URL}/career.html`} className="nav-link">CAREERS</Link> 
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};
}
