import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container, Button, Col, Row } from 'react-bootstrap';
import CountUp from 'react-countup';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import VisibilitySensor from "react-visibility-sensor";
function Atmantan() {
    window.scrollTo(0, 0);
    const [focus, setFocus] = React.useState(false);
    return (
        <div>
            <Helmet>

                <title>Online Lead Generation Case Study - 438% Jump in Qualified Inquiries</title>
                <meta name="description" content="" />
                <link rel="canonical" href="https://www.aandb.xyz/case-studies/online-lead-generation-case-study.html" />
            </Helmet>
            {/* first fold */}
            <div className="casestudy">
                <section className="box padmmdt padmmdb">

                    <Container>

                        <p className="text-left p1 pdl10"> <span class="spcase">AT</span>MANTAN</p>
                    </Container>

                    <Container fluid className="padbsmt padbsmb">
                        <Row>
                            <Col lg="2"></Col>
                            <Col lg="8">
                                <div className="circleboxfirst  padssmb">
                                       <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="roundbx" width="30%" height="30%" alt="" />

                                    <div className="boxcentered">
                                        <h4>  <span className="cuspn"> 438%</span><br /> jump in qualified<br /> inquiries in 12 months

                                        </h4>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="2"></Col>
                        </Row>
                    </Container>
                </section>
            </div>
            {/* The problem  and build fold */}
            <div className="lgbox  problem ">
               <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/atmantan-digitalmedia/1.png`} className="d-block d-sm-none marmsmb " width="100%" height="100%"  />
                <Container fluid className="pdr0 ">
                    <Row>

                        <Col lg="4" md="4" sm="6" xs="12" className="padmmdt mbpt0 casebox offset-lg-1 ">
                            <p className="text-left p1 marmsmb mbb50"> <span class="spcase">THE</span> <strong>Brand</strong> </p>
                            <p className="p2">
                                <span>Atmantan</span> is an <span>international luxury wellness</span> destination which <strong>spans 42 acres</strong> and located <span>in Mulshi</span>, a hill station close to <span>Pune</span> & <span>Mumbai</span>.
                            </p>
                            <p className="text-left p1 mbt50 marbmdt  marmsmb mbb50"> <span class="spcase">THE</span> <strong>Problem</strong> </p>
                            <p className="p2">
                                Atmantan being a 1-year young wellness resort, had very few <span>Domestic inquiries</span> and <span>no International inquiries. </span>
                            </p>
                            <p className="p2">
                                They were driving traffic to their website (www.atmantan.com), but the <span>traffic wasn’t translating to leads.</span> The little leads they got,<br /> were also unqualified.
                            </p>

                        </Col>

                        <Col lg={{ span: 7, order: 'last' }} md="6" sm={{ span: 6, order: 'last' }} xs={{ span: 12, order: 'first' }} className="pdr0 mbppr0 marmsmb mdpdt80 d-none d-sm-block">

                               <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/atmantan-digitalmedia/1.png`} className="" width="100%" height="100%"  />
                        </Col>

                    </Row>
                </Container>
            </div>
            {/* Marketing Insights  */}

            <Container fluid className=" box padbmdt padbmdb">
                <div className="seomatters ">
                    <Row>
                        <Col lg="11" className="casebox offset-lg-1">
                            <p className="text-left p1 marmsmb "> <span class="spcase">THE</span> <strong>Insight</strong></p>
                        </Col>
                    </Row>
                    <Container>
                        <Row className="padsmdt padsmdb mbpdt30 marketing">

                            <Col lg="6" md="4" sm="6" xs="12">
                                <div className="circleboxfirst pdtb20  padssmb">
                                       <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} width="50%" height="50%" alt="" />
                                    <div className="boxcentered">
                                        <h1 className="boxtitle"><br />Atmantan’s <br /> revenue depended<br /> on three factors:-<br /><br /> </h1>

                                    </div>
                                </div>
                                <ul className="text-left mbpt0 mgbx50">
                                    <li>Quality of traffic coming to the website.</li>
                                    <li className="padsxst">Experience of the website after a user<br /> lands on it.</li>
                                    <li className="padsxst">Sales team pitch.</li>
                                </ul>
                            </Col>

                            <Col lg="6" md="4" sm="6" >
                                <div className="circleboxfirst pdtb20   padssmb">
                                       <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} width="50%" height="50%"  alt="" />
                                    <div className="boxcentered">
                                        <h1 className="boxtitle">Atmantan had a<br /> fantastic sales team,<br /> but the other two <br />factors were broken.</h1>

                                    </div>
                                </div>
                                <ul className="text-left mbpt0 mgbx50">
                                    <li>Quality of traffic was poor because<br /> digital advertising was driving irrelevant<br /> traffic to the website.
                                    </li> 
                                    <li className="padsxst">Experience of the website was sub-par<br /> despite the site being visually appealing
                                    </li>
                                </ul>
                            </Col>

                        </Row>
                    </Container>

                </div>

            </Container>
            {/* The Build */}
            <div className="lgbox build">
                <Container fluid className="lgbox padmmdt padmmdb mbpdt30">
                <Row>
                        <Col lg="11" className="casebox offset-lg-1">
                        <p className="text-left p1 marmmdb mgbtom30 margb30"> <span class="spcase">THE</span> <strong>BUILD</strong></p>
                        </Col>
                    </Row>
                  
                    <Row className=" ">

                        <Col lg="5" sm="8" xs="12" className="casebox offset-lg-1 offset-sm-1">

                            <div className="d-none d-sm-block"><span className="aplabox ">a</span></div>
                            <h3 className="marmsmt marmsmb margtab30tb mdmt0 mgl0">
                                Traffic Quality<br /> through advertising
                            </h3>
                            <p className="p2">
                                What Atmantan was targeting earlier were travellers,<br />people who like to travel etc.
                            </p>
                            <p className="p2">
                                The problem was, there is a vast difference between travellers and wellness travellers. Travellers will not appreciate<br /> Atmantan as much as Wellness travellers.
                            </p>
                            <p className="p2">
                                We took this insight and paused all existing campaigns and created new ones with the wellness travellers in mind, and<br /> here is what we achieved through the new paid ads.
                            </p>
                        </Col>

                        <Col lg={{ span: 6, order: 'last' }}  sm={{ span: 10, order: 'last' }} className="offset-lg-0 offset-sm-1">

                               <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/atmantan-digitalmedia/2.png`} className="marblgt  martab10 mbmt0  mx-auto img-fluid text-right mgbtom30 " />
                        </Col>

                    </Row>
                </Container>
            </div>
            {/* b fold */}
            <div className="lgbox build">
                <Container fluid className="pdlr0">
                    <Row className="text-left padmmdt  mbpdt30 mpdt0">
                        <Col lg="10" md="10" sm="10" className="offset-lg-1 offset-sm-1">
                            <div className="d-none d-sm-block">  <span className="aplabox">b</span></div>

                            <h3 className="marmsmt marmsmb  mbmt0">
                                Website Experience
                            </h3>
                            <ul className="pdl20 marbxsb">
                                <li><h4 className="cultitle">The process & fundamentals.</h4></li>
                            </ul>
                            <ol className="cuulb">
                                <li><span>Contact form</span> - There was no way for a user to contact the resort after landing on a page, we<br /> added a contact form at the bottom of all the landing pages. </li>
                                <li className="padmxst padmxsb">
                                    <span>Goal setup</span> - We set up goals in analytics to track those inquiries via the website
                                </li>
                                <li>
                                    <span>Sales process</span> - Streamlining the inquiry flow via a CRM so that the user can be reached out<br /> quickly after an inquiry. Earlier it took up to 48 hours for a callback, now it takes barely 10 minutes.
                                </li>
                            </ol>
                        </Col>
                    </Row>
                    <Row className="text-left padmmdt  mbpdt30 mpdt0">
                        <Col lg="10" md="10" sm="10" className="offset-lg-1 offset-sm-1">
                            <ul className="pdl20 ">
                                <li><h4 className="cultitle">Technical issues on the website</h4></li>
                            </ul>
                            <h3 className="marmxst marmxsb mbmtb0">
                                Page Speed
                            </h3>
                            <p className="para2">The website took more than 8 seconds to load before optimisation; now it loads in about <span className="parasco">3.7 seconds.</span></p>
                               <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/atmantan-digitalmedia/3.png`} className="mx-auto img-fluid text-right mgbtom30" />
                        </Col>
                    </Row>
                    <Row className="text-left padmmdt mbpt0  mbpdt30 mpdt0">
                        <Col lg="10" md="10" sm="10" className="offset-lg-1 offset-sm-1">

                            <h3 className="marmxst marmxsb mbmtb0">
                                Landing page experience
                            </h3>
                            <p className="para2">The landing pages were text-heavy and not built for a user looking for a wellness solution to their problem. We changed the landing pages so that the users can see which retreat is suitable for which objective and made it more user friendly. </p>
                               <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/atmantan-digitalmedia/4.png`} className="mx-auto img-fluid text-right mgbtom30 marmxst " />
                        </Col>
                    </Row>
                    <Row className="text-left padmmdt  mbpdt30 mpdt0">
                        <Col lg="10" md="10" sm="10" className="offset-lg-1 offset-sm-1">

                            <ul>
                                <li><h4 className="cultitle">Trust</h4> </li> 
                            </ul>
                            <p className="para2">Atmantan had no elements on the website to drive trust. We added:-</p>
                        </Col>
                    </Row>
                    <Container>
                        <Row className="text-left padmmdt  mbpdt30 mpdt0">
                        <Col lg="1" sm="1" className="casebox   ">
                                
                            </Col>
                            <Col lg="4" sm="5" xs="12" className="casebox ">
                                   <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/atmantan-digitalmedia/6.png`} className="mbpdr40 mx-auto img-fluid  mgbtom30" />
                            </Col>

                            <Col lg="4" sm="5"  xs="12" className="casebox  offset-lg-1">
                                   <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/atmantan-digitalmedia/7.png`} className="mbpdr40 mx-auto img-fluid  mgbtom30" />
                            </Col>
                        </Row>
                    </Container>
                    <Container>
                        <Row className=" padmmdt padmmdb mbpdtb0 mpdt0">

                            <Col lg="3" sm="4" xs="12"  className="offset-lg-1">
                                   <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/atmantan-digitalmedia/8.png`} className="mbpdr40 mx-auto img-fluid  mgbtom30" />
                            </Col>

                            <Col lg="3" sm="4" xs="12" className=" ">
                                   <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/atmantan-digitalmedia/9.png`} className="mbpdr40 mx-auto img-fluid  mgbtom30" />
                            </Col>

                            <Col lg="3" sm="4" xs="12" className="">
                                   <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/atmantan-digitalmedia/10.png`} className="mbpdr40 mx-auto img-fluid  mgbtom30" />
                            </Col>
                        </Row>
                    </Container>
                </Container>

            </div>
            <div className="box  problem">
                {/* first fold */}
                   <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/atmantan-digitalmedia/5.png`} width="100%" height="100%" className="mbpdtb30 d-block d-sm-none text-right pdlr0 " />
                <Container fluid className="impact pdr0 mbpdb70">
                    <Row>
                    
                        <Col lg="4" md="4" sm="6" xs="12" className="casebox offset-lg-1 padmmdt mbpt0 ">
                            <p className="text-left p1 marbmdb mbb50"> <span class="spcase">THE</span> IMPACT</p><br />
                            <ul className="cuulb">
                                <li>
                            <h4>
                                Revenue went up <br />
                                <span> by 383%</span>
                            </h4>
                            </li>
                            <li>
                            <h4>
                                Inquiries went up <br />
                                <span> by 130.36%</span>
                            </h4>
                            </li>
                            </ul>

                        </Col>

                        <Col lg={{ span: 7, order: 'last' }} md="6" sm={{ span: 6, order: 'last' }} xs={{ span: 12, order: 'first' }} className=" pdr0">
                               <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/atmantan-digitalmedia/5.png`} width="100%" height="100%" className="d-none d-sm-block text-right" />

                        </Col>

                    </Row>
                </Container>
            </div>
            <div className="lgbox build endbox">
                <Container fluid className="pdlr0">
                    <Row className="text-left  mglr0">


                        <Col lg="6" sm="6" xs="12" className=" offset-lg-1" >
                            <Link to={`${process.env.PUBLIC_URL}/case-studies/content-marketing-case-study-increase-in-pageviews.html`}>
                                <Row className="marssmt marssmb pdlr0 mglr0">
                                    <Col lg="3" sm="3" xs="3" >
                                           <img src={`${process.env.PUBLIC_URL}/img/casestudy/footerlogo/blabel.png`} width="97" height="97" className="mx-auto img-fluid text-right" />
                                    </Col>
                                    <Col lg="4" sm="6" xs="7">
                                        
                                        <h4 className="tcgold">Blabel </h4>
                                        <p>1043% increase in unique blogs pageviews </p>
                                    </Col>
                                    <Col lg="2" sm="3" xs="2" className="marbxst">
                                           <img src={`${process.env.PUBLIC_URL}/img/righticon.png`} width="34" height="34" className=" text-right" />
                                    </Col>
                                </Row>
                            </Link>
                        </Col>
                        
                        <Col lg="4" sm="6" xs="12">
                             
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>

    )
}
export default Atmantan;