import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Nav, Container, Accordion, Card, Button, Col, Row, Tab, Carousel } from 'react-bootstrap';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Slider from "react-slick";
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";
import Services from './Services';
function Digitalleadgeneration() { 
    window.scrollTo(0, 0);
    const [focus, setFocus] = React.useState(false);
    return ( 
        <div>
            <Helmet> 
                <title>Best Lead Generation Agency for *Luxury* Brands | A&B</title>
                <meta name="description" content=" Are you losing 50% of your budget on poor-quality leads?" />
                <link rel="canonical" href="https://www.aandb.xyz/digital-lead-generation-agency-luxury.html" /> 

            </Helmet>
            <div className="firstfoldseo ">
                <div className="box padmmdb">
                    {/* first fold */}
                    <Container fluid className="padbsmt padbsmb">
                        <Row>
                            <Col lg="2"></Col>
                            <Col lg="8">
                                <div className="circleboxfirst  padsmdt padsmdb">
                                    <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="roundbx"  width="30%" alt="" />

                                    <div className="boxcentered">
                                        <h4>
                                            Are you <span>losing </span><span className="cuspn"><CountUp start={focus ? 0 : null} end={50} redraw={true}>
                                            {({ countUpRef }) => (
                                                <VisibilitySensor onChange={(isVisible) => {
                                                    if (isVisible) { setFocus(true); }
                                                }}>
                                                    <span ref={countUpRef} />
                                                </VisibilitySensor>
                                            )}
                                        </CountUp>%</span> of your budget<br /> on poor-quality leads?

                                     </h4>

                                    </div>

                                </div>
                                <AnchorLink href="#iwantto" className="btn btn-seo" >Get leads that convert! </AnchorLink>
                            </Col>
                            <Col lg="2"></Col>

                        </Row>
                    </Container>
                </div>
            </div>
            {/* Seo matters */}
            <div id="iwantto" className="seomatters  bgb">
                <Container className="padbmdt padbmdb">

                    <h3 className="text-center txtcolor  padsmdb">Why Digital <span>Lead Generation?</span></h3>
                    <Row className="padsmdb">
                        <Col lg="4" md="4" sm="4">
                            <div className="circleboxfirst  padssmb pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`}  className="servicescirbox"  width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1><CountUp start={focus ? 0 : null} end={62} redraw={true}>
                                            {({ countUpRef }) => (
                                                <VisibilitySensor onChange={(isVisible) => {
                                                    if (isVisible) { setFocus(true); }
                                                }}>
                                                    <span ref={countUpRef} />
                                                </VisibilitySensor>
                                            )}
                                        </CountUp>%</h1>
                                </div>
                            </div>
                            <p>lesser cost of generating leads via digital compared to traditional media</p><br />
                        </Col>
                        <Col lg="4" md="4" sm="4">
                            <div className="circleboxfirst  padssmb pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`}  className="servicescirbox"  width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1><CountUp start={focus ? 0 : null} end={40} redraw={true}>
                                            {({ countUpRef }) => (
                                                <VisibilitySensor onChange={(isVisible) => {
                                                    if (isVisible) { setFocus(true); }
                                                }}>
                                                    <span ref={countUpRef} />
                                                </VisibilitySensor>
                                            )}
                                        </CountUp>%</h1>
                                </div>
                            </div>

                            <p>of your target market is ready to buy, why waste budget on the rest?</p><br />

                        </Col>
                        <Col lg="4" md="4" sm="4">
                            <div className="circleboxfirst  padssmb pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`}  className="servicescirbox"  width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1><CountUp start={focus ? 0 : null} end={80} redraw={true}>
                                            {({ countUpRef }) => (
                                                <VisibilitySensor onChange={(isVisible) => {
                                                    if (isVisible) { setFocus(true); }
                                                }}>
                                                    <span ref={countUpRef} />
                                                </VisibilitySensor>
                                            )}
                                        </CountUp>%</h1>
                                </div>
                            </div>

                            <p>of your competitors are targeting <i>your</i> prospective leads right now on Digital </p><br />

                        </Col>
                    </Row> 
                    <AnchorLink href="#approach" className="btn btn-seo" >Let's get started! </AnchorLink>

                </Container>
            </div>
            {/* our approach seo fold */}
            <div id="approach" className="seoapproach bgbl padmmdt padmmdb ">
                <Container>
                    <div className="appbox  padssmb d-none d-sm-block">
                        <img src={`${process.env.PUBLIC_URL}/img/approach2.png`} className="imgwidt img-fluid" alt="" />
                        <div className="top-left">
                            <h4><strong> VALUE,</strong><br /> <i>NOT JUST VOLUME </i></h4>
                            <p>The number of leads does not matter, how many of them convert is the focus for us. We want less budget, fewer leads, but more bottom line for you.</p>
                        </div>

                        <div className="top-right">
                            <h4><strong>QUALIFIED LEADS, </strong><br /> <i>NOT SURFERS </i></h4>
                            <p>We know exactly who our audience is, more importantly, who is not. We exclude leads that will never convert.</p>
                        </div>

                        <div className="bottom-left">
                            <h4><strong>SOLUTIONS,</strong><br /> <i>NOT JARGON</i></h4>
                            <p>We make Form fill ratio, CTR, CPL, Reach & Impressions work for you and not the other way around. You focus on revenue, we will handle this.</p>
                        </div>


                        <div className="bottom-right">
                            <h4><strong>BEST PRACTICES, </strong><br /> <i>NOT TRY AND TEST</i></h4>
                            <p>We don't waste your budget trying and testing. We know the gold standards of Landing Pages & Advertising.</p>
                        </div>
                        <div className="centered"><h1>OUR <span>APPROACH</span> <br />TO LEAD GENERATION</h1></div>
                    </div>
                    <div className="appbox  padssmb d-block d-sm-none">
                        <h1>OUR <span>APPROACH</span> <br />LEAD GENERATION</h1> <br />

                        <h4><strong> VALUE,</strong><br /> <i>NOT JUST VOLUME </i></h4>
                        <p>The number of leads does not matter,<br /> how many of them convert is the focus for us.<br /> We want less budget, fewer leads,<br /> but more bottom line for you.</p>
                        <img src={`${process.env.PUBLIC_URL}/img/straightline.png`} className="img-fluid" />
                        <h4><strong>QUALIFIED LEADS, </strong><br /> <i>NOT SURFERS </i></h4>
                        <p>We know exactly who our audience is,<br /> more importantly, who is not. We exclude<br /> leads that will never convert.</p>

                        <img src={`${process.env.PUBLIC_URL}/img/straightline.png`} className="img-fluid" />
                        <h4><strong>SOLUTIONS,</strong><br /> <i>NOT JARGON</i></h4>
                        <p>We make Form fill ratio, CTR, CPL, Reach<br /> & Impressions work for you and not the other way around. You focus on revenue, we will handle this.</p>
                        <img src={`${process.env.PUBLIC_URL}/img/straightline.png`} className="img-fluid" />
                        <h4><strong>BEST PRACTICES, </strong><br /> <i>NOT TRY AND TEST</i></h4>
                        <p>We don't waste your budget trying and testing. We know the gold standards of Landing Pages & Advertising.</p> 
                    </div>

                    <Accordion defaultActiveKey="0" className="seoapproach1 marbxst">

                        <Accordion.Toggle as={Button} variant="read" eventKey="1">
                            Our approach in detail
                        </Accordion.Toggle>

                        <Accordion.Collapse eventKey="1">
                            <Card.Body>
                                <h3 className="text-center txtcolor  padsmdb"> <span>Digital Lead Generation Services</span></h3>
                                <Row>
                                    <ol>
                                        <li>Content Marketing - <br /> <br />
 
                                            <ol type="a">
                                                <li>
                                                <strong>Why Content Marketing is important:</strong> Not only does it help in driving traffic, answering your audience’s questions, building trust, and increasing conversions, but Content Marketing acts as a link connecting all your digital marketing efforts.<br /><br />
                                                </li>
                                                <li>
                                                    Our Detailed Approach: <br /><br />
                                                    <ol type="i">
                                                        <li> Objective - To create content for your brand that is user-centric and not Google-centric. <br /><br /></li>
                                                        <li> Audit - We believe that the key to writing a good piece of content is in-depth research. Along with being able to generate enough data to write good content, this also helps us in learning a great deal about what your peer brands are talking about and what your audience is looking for. <br /><br /></li>
                                                        <li> Execution - We write well-researched, interlinked content which is interactive, informative, answers your audience's questions, gives credibility to your brand, is easy to read and understand with images, alt tags, videos, infographics and has the right keywords in the right places. <br /><br /></li>
                                                    </ol>
                                                </li>
                                                <li>
                                                The Impact of Content Marketing for our client:
                                        </li>
                                            </ol>
                                        </li>
                                        <br /> <br />
                                        <li>Social Media Marketing - <br /> <br />
 
                                            <ol type="a">
                                                <li>
                                                Why Social Media Marketing is important: If your luxury brand is not on social media, you’re probably not known to a huge chunk of your online audience. Your brand not only has to be present but active on social media to reach more people, boost leads, and sales. <br /><br />
                                                </li>
                                                <li>
                                                    Our Detailed Approach: <br /><br />
                                                    <ol type="i">
                                                        <li> Objective - To reach that portion of your audience that is not actively searching for your products or services, build brand awareness and recall, develop a brand voice, and use that to engage with your audience through creative, customized and curated content. <br /><br /></li>
                                                        <li> Audit - We're always one step ahead in the social game. You don't have to worry about keeping up with all the latest trends. Our social media team knows them all and also know exactly how to use them for your brand.<br /><br /></li>
                                                        <li> Execution - We don’t believe in jumping on the bandwagon and doing what everyone else is doing on social, blindly. We will follow the right trends to create content for you which is relevant for your brand voice and focus on the right metrics to track performance and growth. <br /><br /></li>
                                                       
                                                    </ol>
                                                </li>
                                                <li>
                                                The Impact of Social Media Marketing for our client: We reached 22,248 hearts with a women’s day campaign we ran for a client.
                                        </li>
                                            </ol>
                                        </li>
                                        <br /> <br />
                                        <li>Email Marketing - <br /> <br />
 
                                            <ol type="a">
                                                <li>
                                                Why Email Marketing is important: Email Marketing not only helps to get your message across to the customer and increases sales but also to build long term relationships with them.
                                                </li>
                                                <li>
                                                    Our Detailed Approach: <br /><br />
                                                    <ol type="i">
                                                        <li> Objective - To send emails to your customers, existing and potential, that not only win their trust but also help in increasing their lifetime value.<br /><br /></li>
                                                        <li> Audit - We don’t believe in sending the same emails to every customer, existing or potential. We make sure every messaging is personalized to the customer and their journey. This requires us to understand your users and categorize them on the basis of which stage they are in your marketing funnel and what is keeping them from going forward. <br /><br /></li>
                                                        <li> Execution - Sending appealing, personalized and interactive emails that help users complete their journey or solve their problems.<br /><br /></li>
                                                    </ol>
                                                </li>
                                                <li>
                                                The Impact of Email Marketing for our client:
                                                </li>
                                            </ol>
                                        </li>

                                        <br /> <br />
                                        <li>Influencer Marketing <br /> <br /> 
                                            <ol type="a">
                                                <li>
                                                Why Influencer Marketing is important: 70% of online users trust recommendations from individuals rather than from brands as it feels more relatable and genuine to them.
                                                </li>
                                                <li>
                                                    Our Detailed Approach: <br /><br />
                                                    <ol type="i">
                                                        <li> Objective - <br /><br /></li>
                                                        <li> Audit -  <br /><br /></li>
                                                        <li> Execution -  <br /><br /></li>
                                                    </ol>
                                                </li>
                                                <li>
                                                The Impact of Influencer Marketing for our client: We generated millions of YouTube views for a client through various regional influencers.

                                                </li> <br />
                                                <li>
                                                    This will go in CRO: For a fashion client, through Analytics, we analysed that the website wasn’t performing well on Safari browsers. On investigation further, we found that image extensions were not supported in Safari because of which users were not able to see the content. Resolving this increased our conversions massively.

                                                </li>
                                            </ol>
                                        </li>

                                        <br /> <br />
                                        <li>PPC Advertising - <br /> <br />

                                       
                                            <ol type="a">
                                                <li>
                                                Why PPC Advertising is important: Before making a luxury purchase, your audience goes through about 15 touchpoints half of which are digital and all of these can be advertised upon. <br /><br />
                                                </li>
                                                <li>
                                                    Our Detailed Approach: <br /><br />
                                                    <ol type="i">
                                                        <li> Objective - To be visible to all your potential customers online, across platforms.<br /><br /></li>
                                                        <li> Audit -  Before setting up any campaign, we make sure that we not just know but understand your audience and their behaviour across platforms.  <br /><br /></li>
                                                        <li> Execution - We don't go the traditional way of running ads to get immediate returns. With all your competitors running ads, we create long term plans that would not get you short-lived immediate returns but increase your ROAS overtime.<br /><br /></li>
                                                    </ol>
                                                </li>
                                                <li>
                                                The Impact of PPC Advertising for our client: One of our clients witnessed a 40% drop in their cost of acquisition through PPC.
                                                </li> <br />

                                            </ol>
                                        </li>
                                        <br /> <br />
                                        <li>Local SEO - <br /> <br />

                                            Do you know that 28% of store transactions comes from “Near me” queries?<br /><br />
                                            <ol type="a">
                                                <li>
                                                    Why Local SEO is important:<br /><br />
                                                    <ol type="i">
                                                        <li>  Local search queries help in driving traffic to your offline store, it’s important to target the right set of keywords which have the offline intent of visiting the store. <br /><br /></li>

                                                    </ol>
                                                </li>
                                                <li>
                                                    Our Detailed Approach: <br /><br />
                                                    <ol type="i">
                                                        <li> Objective - To drive footfalls from searches happening from local searches. Not just Google My Business but other avenues too like Bing Listings, Tripadvisor Listings, Yandex Maps, etc. wherever your target audience is present.<br /><br /></li>
                                                        <li> Audit -  Unanswered reviews, old images, missing phone numbers, etc. are common issues with most of the local listings today, these small tasks have a huge impact on the reach and ranking of your listings. New features which are constantly added in GMB’s are often missed by store owners <br /><br /></li>
                                                        <li> Execution - Analysing platforms & getting your local listings verified is the first step towards your local success. Populating correct data, updating them constantly with new features, and techniques to get reviews helps in your brand reputation and local rankings in search results. <br /><br /></li>
                                                    </ol>
                                                </li>
                                                <li>
                                                    Impact for our client:  All our clients having a physical presence have seen a 40-50% increase in high-value90 footfalls.
                                                </li> <br />

                                            </ol>
                                        </li>

                                    </ol>

                                </Row>
                            </Card.Body>
                        </Accordion.Collapse>

                    </Accordion>
                </Container>
                {/* <img src={`${process.env.PUBLIC_URL}/img/approach.png`} className="img-fluid" /> */}
            </div>
            {/* seo services */}
            {/* <div className="seoservices  bgb">
                <Container className="padmmdt  ">

                <h3 className="text-center txtcolor  padsmdb pdtb20">SUB <span>SERVICES</span></h3>
                    <Row className="padsmdb" xs={12}>
                        <Col lg="4" md="4" sm="4" xs="6" >
                            <div className="circleboxfirst  padssmb">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1><br /> <br /> Content<br /> Marketing</h1>
                                </div>
                            </div>
                             
                        </Col>
                        <Col lg="4" md="4" sm="4" xs="6" >
                            <div className="circleboxfirst  padssmb">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1><br /> <br /> Email   <br />  Marketing</h1>
                                </div>
                            </div>
 
                        </Col>
                        <Col lg="4" md="4" sm="4" xs="6" >
                            <div className="circleboxfirst  padssmb">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1><br /> <br /> Social Media <br /> Marketing</h1>
                                </div>
                            </div>
 
                        </Col>
                        <Col lg="4" md="4" sm="4" xs="6" >
                            <div className="circleboxfirst  padssmb">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1><br /> <br />  PPC <br /> Advertising</h1>
                                </div>
                            </div>
                             
                        </Col>
                        <Col lg="4" md="4" sm="4" xs="6" >
                            <div className="circleboxfirst  padssmb">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1><br /> <br /> Influencer <br />  Marketing</h1>
                                </div>
                            </div>
 
                        </Col>
                    </Row>
                  

                </Container>
            </div> */}
            {/* case study */}

            <div className="bgfooter whyus  padsmdt padbmdb">
                <Col lg="8" className="offset-lg-1" >
                    <h3 className=" txtcolor"><span className="spund">CASE</span> STUDY</h3>
                </Col>
                <Casestudy />
            </div>

            {/* 3 Reasons to choose */}

            <div className="bgb casestudy  ">

                <Reasons />
            </div>

            {/* our services */}
            <Services /> 
        </div>
    );
}


export default Digitalleadgeneration;

function Casestudy() {
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,

        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    adaptiveHeight: true,
                }
            },]
    };
    const next = () => {
        Slider.current.slickNext();
    }
    const previous = () => {
        Slider.current.slickPrev();
    }
    return (
        <Container fluid className="padmmdt casestudy ">
            <Row>
                <Col lg="10" className="offset-lg-1">
                    <Slider {...settings}>
                        <div>
                            <Row className="serbox">
                                <Col className="offset-lg-1" lg={{ span: 4, order: 'first' }} md="6" sm={{ span: 6, order: 'last' }} xs={{ span: 12, order: 'first' }}><img src={`${process.env.PUBLIC_URL}/img/atmantan-casestudy.png`} width="100%" className=" mx-auto d-block" /></Col>
                                <Col lg={{ span: 6, order: 'first' }} sm={{ span: 6, order: 'first' }} md="6" xs={{ span: 12, order: 'last' }} className="mbtxtcenter  offset-lg-1 padbsmb">
                                    <h1 className="padslgt padbxsb ">438% </h1>
                                    <h2 className="padbxsb text-center"><strong>jump in qualified <span>inquiries in 12 months</span></strong></h2>
                                    {/* <a href="" className="btn btn-primary ">Read Case Study</a> */}
                                    <Link to={`${process.env.PUBLIC_URL}/case-studies/online-lead-generation-case-study.html`} className="btn btn-seo " >Read Case Study</Link> 
                                </Col>

                            </Row>

                        </div>
                         

                    </Slider>
                </Col>
            </Row>
        </Container>
    );
}

// reasons
function Reasons() {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        adaptiveHeight: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false,
                    adaptiveHeight: true,
                }
            },],
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    adaptiveHeight: false,
                }
            },]
    };
    const next = () => {
        Slider.current.slickNext();
    }
    const previous = () => {
        Slider.current.slickPrev();
    }
    return (
        <Container fluid className="reasons padslgt padslgb casestudy ">
            <Row>
                <Col lg="10" className="offset-lg-1">
                    <Slider {...settings}>
                        <div>
                            
                            <Row>
                                <Col  className="offset-lg-1 offset-sm-1" lg={{ span: 4, order: 'first' }} md="4" sm={{ span: 3, order: 'first' }} xs={{ span: 12, order: 'first' }}><img src={`${process.env.PUBLIC_URL}/img/reasons3.png`} className="custmt80 padt130 padmmdt mimgw padmsmb marbsmt img-fluid mx-auto d-block" /></Col>
                                <Col className="marmlgt mt0" lg={{ span: 7, order: 'first' }} sm={{ span: 6, order: 'last' }} md="7" xs={{ span: 12, order: 'last' }}  >

                                    <h2 className="marmlgt"> Reasons to choose A&B for <br /><strong> Digital Lead Generation</strong></h2>

                                </Col>

                            </Row>

                        </div>
                        <div>
                            <Row>

                                <Col className="offset-lg-1 offset-sm-1" lg={{ span: 5, order: 'first' }} sm={{ span: 6, order: 'first' }}  xs={{ span: 12, order: 'last' }} >

                                <h2 className="padt190"><span>A&B Commitment</span></h2>
                                    <h3 className=" padmsmb">  If we don't generate <strong>growth</strong> in 3 months, <strong><i>we fire ourselves.</i></strong><br />There are 9 more weird things about us, <br />read them here.</h3>

                                </Col>
                                <Col className="offset-lg-1 mgm90" lg={{ span: 4, order: 'first' }}  sm={{ span: 4, order: 'last' }} xs={{ span: 12, order: 'first' }}>
                                    <img src={`${process.env.PUBLIC_URL}/img/commitment.png`} className="custmt80  padslg125 mimgw img-fluid mx-auto d-block" />
                                </Col>

                            </Row>
                        </div>
                        <div>
                            <Row>

                                <Col className="offset-lg-1 offset-sm-1" lg={{ span: 5, order: 'first' }} sm={{ span: 5, order: 'first' }} xs={{ span: 12, order: 'last' }}  >

                                    <h3 className="marblgt padmsmb padslg125 "> Specialised lead generation agency trusted by global brands</h3>

                                </Col>
                                <Col className=" mgm90" lg={{ span: 5, order: 'first' }}  sm={{ span: 5, order: 'last' }} xs={{ span: 12, order: 'first' }}>
                                    <img src={`${process.env.PUBLIC_URL}/img/worldmap.png`} className="custmt80 padslg125 padbsmb img-fluid mx-auto d-block" />
                                    <img src={`${process.env.PUBLIC_URL}/img/clientslogo.png`} className="padbsmb img-fluid mx-auto d-block" />
                                </Col>
                            </Row>
                        </div>
                       
                        <div>
                        <Row> 
                                <Col className="offset-lg-1 offset-md-0" lg={{ span: 10, order: 'first' }}  md={{ span: 12, order: 'first' }}  sm={{ span: 8, order: 'last' }} xs={{ span: 12, order: 'last' }}>
                                <h3 className="text-center htitle"><span>Don't believe us?</span></h3>
                                    <p className="text-center custml ">  Hear it from our partners.</p>
                                    <div className="feedbackbox marmsmt padmsmt padmsmb">
                                     <img src={`${process.env.PUBLIC_URL}/img/leftcoma.png`}  className="mgt40 img-fluid mx-auto d-block"/>
                                     <br />
                                        
                                        <h4 className="">As a luxury wellness brand, it is very hard to find partners who understand our audience. For the meteoric growth we have seen in the last 4 years, a sizeable credit goes to the performance marketing efforts of A&B.
                                        </h4>  
                                       
                                    </div>
                                    <div className="clientname">
                                    <img src={`${process.env.PUBLIC_URL}/img/clients/Atmantan_roundedlogo.png`} className="img-fluid mx-auto" width="160px" /> <br />
                                    <p className="text-center"> <i>- Nikhil Kapur - Founder, Atmantan</i></p>
                                    </div>
                                </Col>

                            </Row>
                            
                        </div>
                    </Slider>
                </Col>
            </Row>
        </Container>
    );
}