import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Nav, Container, Accordion, Card, Button, Col, Row, Tab, Carousel } from 'react-bootstrap';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Slider from "react-slick";
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";
import Services from './Services';
function Socialmediastrategy() {

    window.scrollTo(0, 0);
    const [focus, setFocus] = React.useState(false);
    return (

        <div>
            <Helmet>

                <title>Best Social Media Marketing Agency for *Luxury* Brands | A&B</title>
                <meta name="description" content="A social media agency for luxury brands in Mumbai. We help you break through the clutter on Instagram, Facebook or Twitter, anywhere." />
                <link rel="canonical" href="https://www.aandb.xyz/luxury-social-media-marketing-agency.html" /> 

            </Helmet>
            <div className="firstfoldseo ">
                <div className="box   padmmdb">
                    {/* first fold */}
                    <Container fluid className="padbsmt padbsmb">
                        <Row>
                            <Col lg="2"></Col>
                            <Col lg="8">
                                <div className="circleboxfirst  padsmdt padsmdb">
                                    <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="roundbx" width="30%" alt="" />

                                    <div className="boxcentered">
                                        <h4>
                                            Only  <span className="cuspn"><CountUp start={focus ? 0 : null} end={5} redraw={true}>
                                            {({ countUpRef }) => (
                                                <VisibilitySensor onChange={(isVisible) => {
                                                    if (isVisible) { setFocus(true); }
                                                }}>
                                                    <span ref={countUpRef} />
                                                </VisibilitySensor>
                                            )}
                                        </CountUp>% </span><span>luxury brands</span><br /> have followers that<br /> convert into customers.

                                        </h4>

                                    </div>

                                </div>
                                <AnchorLink href="#social" className="btn btn-seo" >Get Social With Us </AnchorLink>
                            </Col>
                            <Col lg="2"></Col>

                        </Row>
                    </Container>
                </div>
            </div>
            {/* Seo matters */}
            <div id="social" className="seomatters  bgb">
                <Container className="padbmdt padbmdb">

                    <h3 className="text-center txtcolor  padsmdb">Why your Luxury Brand should be on <span>Social Media</span></h3>
                    <Row className="padsmdb">
                        <Col className="offset-lg-2" lg="4" md="3" sm="3">
                            <div className="circleboxfirst  padssmb  pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="servicescirbox" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1>75%</h1>
                                </div>
                            </div>
                            <p>Consumers say that they have bought from luxury brands that they discovered on Instagram.</p><br />
                        </Col>
                        <Col lg="4" md="3" sm="3">
                            <div className="circleboxfirst  padssmb  pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="servicescirbox" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1>66%</h1>
                                </div>
                            </div>

                            <p>Marketers that spend at least 6 hours on social per week have seen more leads.</p><br />

                        </Col>
                        <Col className="offset-lg-2" lg="4" md="3" sm="3">
                            <div className="circleboxfirst  padssmb  pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="servicescirbox" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1>90%</h1>
                                </div>
                            </div>
                            <p>Online users follow a business on social media platforms.</p><br />

                        </Col>
                        <Col lg="4" md="3" sm="3">
                            <div className="circleboxfirst  padssmb  pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="servicescirbox" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1>53%</h1>
                                </div>
                            </div>
                            <p>Customers who follow your business are likely to be loyal to your business specifically.</p><br />

                        </Col>
                    </Row>

                </Container>
            </div>
            {/* our approach seo fold */}
            <div id="approach" className="seoapproach bgbl padmmdt padmmdb ">
                <Container>
                    <div className="appbox  padssmb d-none d-sm-block">
                        <img src={`${process.env.PUBLIC_URL}/img/approach2.png`} className="imgwidt img-fluid" alt="" />
                        <div className="top-left">
                            <h4><strong> Value,</strong><br /> <i>not Volume</i></h4>
                            <p>We focus on reaching the right audiences with your budget, <br />not just more audiences.</p>
                        </div>

                        <div className="top-right">
                            <h4><strong>Right Trends, </strong><br /> <i>Not all of them </i></h4>
                            <p>We jump on the right trends (that are relevant for the brand voice), not on the bandwagon.</p>
                        </div>

                        <div className="bottom-left">
                            <h4> <strong>Solutions,</strong><br /> <i>not Jargon</i></h4>
                            <p>We help your luxury brands with solutions that work for you (Influencer Marketing, Post Engagement Campaigns).</p>
                        </div>


                        <div className="bottom-right">
                            <h4><strong>Real Metrics,</strong><br /> <i>not BS</i></h4>
                            <p>We track growth metrics like Click Through Rate and Post Engagement, not BS metrics like Organic Reach, Fans and Likes.</p>
                        </div>
                        <div className="centered"><h1>OUR <span>APPROACH</span> <br />TO Social</h1></div>
                    </div>
                    <div className="appbox  padssmb d-block d-sm-none">
                        <h1>OUR <span>APPROACH</span> <br />Social</h1> <br />

                        <h4><strong> Value,</strong><br /> <i>not Volume</i></h4>
                        <p>We focus on reaching the right audiences with your budget, not just more audiences.</p>

                        <img src={`${process.env.PUBLIC_URL}/img/straightline.png`} className="img-fluid" />
                        <h4><strong>Right Trends, </strong><br /> <i>Not all of them </i></h4>
                        <p>We jump on the right trends (that are relevant for the brand voice), not on the bandwagon.</p>

                        <img src={`${process.env.PUBLIC_URL}/img/straightline.png`} className="img-fluid" />
                        <h4> <strong>Solutions,</strong><br /> <i>not Jargon</i></h4>
                        <p>We help your luxury brands with solutions that work for you (Influencer Marketing, Post Engagement Campaigns).</p>
                        <img src={`${process.env.PUBLIC_URL}/img/straightline.png`} className="img-fluid" />
                        <h4><strong>Real Metrics,</strong><br /> <i>not BS</i></h4>
                        <p>We track growth metrics like Click Through Rate and Post Engagement, not BS metrics like Organic Reach, Fans and Likes.</p>
                    </div>

                    <Accordion defaultActiveKey="0" className="seoapproach1 marbxst">

                        <Accordion.Toggle as={Button} variant="read" eventKey="1">
                            Our approach in detail
                        </Accordion.Toggle>

                        <Accordion.Collapse eventKey="1">
                            <Card.Body>
                                <h3 className="text-center txtcolor  padsmdb"> <span>Social Media Marketing Services</span></h3>
                                <Row>
                                    <ol>
                                        <li>
                                            Facebook <br />
                                            Why is Facebook important
                                        </li>
                                        <li>
                                            Our Detailed Approach: <br /><br />
                                            Objective -<br /><br />
                                            Audit-<br /><br />
                                            Execute -<br /><br />


                                        </li>

                                    </ol>

                                </Row>
                            </Card.Body>
                        </Accordion.Collapse>

                    </Accordion>
                </Container>
                {/* <img src={`${process.env.PUBLIC_URL}/img/approach.png`} className="img-fluid" /> */}
            </div>
            {/* seo services */}
            <div className="seoservices  bgb">
                <Container className="padmmdt  ">

                    <h3 className="text-center txtcolor  padsmdb"><span>Social Media </span> Marketing Services </h3>
                    <Row xs={12}>
                        <Col lg="4" md="4" sm="4" xs="6" >
                            <div className="circleboxfirst  padssmb">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw" width="40%" alt="" />
                                <div className="boxcentered sc d-none d-sm-block"><h1>Facebook </h1> <br /><br /> </div>
                                <div className="boxcentered d-block d-sm-none"><h1>Facebook </h1><br />  </div>
                                <p><br />We know how this giant works, our Facebook experts would help you navigate this mammoth of a social media business.</p>
                            </div>

                        </Col>
                        <Col lg="4" md="4" sm="4" xs="6" >
                            <div className="circleboxfirst  padssmb">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw" width="40%" alt="" />
                                <div className="boxcentered sc d-none d-sm-block">  <h1>Twitter </h1><br /><br /> </div>
                                <div className="boxcentered  d-block d-sm-none"><h1>Twitter </h1><br />  </div>
                                <p><br />Getting your business to become a thing for the tweeps is our business, you get on their phones in no time. </p>
                            </div>

                        </Col>
                        <Col lg="4" md="4" sm="4" xs="6" >
                            <div className="circleboxfirst  padssmb">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw" width="40%" alt="" />
                                <div className="boxcentered sc d-none d-sm-block"> <h1>Instagram</h1><br /><br /></div>
                                <div className="boxcentered  d-block d-sm-none"> <h1>Instagram </h1><br /><br /></div>
                                <p><br />We know how to best showcase your luxury brand on this ever-growing & increasing platform. Content Creation & Promotion, Influencer Marketing are just some of the things we excel at.</p>
                            </div>

                        </Col>

                        <Col lg="4" md="4" sm="4" xs="6" >
                            <div className="circleboxfirst  padssmb">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw" width="40%" alt="" />
                                <div className="boxcentered  sc d-none d-sm-block"> <h1>LinkedIn </h1><br /><br /></div>
                                <div className="boxcentered d-block d-sm-none"> <h1>LinkedIn</h1><br /><br /></div>
                                <p><br />Want to reach the corporate audience on LinkedIn? Our LinkedIn experts would help you unleash the potential of this underestimated social platform.</p>
                            </div>

                        </Col>
                        <Col lg="4" md="4" sm="4" xs="6" >
                            <div className="circleboxfirst  padssmb">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw" width="40%" alt="" />
                                <div className="boxcentered sc d-none d-sm-block"><h1>YouTube</h1> <br /> <br /></div>
                                <div className="boxcentered  d-block d-sm-none"><h1>YouTube</h1>  <br /></div>
                                <p><br />We build your brand story in a video which can reach over a Billion people on YouTube. We have an in-house production studio dedicated to this. </p>
                            </div>

                        </Col>
                        <Col lg="4" md="4" sm="4" xs="6" >
                            <div className="circleboxfirst  padssmb">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw" width="40%" alt="" />
                                <div className="boxcentered sc d-none d-sm-block"><h1>Pinterest</h1><br /><br /> </div>
                                <div className="boxcentered  d-block d-sm-none"><h1>Pinterest</h1><br /></div>
                                <p><br />We help your luxury fashion and makeup brands reach the right audiences with Pinterest, where 64% of the users are women.</p>
                            </div>

                        </Col>

                        <Col lg="4" md="4" sm="4" xs="6" >
                            <div className="circleboxfirst  padssmb">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw" width="40%" alt="" />
                                <div className="boxcentered sc d-none d-sm-block"><h1><br />Snapchat </h1><br /><br /></div>
                                <div className="boxcentered d-block d-sm-none"><h1>Snapchat </h1><br /></div>
                                <p><br />We understand when it would be most relevant to use this platform as it has a very specific demographic - Generation Z. </p>
                            </div>

                        </Col>


                    </Row>


                </Container>
            </div>

            {/* case study */}

            <div className="bgfooter whyus  padsmdt padbmdb">
                <Col lg="8" className="offset-lg-1" >
                    <h3 className=" txtcolor"><span className="spund">CASE</span> STUDY</h3>
                </Col>
                <Casestudy />
            </div>

            {/* 3 Reasons to choose */}

            <div className="bgbl  ">

                <Reasons />
            </div>

            {/* our services */}
            <Services />
        </div>
    );
}


export default Socialmediastrategy;

// casestudy

function Casestudy() {
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,

        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    adaptiveHeight: true,
                }
            },]
    };
    const next = () => {
        Slider.current.slickNext();
    }
    const previous = () => {
        Slider.current.slickPrev();
    }
    return (
        <Container fluid className="padmmdt casestudy ">
            <Row>
                <Col lg="10" className="offset-lg-1">
                    <Slider {...settings}>
                        <div>
                            <Row>
                                <Col className="offset-lg-1" lg={{ span: 4, order: 'first' }} md="6" sm={{ span: 6, order: 'last' }} xs={{ span: 12, order: 'first' }}><img src={`${process.env.PUBLIC_URL}/img/evokcarousel.png`} width="100%" className=" mx-auto d-block" /></Col>
                                <Col lg={{ span: 6, order: 'first' }} sm={{ span: 6, order: 'first' }} md="6" xs={{ span: 12, order: 'last' }} className="offset-lg-1 padbsmb">
                                    <h1 className="padslgt padbxsb">1.47 m</h1>
                                    <h2 className="padbxsb"> targeted users engaged through <strong><span>social media.</span></strong></h2>
                                    {/* <a href="" className="btn btn-primary ">Read Case Study</a> */}
                                    <Link to={`${process.env.PUBLIC_URL}/case-studies/social-media-case-study-evok-furnitures.html`}  className="btn btn-seo " >Read Case Study</Link> 
                                </Col>

                            </Row>

                        </div>


                    </Slider>
                </Col>
            </Row>
        </Container>
    );
}

// reasons
function Reasons() {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        adaptiveHeight: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false,
                    adaptiveHeight: true,
                }
            },],
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    adaptiveHeight: false,
                }
            },]
    };
    const next = () => {
        Slider.current.slickNext();
    }
    const previous = () => {
        Slider.current.slickPrev();
    }
    return (
        <Container fluid className="reasons padslgt padslgb casestudy ">
            <Row>
                <Col lg="10" className="offset-lg-1">
                    <Slider {...settings}>
                        <div>
                            <Row>
                                <Col className="offset-lg-1 offset-sm-1" lg={{ span: 4, order: 'first' }} md="4" sm={{ span: 3, order: 'first' }} xs={{ span: 12, order: 'first' }}><img src={`${process.env.PUBLIC_URL}/img/reasons3.png`} className="custmt80 padt130 padmmdt mimgw padmsmb marbsmt img-fluid mx-auto d-block" /></Col>
                                <Col className="marmlgt mt0" lg={{ span: 7, order: 'first' }} sm={{ span: 6, order: 'last' }} md="7" xs={{ span: 12, order: 'last' }}  >

                                    <h2 className="marmlgt"> Reasons to choose A&B <strong>for social</strong></h2> <br />

                                </Col>

                            </Row>


                        </div>
                        <div>
                            <Row>

                                <Col className="offset-lg-1 offset-sm-1" lg={{ span: 5, order: 'first' }} sm={{ span: 6, order: 'first' }} xs={{ span: 12, order: 'last' }} >

                                <h2 className="padt190"><span>A&B Commitment</span></h2>
                                    <h3 className=" padmsmb">  If we don't generate <strong>growth</strong> in 3 months, <strong><i>we fire ourselves.</i></strong><br />There are 9 more weird things about us, <br />read them here.</h3>

                                </Col>
                                <Col className="offset-lg-1 mgm90" lg={{ span: 4, order: 'first' }} sm={{ span: 4, order: 'last' }} xs={{ span: 12, order: 'first' }}>
                                    <img src={`${process.env.PUBLIC_URL}/img/commitment.png`} className="custmt80  padslg125 mimgw img-fluid mx-auto d-block" />
                                </Col>

                            </Row>

                        </div>
                        <div>
                            <Row>

                                <Col className="offset-lg-1 offset-sm-1" lg={{ span: 5, order: 'first' }} sm={{ span: 5, order: 'first' }} xs={{ span: 12, order: 'last' }} >

                                    <h3 className="marblgt padmsmb padslg125 "> Specialised Social Media agency trusted by global brands</h3>

                                </Col>
                                <Col className=" mgm90" lg={{ span: 5, order: 'first' }} sm={{ span: 5, order: 'last' }} xs={{ span: 12, order: 'first' }}>
                                    <img src={`${process.env.PUBLIC_URL}/img/worldmap.png`} className="custmt80 padslg125 padbsmb img-fluid mx-auto d-block" />
                                    <img src={`${process.env.PUBLIC_URL}/img/clientslogo.png`} className=" img-fluid mx-auto d-block" />
                                </Col>

                            </Row>

                        </div>
                        
                        <div>
                            <Row>
                                <Col className="offset-lg-1 offset-md-0" lg={{ span: 10, order: 'first' }} md={{ span: 12, order: 'first' }} sm={{ span: 8, order: 'last' }} xs={{ span: 12, order: 'last' }}>
                                    <h3 className="text-center htitle"><span>Don't believe us?</span></h3>
                                    <p className="text-center custml ">  Hear it from our partners.</p>
                                    <div className="feedbackbox marmsmt padmsmt padmsmb">
                                        <img src={`${process.env.PUBLIC_URL}/img/leftcoma.png`} className="mgt40 img-fluid mx-auto d-block" />
                                        <br />
                                        {/* <img src={`${process.env.PUBLIC_URL}/img/paytminsiderlogo.png`} className="img-fluid mx-auto d-block padmsmt padmsmb" /> */}
                                        <h4 className="">When we started working with A&B, we were digital laggards, now we are leaders. Not just in community size, but also the impact on walkins. We have often argued & disagreed but we have reached here together :)
                                        </h4>
                                        {/* <img src={`${process.env.PUBLIC_URL}/img/1seagallery.png`} className="rounded-circle img-fluid mx-auto d-block" /> */}
                                        {/* <img src={`${process.env.PUBLIC_URL}/img/rightcoma.png`}  className="flr" /> */}
                                    </div>
                                    <div className="clientname">
                                        <img src={`${process.env.PUBLIC_URL}/img/clients/Oberoimall_roundedlogo.png`} className="img-fluid mx-auto" width="160px" /> <br />
                                        <p className="text-center"> <i>- Anuj Arora, General Manager, Oberoi Mall</i></p>
                                    </div>
                                </Col>

                            </Row>

                        </div>
                    </Slider>
                </Col>
            </Row>
        </Container>
    );
}