import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container, Button, Col, Row } from 'react-bootstrap'; 
import Slider from "react-slick";
import CountUp from 'react-countup';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import VisibilitySensor from "react-visibility-sensor";
function Evok() {
    window.scrollTo(0, 0);
    const [focus, setFocus] = React.useState(false);
    return (
        <div>
            <Helmet>

                <title>Furniture Ecommerce Case Study - Scaled Revenue by 2404%</title>
                <meta name="description" content="" />
                <link rel="canonical" href="https://www.aandb.xyz/case-studies/scaling-evok-ecommerce-revenue.html" />
            </Helmet>
            <div className="casestudy">
                <div className="box padmmdt padmmdb">
                    {/* first fold */}
                    <Container>

                        <p className="text-left p1 pdl10"> <span class="spcase">EV</span>OK</p>
                    </Container>

                    <Container fluid className="padbsmt padbsmb">
                        <Row>
                            <Col lg="2"></Col>
                            <Col lg="8">
                                <div className="circleboxfirst  padssmb">
                                    <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`}  width="30%" alt="" />

                                    <div className="boxcentered">
                                        <h4> Scaled  Ecommerce<br /> Revenue by <span>2404%</span>
                                        </h4>

                                    </div>

                                </div>

                            </Col>
                            <Col lg="2"></Col>

                        </Row>
                    </Container>
                </div>
            </div>
            {/* The problem fold */}
            <div className="lgbox padmmdt padmmdb problem">
                {/* first fold */}

                <Container fluid className="">
                    <Row>

                        <Col lg="4" md="4" sm="6" xs="12" className="casebox offset-lg-1 ">
                            <p className="text-left p1 marbmdb mbb50"> <span class="spcase">THE</span> PROBLEM</p>
                            <p className="p2">
                                <span>Evok</span> is a furniture retailer <span> by Hindware.</span>
                            </p>
                            <p className="p2">
                                When we started working with them in 2016, Evok was getting traffic on the site but was struggling to make sales. They were not able to recover even the technology costs.
                            </p>
                            <p className="p2">
                                With almost no presence on social media and therefore no brand recall, Evok had hardly any brand searches and competitors were outranking them for all relevant category searches.
                            </p>

                        </Col>

                        <Col lg={{ span: 7, order: 'last' }} md="6" sm={{ span: 6, order: 'last' }} xs={{ span: 12, order: 'first' }} className="marmsmb">

                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-ecommerce/1.png`} width="100%" height="100%" className="text-right" />
                        </Col>

                    </Row>
                </Container>
            </div>
            {/* Marketing Insights  */}

            <Container fluid className=" box padbmdt padbmdb">
                <div className="seomatters ">
                    <Row>
                        <Col lg="11" className="casebox offset-lg-1">
                            <p className="text-left p1 marmsmb "> <span class="spcase">Mar</span>ket Insights/ Opportunities:</p>
                        </Col>
                    </Row>
                    <div className="marketing">
                        <h3 className="text-center">The furniture market has two audiences:-</h3>
                    </div>
                    <Row className="padsmdt padsmdb mbpdt30 marketing">

                        <Col className="offset-lg-2" lg="4" md="4" sm="6" xs="12">
                            <div className="circleboxfirst  padssmb">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1 className="boxtitle"><br /><br />Research online<br /> buy online </h1>
                                    <p> Comprises 5% of the market,<br /> but is growing fast.</p>
                                </div>
                            </div>
                            <p>They didn’t see Evok as a pure online player<br /> because of poor website experience.</p>
                        </Col>
                        <hr className="mdbordermar d-block d-sm-none" />
                        <Col lg="4" md="4" sm="6" className="bordle">
                            <div className="circleboxfirst  padssmb">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1 className="boxtitle"><br /><br />Research online<br /> buy offline  </h1>
                                    <p> Comprises 95% of the market</p>
                                </div>
                            </div>
                            <p>They weren’t coming to Evok because of a lack of discovery. Evok was advertising all over India but had stores in only 17 cities.</p>
                        </Col>

                    </Row>

                </div>
                <div className="padsmdt padsmdb mbpdt30 marketing">
                    <h3 className="text-center">We identified blockers of growth. Primarily they were:-</h3>

                    <Col className="offset-lg-3 offset-sm-2" lg="9" md="10" sm="10">

                        <ul className="text-left">
                            <li>Poor Tech Stack</li>
                            <li>Catalogue not customised for online</li>
                            <li>Poor website experience and terrible mobile experience</li>
                            <li>Omnichannel integration was missing</li>
                            <li>No buying guidance for the users</li>
                            <li>Underutilised Social media channels</li>
                            <li>Lack of trust & connect with the parent brand</li>
                        </ul>

                    </Col>
                    <h3 className="text-center">We tapped these opportunities and worked on building<br /> and improving the website experience over time. </h3>

                </div>


            </Container>
            {/* The Build */}
            <div className="lgbox build">
                <Container fluid className="lgbox padmmdt padmmdb mbpdt30">

                    <p className="text-left p1 marbmdb mgbtom30 offset-lg-1"> <span class="spcase">THE</span> BUILD</p>
                    <Row className=" ">

                        <Col lg="5" sm="5" xs="12" className="casebox offset-lg-1 offset-sm-1">

                            <div className="d-none d-sm-block"><span className="aplabox ">a</span></div>
                            <h3 className="marmsmt marmsmb d-none d-sm-block ">
                                Fixing the Fundamentals
                            </h3>
                            <p className="p2">
                                Our approach was to start by focusing on creating a bug-free website experience for our users on both desktop and mobile, improving website speed, and over a period of time adding enhancements that improved our conversion rate. We also got the site revamped with a much better UI, intuitive navigation, easy checkout, and more enhanced features.
                            </p>

                        </Col>

                        <Col lg={{ span: 5, order: 'last' }} md="5" sm={{ span: 6, order: 'last' }} xs={{ span: 12, order: 'first' }}>
                            <h3 className="marmsmt marmsmb  d-block d-sm-none">
                                Fixing the Fundamentals
                            </h3>
                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-ecommerce/2.png`} className="buildimg mx-auto img-fluid text-right mgbtom30" />
                        </Col>

                    </Row>
                </Container>
            </div>
            <div className="box build">
                <Container fluid className="padmmdt padmmdb mbpdt30">

                    {/* b */}
                    <Row className="padmmdb mbpdt30">

                        <Col lg="5" sm="5"  className="casebox offset-lg-1 offset-sm-1">

                            <div className="d-none d-sm-block"> <span className="aplabox marbmdt">b</span></div>
                            <h3 className="marmsmt marmsmb  d-none d-sm-block ">
                                Developing a brand voice and improving brand recall
                            </h3>
                            <p className="p2">
                                Simultaneously we started building our social presence by regularly posting and promoting quality content across all the relevant social media platforms which not only resonated with our audience but also followed the latest social trends and conversations.
                            </p>

                        </Col>

                        <Col lg={{ span: 5, order: 'last' }} md="5" sm={{ span: 6, order: 'last' }} xs={{ span: 12, order: 'first' }} >
                            <h3 className="marmsmt marmsmb  d-block d-sm-none">
                                Developing a brand voice and improving brand recall
                            </h3>
                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-ecommerce/3.png`} className="buildimg mx-auto img-fluid text-right mgbtom30" />
                        </Col>

                    </Row>
                </Container>
            </div>
            <div className="lgbox build">
                <Container fluid className="padmmdt padmmdb mbpdt30">

                    {/* c */}
                    <Row className="padmmdb mbpdt30">

                        <Col lg="5" sm="5"  className="casebox offset-lg-1 offset-sm-1">

                            <div className="d-none d-sm-block">  <span className="aplabox marbmdt">c</span></div>
                            <h3 className="marmsmt marmsmb  d-none d-sm-block">
                                Adding relevant content to the site
                            </h3>

                            <p className="p2">
                                The major thing that was missing on the site was content. We made sure to add sufficient content in all the right places, like detailed product category and specification content on the footer covering all relevant keywords, introductory content at the top of all category pages, and product descriptions, all with effective internal linking.
                                We also started updating monthly user-centric blog posts which also suggested our top products related to the topic.
                            </p>
                            <span className="relevantbox d-none d-sm-block ">Introductory content on category pages </span>  
                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-ecommerce/4.png`} className="padmxst  mx-auto img-fluid text-right d-none d-sm-block" />
                        </Col>

                        <Col lg={{ span: 5, order: 'last' }} md="5" sm={{ span: 6, order: 'last' }} xs={{ span: 12, order: 'first' }}>
                            <h3 className="marmsmt marmsmb  d-block d-sm-none">
                                Adding relevant content to the site
                            </h3>
                            <span className="relevantbox d-block d-sm-none">Introductory content on category pages</span> 
                            <span className="relevantbox d-none d-sm-block">Footer content on category pages  </span> 
                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-ecommerce/4.png`} className="mbpdt30 mx-auto img-fluid text-right  d-block d-sm-none" />
                            <span className="relevantbox d-block d-sm-none">Footer content on category pages </span> 
                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-ecommerce/5.png`} className="padmxst  mbpdt30 buildimg mx-auto img-fluid text-right" />
                        </Col>
                    </Row>



                </Container>
                <span className="relevantbox ">Blogs</span>
                <Container className="padmsmt padmmdb mbpdt30 pdlr0 d-none d-sm-block">
                    <Row className="pdlr0">
                  
                        <Col lg="3" sm="3" className="pdlr5">

                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-ecommerce/6.png`} className="mx-auto img-fluid text-right" />
                        </Col>
                        <Col lg="3" sm="3" className="pdlr5">

                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-ecommerce/7.png`} className="mx-auto img-fluid text-right" />
                        </Col>
                        <Col lg="3" sm="3" className="pdlr5">

                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-ecommerce/8.png`} className="mx-auto img-fluid text-right" />
                        </Col>
                        <Col lg="3" sm="3" className="pdlr5">

                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-ecommerce/9.png`} className="mx-auto img-fluid text-right" />
                        </Col>
                    </Row>
                </Container>
                {/* above mobile carousel */}
                <div className="d-block d-sm-none mbpdt30" >
                    <Blogs />
                </div>
            </div>
            {/* d and e */}
            <Container fluid className="dande padbmdt padbmdb pdlr0 mbpdt30">


                <Row className=" padsmdt padsmdb marketing" >

                    <Col lg="5" md="5" sm="5" className="offset-md-1 " >
                        <div className="d-none d-sm-block">  <span className="aplabox marbmdt text-left">d</span></div>
                        <br /> <br />
                        <h3>Improving Store walk-ins and in turn getting more online orders</h3>
                        <br />
                        <p className="para2">
                            Through effective local SEO including optimization of our listings and regular posts on GMB, hyperlocal ads aimed at driving people to their nearby Evok stores and the Pay at Store option on the site which let users place their orders online but make their payments at their nearest store within 3 days of placing the order so as to experience the look and feel of their purchase before making the final payment, we successfully improved store walk-ins which in turn helped in building our online customer base.
                        </p>

                    </Col>
                    <Col lg="5" md="5" sm="5" className="">
                        <div className="d-none d-sm-block">  <span className="aplabox marbmdt text-left">e</span></div>
                        <br />  <br />
                        <h3>Targeting the right users</h3>
                        <br />
                        <p className="para2">
                            We made sure to not just send truck-loads of traffic to the site which might be irrelevant to the brand but to target users with the right purchasing power and intent. For example, we noticed that the income groups lower than 11-20% were not converting so we excluded them and just targeted high-income groups and users from locations that showed a history of conversions. The next step was to remarket to them to build relationships and repeat purchases.
                        </p>

                    </Col>

                </Row>

            </Container>
            {/* f fold */}
            <div className="lgbox build">
                <Container fluid className="lgbox pdlr0">
                    <Row className=" padmmdt padmmdb  mgpb30">

                        <Col lg="5" sm="5" className="casebox offset-lg-1 offset-sm-1">

                            <div className="d-none d-sm-block">  <span className="aplabox">f</span></div>
                            <h3 className="marmsmt marmsmb d-none d-sm-block">
                                Negative review management
                            </h3>
                            <p className="p2">
                                With increased traffic and conversions came increased reviews, both positive and negative. We noticed that a lot of users were searching for queries such as “evok reviews” for which our negative reviews on online review directories such as Mouthshut were ranking. To deal with this, we collected all our positive reviews from various sources and listed them together on a review page that we created on the site. We optimised this page and it slowly started ranking for the same search term and outranked all our negative reviews.
                            </p>

                        </Col>

                        <Col lg={{ span: 5, order: 'last' }} md="5" sm={{ span: 6, order: 'last' }} xs={{ span: 12, order: 'first' }}>
                            <h3 className="marmsmt marmsmb d-block d-sm-none">
                                Negative review management
                            </h3>
                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-ecommerce/10.png`} className="mgbtom30 mx-auto img-fluid text-right" />
                        </Col>

                    </Row>
                </Container>
            </div>
            {/* g h fold */}
            <div className="box build">     
            <Container fluid className="pdlr0">
                    <Row className="text-left padmmdt  mbpdt30 mpdt0">
                        <Col lg="8" md="8"  sm="10" className="offset-lg-1 offset-sm-1">
                            <div className="d-none d-sm-block">  <span className="aplabox">g</span></div>

                            <h3 className="marmsmt marmsmb">
                                Reducing customer complaints on social media
                            </h3>
                            <p className="p2">
                                We took measures to reduce customer complaints on social media through effective communication on the site and emails around the delay in delivery timelines which was one of the major cause of the complaints, and directing users to the right channels and levels of grievance management through our Facebook chatbot, along with of course getting the customer care team to improve their turnaround time.
                            </p>
                        </Col>
                    </Row>
                    <br />

                    <Row className="text-left   padmmdb mbpdt30 mpdt0">
                        <Col lg="5" sm="5" className="offset-lg-1 offset-sm-1">
                            <div className="d-none d-sm-block">  <span className="aplabox">h</span></div>
                            <h3 className="marmsmt marmsmb  d-none d-sm-block">
                                Whatsapp Live Chat
                            </h3>

                            <p className="p2">
                                We added a floating Whatsapp widget on the site which lets users message their queries to us and get answers in real-time. This not only helped our customers but also helped us get more clarity on what our users were looking for, where they were getting stuck, what factors helped them make a choice and where were we missing out. Based on these insights, we made a number of improvements on the site and in our communication. The Whatsapp Live Chat option helped us in assisting a lot of conversions on the website.

                            </p>
                        </Col>
                        <Col lg={{ span: 5, order: 'last' }} sm={{ span: 5, order: 'last' }}   xs={{ span: 12, order: 'first' }}>
                            <br />
                            <h3 className="marmsmt marmsmb d-block d-sm-none">
                                Whatsapp Live Chat
                            </h3>
                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-ecommerce/11.png`} className="mbg30 marslgt mx-auto img-fluid text-right" />
                        </Col>
                    </Row>
                </Container>

            </div>
            <div className="lgbox build">
                <Container className="lgbox">
                    <div className="text-left padmmdt padmmdb mbpdt30 ">
                        <Row>
                            <Col lg="6" sm="6">
                                <br />
                                <h3 className="marmsmt marmsmb d-block d-sm-none">
                                    Leveraging Hindware’s Trust
                                </h3>
                                <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-ecommerce/12.png`} className=" mx-auto img-fluid text-right" />
                            </Col>
                            <Col lg="6" sm="6">
                                <div className="d-none d-sm-block">  <span className="aplabox">i</span></div>

                                <h3 className="marmsmt marmsmb  d-none d-sm-block">
                                    Leveraging Hindware’s Trust
                                </h3>

                                <p className="p2">
                                    Evok being a relatively new brand in the furniture industry was competing against older and bigger players in the market. We realised that users often didn’t know that Evok belonged to the house of Hindware, they probably missed that in the logo. So we started highlighting the same in our advertising communications and also on the website, on product pages and the checkout page. Leveraging Hindware’s Trust, helped us improve our conversion rate.
                                </p>
                            </Col>

                        </Row>
                    </div>
                </Container>
            </div>
            {/* The numbers */}
            <div className="box padmmdt  mbpdt30 ">

                <Container fluid className="numbers ">

                    <Row>

                        <Col lg="5" sm="6" className="casebox offset-lg-1 offset-sm-1">
                            <p className="text-left p1 marmsmb "> <span class="spcase">THE</span> NUMBERS</p>
                            <ul className="text-left  marbmdb marmmdt mt30">
                                <li>Over the years, our total traffic scaled by <span className="tcgold">951%</span><br /> and our organic traffic scaled by <span className="tcgold">591%</span></li>
                                <li>We started <span className="tcgold">ranking for almost all relevant <br />keywords</span> on the first few pages of the SERP.</li>
                                <li>Our social media followers shot across<br /> platforms. Today we have <span className="tcgold">11,100</span> followers on<br /> <span className="tcgold">Instagram, 110,042</span> on <span className="tcgold">Facebook</span>, and <span className="tcgold">965</span> on<br /> <span className="tcgold">Twitter</span>.</li>
                                <li>Our revenue scaled by  <span className="tcgold">2404%</span> (with an <br /> <span className="tcgold">avg 108%</span> YOY increase)</li>
                            </ul>
                        </Col>

                        <Col  lg={{ span: 6, order: 'last' }} sm={{ span: 5, order: 'last' }}   xs={{ span: 12, order: 'first' }}>
                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-ecommerce/13.png`} className="mbg30 mx-auto img-fluid text-right" />
                        </Col>

                    </Row>
                </Container>
            </div>
            {/* case study prev end */}
            <div className="lgbox build endbox">
                <Container fluid className="pdlr0">
                    <Row className="text-left  mglr0">

                    <Col lg="6" sm="6" xs="12">
                        <Link to={`${process.env.PUBLIC_URL}/case-studies/content-marketing-case-study-increase-in-pageviews.html`}>
                            <Row className="marssmt marssmb mglr0">
                                <Col lg="3" sm="3" xs="3" >
                                    <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/footerlogo/blabel.png`} className="mx-auto img-fluid text-right" />
                                </Col>
                                <Col lg="5" sm="6" xs="7">
                                    
                                    <h4 className="tcgold">BLABEL</h4>
                                    <p>1043% increase in unique blogs pageviews</p>
                                </Col>
                                <Col lg="2" sm="3" xs="2" className="marbxst">
                                    <img src={`${process.env.PUBLIC_URL}/img/righticon.png`} width="40%" className=" text-right" />
                                </Col>
                            </Row>
                            </Link>
                        </Col>
                        <Col lg="6" sm="6" xs="12" className="bgbl " >
                        <Link to={`${process.env.PUBLIC_URL}/case-studies/seo-case-study-for-event-ticketing-company.html`}>
                            <Row className="marssmt marssmb pdlr0 mglr0">
                                <Col lg="3" sm="3" xs="3" >
                                    <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/footerlogo/insider.png`} className="mx-auto img-fluid text-right" />
                                </Col>
                                <Col lg="4" sm="6" xs="7">
                                     
                                    <h4 className="tcgold">INSIDER </h4>
                                    <p>430% Increase in Organic Traffic</p>
                                </Col>
                                <Col lg="2" sm="3" xs="2" className="marbxst">
                                    <img src={`${process.env.PUBLIC_URL}/img/righticon.png`} width="40%" className=" text-right" />
                                </Col>
                            </Row>
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

// reasons
function Blogs() {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false,
                    adaptiveHeight: false,
                }
            },],
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    adaptiveHeight: true,
                }
            },]
    };
    const next = () => {
        Slider.current.slickNext();
    }
    const previous = () => {
        Slider.current.slickPrev();
    }
    return (
        <Container fluid className="reasons blogs  padmmdb casestudy ">
            <Row>
                <Col lg="10" className="offset-lg-1">
                    <Slider {...settings}>
                        <div>
                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-ecommerce/6.png`} className="mx-auto img-fluid mx-auto" />
                        </div>
                        <div>
                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-ecommerce/7.png`} className="mx-auto img-fluid mx-auto" />

                        </div>
                        <div>
                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-ecommerce/8.png`} className="mx-auto img-fluid mx-auto" />
                        </div>
                        <div>
                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-ecommerce/9.png`} className="mx-auto img-fluid mx-auto" />

                        </div>
                    </Slider>
                </Col>
            </Row>
        </Container>
    );
}
export default Evok;