import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Nav, Container, Accordion, Card, Button, Col, Row, Tab, Carousel } from 'react-bootstrap';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Slider from "react-slick"; 
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";
import Services from './Services';
function Digitalbrandbuilding() {
     window.scrollTo(0, 0);
     const [focus, setFocus] = React.useState(false);
    return (
        <div>
            <Helmet>

                <title>Best Brand Building Agency for *Luxury* Brands | A&B</title>
                <meta name="description" content="55%  of all Luxury Branding reaches the wrong audience" />
                <link rel="canonical" href="https://www.aandb.xyz/luxury-digital-branding-agency.html" /> 

            </Helmet>
            <div className="firstfoldseo ">
                <div className="box  padmmdb">
                    {/* first fold */}
                    <Container fluid className="padbsmt padbsmb">
                        <Row>
                            <Col lg="2"></Col>
                            <Col lg="8">
                                <div className="circleboxfirst  padsmdt padsmdb">
                                    <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="roundbx"  width="30%" alt="" />

                                    <div className="boxcentered">
                                        <h4> 
                                        <span  className="cuspn">
                                        <CountUp start={focus ? 0 : null} end={55} redraw={true}>
                                            {({ countUpRef }) => (
                                                <VisibilitySensor onChange={(isVisible) => {
                                                    if (isVisible) { setFocus(true); }
                                                }}>
                                                    <span ref={countUpRef} />
                                                </VisibilitySensor>
                                            )}
                                        </CountUp>%</span> of all Luxury Branding<br /> reaches the wrong audience
                                     </h4>

                                    </div>

                                </div>
                                <AnchorLink href="#luxury" className="btn btn-seo" >Fix your Luxury Branding </AnchorLink>
                            </Col>
                            <Col lg="2"></Col>

                        </Row>
                    </Container>
                </div>
            </div>
            {/* Seo matters */}
            <div id="luxury" className="seomatters  bgb">
                <Container className="padbmdt padbmdb">

                    <h3 className="text-center txtcolor  padsmdb">Why <span>Digital Brand building</span> for luxury brands should be done differently?</h3>
                    <Row className="padsmdb">
                        <Col lg="4" md="4" sm="4">
                            <div className="circleboxfirst  padssmb  pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`}   className="servicescirbox" width="40%" alt="" />
                                <div className="boxcentered">
                                <h1>80%</h1>
                                </div>
                            </div>
                            <p>of luxury marketers feel they are attracting the wrong audience to their brands. </p><br />
                        </Col>
                        <Col lg="4" md="4" sm="4">
                            <div className="circleboxfirst  padssmb  pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`}  className="servicescirbox" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1>85%</h1>
                                </div>
                            </div>

                            <p>of luxury consumers are very particular about the brands they follow.</p><br />

                        </Col>
                        <Col lg="4" md="4" sm="4">
                            <div className="circleboxfirst  padssmb  pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`}  className="servicescirbox" width="40%" alt="" />
                                <div className="boxcentered">
                                <h1>95%</h1>
                                </div>
                            </div>

                            <p>of luxury consumers say they never engage with branded content.</p>

                        </Col>
                    </Row>
                    <AnchorLink href="#approach" className="btn btn-seo" >Let's do it!</AnchorLink>
                </Container>
            </div>
            {/* our approach seo fold */}
            <div id="approach" className="seoapproach bgbl padmmdt padmmdb ">
                <Container>
                    <div className="appbox  padssmb d-none d-sm-block">
                        <img src={`${process.env.PUBLIC_URL}/img/approach2.png`} className="imgwidt img-fluid" alt="" />
                        <div className="top-left">
                            <h4><strong>Value,</strong><br /> <i>not just Volume</i></h4>
                            <p>The number of walk-ins does not matter, how many converts is the focus for us. </p>
                        </div>

                        <div className="top-right">
                            <h4><strong> Real Community,</strong><br /> <i>not bots</i></h4>
                            <p>Build a community that really believes in your values, not bot followers from around the world.</p>
                        </div>

                        <div className="bottom-left">
                            <h4><strong>Consistent,</strong><br /> <i> not patchy </i></h4>
                            <p>A seamless website, social & chat experience; not a different brand on every channel.</p>
                        </div>


                        <div className="bottom-right"> <h4><strong>Buyers,</strong><br /> <i>not window shoppers</i></h4>
                            <p>We target your customers in the right catchment with the right intent and purchasing power.</p>
                        </div>
                        <div className="centered"><h1>OUR <span>APPROACH</span> <br />TO Digital Brand Building</h1></div>
                    </div>
                    <div className="appbox  padssmb d-block d-sm-none">
                    <h1>OUR <span>APPROACH</span> <br />TO Digital Brand Building</h1> <br />

                        <h4><strong>Value,</strong><br /> <i>not window shoppers</i></h4>
                        <p>The number of walk-ins does not matter, how many converts is the focus for us. </p>
                        <img src={`${process.env.PUBLIC_URL}/img/straightline.png`} className="img-fluid"/>
                        <h4><strong>  Buyers,</strong><br /> <i>not just Volume</i></h4>
                        <p>We target your customers in the right catchment with the right intent and purchasing power.</p>

                        <img src={`${process.env.PUBLIC_URL}/img/straightline.png`} className="img-fluid"/>
                        <h4><strong> More,</strong><br /> <i> with less </i></h4>
                        <p>We build a bigger brand without a massy approach, only affluent audiences.</p>
                        <img src={`${process.env.PUBLIC_URL}/img/straightline.png`} className="img-fluid"/>
                        <h4><strong>Insights,</strong><br /> <i>NOT BS</i></h4>
                        <p>Knowing & doing automation is not important, tracking & refining over time is.</p>


                    </div>

                    
                </Container>
                {/* <img src={`${process.env.PUBLIC_URL}/img/approach.png`} className="img-fluid" /> */}
            </div>
             
            {/* case study */}

            <div className="bgfooter whyus   padsmdt padbmdb">
                <Col lg="8" className="offset-lg-1" >
                    <h3 className=" txtcolor"><span className="spund">CASE</span> STUDY</h3>
                </Col>
                <Casestudy />
            </div>

            {/* 3 Reasons to choose */}

            <div className="bgb casestudy ">

                <Reasons />
            </div>

            {/* our services */}
            <Services /> 
        </div>
    );
}


export default Digitalbrandbuilding;

function Casestudy() {
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,

        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    adaptiveHeight: true,
                }
            },]
    };
    const next = () => {
        Slider.current.slickNext();
    }
    const previous = () => {
        Slider.current.slickPrev();
    }
    return (
        <Container fluid className="padmmdt casestudy ">
            <Row>
                <Col lg="10" className="offset-lg-1">
                    <Slider {...settings}>
                        <div>
                            <Row>
                                <Col className="offset-lg-1" lg={{ span: 4, order: 'first' }} md="6" sm={{ span: 6, order: 'last' }} xs={{ span: 12, order: 'first' }}><img src={`${process.env.PUBLIC_URL}/img/evokcarousel.png`} width="100%" className="mx-auto d-block" /></Col>
                                <Col lg={{ span: 6, order: 'first' }} sm={{ span: 6, order: 'first' }} md="6" xs={{ span: 12, order: 'last' }} className="mbtxtcenter offset-lg-1 padbsmb ">
                                    <h1 className="padslgt padbxsb">1.47 m</h1>
                                    <h2 className="padbxsb "> targeted users engaged through <strong><span>social media.</span></strong></h2>
                                    {/* <a href="" className="btn btn-primary ">Read Case Study</a> */}
                                    <Link to={`${process.env.PUBLIC_URL}/case-studies/social-media-case-study-evok-furnitures.html`}  className="btn btn-seo " >Read Case Study</Link> 
                                </Col>

                            </Row>

                        </div>
                        

                    </Slider>
                </Col>
            </Row>
        </Container>
    );
}

// reasons
function Reasons() {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        adaptiveHeight: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false,
                    adaptiveHeight: true,
                }
            },],
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    adaptiveHeight: false,
                }
            },]
    };
    const next = () => {
        Slider.current.slickNext();
    }
    const previous = () => {
        Slider.current.slickPrev();
    }
    return (
        <Container fluid className="reasons padslgt padslgb casestudy ">
            <Row>
                <Col lg="10" className="offset-lg-1">
                    <Slider {...settings}>
                        <div>
                        <Row>
                                <Col className="offset-lg-1 offset-sm-1" lg={{ span: 4, order: 'first' }} md="4" sm={{ span: 3, order: 'first' }} xs={{ span: 12, order: 'first' }}><img src={`${process.env.PUBLIC_URL}/img/reasons3.png`} className="custmt80 padt130 padmmdt mimgw padmsmb marbsmt img-fluid mx-auto d-block" /></Col>
                                <Col className="marmlgt mt0" lg={{ span: 7, order: 'first' }} sm={{ span: 6, order: 'last' }} md="7" xs={{ span: 12, order: 'last' }}  >

                                    <h2 className="marmlgt"> Reasons to choose A&B for<br/><strong>Luxury Brand Building</strong></h2> <br />

                                </Col>

                            </Row>
                             
                        </div>
                        <div>
                         <Row>

                            <Col className="offset-lg-1 offset-sm-1" lg={{ span: 5, order: 'first' }} sm={{ span: 6, order: 'first' }}  xs={{ span: 12, order: 'last' }} >

                            <h2 className="padt190"><span>A&B Commitment</span></h2>
                                    <h3 className=" padmsmb">  If we don't generate <strong>growth</strong> in 3 months, <strong><i>we fire ourselves.</i></strong><br />There are 9 more weird things about us, <br />read them here.</h3>

                            </Col>
                            <Col className="offset-lg-1 mgm90" lg={{ span: 4, order: 'first' }}  sm={{ span: 4, order: 'last' }} xs={{ span: 12, order: 'first' }}>
                                <img src={`${process.env.PUBLIC_URL}/img/commitment.png`} className="custmt80  padslg125 mimgw img-fluid mx-auto d-block" />
                            </Col>

                            </Row>
                            
                        </div>
                        <div>
                           <Row>

                            <Col className="offset-lg-1 offset-sm-1" lg={{ span: 5, order: 'first' }} sm={{ span: 5, order: 'first' }} xs={{ span: 12, order: 'last' }} >

                                <h3 className="marblgt padmsmb padslg125 "> Specialised brand building agency trusted by global brands</h3>

                            </Col>
                            <Col className=" mgm90" lg={{ span: 5, order: 'first' }}  sm={{ span: 5, order: 'last' }} xs={{ span: 12, order: 'first' }}>
                                <img src={`${process.env.PUBLIC_URL}/img/worldmap.png`} className="custmt80 padslg125 padbsmb img-fluid mx-auto d-block" />
                                <img src={`${process.env.PUBLIC_URL}/img/clientslogo.png`} className=" img-fluid mx-auto d-block" />
                            </Col>
                            
                            </Row>
                             
                        </div>
                       
                        <div>
                        <Row> 
                                <Col className="offset-lg-1 offset-md-0" lg={{ span: 10, order: 'first' }}  md={{ span: 12, order: 'first' }}  sm={{ span: 8, order: 'last' }} xs={{ span: 12, order: 'last' }}>
                                <h3 className="text-center htitle"><span>Don't believe us?</span></h3>
                                    <p className="text-center custml ">  Hear it from our partners.</p>
                                    <div className="feedbackbox marmsmt padmsmt padmsmb">
                                     <img src={`${process.env.PUBLIC_URL}/img/leftcoma.png`}  className="mgt40 img-fluid mx-auto d-block"/>
                                     <br />
                                        {/* <img src={`${process.env.PUBLIC_URL}/img/paytminsiderlogo.png`} className="img-fluid mx-auto d-block padmsmt padmsmb" /> */}
                                        <h4 className="">Luxury brand building requires nuance, few have it. Luckily for us, A&B specialises in this space & helped build our brand for the right audience.
                                        </h4>  
                                        {/* <img src={`${process.env.PUBLIC_URL}/img/1seagallery.png`} className="rounded-circle img-fluid mx-auto d-block" /> */}
                                        {/* <img src={`${process.env.PUBLIC_URL}/img/rightcoma.png`}  className="flr" /> */}
                                    </div>
                                    <div className="clientname">
                                    <img src={`${process.env.PUBLIC_URL}/img/clients/Regalia_roundedlogo.png`} className="img-fluid mx-auto" width="160px" /> <br />
                                    <p className="text-center"> <i>- Pratik Dalmia, Regalia Luxury.</i></p>
                                    </div>
                                </Col>

                            </Row> 
                        </div>
                    </Slider>
                </Col>
            </Row>
        </Container>
    );
}