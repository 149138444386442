import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from "jquery";
import axios from 'axios';
import _ from 'lodash'
import { Container, Col, Row, Form, Button, FormControl } from 'react-bootstrap';

// run this code live and check data is coming or not.
const config = {
    cors: 'https://cors-anywhere.herokuapp.com/', // <optional> doesn't display the cors error
    formUrl: 'https://docs.google.com/forms/d/e/1FAIpQLSdnLhjzmFZd-Eo5KfBLb7UldSh9Yx4X0gFkS0r5jp3QwUq-Og/formResponse'
};

const Input = ({ name, doChange, placeholder, type }) => {
    return (
        <label htmlFor={name} >
            <Form.Control type={type} id={name} name={name} onChange={doChange} placeholder={placeholder} required />

        </label>
    )
}

class Footer extends React.Component {
    state = {
        inputs: {
            name: { id: 215692566, value: '' },
            email: { id: 1698047840, value: '' },
            phone: { id: 111368972, value: null },
            budget: { id: 756881499, value: '' },
            message: { id: 429526634, value: '' }
        },
    }

    doSubmit = async (e) => {
        e.preventDefault();

        const { inputs } = this.state;
        const formData = new FormData();

        _.map(inputs, (item) => {
            formData.append(`entry.${item.id}`, item.value)
        });

        await axios({
            url: `${config.formUrl}`,
            method: 'post',
            data: formData,
            responseType: 'json'
        })
            .then(function (response) {
                console.log('response', response);

            })
            .catch(function (error) {
                //console.log('err', error);
                // window.location.replace("/thank-you") 
            })

        window.Email.send({
            SecureToken: "d1db287b-8bb5-46dd-b1fe-d0bb3c7722d4",
            To: 'syed@aandb.xyz, lalit@aandb.xyz, preksha@aandb.xyz, syed_h+hs0g1ssnq4vmceopeoqt@boards.trello.com',
            From: "no-reply@aandb.xyz",
            Subject: "A&B New Contact Form",
            Body: "Contact Form Details: <br>" +
                "Name: &nbsp;" + formData.get("entry.215692566") + "<br>" +
                "Email: &nbsp;" + formData.get("entry.1698047840") + "<br>" +
                "Phone No.: &nbsp;" + formData.get("entry.111368972") + "<br>" +
                "Budget: &nbsp;" + formData.get("entry.756881499") + "<br>" +
                "Message: &nbsp;" + formData.get("entry.429526634") + "<br>"
        }).then(
            message => console.log(message),
            window.setTimeout(function () {
                window.location.replace("/thank-you")
            }, 1000)
        );
    }

    handleChange = (e) => {
        const { value, name } = e.target;
        const { inputs } = this.state;

        inputs[name].value = value;

        this.setState({
            inputs
        });
    }

    render() {
        return (

            <div>
                <div id="footer" className="footer bgfooter padmsmt padmsmb">
                    <Col lg="10" className="offset-lg-1 padbxsb" >
                        <h3 className=" txtcolor"><span className="spund">CO</span>NTACT US</h3>
                    </Col>
                    <Container lg="10" className="">
                        <Row>


                            <Col lg={{ span: 6, order: 'first' }} md="6" xs={{ span: 12, order: 'last' }} className="padsmdt">
                                <h4 className=" txtcolor sam mt30">Drop by for some coffee</h4>
                                <Row>
                                    <Col lg="2" md="2" sm="2" xs="3" className="padsmdt">
                                        <img src={`${process.env.PUBLIC_URL}/img/mailicon.png`} className="boxsm" height="15" width="18" />
                                    </Col>
                                    <Col lg="8" md="8" sm="8" xs="9" className="padsmdt">
                                        <h4><a href="mailto:hello@aandb.xyz">hello@aandb.xyz</a></h4>
                                    </Col>
                                </Row>
                                {
                                    window.location.pathname === '/luxury-digital-agency-dubai.html' ?

                                        <Row>
                                            <Row>
                                                <Col lg="2" md="2" sm="2" xs="3" className="padsmdt">
                                                    <img src={`${process.env.PUBLIC_URL}/img/map-pin.png`} height="22" width="15" className="boxsm" />
                                                </Col>
                                                <Col lg="8" md="8" sm="8" xs="9" className="padsmdt">

                                                    <p>Envalon FZCO, Dubai Silicon Oasis, DDP, Building A2, Dubai, United Arab Emirates.</p>

                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="2" md="2" sm="2" xs="3" className="padsmdt">
                                                    <img src={`${process.env.PUBLIC_URL}/img/pirate.png`} height="38" width="38" className="img-fluid" />
                                                </Col>
                                                <Col lg="8" md="8" sm="8" xs="9" className="padsmdt">

                                                    <p>To board the ship, interact with Coco (Chatbot)</p>

                                                </Col>
                                            </Row>

                                        </Row>
                                        :

                                        window.location.pathname === '/luxury-digital-agency-london.html' ?

                                            <Row>
                                                <Row>
                                                    <Col lg="2" md="2" sm="2" xs="3" className="padsmdt">
                                                        <img src={`${process.env.PUBLIC_URL}/img/map-pin.png`} height="22" width="15" className="boxsm" />
                                                    </Col>
                                                    <Col lg="8" md="8" sm="8" xs="9" className="padsmdt">

                                                        <p>Middlesex, 52 High Street Pinner, London HA5 5PW, United Kingdom</p>

                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="2" md="2" sm="2" xs="3" className="padsmdt">
                                                        <img src={`${process.env.PUBLIC_URL}/img/pirate.png`} height="38" width="38" className="img-fluid" />
                                                    </Col>
                                                    <Col lg="8" md="8" sm="8" xs="9" className="padsmdt">

                                                        <p>To board the ship, interact with Coco (Chatbot)</p>

                                                    </Col>
                                                </Row>

                                            </Row>

                                            :

                                            <Row>
                                                <Row>
                                                    <Col lg="2" md="2" sm="2" xs="3" className="padsmdt">
                                                        <img src={`${process.env.PUBLIC_URL}/img/map-pin.png`} className="boxsm" height="22" width="15" />
                                                    </Col>
                                                    <Col lg="8" md="8" sm="8" xs="9" className="padsmdt">

                                                        <h3>Mumbai</h3>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="2" md="2" sm="2" xs="3" className="padsmdt">
                                                        <img src={`${process.env.PUBLIC_URL}/img/map-pin.png`} className="boxsm" height="22" width="15" />
                                                    </Col>
                                                    <Link to={`${process.env.PUBLIC_URL}/luxury-digital-agency-london.html`} className="">
                                                        <Col lg="8" md="8" sm="8" xs="9" className="padsmdt footer-arrow">
                                                            <h3 className=''>London</h3>
                                                        </Col>
                                                    </Link>
                                                </Row>
                                                <Row>
                                                    <Col lg="2" md="2" sm="2" xs="3" className="padsmdt">
                                                        <img src={`${process.env.PUBLIC_URL}/img/map-pin.png`} className="boxsm" height="22" width="15" />
                                                    </Col>
                                                    <Link to={`${process.env.PUBLIC_URL}/luxury-digital-agency-dubai.html`} className="">
                                                        <Col lg="8" md="8" sm="8" xs="9" className="padsmdt footer-arrow">
                                                            <h3 className=''>Dubai</h3>
                                                        </Col>
                                                    </Link>
                                                </Row>
                                                <Row>
                                                    <Col lg="2" md="2" sm="2" xs="3" className="padsmdt">
                                                        <img src={`${process.env.PUBLIC_URL}/img/map-pin.png`} className="boxsm" height="22" width="15" />
                                                    </Col>
                                                    <Col lg="8" md="8" sm="8" xs="9" className="padsmdt">
                                                        <h3>Brisbane</h3>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="2" md="2" sm="2" xs="3" className="padsmdt">
                                                        <img src={`${process.env.PUBLIC_URL}/img/pirate.png`} height="38" width="38" className="img-fluid" />
                                                    </Col>
                                                    <Col lg="8" md="8" sm="8" xs="9" className="padsmdt">

                                                        <p>To board the ship, interact with Coco (Chatbot)</p>

                                                    </Col>
                                                </Row>
                                               
                                            </Row>
                                }

                            </Col>
                            <Col lg="6" md="6" sm="6" xs="12" className="padsmdt">

                                <iframe style={{ backgroundColor: "#0D0D0D" }} src="https://links.collect.chat/5ae73a7873b7fa23f9c2327a" width="100%" height="600" frameBorder="0"></iframe><script async type="text/javascript" src="https://collectcdn.com/embed.js"></script>

                                {/* <h4 className=" txtcolor sam">Invite us for a coffee</h4>

                                <Form name="contact-form" onSubmit={this.doSubmit} >
                                    <Form.Group>

                                         
                                        <Input name="name" doChange={this.handleChange} placeholder="Name*" className="form-control" required />

                                    </Form.Group>
                                    <Form.Group>

                                        <input className="form-control" type="email" label="email" name="email" onChange={this.handleChange} placeholder="E-mail*" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" required />

                                    </Form.Group>
                                    <Form.Group>

                                        <input className="form-control" type="tel" label="phone" name="phone" maxlength="10" pattern="[0-9]{10}" onChange={this.handleChange} placeholder="Phone Number*" required />

                                    </Form.Group>

                                    <Form.Control name="budget" label="budget" onChange={this.handleChange} as="select" defaultValue={'Your monthly marketing budget is?'}>

                                        <option value="Your monthly marketing budget is?" disabled >Your monthly marketing budget is?</option>
                                        <option value="< 100000">&lt; 100000</option>
                                        <option value="100001-200000">100001-200000</option>
                                        <option value="200001-500000">200001-500000</option>
                                        <option value="> 500000">&gt; 500000</option>
                                    </Form.Control>



                                    <Form.Group className="marmxst" >
                                        <Form.Control onChange={this.handleChange} name="message" as="textarea" label="message" placeholder="Message" rows={3} />

                                    </Form.Group>

                                    <Button variant="primary" type="submit" value="Submit">
                                        SUBMIT
                                    </Button>
                                </Form> */}

                            </Col>
                        </Row>
                    </Container>

                </div>
                <div>
                    <Container fluid={true} className="bgb footer padssmt padssmb bottomsize">
                        <Col lg="10" className="offset-lg-1" >

                            <Row style={{ textAlign: 'center' }}>

                                <h5><strong>NOTIFICATION</strong>
                                    <br></br>
                                    A&B Consultancy Solutions Pvt. Ltd. ("A&B") does not engage in cryptocurrency trading, Social Media "likes" manipulation, work-from-home, part-time jobs or any other get-rich-quick kind of fraudulent schemes. A&B urges you not to engage with anyone approaching you with such schemes and report such individuals/phone numbers/Emails to Cyber Cell Police. A&B or its representatives will not be held responsible for any loss or damage suffered in such matters.</h5>
                            </Row>
                            <Row className='mt-4' style={{ textAlign: 'center' }}>
                                {
                                    window.location.pathname === '/luxury-digital-agency-dubai.html' || window.location.pathname === '/luxury-digital-agency-london.html' ?

                                        <h5>A&B is a digital agency for luxury & premium brands and is based in London & Dubai. We focus on growth via Digital Strategy including CRO, Search, Social, Influencers & PPC solutions. Our team has hardcore data scientists & mad women/men of advertising.</h5>

                                        :
                                        <h5>A&B is a digital agency for luxury & premium brands and is based in Mumbai & London. We focus on growth via Digital Strategy including CRO, Search, Social, Influencers & PPC solutions. Our team has hardcore data scientists & mad women/men of advertising.</h5>
                                }

                            </Row>
                            <Row className="padssmt">
                                <h4 className=""><a href='/privacy-policy.html'>PRIVACY POLICY</a></h4>
                            </Row>

                           


                            <Row>
                                <Col lg="8" md="8" sm="8" xs="6" ></Col>
                                <Col lg="4" md="4" sm="4" xs="12">
                                    <Row>
                                        <Col lg="2" md="2" sm="2" xs="2" className=" d-none d-sm-block"> </Col>
                                        <Col lg="2" md="2" sm="2" xs="2" className="mbpdl5">
                                            <a href="https://www.youtube.com/channel/UCMDnlXUukdHeGUi7mpT0rUg" target="blank">
                                                <img width="100%" height="100%" src={`${process.env.PUBLIC_URL}/img/youtube.png`} className="img-fluid" />
                                            </a>
                                        </Col>
                                        <Col lg="2" md="2" sm="2" xs="2" className="mbpdl5">
                                            <a href="https://www.linkedin.com/company/a&b" target="blank">
                                                <img width="100%" height="100%" src={`${process.env.PUBLIC_URL}/img/linkedin.png`} className="img-fluid" />
                                            </a>
                                        </Col>
                                        <Col lg="2" md="2" sm="2" xs="2" className="mbpdl5">
                                            <a href="https://www.aandb.xyz/blog/" target="blank">
                                                <img width="100%" height="100%" src={`${process.env.PUBLIC_URL}/img/blog.png`} className="img-fluid" />
                                            </a>
                                        </Col>
                                        <Col lg="2" md="2" sm="2" xs="2" className="mbpdl5">
                                            <a href="https://www.facebook.com/aandbxyz/" target="blank">
                                                <img width="100%" height="100%" src={`${process.env.PUBLIC_URL}/img/fbicon.png`} className="img-fluid" />
                                            </a>
                                        </Col>
                                        <Col lg="2" md="2" sm="2" xs="2" className="mbpdl5">
                                            <a href="https://www.instagram.com/aandbxyz/" target="blank">
                                                <img width="100%" height="100%" src={`${process.env.PUBLIC_URL}/img/instaicon.png`} className="img-fluid" />
                                            </a>
                                        </Col>

                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Container>

                </div>

            </div>
        )
    }
}
export default Footer;

