import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Nav, Container, Accordion, Card, Button, Col, Row, Tab, Carousel } from 'react-bootstrap';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Slider from "react-slick";
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";
import Services from './Services';
function Orm() {
    window.scrollTo(0, 0);
    const [focus, setFocus] = React.useState(false);
    return (
        <div>
            <Helmet>

                <title>Best Online Reputation Management Agency for *Luxury* Brands | A&B</title>
                <meta name="description" content="Get your brand's reputation managed by the highest-rated agency in India." />
                <link rel="canonical" href="https://www.aandb.xyz/online-reputation-management-agency-luxury.html" /> 

            </Helmet>
            <div className="firstfoldseo ">
                <div className="box  padmmdb">
                    {/* first fold */}
                    <Container fluid className="padbsmt padbsmb">
                        <Row>
                            <Col lg="2"></Col>
                            <Col lg="8">
                                <div className="circleboxfirst padsmdt padsmdb">
                                    <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="roundbx"  width="30%" alt="" />

                                    <div className="boxcentered">
                                        <h4>
                                            Get your brand's reputation managed by the <span>highest-rated agency</span> in India.
                                        </h4>

                                    </div>

                                </div>
                                <AnchorLink href="#gamechanger" className="btn btn-seo" >Fix your Online Reputation</AnchorLink>
                            </Col>
                            <Col lg="2"></Col>

                        </Row>
                    </Container>
                </div>
            </div>
            {/* Seo matters */}
            <div id="gamechanger" className="seomatters  bgb">
                <Container className="padbmdt padbmdb">

                    <h3 className="text-center txtcolor  padsmdb mgb50">Why is <span>Online Reputation Management</span> Critical for Luxury Brands?</h3>
                    <Row className="padsmdb">
                        <Col lg="4" md="4" sm="4">
                            <div className="circleboxfirst  padssmb pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="servicescirbox" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1>85%</h1>
                                </div>
                            </div>
                            <p>brands' online & offline reputation doesn't match.</p> <br />
                        </Col>
                        <Col lg="4" md="4" sm="4">
                            <div className="circleboxfirst  padssmb pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="servicescirbox" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1>75%</h1>
                                </div>
                            </div>

                            <p>customers look for service info before purchasing.</p> <br />

                        </Col>
                        <Col lg="4" md="4" sm="4">
                            <div className="circleboxfirst  padssmb pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="servicescirbox" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1>90%</h1>
                                </div>
                            </div>

                            <p>of luxury brands have no metric of tracking reputation.</p> 

                        </Col>
                    </Row>
                    <AnchorLink href="#approach" className="btn btn-seo" >Fix your Online Reputation</AnchorLink>
                </Container>
            </div>
            {/* our approach seo fold */}
            <div id="approach" className="seoapproach bgbl padmmdt padmmdb ">
                <Container>
                    <div className="appbox  padssmb d-none d-sm-block">
                        <img src={`${process.env.PUBLIC_URL}/img/approach2.png`} className="imgwidt img-fluid" alt="" />
                        <div className="top-left">
                            <h4><strong>Real 5  ⭐,</strong><br /> <i>not fake</i></h4>
                            <p>We don't write fake reviews, we help you get real customer love.</p>
                        </div>

                        <div className="top-right">
                            <h4><strong>Customers,</strong><br /> <i>not Competition</i></h4>
                            <p>We eliminate reviews posted by competition with mal intent.</p>
                        </div>

                        <div className="bottom-left">
                            <h4><strong>Control,</strong><br /> <i>not blackmail</i></h4>
                            <p>We destroy the rankings of websites that blackmail you. </p>
                        </div>


                        <div className="bottom-right"> <h4><strong>Experience,</strong><br /> <i>not a checkbox</i></h4>
                            <p>Not just Google reviews but reviews on your website, videos etc.</p>
                        </div>
                        <div className="centered"><h1>OUR <span>APPROACH</span> <br />to ORM</h1></div>
                    </div>
                    <div className="appbox  padssmb d-block d-sm-none">
                        <h1>OUR <span>APPROACH</span> <br />TO ORM</h1> <br />

                        <h4><strong>Real 5 ⭐,</strong><br /> <i>not fake</i></h4>
                        <p>We don't write fake reviews, we help you get real customer love.</p>
                        <img src={`${process.env.PUBLIC_URL}/img/straightline.png`} className="img-fluid" />
                        <h4><strong>Customers,</strong><br /> <i>not Competition</i></h4>
                        <p>We eliminate reviews posted by competition with mal intent.</p>

                        <img src={`${process.env.PUBLIC_URL}/img/straightline.png`} className="img-fluid" />
                        <h4><strong>Control,</strong><br /> <i>not blackmail</i></h4>
                        <p>We destroy the rankings of websites that blackmail you. </p>
                        <img src={`${process.env.PUBLIC_URL}/img/straightline.png`} className="img-fluid" />
                        <h4><strong>Experience,</strong><br /> <i>not a checkbox</i></h4>
                        <p>Not just Google reviews but reviews on your website, videos etc.</p>


                    </div>


                </Container>
                {/* <img src={`${process.env.PUBLIC_URL}/img/approach.png`} className="img-fluid" /> */}
            </div>



            {/* 3 Reasons to choose */}

            <div className="bgb casestudy  ">

                <Reasons />
            </div>

            {/* our services */}
            <Services />
        </div>
    );
}


export default Orm;



// reasons
function Reasons() {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        adaptiveHeight: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false,
                    adaptiveHeight: true,
                }
            },],
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    adaptiveHeight: false,
                }
            },]
    };
    const next = () => {
        Slider.current.slickNext();
    }
    const previous = () => {
        Slider.current.slickPrev();
    }
    return (
        <Container fluid className="reasons padslgt padslgb casestudy ">
            <Row>
                <Col lg="10" className="offset-lg-1">
                    <Slider {...settings}>
                        <div>
                            <Row>
                                <Col className="offset-lg-1 offset-sm-1" lg={{ span: 4, order: 'first' }} md="4" sm={{ span: 3, order: 'first' }} xs={{ span: 12, order: 'first' }}><img src={`${process.env.PUBLIC_URL}/img/reasons3.png`} className="custmt80 padt130 padmmdt mimgw padmsmb marbsmt img-fluid mx-auto d-block" /></Col>
                                <Col className="marmlgt mt0" lg={{ span: 7, order: 'first' }} sm={{ span: 6, order: 'last' }} md="7" xs={{ span: 12, order: 'last' }}  >

                                    <h2 className="marmlgt"> Reasons to choose A&B  for<strong> <br/> Online Reputation Management</strong></h2> <br />

                                </Col>

                            </Row>


                        </div>
                        <div>
                            <Row>

                                <Col className="offset-lg-1 offset-sm-1" lg={{ span: 5, order: 'first' }} sm={{ span: 6, order: 'first' }} xs={{ span: 12, order: 'last' }} >

                                <h2 className="padt190"><span>A&B Commitment</span></h2>
                                    <h3 className=" padmsmb">  If we don't generate <strong>growth</strong> in 3 months, <strong><i>we fire ourselves.</i></strong><br />There are 9 more weird things about us, <br />read them here.</h3>

                                </Col>
                                <Col className="offset-lg-1 mgm90" lg={{ span: 4, order: 'first' }} sm={{ span: 4, order: 'last' }} xs={{ span: 12, order: 'first' }}>
                                    <img src={`${process.env.PUBLIC_URL}/img/commitment.png`} className="custmt80  padslg125 mimgw img-fluid mx-auto d-block" />
                                </Col>

                            </Row>
                        </div>
                        <div>
                            <Row>

                                <Col className="offset-lg-1 offset-sm-1" lg={{ span: 5, order: 'first' }} sm={{ span: 5, order: 'first' }} xs={{ span: 12, order: 'last' }} >

                                    <h3 className="marblgt padmsmb padslg125 "> Specialised ORM agency trusted by global brands</h3>

                                </Col>
                                <Col className=" mgm90" lg={{ span: 5, order: 'first' }} sm={{ span: 5, order: 'last' }} xs={{ span: 12, order: 'first' }}>
                                    <img src={`${process.env.PUBLIC_URL}/img/worldmap.png`} className="custmt80 padslg125 padbsmb img-fluid mx-auto d-block" />
                                    <img src={`${process.env.PUBLIC_URL}/img/clientslogo.png`} className=" img-fluid mx-auto d-block" />
                                </Col>

                            </Row>

                        </div>
                       
                        <div>
                            <Row>
                                <Col className="offset-lg-1 offset-md-0" lg={{ span: 10, order: 'first' }} md={{ span: 12, order: 'first' }} sm={{ span: 8, order: 'last' }} xs={{ span: 12, order: 'last' }}>
                                    <h3 className="text-center htitle"><span>Don't believe us?</span></h3>
                                    <p className="text-center custml ">  Hear it from our partners.</p>
                                    <div className="feedbackbox marmsmt padmsmt padmsmb">
                                        <img src={`${process.env.PUBLIC_URL}/img/leftcoma.png`} className="mgt40 img-fluid mx-auto d-block" />
                                        <br />
                                        {/* <img src={`${process.env.PUBLIC_URL}/img/paytminsiderlogo.png`} className="img-fluid mx-auto d-block padmsmt padmsmb" /> */}
                                        <h4 className="">Reputation is critical in the luxury business. A&B not only fixed the problem of competition reviews but also helped us get more credibility by working with our partners like Bovet, Kiton, Ressence, Isaia etc.
                                        </h4>
                                        {/* <img src={`${process.env.PUBLIC_URL}/img/1seagallery.png`} className="rounded-circle img-fluid mx-auto d-block" /> */}
                                        {/* <img src={`${process.env.PUBLIC_URL}/img/rightcoma.png`}  className="flr" /> */}
                                    </div>
                                    <div className="clientname">
                                        <img src={`${process.env.PUBLIC_URL}/img/clients/Regalia_roundedlogo.png`} className="img-fluid mx-auto" width="160px" /> <br />
                                        <p className="text-center"> <i>-  Pratik Dalmia, Founder, Regalia Luxury</i></p>
                                    </div>
                                </Col>

                            </Row>

                        </div>
                    </Slider>
                </Col>
            </Row>
        </Container>
    );
}