import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container, Button, Col, Row } from 'react-bootstrap';
import Slider from "react-slick";
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";
import { LazyLoadImage } from 'react-lazy-load-image-component';

function Saundh() {
    window.scrollTo(0, 0);
    const [focus, setFocus] = React.useState(false);
    return (
        <div>
            <Helmet>

                <title>E-Commerce Conversion Rate Optimisation Case Study - 5x Improvement</title>
                <meta name="description" content="" />
                <link rel="canonical" href="https://www.aandb.xyz/case-studies/ecommerce-conversion-rate-case-study.html" />

            </Helmet>
            {/* first fold */}
            <div className="casestudy">
                <div className="box padmmdt padmmdb">

                    <Container>

                        <p className="text-left p1 pdl10"> <span class="spcase">SA</span>UNDH</p>
                    </Container>

                    <Container fluid className="padbsmt padbsmb">
                        <Row>
                            <Col lg="2"></Col>
                            <Col lg="8">
                                <div className="circleboxfirst  padssmb">
                                      <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="roundbx"  width="30%" alt="" />

                                    <div className="boxcentered tpfold">
                                        <h4 className="d-none d-sm-block "> <span className="cuspn">5X</span><br /> Improvement in E-Commerce <br /> Conversion Rate.
                                        </h4>
                                        <h4 className="d-block d-sm-none">  <br /> <span className="cuspn">5X</span><br /> Improvement in E-Commerce <br />
                                            Conversion Rate.

                                        </h4>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="2"></Col>
                        </Row>
                    </Container>
                </div>
            </div>
            {/* The problem  and build fold */}
            <div className="lgbox  problem">

                <Container fluid className="tpfold">
                    <Row>

                        <Col lg="5" md="4" sm="6" xs="12" className="padmmdt mbpt0 casebox offset-lg-1 ">

                            <p className="text-left p1 mrg30 " > <span class="spcase">THE</span> Problem </p>
                            <p className="p2   marbmdt  mbt20 ">
                                <span>Saundh</span> is a <span>premium fashion retailer</span> for everyday <span>ethnic luxury clothing</span>. They retail through offline stores and their website <span> www.saundh.com</span>.
                            </p>
                            <p className="p2  ">
                                Saundh’s website had a lot of traffic coming through direct and social media but very few of those translated into orders. Instead of trying to get more traffic, we decided to try and convert the existing visitors to improve the revenue.

                            </p>
                        </Col>

                        <Col lg={{ span: 5, order: 'last' }}   sm={{ span: 6, order: 'last' }} xs={{ span: 12, order: 'first' }} className="offset-lg-1">
                              <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/saundh/1.png`} className="img-fluid text-right" />
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* Insights */}
            <Container fluid className=" box padbsmt padbsmb">
                <div className="seomatters ">
                    <Row>
                        <Col lg="11" className="casebox offset-lg-1">
                            <p className="text-left p1 marmsmb "> <span class="spcase">THE</span> Insight</p>
                        </Col>
                    </Row>
                    <Container>
                        <Row className=" mbpdt30 marketing">

                            <Col lg="6" md="4" sm="6" xs="12">
                                <div className="circleboxfirst  padssmb">
                                      <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/bgbox.png`} width="50%" alt="" />
                                    <div className="boxcentered">
                                        <h1 className="boxtitle"><br /><br />A website conversion<br />  rate is comprised<br />  of 2 factors:-<br /><br /> </h1>

                                    </div>
                                </div>
                                <ul className="text-left mgbx50">
                                    <li>Quality of traffic coming to the website</li>
                                    <li>Experience of the website after a user<br /> lands on it.</li>

                                </ul>
                            </Col>

                            <Col lg="6" md="4" sm="6" >
                                <div className="circleboxfirst  padssmb">
                                      <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/bgbox.png`} width="50%" alt="" />
                                    <div className="boxcentered">
                                        <h1 className="boxtitle"><br />Saundh had both<br />  the problems<br /> </h1>

                                    </div>
                                </div>
                                <ul className="text-left mgbx50">
                                    <li>There was a bunch of irrelevant traffic coming on the website.
                                    </li>
                                    <li>The experience was sub-par despite the site being visually appealing.
                                    </li>
                                </ul>
                            </Col>

                        </Row>
                        <Row className="padsmdt padsmdb mbpdt30 marketing">

                            <Col lg="6" md="4" sm="6" xs="12">
                                <p className="p2 text-left">This was causing the website to have a <span>poor conversion rate of 0.15%</span> </p>
                            </Col>

                            <Col lg="6" md="4" sm="6" >
                                  <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/saundh/3.png`} width="100%" className="text-right" />
                            </Col>

                        </Row>
                    </Container>

                </div>

            </Container>
            {/* The Build */}
            <div className="lgbox build">
                <Container fluid className="lgbox mbpdt30">

                    <Row className=" ">

                        <Col lg="5" sm="6" xs="12" className="padssmt  padmmdb pdtb20 casebox offset-lg-1 offset-sm-1">
                            <p className="text-left p1 marssmb  mbb50"> <span class="spcase">THE</span> BUILD </p>
                              <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/saundh/2.png`} className="d-block d-sm-none mbmt0  mx-auto img-fluid text-right mgbtom30" />
                            <div className="d-none d-sm-block marmxsb"><span className="aplabox ">a</span></div>
                            <h3 className=" ">
                                Fixing irrelevant traffic
                            </h3>
                            <p className="p2">
                                The site had enough traffic, but the traffic quality was extremely poor. This was because Saundh is a premium brand, and all the ad campaigns were targeting broad and irrelevant keywords, leading to low-quality traffic.
                            </p>
                            <p className="p2">
                                We paused all existing campaigns and created new ones on OSIDE, our media platform for luxury and premium brands.
                            </p>

                            <div className="d-none d-sm-block marmxsb"><span className="aplabox ">b</span></div>
                            <h3 className="">
                                Experience of the website
                            </h3>
                            <p className="p2">
                                After auditing the website, we found some technical issues with the website, keeping the conversion rate very low.
                            </p>
                            <p className="p2">
                                Here are some of the issues we addressed:-
                            </p>

                        </Col>

                        <Col lg={{ span: 6, order: 'last' }} md="5" sm={{ span: 6, order: 'last' }}>

                              <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/saundh/2.png`} className="d-none d-sm-block mbmt0  mx-auto img-fluid text-right mgbtom30" />
                        </Col>

                    </Row>
                    <Row className="padbsmt padbsmb ">

                        <Col lg="3" sm="3" xs="12" className="casebox offset-lg-1 offset-sm-1">
                            <h3>1. Page Speed</h3>
                        </Col>

                        <Col lg={{ span: 7, order: 'last' }}  sm={{ span: 8, order: 'last' }}>

                            <ul className="text-left cuulb">
                                <li>
                                    The <span>load time is a crucial factor</span> for the user experience that can directly impact the conversion rate. Improving this was a priority.
                                </li>
                                <li>
                                    The average page load time of the website was above <span>15 seconds</span> which was <span>very high</span>.
                                </li>
                                <li>
                                    When was the last time you waited 15 seconds for a site to load? 😁
                                </li>
                                <li>
                                    So, we worked on <span>decreasing page size, lazy loading images, browser caching, server response time, reducing the total blocking time</span>, etc. to improve the page speed.
                                </li>
                            </ul>
                            <span className="relevantbox">Results</span>
                              <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/saundh/4.png`} className="padbxst padbxsb mbmt0  mx-auto img-fluid text-right mgbtom30" />
                            <span className="relevantbox d-none d-sm-block">The load time went down from 15 seconds to <span className="tcgold">6.4 seconds</span>.</span>
                            <span className="relevantbox padbxst d-block d-sm-none mx-auto">The load time went down from 15 seconds to <span className="tcgold">6.4 seconds</span>.</span>

                        </Col>

                    </Row>
                </Container>
            </div>
            {/* website uptime */}
            <Container fluid className="box padbxst padbmdb">
                <Row className="text-left   mbpdt30 mpdt0">
                    <Col lg="10" md="10" sm="10" className="offset-lg-1 offset-sm-1">
                        <h3>2. Website Uptime</h3>

                        <p className="p2 padbxst text-center">While working on the website, we observed that, due to server issues, the website used to go down<br /> frequently during the day, when the ads were running, impacting the conversions. </p>

                    </Col>
                </Row>
                  <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/saundh/5.png`} className="mx-auto img-fluid text-right mgbtom30" />

                <br />    <br />
                <span className="relevantbox padbxsb d-none d-sm-block mx-auto">To address this issue, <span className="tcgold">we shifted the website to a better server to minimize downtime.</span></span>
                <div className="tpbox">
                <span className="relevantbox padbxsb d-block d-sm-none">To address this issue, <span className="tcgold">we shifted the website to a better server to minimize downtime.</span></span>
                </div>
                <br />    <br />  <br />
                <div className="tpbox">
                <span className="relevantbox padbxst">Results</span>
                </div>
                <br />    <br />
                  <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/saundh/5.1.png`} className="mx-auto img-fluid text-right mgbtom30" />
                <br />    <br />  <br />
                <span className="relevantbox padbxst d-none d-sm-block mx-auto">After shifting the servers, the <span className="tcgold">website uptime was 99.9%</span></span>
                <div className="tpbox">
                <span className="relevantbox padbxst d-block d-sm-none mx-auto">After shifting the servers, the <span className="tcgold">website uptime was 99.9%</span></span>
                </div>
                <br />    <br />


            </Container>

            <div className="lgbox build endbox">
                <Container fluid className="pdlr0">

                    <Row className="text-left  mglr0"> 
                        <Col lg="9" sm="10" xs="12" className="numbers padmmdt  mpdt0  offset-lg-1">
                            <h3>3. Enabled Guest checkout</h3>
                            <p className="p2 padmxst">
                                We found that most people are not comfortable creating an account on the website they are visiting for the first time. Most visitors won't buy from us because it was compulsory to create an account on the website to make any purchase.
                            </p>
                            <p className="p2 padmxsb">
                                To resolve this issue, we enabled the guest checkout on the website.
                            </p>
                        </Col>
                    </Row>

                    <span className="relevantbox ">Results</span>

                    <Row className="text-left  mglr0">

                        <Col lg="9" sm="10" xs="12" className="numbers   mpdt0  offset-lg-1">

                            <br /> <br />
                            <p className="p2 padmxsb">
                                After implementing these changes, our conversion rate went up from 0.15% to 0.74% within a few months, a 3x jump!
                            </p>
                            <p className="p2 padmxsb">
                                And even though the number of users on the website was almost the same, the revenue increased drastically.
                            </p>

                        </Col>
                    </Row>
                    <span className="relevantbox padbxst">
                       <strong> Before</strong> Conversion Rate Optimization:
                    </span>   <br />  <br />
                      <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/saundh/6.png`} className="mx-auto img-fluid" />
                    <br />  <br />
                    <span className="relevantbox padbxst"> <strong>After</strong> Conversion Rate Optimization:</span>
                    <br />    <br />
                      <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/saundh/7.png`} className="mx-auto img-fluid" />
                    <br />  <br />
                </Container>
            </div>
            <div className="lgbox build endbox">
                <Container fluid className="pdlr0">
                    <Row className="text-left  mglr0">

                    <Col lg="6" sm="6" xs="12">
                            <Link to={`${process.env.PUBLIC_URL}/case-studies/parfait-snapchat-instagram-case-study.html`}>
                                <Row className="marssmt marssmb mglr0">

                                    <Col lg="3" sm="3" xs="3" >
                                          <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/footerlogo/parfait.png`} className="mx-auto img-fluid text-right" />
                                    </Col>
                                    <Col lg="5" sm="6" xs="7">
                                    
                                    <h4 className="tcgold">Parfait </h4>
                                    <p>Magazine covers became size inclusive with just a lens! </p>
                                    </Col>
                                    <Col lg="2" sm="3" xs="2" className="marbxst">
                                          <img src={`${process.env.PUBLIC_URL}/img/righticon.png`} width="40%" className=" text-right" />
                                    </Col>
                                </Row>
                            </Link>
                        </Col>

                        <Col lg="6" sm="6" xs="12" className="bgbl " >
                            <Link to={`${process.env.PUBLIC_URL}/case-studies/independence-day-social-media-campaign-tiranga-dabba.html`}>
                                <Row className="marssmt marssmb mglr0">
                                    <Col lg="3" sm="3" xs="3" >
                                          <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/footerlogo/tiranga.png`} className="mx-auto img-fluid text-right" />
                                    </Col>
                                    <Col lg="5" sm="6" xs="7">
                                        
                                        <h4 className="tcgold">Tiranga dabba </h4>
                                        <p>Restoring the dignity of hundreds of national flags</p>
                                    </Col>
                                    <Col lg="2" sm="3" xs="2" className="marbxst">
                                          <img src={`${process.env.PUBLIC_URL}/img/righticon.png`} width="40%" className=" text-right" />
                                    </Col>
                                </Row>
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>

    )
}
export default Saundh;