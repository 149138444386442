import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Nav, Container, Accordion, Card, Button, Col, Row, Tab, Carousel } from 'react-bootstrap'; 
import Slider from "react-slick"; 
import Services from './Services';
function Career() {
    window.scrollTo(0, 0);
    return (
        <div>
            <Helmet>
                <title>A&B Careers - Digital Marketing Jobs in Mumbai</title>
                <meta name="description" content="When we started A&B, our motto was clear. We will never treat our team as resources, ever. We will trust them, mentor them and stay invested in their success." />
                <link rel="canonical" href="https://www.aandb.xyz/career.html" />
            </Helmet>
            <Link to={`${process.env.PUBLIC_URL}/img/bgbox.png`}></Link>
             
            <div className="firstfoldseo career">
                <div className="box padmmdb">
                    {/* first fold */}
                    <Container fluid className="padbsmt padbsmb">
                        <Row>
                            <Col lg="2"></Col>
                            <Col lg="8">
                                <div className="circleboxfirst  padsmdt padsmdb">
                                    <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="roundbx"   width="30%" alt="" />

                                    <div className="boxcentered">
                                        <h4 className="d-none d-sm-block">
                                            Join a movement where arrogant  <br /> <span >assholes aren't welcome.</span>
                                            <br /> <br />
                                        </h4>
                                        <h4 className="d-block d-sm-none">
                                        <br /> <br /><br />  Join a movement where arrogant  <span >assholes aren't welcome.</span>
                                          
                                        </h4>

                                    </div>

                                </div>
                                <p className="p2">When we started A&B, our motto was clear. We will never treat our team as resources,<br /> ever. We will trust them, mentor them and stay invested in their success.</p>
                            </Col>
                            <Col lg="2"></Col>

                        </Row>
                    </Container>
                </div>
            </div>

            {/* CULTURE*/}
            <div id="scroll-hero" className=" bgb specialist padbmdt padbmdb">
                <h4 className="tcwhite text-center padsmdb ">CULTURE</h4>
                <Specialist />
            </div>
            {/* PERKS */}
            <div className="seoservices career  box">
                <Container className="padmmdt  ">

                    <h3 className="text-center txtcolor  padsmdb">PERKS </h3>
                    <Row className="padsmdb" xs={12}>
                        <Col lg="4" md="4" sm="4" xs="6" >
                            <div className="circleboxfirst  padssmb">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1>Unlimited <br />Leaves</h1>

                                </div>
                                <p>No cap on leaves till we think you have absconded. Although that has never happened.</p>
                            </div>

                        </Col>
                        <Col lg="4" md="4" sm="4" xs="6" >
                            <div className="circleboxfirst  padssmb">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1>Fly Home, on <br />the house </h1>

                                </div>
                                <p>If your parents don't live in Mumbai, we fly you home and back twice a year. Visit them for Diwali or Holi!</p>
                            </div>

                        </Col>
                        <Col lg="4" md="4" sm="4" xs="6" >
                            <div className="circleboxfirst  padssmb">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1>Increase your<br /> Paycheck </h1>

                                </div>
                                <p>Every time you become an employee of the month or accomplish goals that YOU set, you make more money. </p>
                            </div>
                        </Col>
                        <Col lg="4" md="4" sm="4" xs="6" >
                            <div className="circleboxfirst  padssmb">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1>Health  <br /> Insurance</h1>

                                </div>
                                <p>We get you enough health insurance to ensure you are worry free of medical expenses.  </p>
                            </div>
                        </Col>
                        <Col lg="4" md="4" sm="4" xs="6" >
                            <div className="circleboxfirst  padssmb">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1>Employee of<br /> the Year </h1>

                                </div>
                                <p>If you win this title, you get a work Macbook, additional appraisal, 200 team points for house cup and other benefits.</p>
                            </div>
                        </Col>
                        <Col lg="4" md="4" sm="4" xs="6" >
                            <div className="circleboxfirst  padssmb">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1>Trainings about<br /> Finance and Life </h1>

                                </div>
                                <p>At A&B, we don't just train you in your field, but we train you in things like how to file taxes, invest money, how to vote etc.</p>
                            </div>
                        </Col>
                    </Row>


                </Container>
            </div>
            {/* IMAGES */}
            <div id="scroll-hero" className=" bgb specialist padbsmt padbsmb">
                <h4 className="tcwhite text-center  padbxsb ">IMAGES</h4>
                <Container >
                    <Images />
                </Container>
            </div>

            {/* Team names */}
            <div className="seoservices  box">
                <Container className="padmmdt career  ">

                    <h3 className="text-center txtcolor  padsmdb">TEAM NAMES </h3>
                    <Row className="padsmdb" xs={12}>
                        <Row xs={12}>
                            <Col lg="6" md="6" sm="6" xs="6" >
                                <div className="circleboxfirst  padssmb">
                                    <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`}  className="imgbw"  width="20%" alt="" />
                                    <div className="boxcentered">
                                        <br />
                                        <h1>Baazigar</h1>
                                    </div>

                                </div>

                            </Col>
                            <Col lg="6" md="6" sm="6" xs="6" >
                                <div className="circleboxfirst  padssmb">
                                    <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw" width="20%" alt="" />
                                    <div className="boxcentered ">
                                        <br />
                                        <h1>Pandavas</h1>

                                    </div>
                                </div>

                            </Col>
                        </Row>
                        <Row xs={12}>

                            <Col lg="4" md="4" sm="4" xs="12" className="offset-lg-4 offset-sm-4 text-center">
                                <img src={`${process.env.PUBLIC_URL}/img/swordsicons.png`} width="60%" className="d-none d-sm-block img-fluid mx-auto" alt="" />
                                <img src={`${process.env.PUBLIC_URL}/img/swordsicons.png`} width="30%" className="d-block d-sm-none img-fluid mx-auto" alt="" />
                            </Col>

                        </Row>
                        <Row className="padsmdb" xs={12}>
                            <Col lg="6" md="6" sm="6" xs="6" >
                                <div className="circleboxfirst  padssmb">
                                    <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw" width="20%" alt="" />
                                    <div className="boxcentered">
                                        <br />
                                        <h1>Incognito</h1>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="6" md="6" sm="6" xs="6" >
                                <div className="circleboxfirst  padssmb">
                                    <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw" width="20%" alt="" />
                                    <div className="boxcentered">
                                        <br />
                                        <h1>Shoorvers</h1>

                                    </div>

                                </div>
                            </Col>
                        </Row>

                    </Row>


                </Container>
            </div>
            {/* PROCESS */}
            <div className="seoservices  lgbox ">
                <Container className="padmmdt padmmdb  mbpdt30">

                    <h3 className="text-center txtcolor  padmdb mbpdt30">PROCESS </h3>
                    <Row className="padmdb  process " xs={12}>
                        <Col lg="3" md="3" sm="3" xs="12"  >
                            <div className="circleboxfirst  padssmb">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw" width="40%" alt="" />
                                <div className="boxcentered mgm40">
                                    <h1> Apply</h1>

                                </div>
                                <p className="pca">Apply on the Chatbot<br /> for the Role you like</p>
                            </div>

                        </Col>
                        
                        <Col lg="3" md="3" sm="3" xs="12" >
                            <div className="circleboxfirst  padssmb">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw" width="40%" alt="" />
                                <div className="boxcentered mgm40">
                                    <h1>5 min call </h1>

                                </div>
                                <p className="pca">We will chat up real<br /> quick to see if we are a fit. </p>
                            </div>
                        </Col>
                        <Col lg="3" md="3" sm="3" xs="12" >
                            <div className="circleboxfirst  padssmb">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw" width="40%" alt="" />
                                <div className="boxcentered mgm40">
                                    <h1>Panel Interview </h1>

                                </div>
                                <p className="pca">We will do one interview,<br /> no rounds or hassles.</p>
                            </div>
                        </Col>
                        <Col lg="3" md="3" sm="3" xs="12" >
                            <div className="circleboxfirst  padssmb">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw" width="40%" alt="" />
                                <div className="boxcentered mgm40">
                                    <h1>Paid Trial Week </h1>

                                </div>
                                <p className="pca">7 days of paid trial for both of us with no strings attached,<br /> don't like us, no problem.</p>
                            </div>
                        </Col>
                    </Row>


                </Container>
            </div>
        </div>
    );
}


export default Career;
// Images
function Images() {
    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
        <button
            {...props}
            className={
                "slick-prev slick-arrow" +
                (currentSlide === 0 ? " slick-disabled" : "")
            }
            aria-hidden="true"
            aria-disabled={currentSlide === 0 ? true : false}
            type="button"
        >
            Previous
        </button>
    );
    const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
        <button
            {...props}
            className={
                "slick-next slick-arrow" +
                (currentSlide === slideCount - 1 ? " slick-disabled" : "")
            }
            aria-hidden="true"
            aria-disabled={currentSlide === slideCount - 1 ? true : false}
            type="button"
        >
            Next
        </button>
    );
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        adaptiveHeight: true,
        prevArrow: <SlickArrowLeft />,
        nextArrow: <SlickArrowRight />,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    adaptiveHeight: false,
                }
            },]
    };
    const next = () => {
        Slider.current.slickNext();
    }
    const previous = () => {
        Slider.current.slickPrev();
    }
    return (
        <Container fluid className="pdlr0 culture ">

            <Slider {...settings}>
                <div>
                    <Col className="mx-auto " lg="9" md="9" sm="9" xs="12" >

                        <img src={`${process.env.PUBLIC_URL}/img/careers/1.png`} className="img-fluid mx-auto" alt="" />
                        <br />
                    </Col>
                </div>
                <div>
                    <Col className="mx-auto " lg="9" md="9" sm="9" xs="12" >

                        <img src={`${process.env.PUBLIC_URL}/img/careers/2.png`} className="img-fluid mx-auto" alt="" />
                        <br />
                    </Col>
                </div>
                <div>
                    <Col className="mx-auto " lg="9" md="9" sm="9" xs="12" >

                        <img src={`${process.env.PUBLIC_URL}/img/careers/3.png`} className="img-fluid mx-auto" alt="" />
                        <br />
                    </Col>
                </div>
                <div>
                    <Col className="mx-auto " lg="9" md="9" sm="9" xs="12" >

                        <img src={`${process.env.PUBLIC_URL}/img/careers/4.png`} className="img-fluid mx-auto" alt="" />
                        <br />
                    </Col>
                </div>


            </Slider>


        </Container>
    )
}
// Specialist 
function Specialist() {
    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
        <button
            {...props}
            className={
                "slick-prev slick-arrow" +
                (currentSlide === 0 ? " slick-disabled" : "")
            }
            aria-hidden="true"
            aria-disabled={currentSlide === 0 ? true : false}
            type="button"
        >
            Previous
        </button>
    );
    const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
        <button
            {...props}
            className={
                "slick-next slick-arrow" +
                (currentSlide === slideCount - 1 ? " slick-disabled" : "")
            }
            aria-hidden="true"
            aria-disabled={currentSlide === slideCount - 1 ? true : false}
            type="button"
        >
            Next
        </button>
    );
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        adaptiveHeight: true,
        prevArrow: <SlickArrowLeft />,
        nextArrow: <SlickArrowRight />,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    adaptiveHeight: true,
                }
            },]
    };
    const next = () => {
        Slider.current.slickNext();
    }
    const previous = () => {
        Slider.current.slickPrev();
    }
    return (
        <Container fluid className="pdlr0 culture ">

            <Slider {...settings}>
                <div>
                    <Col className="mx-auto " lg="6" md="6" sm="6" xs="12" >
                        <div className="circleboxfirst  padssmb ">
                            <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw mx-auto" width="30%" alt="" />
                            <div className="boxcareers ">
                                <h1 className="tcgold"><br />Core Values <br /> </h1>
                            </div>
                            <br />
                            <p className="p2">Our core values are Team, Hard Work and Craft. <br />If you aren't nice to others or love escaping work<br /> & training, A&B is not the place for you.</p>
                        </div>
                    </Col>
                </div>
                <div>
                    <Col className="mx-auto " lg="6" md="6" sm="6" xs="12" >
                        <div className="circleboxfirst  padssmb">
                            <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw mx-auto" width="30%" alt="" />
                            <div className="boxcareers">
                                <h1 className="tcgold"><br />You are not late! <br /> </h1>
                            </div>
                            <br />
                            <p className="p2">Come late, go late. Come early, leave early.<br /> Get the job done and your timing isn't our business. <br />Just inform in advance, that's all.</p>
                        </div>
                    </Col>
                </div>
                <div>
                    <Col className="mx-auto " lg="6" md="6" sm="6" xs="12" >
                        <div className="circleboxfirst  padssmb">
                            <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw mx-auto" width="30%" alt="" />
                            <div className="boxcareers">
                                <h1 className="tcgold"><br />No Cabin Policy <br /> </h1>
                            </div>
                            <br />
                            <p className="p2">You can tap on the CEO's shoulder,<br /> ask anything (really anything). Go for a walk with the Co-Founder<br /> or give your manager the order for Vada Pav.</p>
                        </div>
                    </Col>
                </div>
                <div>
                    <Col className="mx-auto " lg="6" md="6" sm="6" xs="12" >
                        <div className="circleboxfirst  padssmb">
                            <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw mx-auto" width="30%" alt="" />
                            <div className="boxcareers">
                                <h1 className="tcgold"><br /> We ❤️  Freshers. <br /> </h1>
                            </div>
                            <br />
                            <p className="p2">Yep, we do. 95% of our team hasn't worked <br />anywhere else and we intend to keep it that way. <br /> Experienced folks - we like you too, but a little less.</p>
                        </div>
                    </Col>
                </div>
                <div>
                    <Col className="mx-auto " lg="6" md="6" sm="6" xs="12" >
                        <div className="circleboxfirst  padssmb">
                            <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw mx-auto" width="30%" alt="" />
                            <div className="boxcareers">
                                <h1 className="tcgold"><br /> Feedback for everyone <br /> </h1>
                            </div>
                            <br />
                            <p className="p2">We take and give feedback to everyone (Including the leadership). Every month, just nominate a person and hear the good and the bad.</p>
                        </div>
                    </Col>
                </div>
                <div>
                    <Col className="mx-auto " lg="6" md="6" sm="6" xs="12" >
                        <div className="circleboxfirst  padssmb">
                            <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw mx-auto" width="30%" alt="" />
                            <div className="boxcareers">
                                <h1 className="tcgold"><br /> Clients ❌   &#62; Our team <br /> </h1>
                            </div>
                            <br />
                            <p className="p2">As much we are passionate and respectful to our clients, we won't let them be rude to our employees. If they abuse the team, we fire the client!</p>
                        </div>
                    </Col>
                </div>



            </Slider>


        </Container>
    );
}