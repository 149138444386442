import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Nav, Container, Accordion, Card, Button, Col, Row, Tab, Carousel } from 'react-bootstrap';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Slider from "react-slick";
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";
import Services from './Services';
function Digitalmediaplanning() {

    window.scrollTo(0, 0);
    const [focus, setFocus] = React.useState(false);
    return (

        <div>
            <Helmet>

                <title>Best Digital Media Planning & Buying Agency for *Luxury* Brands | A&B</title>
                <meta name="description" content="A media buying agency for luxury brands in Mumbai. Finding the right customers on digital is hard, we help you reach them on Insta, YouTube, Facebook etc." />
                <link rel="canonical" href="https://www.aandb.xyz/media-buying-agency-luxury.html" /> 

            </Helmet>
            <div className="firstfoldseo ">
                <div className="box  padmmdb">
                    {/* first fold */}
                    <Container fluid className="padbsmt padbsmb">
                        <Row>
                            <Col lg="2"></Col>
                            <Col lg="8">
                                <div className="circleboxfirst padsmdt padsmdb">
                                    <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="roundbx"  width="30%" alt="" />

                                    <div className="boxcentered">
                                        <h4 className="d-none d-sm-block">
                                            <span className="cuspn"><CountUp start={focus ? 0 : null} end={55} redraw={true}>
                                            {({ countUpRef }) => (
                                                <VisibilitySensor onChange={(isVisible) => {
                                                    if (isVisible) { setFocus(true); }
                                                }}>
                                                    <span ref={countUpRef} />
                                                </VisibilitySensor>
                                            )}
                                        </CountUp>%</span><span> of all budget</span> spent by Luxury brands<br /> on digital ads is <span>wasted.</span><br /><br />
                                        </h4>
                                        <h4 className="d-block d-sm-none">
                                            <span className="cuspn"><CountUp start={focus ? 0 : null} end={55} redraw={true}>
                                            {({ countUpRef }) => (
                                                <VisibilitySensor onChange={(isVisible) => {
                                                    if (isVisible) { setFocus(true); }
                                                }}>
                                                    <span ref={countUpRef} />
                                                </VisibilitySensor>
                                            )}
                                        </CountUp>%</span><span> of all budget</span><br /> spent by Luxury brands<br /> on digital ads is <span>wasted.</span><br /><br />
                                        </h4>
                                    </div>

                                </div>
                                <AnchorLink href="#iwantto" className="btn btn-seo" >Stop wasting $$ </AnchorLink>
                            </Col>
                            <Col lg="2"></Col>

                        </Row>
                    </Container>
                </div>
            </div>
            {/* Seo matters */}
            <div id="iwantto" className="seomatters  bgb">
                <Container className="padbmdt padbmdb">

                    <h3 className="text-center txtcolor  padsmdb">Why <span>this service</span></h3>
                    <Row className="padsmdb">
                        
                        <Col lg="4" md="4" sm="4">
                            <div className="circleboxfirst  padssmb pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`}  className="servicescirbox" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1> <CountUp start={focus ? 0 : null} end={76} redraw={true}>
                                            {({ countUpRef }) => (
                                                <VisibilitySensor onChange={(isVisible) => {
                                                    if (isVisible) { setFocus(true); }
                                                }}>
                                                    <span ref={countUpRef} />
                                                </VisibilitySensor>
                                            )}
                                        </CountUp> Crore</h1>
                                        <br />
                                </div>
                            </div>
                            <p>Indians are online, all of them are not your target audience. You need to escape this clutter.</p>
                            <br />
                        </Col>
                        <Col lg="4" md="4" sm="4">
                            <div className="circleboxfirst  padssmb pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`}  className="servicescirbox" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1><CountUp start={focus ? 0 : null} end={50} redraw={true}>
                                            {({ countUpRef }) => (
                                                <VisibilitySensor onChange={(isVisible) => {
                                                    if (isVisible) { setFocus(true); }
                                                }}>
                                                    <span ref={countUpRef} />
                                                </VisibilitySensor>
                                            )}
                                        </CountUp>%</h1>
                                        <br />
                                </div>
                            </div>

                            <p>of the luxury market consists of millennials who use social media platforms to discover products.</p>
                            <br />
                        </Col>
                        <Col lg="4" md="4" sm="4">
                            <div className="circleboxfirst  padssmb pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`}  className="servicescirbox" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1><CountUp start={focus ? 0 : null} end={15} redraw={true}>
                                            {({ countUpRef }) => (
                                                <VisibilitySensor onChange={(isVisible) => {
                                                    if (isVisible) { setFocus(true); }
                                                }}>
                                                    <span ref={countUpRef} />
                                                </VisibilitySensor>
                                            )}
                                        </CountUp></h1>
                                        <br />
                                </div>
                            </div>
                            <p>total touchpoints before a luxury purchase, 7 are digital and all of them can be advertised on.  </p>
                             
                        </Col>
                    </Row>
                    <AnchorLink href="#approach" className="btn btn-seo" >Get in front of buyers!</AnchorLink>
                </Container>
            </div>
            {/* our approach seo fold */}
            <div id="approach" className="seoapproach bgbl padmmdt padmmdb ">
                <Container>
                    <div className="appbox  padssmb d-none d-sm-block">
                        <img src={`${process.env.PUBLIC_URL}/img/approach2.png`} className="imgwidt img-fluid" alt="" />
                        <div className="top-left">
                            <h4><strong> Plans,</strong><br /> <i>not Intermittent Spending </i></h4>
                            <p>Most Luxury brands spend on "campaign" basis without a long term plan, let's change that.</p>
                        </div>

                        <div className="top-right">
                            <h4><strong>Audiences, </strong><br /> <i>not Platforms </i></h4>
                            <p>We sweat to understand more about the most relevant audience and their behaviour across platforms.</p>
                        </div>

                        <div className="bottom-left">
                            <h4> <strong>Budgeting,</strong><br /> <i>not Spillage</i></h4>
                            <p>We treat your budget as our own money, no spillage. We get heat from clients for spending slowly :)</p>
                        </div>


                        <div className="bottom-right">
                            <h4><strong>Growth,</strong><br /> <i>not BS</i></h4>
                            <p>We track growth metrics like ROAS & Cost per Acquisition, not BS metrics like impressions & reach.</p>
                        </div>
                        <div className="centered"><h1>OUR <span>APPROACH</span> <br />TO Digital Advertising</h1></div>
                    </div>
                    <div className="appbox  padssmb d-block d-sm-none">
                        <h1>OUR <span>APPROACH</span> <br />Digital Advertising</h1> <br />

                        <h4><strong> Plans,</strong><br /> <i>not Intermittent Spending </i></h4>
                        <p>Most Luxury brands spend on "campaign" basis without a long term plan, let's change that.</p>

                        <img src={`${process.env.PUBLIC_URL}/img/straightline.png`} className="img-fluid" />
                        <h4><strong>Audiences, </strong><br /> <i>not Platforms </i></h4>
                        <p>We sweat to understand more about the most relevant audience and their behaviour across platforms.</p>

                        <img src={`${process.env.PUBLIC_URL}/img/straightline.png`} className="img-fluid" />
                        <h4> <strong>Budgeting,</strong><br /> <i>not Spillage</i></h4>
                        <p>We treat your budget as our own money, no spillage. We get heat from clients for spending slowly :)</p>
                        <img src={`${process.env.PUBLIC_URL}/img/straightline.png`} className="img-fluid" />
                        <h4><strong>Growth,</strong><br /> <i>not BS</i></h4>
                        <p>We track growth metrics like ROAS & Cost per Acquisition, not BS metrics like impressions & reach.</p>
                    </div>

                    <Accordion defaultActiveKey="0"  className="seoapproach1 marbxst">

                        <Accordion.Toggle as={Button} variant="read" eventKey="1">
                            Our approach in detail 
                        </Accordion.Toggle>

                        <Accordion.Collapse eventKey="1">
                            <Card.Body>
                                <h3 className="text-center txtcolor  padsmdb"> <span>Digital Media Planning & Buying Agency</span></h3>
                                <Row>
                                    <p>Are you running digital advertising only on Facebook & Google? It’s high time to get out of duopoly and look at other advertising platforms. A&B brings a unique approach for running targeted digital advertising campaigns not only on Facebook & Google but also on Bing, Snapchat, Amazon, Hotstar, Quora and more such platforms. We help brands to be present where the target audience is searching for them irrespective of the advertising platform</p>
                                    <br />
                                   <ol>
                                       <li>Facebook Advertising Agency
                                    <ol type='a'>
                                        <li>
                                       <p>Why is Facebook important: </p> 
                                        <p>The "Must Have" in your digital promotion plan, Facebook advertising has moved from strength to strength over the past few years. The continued decline of organic reach of posts on Facebook has led to its advertising model to become more desirable. In fact, an organic page post has a reach of around 5.20% only. That means roughly one in every 19 fans sees the page’s non-promoted content. Hence, this makes Facebook advertising a no-brainer if you're looking to boost your online presence. </p>
                                       </li>
                                        <li>
                                       <p>Our Detailed Approach: </p> 
                                        <p>
                                        Objective - To boost your direct sales by boosting online presence, to increase brand visibility and engagement <br />
                                        Audit - We audit any past ads that you may have run (CTRs, CPCs, etc.) and optimize them to bring the overall costs in line with or below the targets  <br />
                                        Execute - 1. Website Traffic - We drive quality traffic to the website through highly relevant detailed targeting  <br />
                                        2. Lead Gen - We generate high quality leads at low costs through Remarketing & Lookalike audience targeting to reduce the overall COA.   <br />
                                        3. E-commerce - We drive revenue growth by targeting bottom and middle funnel while also driving users from the top funnel to the middle and bottom.  <br />
                                        4. Engagement - We boost posts with the right audience at the right time to gain maximum engagement. 
                                        </p>
                                        </li>
                                    
                                      </ol>
                                   </li>
                                   <li> 
                                       Instagram Advertising Agency 
                                    <ol type='a'>
                                        <li><p>Why is Instagram important: <br />
                                        Instagram is where people are the most expressive and engaged with the brand. According to a survey, 78% say that they see brands on Instagram as popular, 77% as creative and 76% as entertaining. Also, due to its increasing popularity and increased engagement with Stories, advertisers are spending more money on Instagram, 23% higher than Facebook to be precise. With more and more brands spending monies on Instagram, it cannot be missed from your media spending plans. 
                                        </p> 
                                        </li>
                                        <li>
                                        <p> Our Detailed Approach: <br />
                                        Objective - To drive results at a lower cost 
                                        Audit - We audit any past ads that you may have run (CTRs, CPCs, etc.) and optimize them to bring the overall costs in line with or below the targets <br />
                                        Execute - 1. Website Traffic - We drive quality traffic to the website through highly relevant detailed targeting <br />
                                        2. Lead Gen - We generate high quality leads at low costs through Remarketing & Lookalike audience targeting to reduce the overall COA. <br />
                                        3. E-commerce - We drive revenue growth by targeting bottom and middle funnel while also driving users from the top funnel to the middle and bottom. <br />
                                        4. Engagement - We boost of posts with the right audience to at the right time to gain maximum engagement</p>
                                        </li>
                                        <li>
                                            <p>Impact For Our Client: <br />
                                            We got a 50% less COA on Instagram than Facebook for a fashion brand 
                                            </p> <br /> 
                                        </li>
                                        </ol>
                                   </li>

                                   <li> 
                                   Google Advertising Agency
                                    <ol type='a'>
                                        <li><p>Why is Google important:  <br />
                                        Merriam’s Webster Dictionary defines Google as a verb which means to use the search engine to find information about something. It has transitioned into a verb where people look for information. So it is THE place to get the best bang for your buck so that you can harness the high intent that you get here.  
                                        </p> 
                                        </li>
                                        <li>
                                        <p> Our Detailed Approach: <br />
                                           Objective - To target people who have genuine intent towards your product/service
                                            Audit - We look at search volumes, estimated CPCs & CTRs, to suggest a plan that will drive incremental results  <br />
                                            Execute - 1. Search - We target ultra specific keywords relevant to your brand so that the quality of the users are high which ultimately results in low COA <br />
                                            2. Display Remarketing - Remarketing is the best way to leverage the Display Network to drive actionable results for your brand. We focus on the quality of the traffic rather than the reach/impressions to bring low COA <br />
                                            3. Shopping - The go-to campaign if you have a line of products that you want to advertise instead of just one single product/service.</p>
                                        </li>
                                        <li>
                                            <p>Impact For Our Client: <br />
                                            We saw a 25% increase in ROAS for an online furniture client
                                            </p> <br /> 
                                        </li>
                                        </ol>
                                   </li> 

                                   <li> 
                                   YouTube Advertising Agency
                                    <ol type='a'>
                                        <li><p>Why is YouTube important: <br />
                                        YouTube is the second most popular website after Google. Video is much more engaging and expressive in telling users what a brand is all about than plain text. By 2022, online videos will make up more than 82% of all consumer internet traffic. And YouTube will occupy a large chunk of it, which makes it an integral part of your media plan.  
                                        </p> 
                                        </li>
                                        <li>
                                        <p> Our Detailed Approach: <br />
                                        Objective - To deeply engage consumers with your brand and drive quality conversions.
                                        Audit - We look at estimated reach and CPMs of a specific campaign that you want to run and how it will perform and suggest a plan accordingly.  <br />
                                        Execute - 1. Funnel Marketing - YouTube is the go to platform for building a digital funnel from top to bottom  <br />
                                        2. Action Campaigns - We drive actions to fulfill the objectives of your business</p>
                                        </li> 
                                        </ol>
                                   </li> 

                                   <li> 
                                   Snapchat Advertising Agency
                                    <ol type='a'>
                                        <li><p> Why is Snapchat important:  <br />
                                        To reach the vastly online population of millennials and Gen Z, Snapchat advertising is the way to go. This is the audience which is increasingly having buying power and forming their life-long brand preferences. Snapchat is also a great way to communicate with the audience through stories to have a deeper impact in their minds and drive results. 
                                        </p> 
                                        </li>
                                        <li>
                                        <p> Our Detailed Approach: <br />
                                        Objective - Engage, increase followers via promotions <br />
                                        Audit - We look at any previous ad accounts/We determine whether your TG is present on Snapchat as its still an evolving platform<br />
                                        Execute - Consisting of a largely young audience, Snapchat is mainly used to drive actions through telling stories.
</p>
                                        </li>
                                         
                                        </ol>
                                   </li>   

                                    <li> 
                                    Twitter Advertising Agency
                                    <ol type='a'>
                                        <li><p> Why is Twitter important:   <br />
                                        Twitter is the way to go if you want to have a  relevant, timely and engaging interaction with your target audience. Apart from the abilities of FB ads, Twitter also allows you to increase relevancy of your communication by targeting keywords in people’s tweets.
                                        </p> 
                                        </li>
                                        <li>
                                        <p> Our Detailed Approach: <br />
                                    Objective - Finding and engaging with tailored audiences at dirt cheap CPCs compared to other social platforms. <br />
                                    Audit - We not only run A/B tests and competitive analysis to find what’s really working with our target audience but also keep monitoring the organic tweets and apply what’s working well with those tweets to our Twitter ads. <br />
                                    Execute - Apart from using high-quality creatives and writing crisp and compelling copies with clear CTAs, we make sure to clearly define the brand so that users who aren’t a part of the brand’s network are able to tell in an instant what the brand does. </p>
                                        </li>
                                         
                                        </ol>
                                   </li>  

                                   <li> 
                                   LinkedIn Advertising Agency
                                    <ol type='a'>
                                        <li><p> Why is LinkedIn important: <br />
                                        The social app for businesses and professionals! A study by HubSpot found that on average, LinkedIn ads convert users to leads at a 6.1 per cent conversion rate. LinkedIn is a great way to reach B2B customers through professional and job targeting. You can even target industry specific variables like job function, seniority, etc. which makes it an excellent platform to capture and nurture leads.
                                        </p> 
                                        </li>
                                        <li>
                                        <p> Our Detailed Approach: <br />
                                    Objective - Leveraging LinkedIn’s c-suite professional audience base for our B2B clients. <br />
                                    Audit - LinkedIn works well as a final best practice but is not enough to be advertised exclusively on. Hence our audit involves monitoring our targeting and copy parameters that work well on other platforms to repurpose on Linkedin so as to give our audience a unified experience across platforms.  <br />
                                    Execute - We select the LinkedIn ad type depending on which stage the targeted customer is in their journey. For example, sponsored in-mail ads don’t work for top-funnel audiences because they can seem a little invasive but should be used to re-engage with prospects who have already interacted with the business.
 </p>
                                        </li>
                                         
                                        </ol>
                                   </li> 

                                    <li> 
                                    Quora Advertising Agency
                                    <ol type='a'>
                                        <li><p> Why is Quora important: <br />
                                        Quora is a useful platform during the awareness and traffic generation stages of your funnel. The USP of Quora is Questions & Answers, which is a great way to build reputation and authenticity on topics and trends that are related to your brand. Another thing that works in favour of Quora is that the Quora answers often come up on the first page of google for a query which can indirectly drive traffic to your website.
                                        </p> 
                                        </li>
                                        <li>
                                        <p> Our Detailed Approach: <br />
                                        Objective - Generating high-intent leads by leveraging Quora’s credibility building platform to serve an ad between chunks of insightful information related to the business.
Audit - It’s worth taking the time to research whether your audience can be found on other channels apart from Facebook & Google, which may actually be more affordable than the ones you’re already targeting. Quora is one of those channels. Adding the Quora pixel to your site is an easy way to determine whether you should be on the platform. <br />
Execute - Question Targeting - We target highly relevant questions to your objective and to only those people who are actively reading a question so that we get the highest possible qualified audience. <br />
Topic Targeting - We target certain broad topics to expand reach on Quora if we are not getting enough volumes through question targeting.  <br />
Audience Targeting - This is ever-present across platforms and where we can upload our own customer lists and target them on Quora.

 </p>
                                        </li>
                                         
                                        </ol>
                                   </li>  

                                   <li> 
                                   Bing Advertising Agency
                                    <ol type='a'>
                                        <li><p> Why is Bing important: <br />
                                        For luxury brands, leaving Bing Ads out of their media plan is a straight up crime. It’s one-third audience has a net income in excess of $100,000. With almost 30% lower CPCs than Google Ads, Bing has much less competition than Google Ads. Bing Ads also give you access to 63 million search users that can’t be reached via Google Ads.
                                        </p> 
                                        </li>
                                        <li>
                                        <p> Our Detailed Approach: <br />
                                       Objective - To reach mature audience that cannot be reached on Google Ads
                                        Audit - We look at search volumes, estimated CPCs & CTRs, to suggest a plan that will drive incremental results  <br />
                                        Execute - 1. Search - We target ultra specific keywords relevant to your brand so that the quality of the users are high which ultimately results in low COA <br />
                                        2. Shopping - The go-to campaign if you have a line of products that you want to advertise instead of just one single product/service. </p>
                                        </li>
                                        <li>
                                        <p>  Impact For Our Client: <br />
                                        There was a 180% increase in the ROAS for Bing than Google for an online furniture client.</p>
                                        </li>
                                        </ol>
                                   </li> 
     
                                   <li> 
                                   Reddit Advertising Agency
                                    <ol type='a'>
                                        <li><p>  Why is Reddit important: <br />
                                        Reddit ads are one of the best, if not the best, way to target a niche audience which is exactly how luxury brands should advertise. Its focus on niche communities can increase the effectiveness of a simple text ad immensely, hence getting the best bang for your buck.
                                        </p> 
                                        </li>
                                        <li>
                                        <p> Our Detailed Approach: <br />
                                        Objective - To reach specific communities that cannot be reached through any other platforms
Audit - Communities like fashion may have subreddits or further niche communities like tote bags, italian watches, etc. which is very important to identify before running any campaign on reddit <br />
Execute - 1. Campaign Objective - Whether you want to drive traffic to the website or get the audience to take a specific action, we will be analyzing and getting at the right solution for you. <br />
2. Targeting - Reddit has a multitude of communities to choose from which we can target or exclude in accordance with what your brand requires. <br />
3. Ad type - Reddit has self-serve ads including only text, image, video or carousel. We will do a thorough check on whether which ad type will perform better for your brand.

 </p>
                                        </li>
                                        <li>
                                        <p>  Impact For Our Client: <br />
                                        There was a 180% increase in the ROAS for Bing than Google for an online furniture client.</p>
                                        </li>
                                        </ol>
                                   </li> 
                                   </ol>
                                    

                                </Row>
                            </Card.Body>
                        </Accordion.Collapse>

                    </Accordion>
                </Container>
                {/* <img src={`${process.env.PUBLIC_URL}/img/approach.png`} className="img-fluid" /> */}
            </div>
            {/* seo services */}
            <div className="seoservices  bgb">
                <Container className="padmmdt  ">

                    <h3 className="text-center txtcolor  padsmdb"><span>Advertising </span>Platforms </h3>
                    <Row xs={12}>
                        <Col lg="4" md="4" sm="4" xs="6" >
                            <div className="circleboxfirst  padssmb pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw" width="40%" alt="" />
                                <div className="boxcentered ">
                                    <h1>Facebook </h1>  

                                </div>
                               <p> <br />Facebook ads start being cost-effective and then become really expensive; why? ask us :)</p>
                            </div>

                        </Col>
                        <Col lg="4" md="4" sm="4" xs="6" >
                            <div className="circleboxfirst  padssmb pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1>Instagram </h1>
                                </div>
                                <p> <br />Aren't Instagram ads part of Facebook advertising? Yes and No :) </p>
                            </div>

                        </Col>
                        <Col lg="4" md="4" sm="4" xs="6" >
                            <div className="circleboxfirst  padssmb pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1>Google </h1>
                                </div>
                                <p> <br />Search, Display, Shopping-PLA, Gmail & Retargeting Ads; we understand what's right for you.</p>
                            </div>

                        </Col>
                   
                        <Col lg="4" md="4" sm="4" xs="6" >
                            <div className="circleboxfirst  padssmb pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1>Programmatic </h1>
                                </div>
                                <p> <br />With a lot of buzz around this buzzword, does your brand really need this?</p>
                            </div>

                        </Col>
                        <Col lg="4" md="4" sm="4" xs="6" >
                            <div className="circleboxfirst  padssmb pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1>YouTube </h1>
                                </div>
                                <p> <br />Is YouTube advertising brand safe? Does it generate ROI? </p>
                            </div>

                        </Col>
                        <Col lg="4" md="4" sm="4" xs="6" >
                            <a href="https://www.aandb.xyz/snapchat-marketing-agency/">
                            <div className="circleboxfirst  padssmb pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1>Snapchat </h1>
                                </div>
                                <p> <br />Isn't it dead yet? (Snapchat has grown in India from 7M to 18M :) ) Ask us why or how?</p>
                            </div>
                            </a>
                        </Col>

                    
                        <Col lg="4" md="4" sm="4" xs="6" >
                            <div className="circleboxfirst  padssmb pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1>Bing </h1>
                                </div>
                                <p> <br />Woah! Bing?? Yes, Bing. Bing drives 50% lower cost per conversion on an average.</p>
                            </div>

                        </Col>
                        <Col lg="4" md="4" sm="4" xs="6" >
                            <div className="circleboxfirst  padssmb pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1>LinkedIn </h1>
                                </div>
                                <p> <br />Have a B2B brand and a high average order value product? Let's talk about LinkedIn </p>
                            </div>

                        </Col>
                        <Col lg="4" md="4" sm="4" xs="6" >
                            <div className="circleboxfirst  padssmb pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1>Twitter </h1>
                                </div>
                                <p> <br />Are you a brand with an opinion? Let's go to the Twitter battlefront.</p>
                            </div>

                        </Col>
 
                        <Col lg="4" md="4" sm="4" xs="6" >
                            <div className="circleboxfirst  padssmb pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1>Pinterest </h1>
                                </div>
                                <p> <br />The only platform with over 70% women, now available in India.</p>
                            </div>

                        </Col>
                        <Col lg="4" md="4" sm="4" xs="6" >
                            <div className="circleboxfirst  padssmb pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1>Quora </h1>
                                </div>
                                <p> <br />The biggest intent-based platform after Google is not open for advertising. </p>
                            </div>

                        </Col>
                        {/* <Col lg="4" md="4" sm="4" xs="6" >
                            <div className="circleboxfirst  padssmb pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1>OSIDE </h1>
                                </div>
                                <p> <br />Bing, Snapchat, Twitter, Pinterest, Quora, Yahoo all rolled into one. </p>
                            </div>

                        </Col> */}

                    </Row>

                </Container>
            </div>

            {/* Case study */}
            <div className="bgfooter whyus  padsmdt padsmdb ">
                <Col lg="8" className="offset-lg-1" >
                    <h3 className=" txtcolor"><span className="spund">CASE</span> STUDY</h3>
                </Col>
                <Casestudy />
            </div>

            {/* 3 Reasons to choose */}

            <div className="bgbl casestudy ">

                <Reasons />
            </div>

            {/* our services */}
            <Services /> 
        </div>
    );
}


export default Digitalmediaplanning;

function Casestudy() {
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,

        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    adaptiveHeight: true,
                }
            },]
    };
    const next = () => {
        Slider.current.slickNext();
    }
    const previous = () => {
        Slider.current.slickPrev();
    }
    return (
        <Container fluid className="padmmdt casestudy ">
            <Row>
                <Col lg="10" className="offset-lg-1">
                    <Slider {...settings}>
                    <div>
                            <Row className="serbox">
                                <Col className="offset-lg-1" lg={{ span: 4, order: 'first' }} md="6" sm={{ span: 6, order: 'last' }} xs={{ span: 12, order: 'first' }}><img src={`${process.env.PUBLIC_URL}/img/atmantan-casestudy.png`} width="100%" className=" mx-auto d-block" /></Col>
                                <Col lg={{ span: 6, order: 'first' }} sm={{ span: 6, order: 'first' }} md="6" xs={{ span: 12, order: 'last' }} className="mbtxtcenter  offset-lg-1 padbsmb">
                                    <h1 className="padslgt padbxsb ">438% </h1>
                                    <h2 className="padbxsb text-center"><strong>jump in qualified <span>inquiries in 12 months</span></strong></h2>
                                    {/* <a href="" className="btn btn-primary ">Read Case Study</a> */}
                                    <Link to={`${process.env.PUBLIC_URL}/case-studies/online-lead-generation-case-study.html`} className="btn btn-seo " >Read Case Study</Link> 
                                </Col>

                            </Row>

                        </div>
                         

                    </Slider>
                </Col>
            </Row>
        </Container>
    );
}
// reasons
function Reasons() {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        adaptiveHeight: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false,
                    adaptiveHeight: true,
                }
            },],
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    adaptiveHeight: false,
                }
            },]
    };
    const next = () => {
        Slider.current.slickNext();
    }
    const previous = () => {
        Slider.current.slickPrev();
    }
    return (
        <Container fluid className="reasons padslgt padslgb casestudy ">
            <Row>
                <Col lg="10" className="offset-lg-1">
                    <Slider {...settings}>
                        <div>
                        <Row>
                                <Col className="offset-lg-1 offset-sm-1" lg={{ span: 4, order: 'first' }} md="4" sm={{ span: 3, order: 'first' }} xs={{ span: 12, order: 'first' }}><img src={`${process.env.PUBLIC_URL}/img/reasons3.png`} className="custmt80 padt130 padmmdt mimgw padmsmb marbsmt img-fluid mx-auto d-block" /></Col>
                                <Col className="marmlgt mt0" lg={{ span: 7, order: 'first' }} sm={{ span: 6, order: 'last' }} md="7" xs={{ span: 12, order: 'last' }}  >

                                    <h2 className="marmlgt"> Reasons to choose A&B </h2> <br />

                                </Col>

                            </Row> 

                        </div>
                        <div>
                            <Row>

                                <Col className="offset-lg-1 offset-sm-1" lg={{ span: 5, order: 'first' }} sm={{ span: 6, order: 'first' }}  xs={{ span: 12, order: 'last' }} >

                                <h2 className="padt190"><span>A&B Commitment</span></h2>
                                    <h3 className=" padmsmb">  If we don't generate <strong>growth</strong> in 3 months, <strong><i>we fire ourselves.</i></strong><br /> There are 9 more weird things about us, <br />read them here.</h3>

                                </Col>
                                <Col className="offset-lg-1 mgm90" lg={{ span: 4, order: 'first' }}  sm={{ span: 4, order: 'last' }} xs={{ span: 12, order: 'first' }}>
                                    <img src={`${process.env.PUBLIC_URL}/img/commitment.png`} className="custmt80  padslg125 mimgw img-fluid mx-auto d-block" />
                                </Col>

                            </Row>
                        </div>
                        <div>
                            <Row>

                                <Col className="offset-lg-1 offset-sm-1" lg={{ span: 5, order: 'first' }} sm={{ span: 5, order: 'first' }} xs={{ span: 12, order: 'last' }} >

                                    <h3 className="marblgt padmsmb padslg125 "> Specialised media agency trusted by global brands</h3>

                                </Col>
                                <Col className=" mgm90" lg={{ span: 5, order: 'first' }}  sm={{ span: 5, order: 'last' }} xs={{ span: 12, order: 'first' }}>
                                    <img src={`${process.env.PUBLIC_URL}/img/worldmap.png`} className="custmt80 padslg125 padbsmb img-fluid mx-auto d-block" />
                                    <img src={`${process.env.PUBLIC_URL}/img/clientslogo.png`} className="padbsmb img-fluid mx-auto d-block" />
                                </Col>
                            </Row>
                        </div>
                        
                        <div>
                        <Row> 
                                <Col className="offset-lg-1 offset-md-0" lg={{ span: 10, order: 'first' }}  md={{ span: 12, order: 'first' }}  sm={{ span: 8, order: 'last' }} xs={{ span: 12, order: 'last' }}>
                                <h3 className="text-center htitle"><span>Don't believe us?</span></h3>
                                    <p className="text-center custml ">  Hear it from our partners.</p>
                                    <div className="feedbackbox marmsmt padmsmt padmsmb">
                                     <img src={`${process.env.PUBLIC_URL}/img/leftcoma.png`}  className="mgt40 img-fluid mx-auto d-block"/>
                                     <br />
                                        
                                        <h4>There are not many SEO agencies that can work on a really high volume traffic website and grow it manifold. We have seen a 4x bump in our organic traffic that has led to massive savings in ad budgets!
                                </h4> <br />
                                         
                                    </div>
                                    <div className="clientname">
                                    <img src={`${process.env.PUBLIC_URL}/img/clients/PaytmInsider_roundedlogo.png`} className="img-fluid mx-auto" width="160px" /> <br />
                                    <p className="text-center"> - Samidh Bhattacharya - Insider.in</p>
                                    </div>
                                </Col>

                            </Row>
                             
                        </div>
                    </Slider>
                </Col>
            </Row>
        </Container>
    );
}