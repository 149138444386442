import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Nav, Container, Accordion, Card, Button, Col, Row, Tab, Carousel, Modal } from 'react-bootstrap';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Slider from "react-slick";
import { Player } from 'video-react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Services from './Services'; 
function London() { 
    return (
        <div>
            <Helmet>
                <title>Best Digital Marketing Agency in London for *Luxury* Brands | A&B</title>
                <meta name="description" content="A digital marketing agency for luxury brands in London. We focus on growth via Digital Strategy, including CRO, Search, Social, Influencers & PPC solutions." />
                <link rel="canonical" href="https://www.aandb.xyz/luxury-digital-agency-london.html" />
            </Helmet> 
            <div className="page-container">

                <div className="bloc padl padr fullscreen-bg d-bloc hero" id="hero-bloc">

                    <div className="box">
                  {/*  <video preload="none" poster={`${process.env.PUBLIC_URL}/img/firstboldbg.jpg`} alt="" className="vdtp" id="finalv" width="100%" height="100%">
    <source src="video/final.mp4" type="video/mp4" /> </video> */}
    <img  src={`${process.env.PUBLIC_URL}/img/firstboldbg.jpg`} alt="" className="vdtp" id="finalv" width="100%" height="100%">
                        </img>
                        {/* <img src={`${process.env.PUBLIC_URL}/img/firstboldbg.png`} alt=""   className="vdtp"  /> */}
                        <div className="centered">
                            <h1>Navigating Luxury <br /> Brands Through Digital</h1>

                            {/* <div className="showreel">
                            <Modalvideo /> */}
                            {/* <span> <h4> <span id="imgd"></span> &nbsp;Watch Showreel</h4></span> */}
                            {/* </div> */}
                        </div>
                        <AnchorLink href="#scroll-hero" className="btn-dwn" >
                            <img src={`${process.env.PUBLIC_URL}/img/down-arrow.png`} height="auto" width="40%" />
                            {/* <span className="fa-chevron-down fa"></span>  */}
                        </AnchorLink>

                    </div>

                </div>
            </div>
            {/* second fold specialist */}

            <div id="scroll-hero" className=" bgb specialist">
            
                <Specialist />  
            </div>


            {/* our services */}

            <div id="services" className="bgservices padbsmt padbsmb services">
        
                <Services />
                
                {/* button */}

                <Accordion defaultActiveKey="0" className="seoapproach1 ">


                    <Container>
                        <Accordion.Toggle as={Button} variant="read" eventKey="1">
                            Read More
                            {/* <i className="fa fa-chevron-circle-down"></i> */}
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body>

                                <Row>
                                <h4>About us.</h4>
                                <p>A&B is a London based luxury digital marketing agency providing digital marketing services for luxury & premium brands. We focus on growth via Digital Strategy, including CRO, Search, Media Planning, Influencers & PPC. We are hardcore data scientists & mad women/men of advertising. We are a full-service digital agency in HNI & UHNI marketing.</p>
                                <p>As a performance digital marketing agency, we want to be the best growth drivers. We are one of the best digital marketing companies that have grown over 50+ luxury brands. Spanning an experience of over more than six years, we know what it takes to grow a luxury brand. What drives our success and revenue growth? No awards, no suits, no distraction from the client’s objective. </p>
                                <h4>How is a luxury digital marketing agency different?</h4>
                                <p>A luxury digital marketing agency differs from a traditional marketing agency in several ways:</p>
                                <p><strong>Target Audience:</strong> Luxury digital marketing agencies primarily target high-end luxury consumers.</p>
                                <p><strong>Creativity:</strong> Luxury digital marketing agencies are known for their creativity and unique approach. They focus on creating visually appealing and engaging content that resonates with their client's brand image and their audience.</p>
                                <p><strong>Exclusivity:</strong> Luxury digital marketing agencies work with a limited number of clients to provide exclusive and personalized services. They ensure that each client receives the attention and resources needed to achieve their goals.</p>
                                <p>Luxury marketing agencies focus on providing high-end, personalized services to their luxury brand clients. They are known for their creativity and innovation beyond traditional digital marketing.</p>
                                <h4>Why you should hire us?</h4>
                                <p>We are one of the best luxury digital marketing agencies providing digital marketing services for more than five years. We will help you stand out from competitors and help you escape the clutter. We focus on targeting the right audience with the right buying intent and purchasing power. We place you in front of potential luxury buyers.</p>
                                <p>We will help your customers discover you through search, social or ads. Our expert marketers and creative strategists devise a plan that perfectly aligns with your marketing goals and revenue.</p>
                                
                                <h4>Why do you need a London based digital marketing agency for your brand?</h4>
                                <p>There are several reasons why you might need a top digital marketing agency for your brand:</p>
                                <p><strong>Expertise:</strong> A digital marketing agency has the experience and expertise to create compelling marketing strategies that drive results. They have a deep understanding of the latest trends and best practices in digital marketing and can help you stay ahead of the competition.</p>
                                <p><strong>Access to Technology and Tools:</strong> Digital marketing agencies invest in the latest technology and tools to assist their clients in achieving their marketing goals. By partnering with a top agency, you can gain access to these tools and technology without investing.</p>
                                <p><strong>Saves Time and Resources:</strong> Developing and executing a successful digital marketing campaign requires time, resources, and expertise. By outsourcing your digital marketing needs to an agency, you can save time and resources and focus on other essential aspects of your business.</p>
                                <p><strong>Measurable Results:</strong> A digital marketing agency will use data-driven strategies to ensure that your marketing campaigns deliver measurable results. They will track and analyze your campaigns' performance, making data-driven decisions to optimize and improve your marketing efforts.</p>
                                <p><strong>Scalability:</strong> As your brand grows, so will your marketing needs. A digital marketing agency can provide scalable solutions to your brand's changing needs, helping you achieve your marketing goals.</p>
                                
                                
                                <h4>What makes us different as a specialised online marketing agency?</h4>
                                <p>We specialise in luxury digital marketing only.</p>
                                <p>What differentiates us from other agencies offering services as a digital marketing agency specialising in luxury brands? Our extensive experience working with high-end brands has given us unique insights into what it takes to grow a luxury brand.</p>
                                <p>What makes us highly effective is we focus on key marketing channels. We only focus on focus on high-intent buying customers and target specific keywords to reach out to the premium audience. We also target international audiences by researching & targeting keywords used by international visitors.</p>

                                <p><strong>We want to increase your profits, that's it.</strong></p>
                                <p>Our focus is to increase your profits while you focus on the rest. We work hard to increase your conversion rate and customer lifetime value. Our team of hardworking data scientists will increase traffic, generate leads, and convert traffic into customers while staying within the budget. We will provide you with actual accurate data and not imaginary numbers.</p>
                                <p><strong>We treat your budget like ours. </strong></p>
                                <p>We know that a budget is crucial to any company. So we always make sure we never overspend. Before deciding on any project, our team works on a practical budget planning process. The budget is created while keeping the company's priorities in mind and setting achievable and measurable goals.</p>
                                <p><strong>Our Services as a Digital Strategy Agency</strong></p>

                                <p><strong>Luxury eCommerce Sales Growth Agency</strong></p>
                                <p>The most crucial part of driving sales to your eCommerce store is driving traffic that converts. What percentage of your eCommerce website do visitors convert? If your eCommerce store needs to meet your end goal revenue, we provide eCommerce SEO services that will help you increase your store traffic and revenue.</p>
                                <p>We audit and look for areas and acquisition mediums (such as ads, organic, etc.) that can really drive traffic. The second part is ensuring that the traffic that has landed is converting on the website.</p>
                                <p><strong>Luxury Retail Store Footfall Growth Agency in London</strong></p>
                                <p>We bring actual walk-ins, not just engagement/visitors, on social media and the website. The walk-ins that happen our high intent and relevant shoppers and not just window shoppers! 75% of the users who make the "near me" search visit the store they see increasing the correct footfalls to your offline store in the age of online shopping is crucial because a couple of users still want to experience the look and feel of the product before making a purchase decision.</p>
                                <p><strong>Luxury Digital Lead Generation Agency in London</strong></p>
                                <p>What percentage of leads that you get from digital converts? The process for the unknown prospect to become your paying customer is what we optimise for. Out of all the traffic that comes through digital channels, generating leads that convert for your business is crucial. Business owners usually assume that the B2B lead generation is as simple as Prospects {'>'} Customers. Still, in reality, the prospects need to be nurtured in many stages: Unknown user {'>'} Content {'>'} Prospect {'>'} Queries {'>'} Customer.</p>
                                <p><strong>Luxury Digital Media Planning and Buying Agency in London</strong></p>
                                <p>Everyone is running ads on mainstream platforms such as Google & Facebook (which is saturating day by day). Our approach as a media planning agency for digital advertising is to be present where the prospects are. Be it Quora, Linkedin, Pinterest, Bing, Snapchat, or any other platform. We will place you in front of the right customers where there are less competition and more conversions!</p>
                                <p><strong>Luxury Conversion Rate Optimisation Agency in London</strong></p>
                                <p>What is the average conversion rate of your website? Just imagine doubling your revenue with the same traffic on your website. That's possible with the right CRO strategy! Our approach is to make the website usability so easy that the prospects don't face any issues while converting. We don't keep any stone unturned to make this happen. The variety of tools we use gives us a detailed look at what users are doing on the website.</p>
                                <p><strong>Luxury Search Engine Optimisation Agency in London</strong></p>
                                <p>Do you know your brand vs non-brand traffic ratio coming through organic searches? SEO is easily the most sought-after and high-converting acquisition medium and the hardest nut to crack. With the right SEO agency and strategy, you can see exponential growth in your overall brand growth. It not only helps in increasing your revenue but also works to improve your brand. With an increasing focus on topic and entity optimisation, SEO will revolutionise in the coming years!</p>
                                <p><strong>Luxury Marketing Automation Agency in London</strong></p>
                                <p><strong>76% of business owners using automation see a positive ROI within a year!</strong></p>
                                <p>Machine learning and Artificial intelligence are not left behind in the marketing industry. With so much data available, we must get the correct insight and use it to improve our overall performance. Automating Google ads through dynamic feed or the meta titles of your website through a sheet is just the starter for this. Automation is the future {'(a bit far)'}, but it's on the way.</p>
                                <p><strong>Luxury Conversational Marketing Agency in London</strong></p>
                                <p>Up to 44% of consumers have used chatbots, and almost 50% are open to buying items while interacting with the chatbot.</p>
                                <p>36% of companies now use live chatbot functions for daily marketing, sales or customer service inquiries.</p>
                                <p>79% of consumers are willing to use messaging apps to get customer service from companies as opposed to more traditional forms of communication. Improving how your users communicate with you can impact your brand long-term. Chatbots, AR lenses, Emojis, and all these technologies must be used now. If we can conservatively market our products, that doesn't even look like marketing.</p>
                                <p><strong>Our Work & Case Studies in Luxury Marketing</strong><br/><a href='https://www.aandb.xyz/case-studies/isaia-retail-store-digital-transformation.html'>ISAIA</a> - 100% Jump in walkins in 4 months <br/>ISAIA is a luxury menswear brand founded in Naples, Italy. </p>
                                <br/>
                                
                                <p>Problem <br/> <br/>They were present in India's most luxurious mall, DLF Emporio, in South Delhi. Still, They were struggling to get footfalls because of the obscure store location and lack of brand awareness & aspiration.</p>
                                <p>Solution</p>
                                <p>While ISAIA were worn by all the top stars, including Robert Downey Jr., Dwayne Johnson, Robert Pattinson etc., they did not discuss this fact. We took insight from the industry and developed communication around celebrities. Every piece of communication was around this itself.</p>
                                <p>Impact</p>
                                <p>The in-store revenue increased by 76.34% from Feb {'(when the store launched)'} to September. Store Footfalls increased by 100% in 4 months. The number of calls from digital increased by 100%. Call Inquiries turned to walk-ins and generated leads.</p>
                                <p><a href='https://www.aandb.xyz/case-studies/ecommerce-advertising-case-study.html'>Limelight</a> - 780% ROI via Digital, scaled, non-existent e-commerce.</p>
                                <p>Limelight Diamonds is India's leading sustainable diamond luxury brand.</p>
                                <p>Problem <br/> <br/> For the thousands of people wanting to know about Lab Grown Diamonds and the brand still needs to be discovered. E-commerce sales were negligible. The website required e-commerce features leading to a better buying experience. Online trust, which is critical in the category, needed to be included.</p>
                                <p>Solution</p>
                                <p>The Limelight website was built well but needed optimisation and more performance factors to push users in the purchase flow. We added a celebrity fold, influencers collab section & customer testimonial on the homepage and all product pages. Captured a high-intent audience looking/wanting to learn about lab-grown diamonds.</p>
                                <p>Impact</p>
                                <p>Overall revenue of the company via digital went up by +503%. Limelight Diamonds achieved an ROI of 780% via digital. The product pageview increased by 60%, and the bounce rate decreased by 73%.</p>
                                <p><a href='https://www.aandb.xyz/case-studies/online-lead-generation-case-study.html'>Atmantan</a> <br/><br/>Atmantan is an international luxury wellness destination.</p>
                                <p>Problem <br/> <br/> There was no way for a user to contact the resort after landing on a page. The website took more than 8 seconds to load. The landing pages were text-heavy and not built for a user looking for a wellness solution to their problem.</p>
                                <p>Solution</p>
                                <p>We added a contact form at the bottom of all the landing pages. We set up goals in analytics to track those inquiries via the website. Streamlined the inquiry flow via a CRM so the user can be reached quickly after an enquiry.</p>
                                <p>It took up to 48 hours for a callback, and now, it takes barely 10 minutes.</p>
                                <p>The website now loads in about 3.7 seconds. We changed the landing pages so that the users can see which retreat is suitable for which objective and made it more user-friendly.</p>
                                <p>Impact</p>
                                <p>Revenue went up by 383%, and Inquiries went up by 130.36%. The company witnessed a 438% jump in qualified inquiries in 12 months.</p>
                                <p>FAQ</p>
                                <p>What is luxury brand digital marketing?</p>

                                <p>Luxury digital marketing differs from digital marketing as luxury marketing agencies only cater to luxury brands. They help you escape the clutter and market your brand to the HNWI individuals with the purchase intent.</p>

                                <p><strong>Why is digital marketing important for luxury brands?</strong></p>

                                <p>81% of retail shoppers conduct online research before making an informed buying decision and 80% of luxury sales are digitally influenced. </p>

                                <p><strong>How is luxury marketing different?</strong></p>

                                <p>Luxury marketing is different as luxury shoppers are different from normal consumers. a luxury digital agency knows how to target the right audience as the companies have experience working with high-end brands. </p>
                                </Row>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Container>
                </Accordion>
            </div>
            {/* Our Work */}
            <div className="work bgwork padmsmt padmsmb " >
                <Col lg="10" className="offset-lg-1" >
                    <h3><span className="spund">Ou</span>r Work</h3>
                </Col>
                {/* for desktop */}
                <Container className="padmsmt d-none d-sm-block">
                    <Row>
                    <Col lg="4" md="4" sm="4">
                    <div className="bg">
                        <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casaire1.png`} alt="" width="100%" height="100%" />
                        <div className="centeredbox"><h4>Luxury Furniture Brand Dubai - Walkins up by 400% - Case Study</h4>
                            <Link to="">View Case Study &gt;</Link></div>
                        <div className="overlay">
                            <h1>
                            CASAIRE <br />
                            </h1>
                            <h2>
                                <Link to={`${process.env.PUBLIC_URL}/case-studies/dubai-luxury-furniture-store-walkin-case-study.html`}>
                                    <u>Luxury Furniture Brand Dubai - Walkins up by 400% - Case Study</u>
                                </Link> </h2>

                            {/* <p>View Case Study</p> */}
                        </div>
                    </div>
                </Col>
                        <Col lg="4" md="4" sm="4">
                            <div className="bg">
                                <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/1seagallery.png`} alt="" width="100%" height="100%" />
                                <div className="centeredbox"><h4>438% jump in qualified inquiries in 12 months</h4>
                                    <Link to={`${process.env.PUBLIC_URL}/case-studies/online-lead-generation-case-study.html`}>View Case Study &gt;</Link></div>
                                <div className="overlay ">
                                    <h1>
                                        Atmantan<br />
                                    </h1>
                                    <h2>
                                        <Link to={`${process.env.PUBLIC_URL}/case-studies/online-lead-generation-case-study.html`}>
                                            <u>438% jump in qualified inquiries in 12 months</u>
                                        </Link>
                                        <br />
                                    </h2> 
                                </div>
                            </div>
                        </Col>
                        <Col lg="4" md="4" sm="4">
                            <div className="bg">
                                <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/paytmInsider.png`} alt="" width="100%" height="100%" />
                                <div className="centeredbox"><h4>400% increase in organic traffic, and 200% lift in conversions.</h4>
                                    <Link to="">View Case Study &gt;</Link></div>
                                <div className="overlay">
                                    <h1>Insider<br /></h1>
                                    <h2>
                                        <Link to={`${process.env.PUBLIC_URL}/case-studies/seo-case-study-for-event-ticketing-company.html`}>
                                            <u>400% increase in organic traffic, and 200% lift in conversions.</u>
                                        </Link></h2>
                                    {/* <p>View Case Study</p> */}
                                </div>
                            </div>
                        </Col>
                        <Col lg="4" md="4" sm="4" className="marmxst">
                            <div className="bg">
                                <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/evok.png`} className="customtint" alt="" width="100%" height="100%" />
                                <div className="centeredbox"><h4>Scaled  Ecommerce Revenue by 2404%</h4>
                                    <Link to="">View Case Study &gt;</Link></div>
                                <div className="overlay">
                                    <h1>Evok <br /></h1>
                                    <h2>
                                        <Link to={`${process.env.PUBLIC_URL}/case-studies/scaling-evok-ecommerce-revenue.html`}>
                                            <u>Scaled  Ecommerce Revenue by 2404%</u>
                                        </Link></h2>
                                    {/* <p>View Case Study</p> */}
                                </div>
                            </div>
                        </Col>

                        <Col lg="4" md="4" sm="4" className="marmxst">
                            <div className="bg">
                                <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/Parfait.png`} alt="" width="100%" height="100%" />
                                <div className="centeredbox"><h4>Magazine covers became size inclusive with just a lens!</h4>
                                    <Link to="">View Case Study &gt;</Link></div>
                                <div className="overlay">
                                    <h1>
                                        Parfait <br />
                                    </h1>
                                    <h2>
                                        <Link to={`${process.env.PUBLIC_URL}/case-studies/parfait-snapchat-instagram-case-study.html`}>
                                            <u>Magazine covers became size inclusive with just a lens!</u>
                                        </Link>
                                        <br /></h2>
                                    {/* <p>View Case Study</p> */}
                                </div>
                            </div>
                        </Col>
                        <Col lg="4" md="4" sm="4" className="marmxst">
                            <div className="bg">
                                <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/Isaia.png`} alt="" width="100%" height="100%" />
                                <div className="centeredbox"><h4>100% Jump in Walkin Revenue in 4 Months</h4>
                                    <Link to={`${process.env.PUBLIC_URL}/case-studies/isaia-retail-store-digital-transformation.html`}>View Case Study &gt;</Link></div>
                                <div className="overlay">
                                    <h1>
                                        ISAIA <br />
                                    </h1>
                                    <h2>
                                        <Link to={`${process.env.PUBLIC_URL}/case-studies/isaia-retail-store-digital-transformation.html`}>
                                            <u>100% Jump in Walkin Revenue in 4 Months</u>
                                        </Link>
                                    </h2>
                                    {/* <p>View Case Study</p> */}
                                </div>
                            </div>
                        </Col>
                       
                    </Row>

                </Container>
                <Container className="d-block d-sm-none">
                    <Ourworkmob />
                </Container>
            </div>
            {/* why us */}
            <div className="bgfooter whyus padsmdt padbmdb mbpadbmdb">
                <Col lg="8" className="offset-lg-1" >
                    <h3 className=" txtcolor"><span className="spund">WHY </span>US</h3>
                </Col>
                <Whyus />


            </div>
            {/* Our clients */}
            <div id="clients" className="clients bgclients padmsmt padmsmb">
                <Col lg="10" xs="12" className="offset-lg-1" >
                    <h3 className=" txtcolor"><span className="spund">Ou</span>r Clients</h3>
                </Col>


                <Container>
                    <Row>
                    <Col lg="3" md="3" sm="3" xs="4" className="padsmdt ">
                    <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/clients/adani-logo.png`} alt="" className="img-fluid cbnimg" />
                </Col>

                        <Col lg="3" md="3" sm="3" xs="4" className="padsmdt ">
                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/clients/atmantan.png`} alt="" className="img-fluid cbnimg" />
                        </Col>

                        <Col lg="3" md="3" sm="3" xs="4" className="padsmdt ">
                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/clients/blabel.png`} alt="" className="img-fluid clnimg" />
                        </Col>

                        <Col lg="3" md="3" sm="3" xs="4" className="padsmdt ">
                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/clients/bovetlogo.png`} alt="" className="img-fluid clnimg" />

                        </Col>
                        <Col lg="3" md="3" sm="3" xs="4" className="padsmdt ">
                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/clients/evok.png`} alt="" className="img-fluid clnimg" />
                        </Col>

                        <Col lg="3" md="3" sm="3" xs="4" className="padsmdt ">
                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/clients/Isaia.png`} alt="" className="img-fluid  cbnimg" />
                        </Col>

                        <Col lg="3" md="3" sm="3" xs="4" className="padsmdt ">
                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/clients/johnlobb.png`} alt="" className="img-fluid  cbnimg" />
                        </Col>

                        <Col lg="3" md="3" sm="3" xs="4" className="padsmdt ">
                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/clients/kitonlogo.png`} alt="" className="img-fluid clnimg" />
                        </Col>
                        <Col lg="3" md="3" sm="3" xs="4" className="padsmdt ">
                            {/* <HoverImage src={`${process.env.PUBLIC_URL}/img/clients/leviswhite.png`} hoverSrc={`${process.env.PUBLIC_URL}/img/clients/levis.png`} className="img-fluid clnimg" /> */}
                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/clients/levis.png`} alt="" className="img-fluid cbnimg"
                            />

                        </Col>
                        <Col lg="3" md="3" sm="3" xs="4" className="padsmdt d-none d-sm-block">

                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/clients/marico.png`} alt="" className="img-fluid cbnimg"
                            />

                        </Col>
                        <Col lg="3" md="3" sm="3" xs="4" className="padsmdt ">
                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/clients/myntra.png`} alt="" className="img-fluid clnimg1" />
                        </Col>

                        <Col lg="3" md="3" sm="3" xs="4" className="padsmdt d-none d-sm-block">
                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/clients/weekender.png`} alt="" className="img-fluid clnimg1" />
                        </Col>

                       
                        <Col lg="3" md="3" sm="3" xs="4" className="padsmdt ">
                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/clients/paytminsider.png`} alt="" className="img-fluid clnimg" />
                        </Col>


                        <Col lg="3" md="3" sm="3" xs="4" className="padsmdt ">
                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/clients/queo.png`} alt="" className="img-fluid clnimg" />
                        </Col>
                        <Col lg="3" md="3" sm="3" xs="4" className="padsmdt ">
                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/clients/Regalia.png`} alt="" className="img-fluid cbnimg" />
                        </Col>
                        <Col lg="3" md="3" sm="3" xs="4" className="padsmdt ">
                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/clients/ressence.png`} alt="" className="img-fluid clnimg" />
                        </Col>
                        <Col lg="3" md="3" sm="3" xs="4" className="padsmdt ">
                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/clients/sarda-white.png`} alt="" className="img-fluid clnimg1" />

                        </Col>
                        <Col lg="3" md="3" sm="3" xs="4" className="padsmdt ">
                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/clients/saundhlogo.png`} alt="" className="img-fluid clnimg" />
                        </Col>
                        <Col lg="3" md="3" sm="3" xs="4" className="padsmdt ">
                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/clients/vice.png`} alt="" className="img-fluid clnimg" />
                        </Col>
                        <Col lg="3" md="3" sm="3" xs="4" className="padsmdt ">
                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/clients/warner-bros.png`} alt="" className="img-fluid clnimg1" />
                        </Col>
                        <Col lg="3" md="3" sm="3" xs="4" className="padsmdt ">
                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/clients/ceew.png`} alt="" className="img-fluid clnimg" />
                        </Col>
                        <Col lg="3" md="3" sm="3" xs="4" className="padsmdt ">
                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/clients/limelight.png`} alt="" className="img-fluid clnimg" />
                        </Col>
                        <Col lg="3" md="3" sm="3" xs="4" className="padsmdt ">
                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/clients/novakid.png`} alt="" className="img-fluid clnimg" />
                        </Col>
                        <Col lg="3" md="3" sm="3" xs="4" className="padsmdt ">
                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/clients/squareoff.png`} alt="" className="img-fluid clnimg" />
                        </Col>
                        <Col lg="3" md="3" sm="3" xs="4" className="padsmdt ">
                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/clients/Aprilia.png`} alt="" className="img-fluid clnimg" />
                        </Col>
                        <Col lg="3" md="3" sm="3" xs="4" className="padsmdt ">
                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/clients/Carysil.png`} alt="" className="img-fluid clnimg1" />
                        </Col>
                        <Col lg="3" md="3" sm="3" xs="4" className="padsmdt ">
                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/clients/Gerald-Charles.png`} alt="" className="img-fluid clnimg" />
                        </Col>
                        <Col lg="3" md="3" sm="3" xs="4" className="padsmdt ">
                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/clients/Vespa.png`} alt="" className="img-fluid clnimg" />
                        </Col>

                    </Row>
                </Container>
            </div>
            {/* our commitment */}
            <div className="commitment  bgcommit padmsmt padbmdb">
                <Col lg="10" className="offset-lg-1" >
                    <h3 className=" txtcolor"><span className="spund">CO</span>MMITMENT</h3>
                </Col>
                <Container>
                    <Row>
                        <Col lg={{ span: 6, order: 'first' }} md="6" xs={{ span: 12, order: 'last' }} className="padmlgt">
                            <h4 className="tcwhite"><span className="quotesize">“</span>

                            Our endeavour is simple,<br />  we drive growth in 3 months,<br />  or we part ways.
                                <span className="quotesize">”</span></h4>
                            <div className="text-left tcgold padmsmt flr">
                                <h5>- Rosalie N & Chetan J</h5>
                                <h5>Division Chiefs of UK</h5>
                            </div>
                        </Col>
                        <Col lg={{ span: 5, order: 'first' }} md="6" xs={{ span: 12, order: 'first' }} className="padsmdt offset-lg-1">
                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/chetan.jpg`} alt="" className="img-fluid mx-auto d-block" />
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* our associations & certifications */}
            <div className="certifications bgb padmsmt padbmdb">
                <Col lg="8" className="offset-lg-1" >
                    <h3 className=" txtcolor"><span className="spund">OU</span>R ASSOCIATIONS & CERTIFICATIONS</h3>
                </Col>
                <Container className="text-center">
                    <Row>
                        <Col lg="4" md="4" sm="4" xs="6" className="padbmdt ">
                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/certifications/facebookmarketplanner.png`} alt="" className="img-fluid mx-auto clnimg2" />
                        </Col>
                        <Col lg="4" md="4" sm="4" xs="6" className="">
                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/certifications/googlepartner.png`} alt="" className="img-fluid mx-auto clnimg2" />
                        </Col>
                        <Col lg="4" md="4" sm="4" xs="6" className="padbmdt d-none d-sm-block">
                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/certifications/youtubecertified.png`} alt="" className="img-fluid mx-auto clnimg2" />
                        </Col>
                        <Col lg="4" md="4" sm="4" xs="6" className="padbmdt ">
                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/certifications/displayvideo360.png`} alt="" className="mt13 img-fluid mx-auto clnimg" />
                        </Col>
                        <Col lg="4" md="4" sm="4" xs="6" className="padbmdt ">
                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/certifications/adobemarketingcloud.png`} alt="" className="mbt40 img-fluid mx-auto clnimg2" />

                        </Col>

                        <Col lg="4" md="4" sm="4" xs="6" className="padbmdt ">
                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/certifications/facebookblueprint.png`} alt="" className="img-fluid mx-auto clnimg2" />
                        </Col>

                        <Col lg="4" md="4" sm="4" xs="6" className="padbmdt ">
                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/certifications/googlemarketingplatform.png`} alt="" className="mbcs40 img-fluid mx-auto clnimg" />
                        </Col>
                        <Col lg="4" md="4" sm="4" xs="6" className="padbmdt ">
                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/certifications/googleanalytics.png`} alt="" className="img-fluid mx-auto clnimg" />
                        </Col>


                        <Col lg="4" md="4" sm="4" xs="6" className="padbmdt ">
                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/certifications/microsoftadvertising.png`} alt="" className="img-fluid  mx-auto clnimg" />
                        </Col>

                    </Row>
                </Container>
            </div>
        </div >

    );
}

export default London;

// Specialist 
function Specialist() {
    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
        <button
            {...props}
            className={
                "slick-prev slick-arrow" +
                (currentSlide === 0 ? " slick-disabled" : "")
            }
            aria-hidden="true"
            aria-disabled={currentSlide === 0 ? true : false}
            type="button"
        >
            Previous
        </button>
    );
    const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
        <button
            {...props}
            className={
                "slick-next slick-arrow" +
                (currentSlide === slideCount - 1 ? " slick-disabled" : "")
            }
            aria-hidden="true"
            aria-disabled={currentSlide === slideCount - 1 ? true : false}
            type="button"
        >
            Next
        </button>
    );
    const settings = {
        dots: true,
        lazyLoad: "progressive", 
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        adaptiveHeight: true,
        prevArrow: <SlickArrowLeft />,
        nextArrow: <SlickArrowRight />,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1, 
                    infinite: true,
                    dots: true,
                    adaptiveHeight: true,
                }
            },]
    };
    const next = () => {
        Slider.current.slickNext();
    }
    const previous = () => {
        Slider.current.slickPrev();
    }
    return (
        <Container fluid className="pdlr0 bgservices">

            <Slider {...settings}>
                <div> 
                    <div className="homepgesecond">
                        <img  src={`${process.env.PUBLIC_URL}/img/1luxury.jpg`} width="100%" height="100%"   className=" d-none d-sm-block" />
                        <img  src={`${process.env.PUBLIC_URL}/img/1luxurymob.png`} width="100%" height="100%" className="d-block  d-sm-none" />
                        <div className=" homesecondfoldmob homesecondfoldcentered ">
                            <h2 className=" d-none d-sm-block">80% of all luxury sales are digitally influenced, <br />but...</h2>
                            <h2 className=" d-block d-sm-none">80% of all luxury sales <br/> are digitally influenced, <br />but...</h2>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="homepgesecond">
                        <img  src={`${process.env.PUBLIC_URL}/img/2moneyspent.jpg`} width="100%" height="100%" className=" d-none d-sm-block " />
                        <img  src={`${process.env.PUBLIC_URL}/img/2moneybrandsmob.png`} width="100%" height="100%" className="d-block d-sm-none" />
                        <div className="hbottomfcenter">
                            <h2 className="hfor3  d-none d-sm-block">55% of all money spent by luxury brands on digital is wasted.</h2>
                            <h2 className="hfor3  d-block d-sm-none">55% of all money spent<br /> by luxury brands on<br /> digital is wasted.</h2>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="homepgesecond">
                        <img  src={`${process.env.PUBLIC_URL}/img/3clutteraudience.jpg`} width="100%" height="100%" className=" d-none d-sm-block" />
                        {/* <img src={`${process.env.PUBLIC_URL}/img/3clutteraudience.png`} width="100%" height="100%" className=" d-none d-sm-block " /> */}
                        <img  src={`${process.env.PUBLIC_URL}/img/3clutteraudiencemob.png`} width="100%" height="100%" className="d-block d-sm-none" />
                        <div className="hbottomfcenter">
                            <h2 className="hfor3  d-none d-sm-block">Due to clutter, the budget reaches the wrong audience</h2>
                            <h2 className="hfor3  d-block d-sm-none">Due to clutter, the budget<br /> reaches the wrong audience</h2>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="homepgesecond">
                        <img  src={`${process.env.PUBLIC_URL}/img/london.jpg`} width="100%" height="100%" className=" d-none d-sm-block" />
                        {/* <img src={`${process.env.PUBLIC_URL}/img/4clutterindiansonline.jpg`} width="100%" height="100%" className=" d-none d-sm-block brgt" /> */}
                        <img  src={`${process.env.PUBLIC_URL}/img/london-mobile.jpg`} width="100%" height="100%" className="d-block d-sm-none brgt" />
                        {/* <div className="homesecondfoldcentered"><h2 className="clutterh3">Clutter.</h2></div> */}
                        <div className="hbottomfcenter hbottom4slide">
                            <h2 className="clutterh3">Clutter.</h2> <br />
                            <h2 className="hfor3 d-none d-sm-block">More than 67 million Britons <br />are online now</h2>
                            <h2 className="hfor3 d-block d-sm-none ">More than 67 million Britons <br/>are online now</h2>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="homepgesecond">
                        <img  src={`${process.env.PUBLIC_URL}/img/London.jpg`} width="100%" height="100%" className=" d-none d-sm-block" />
                        {/* <img src={`${process.env.PUBLIC_URL}/img/5clutterpeople.jpg`} width="100%" height="100%" className=" d-none d-sm-block" /> */}
                        <img  src={`${process.env.PUBLIC_URL}/img/London-mobile.jpg`} width="100%" height="100%" className="d-block d-sm-none" />
                        {/* <div className="homesecondfoldcentered"><h2 className="clutterh3">Clutter.</h2></div> */}
                        <div className="hbottomfcenter hbottom4slide">
                            <h2 className="clutterh3">Clutter.</h2> <br />
                            <h2 className="hfor3 d-none d-sm-block">67 Million people are not your audience.</h2>
                            <h2 className="hfor3 d-block d-sm-none">67 Million people are not <br />your audience.</h2>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="homepgesecond">
                        <img  src={`${process.env.PUBLIC_URL}/img/6clutter-brand.jpg`} width="100%" height="100%" className=" d-none d-sm-block" />
                        <img  src={`${process.env.PUBLIC_URL}/img/6clutter-brandmob.png`} width="100%" height="100%" className="d-block d-sm-none" />
                        {/* <div className="homesecondfoldcentered"><h2 className="clutterh3">Clutter.</h2></div> */}
                        <div className="hbottomfcenter hbottom4slide">
                            <h2 className="clutterh3">Clutter.</h2> <br />
                            <h2 className="hfor3 d-none d-sm-block">Digital is now a mass medium, but you are not a mass brand.</h2>
                            <h2 className="hfor3 d-block d-sm-none">Digital is now a mass medium, but you are not a mass brand.</h2>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="homepgesecond">
                        <img  src={`${process.env.PUBLIC_URL}/img/7clutterleads.jpg`} width="100%" height="100%" className=" d-none d-sm-block" />
                        {/* <img src={`${process.env.PUBLIC_URL}/img/7clutterleads.png`} width="100%" height="100%" className=" d-none d-sm-block" /> */}
                        <img  src={`${process.env.PUBLIC_URL}/img/7clutterleadsmob.png`} width="100%" height="100%" className="d-block d-sm-none" />
                        <div className="homesecondfoldcentered1"><h2>This Clutter Leads To</h2></div>
                        <div className="secondlastcentered">
                            <Container>
                                <Row>
                                    <Col lg="3" md="3" sm="3">
                                        <div className="homepgesecond">
                                            <img  src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="mx-auto img-fluid mobcirclwd" />
                                            <div className="homecircle">
                                                <h2 className="secondlasth2 d-none d-sm-block">Poor ROI<br /> from Social<br /> Media</h2>
                                                <h2 className="secondlasth2 d-block d-sm-none">Poor ROI from<br />  Social Media</h2>
                                            </div>

                                        </div>
                                    </Col>
                                    <Col lg="3" md="3" sm="3">
                                        <img  src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="mx-auto  img-fluid mobcirclwd" />
                                        <div className="homecircle">
                                            <h2 className="secondlasth2  d-none d-sm-block">Low <br />E-commerce <br />Conversion Rate</h2>
                                            <h2 className="secondlasth2  d-block d-sm-none">Low E-commerce <br />Conversion Rate</h2>
                                        </div>
                                    </Col>
                                    <Col lg="3" md="3" sm="3">
                                        <img  src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="mx-auto  img-fluid mobcirclwd" />
                                        <div className="homecircle">
                                            <h2 className="secondlasth2 d-none d-sm-block">Wastage of<br /> budget in<br /> Paid Ads</h2>
                                            <h2 className="secondlasth2 d-block d-sm-none">Wastage of<br /> budget in Paid Ads</h2>
                                        </div>
                                    </Col>
                                    <Col lg="3" md="3" sm="3" >
                                        <img  src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="mx-auto  img-fluid mobcirclwd" />
                                        <div className="homecircle">
                                            <h2 className="secondlasth2 d-none d-sm-block">No revenue<br /> from Content<br /> & Influencers</h2>
                                            <h2 className="secondlasth2 d-block d-sm-none">No revenue from<br /> Content & Influencers</h2>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                </div>
                <div className="homepgesecond">
                    <img  src={`${process.env.PUBLIC_URL}/img/8digitalclutter.jpg`} width="100%" height="100%" className=" d-none d-sm-block" />
                    <img  src={`${process.env.PUBLIC_URL}/img/8digitalcluttermob.png`} width="100%" height="100%" className="d-block d-sm-none" />
                    <div className="homelastfoldmob homesecondfoldcentered  ">
                        <h2 className="d-none d-sm-block ">Digital is cluttered,<br /> we help luxury brands navigate.</h2>
                        <h2 className="d-block d-sm-none h24digital">Digital is cluttered,<br /> <br /> we help luxury<br /> brands navigate.</h2>
                    </div>
                </div>


            </Slider>


        </Container>
    );
}

// why us
function Whyus() {
    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
        <button
            {...props}
            className={
                "slick-prev slick-arrow" +
                (currentSlide === 0 ? " slick-disabled" : "")
            }
            aria-hidden="true"
            aria-disabled={currentSlide === 0 ? true : false}
            type="button"
        >
            Previous
        </button>
    );
    const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
        <button
            {...props}
            className={
                "slick-next slick-arrow" +
                (currentSlide === slideCount - 1 ? " slick-disabled" : "")
            }
            aria-hidden="true"
            aria-disabled={currentSlide === slideCount - 1 ? true : false}
            type="button"
        >
            Next
        </button>
    );
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        fade: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        prevArrow: <SlickArrowLeft />,
        nextArrow: <SlickArrowRight />,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    adaptiveHeight: false,
                }
            },]
    };
    const next = () => {
        Slider.current.slickNext();
    }
    const previous = () => {
        Slider.current.slickPrev();
    }
    return (
        <Container fluid className="padmmdt">
            <Row>
                <Col lg="10" className="offset-lg-1 offset-sm-1" sm="10">
                    <Slider {...settings}>
                        <div>
                            <Row>
                                <Col lg={{ span: 6, order: 'first' }} sm={{ span: 6, order: 'first' }} md="6" xs={{ span: 12, order: 'last' }} className="offset-lg-1">  <h2 className="padslgt h2csf">What <br /> makes us<br />  <span>different?</span></h2></Col>
                                <Col lg={{ span: 4, order: 'last' }} md="6" sm={{ span: 6, order: 'last' }} xs={{ span: 12, order: 'first' }}>
                                    <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/different.png`} className="padssmt csmimg  img-fluid mx-auto d-block" /></Col>
                            </Row>

                        </div>
                        <div>
                            <Row>
                                <Col lg={{ span: 7, order: 'first' }} sm={{ span: 6, order: 'first' }} md="6" xs={{ span: 12, order: 'last' }} className="offset-lg-1">  <h2 className="padslgt">We specialise in <br /><span>Luxury Digital Marketing,</span> only.</h2></Col>
                                <Col lg={{ span: 3, order: 'last' }} md="6" xs={{ span: 12, order: 'first' }}><LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/special.png`} className="csmimg  img-fluid mx-auto d-block" /></Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={{ span: 3, order: 'first' }} sm={{ span: 4, order: 'first' }} className="offset-lg-1">  <h2 className="text-center mbb20 padslgt mbpt0">We want to<br /></h2></Col>
                                <Col lg="4" sm="4">
                                    <div className="circlebox">
                                        <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="bbox" width="100%" height="100%" />
                                        <div className="top-left">INCREASE <br /> YOUR  <br /> PROFITS </div>
                                        <div className="bottom-right">BUILD <br /> YOUR<br /> BRAND</div>
                                        <div className="middleline"><LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/Line.png`} width="100%" height="100%" /></div>
                                    </div>

                                </Col>
                                <Col lg={{ span: 3, order: 'last' }} sm="3" xs={{ span: 12, order: 'last' }} className="offset-lg-1">  <h2 className="padslgt">That’s it!</h2></Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={{ span: 6, order: 'first' }} md="6" xs={{ span: 12, order: 'last' }} className="offset-lg-1">  <h2 className="padslgt">Awards don’t make us<br /> happy, <span>driving growth does.</span></h2></Col>
                                <Col lg={{ span: 3, order: 'last' }} className="offset-lg-1" md="6" xs={{ span: 12, order: 'first' }}><LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/awards.png`} className="marssmt csmimg img-fluid mx-auto d-block" /></Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={{ span: 5, order: 'first' }} md="6" xs={{ span: 12, order: 'last' }} className="offset-lg-1">  <h2 className="padslgt">We are updated <br /><strong>for <span>Digital 2024.</span></strong></h2></Col>
                                <Col lg={{ span: 3, order: 'last' }} className="offset-lg-1" md="6" xs={{ span: 12, order: 'first' }}><LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/digital2023.png`} className="marssmt csmimg  img-fluid mx-auto d-block" /></Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={{ span: 6, order: 'first' }} md="6" xs={{ span: 12, order: 'last' }} className="offset-lg-1">  <h2 className="padslgt">We treat your <br /> budget like ours.<br /> <strong><span>Every Pound</span> counts.</strong>
                                </h2></Col>
                                <Col lg={{ span: 4, order: 'last' }} md="6" xs={{ span: 12, order: 'first' }} className="padslgt">
                                    <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/pound.png`} className="marssmt csmimg  img-fluid mx-auto d-block" /></Col>
                            </Row>
                        </div>

                    </Slider>
                </Col>
            </Row>
        </Container>
    );
}


// work mobile
function Ourworkmob() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,

        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    adaptiveHeight: false,
                }
            },]
    };
    const next = () => {
        Slider.current.slickNext();
    }
    const previous = () => {
        Slider.current.slickPrev();
    }
    return (
        <Container fluid className="padmmdt">
            <Row>
                <Col lg="10" className="offset-lg-1">
                    <Slider {...settings}>
                        <div>
                            <Row>
                                <Link to={`${process.env.PUBLIC_URL}/case-studies/online-lead-generation-case-study.html`}>
                                    <Col lg="4"><LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/1seagallery.png`} className="csmimg  img-fluid mx-auto d-block" /></Col>
                                    <Col lg="6" className="offset-lg-1"><h4><span className="custom1">Atmantan</span><br />438% jump in qualified inquiries in 12 months</h4><a href="/">View Case Study &gt;</a></Col>
                                </Link>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Link to={`${process.env.PUBLIC_URL}/case-studies/seo-case-study-for-event-ticketing-company.html`}>
                                    <Col lg="4"><LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/paytmInsider.png`} className="csmimg  img-fluid mx-auto d-block" /></Col>
                                    <Col lg="6" className="offset-lg-1"><h4><span className="custom1">Insider</span><br />430% increase in organic traffic, and 200% lift in conversions.</h4><a href="/">View Case Study &gt;</a></Col>
                                </Link>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Link to={`${process.env.PUBLIC_URL}/case-studies/scaling-evok-ecommerce-revenue.html`}>
                                    <Col lg="4"><LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/evok.png`} className="csmimg  img-fluid mx-auto d-block" /></Col>
                                    <Col lg="6" className="offset-lg-1"><h4><span className="custom1">Evok</span><br />Scaled  Ecommerce Revenue by 2404%</h4><a href="/">View Case Study &gt;</a></Col>
                                </Link>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Link to={`${process.env.PUBLIC_URL}/case-studies/parfait-snapchat-instagram-case-study.html`}>
                                    <Col lg="4"><LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/Parfait.png`} className="csmimg  img-fluid mx-auto d-block" /></Col>
                                    <Col lg="6" className="offset-lg-1"><h4><span className="custom1">Parfait</span><br />Magazine covers became size inclusive with just a lens!</h4><a href="/">View Case Study &gt;</a></Col>
                                </Link>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Link to={`${process.env.PUBLIC_URL}/case-studies/isaia-retail-store-digital-transformation.html`}>
                                    <Col lg="4"><LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/Isaia.png`} className="csmimg  img-fluid mx-auto d-block" /></Col>
                                    <Col lg="6" className="offset-lg-1"><h4><span className="custom1">Isaia</span><br />100% Jump in Walkin Revenue in 4 Months </h4><a href="/">View Case Study &gt;</a></Col>
                                </Link>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Link to={`${process.env.PUBLIC_URL}/case-studies/content-marketing-case-study-increase-in-pageviews.html`}>
                                    <Col lg="4"><LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/blabel.png`} className="csmimg  img-fluid mx-auto d-block" /></Col>
                                    <Col lg="6" className="offset-lg-1"><h4><span className="custom1">B Label</span><br />1043% increase in unique blogs pageviews</h4><a href="/">View Case Study &gt;</a></Col>
                                </Link>
                            </Row>
                        </div>



                    </Slider>
                </Col>
            </Row>
        </Container>
    );
}

// Modal Video

// function Modalvideo() {
//     const [show, setShow] = useState(false);

//     const handleClose = () => setShow(false);
//     const handleShow = () => setShow(true);


//     return (
//       <>
//         <Button variant="default" onClick={handleShow}>
//         <span> <h4> <span id="imgd"></span> &nbsp;Watch Showreel</h4></span>
//         </Button> 
//         <Modal show={show} onHide={handleClose} animation={true} size="xl"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered>
//           <Modal.Header closeButton> 
//           </Modal.Header>
//           <Modal.Body>
//           <Player  autoPlay
//       playsInline
//       poster={`${process.env.PUBLIC_URL}/img/firstboldbg.png`}
//       src={`${process.env.PUBLIC_URL}/video/final.mp4`}
//     />
//           </Modal.Body>

//         </Modal>
//       </>
//     );
//   }