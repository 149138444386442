import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container, Button, Col, Row } from 'react-bootstrap';
import Slider from "react-slick";
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";
import { LazyLoadImage } from 'react-lazy-load-image-component';

function evokaor() {
    window.scrollTo(0, 0);
    return (
        <div>
            <Helmet>

                <title>Evok Social Media Case Study - 1.47 Million Targeted Users Engaged</title>
                <meta name="description" content="" />
                <link rel="canonical" href="https://www.aandb.xyz/case-studies/social-media-case-study-evok-furnitures.html" />
            </Helmet>
            <div className="casestudy">
                <div className="box padmmdt padmmdb">
                    {/* first fold */}
                    <Container>

                        <p className="text-left p1 pdl10"> <span class="spcase">EV</span>OK HOMES AOR</p>
                    </Container>

                    <Container fluid className="padbsmt padbsmb">
                        <Row>
                            <Col lg="2"></Col>
                            <Col lg="8">
                                <div className="circleboxfirst  padssmb">
                                      <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="wdboxtp"  width="30%" alt="" />

                                    <div className="boxcentered evok">
                                        <h4>
                                            <span>1.47 M</span> targeted<br /> users engaged<br /> through social media.

                                        </h4>

                                    </div>

                                </div>

                            </Col>
                            <Col lg="2"></Col>

                        </Row>
                    </Container>
                </div>
            </div>
            {/* The problem fold */}
            <div className="lgbox padmmdt padmmdb problem">
                {/* first fold */}

                <Container fluid className="">
                    <Row>

                        <Col lg="3" md="3" sm="6" xs="12" className="casebox offset-lg-1 ">

                            <p className="p2">
                                The idea of a better home and a better lifestyle was something that needed to be imbibed in the minds of the customer by showcasing the wide range of Evok’s finest furniture products and relating it with what the customer actually wants to know about the world of furniture, decor and interior design. Our association with Evok started in the year (2016) and we’ve been constantly optimizing their content ever since by using a variety of post formats and promotion methods, helping us build the brand better and connect with the customer the right way.
                            </p>

                        </Col>

                        <Col lg={{ span: 6, order: 'last' }} sm={{ span: 6, order: 'last' }} xs={{ span: 12, order: 'first' }} className="mbb20 marmsmb offset-lg-1">
                            <Row className="padsmdt padsmdb mbpdt30 marketing">

                                <Col lg="6" md="4" sm="6" xs="12">
                                    <div className="circleboxfirst evok padssmb">
                                          <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} width="50%" alt="" />
                                        <div className="boxcentered evok">
                                            <h1 className="boxtitle d-none d-sm-block">2.5m  Reach<br /><br /> </h1>
                                            <h1 className="boxtitle d-block d-sm-none"><br />2.5m  Reach<br /><br /> </h1>

                                        </div>
                                    </div>

                                </Col>

                                <Col lg="6" md="4" sm="6" >
                                    <div className="circleboxfirst evok padssmb">
                                          <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} width="50%" alt="" />
                                        <div className="boxcentered">
                                            <h1 className="boxtitle d-none d-sm-block"> 1.47m <br />Engagement <br /> </h1>
                                            <h1 className="boxtitle d-block d-sm-none"> 1.47m <br />Engagement <br /> </h1>

                                        </div>
                                    </div>

                                </Col>

                            </Row>
                            <p className="p2 text-left">
                                Over a period of 5 years, we’ve concocted and executed social media campaigns of different styles and tastes, all of which were put together with the best of design and copy. Take a look at some of those.
                            </p>
                        </Col>

                    </Row>
                </Container>
            </div>
            {/* Campaigns */}
            <Container fluid className=" box padbmdt padbmdb text-left">
                <div className="seomatters ">
                    <Row>
                        <Col lg="11" className="casebox offset-lg-1">
                            <p className="text-left p1 marmsmb "> <span class="spcase">CAM</span>PAIGNS</p>
                        </Col>
                    </Row>
                    <Row className="padsmdt padsmdb mbpdt30 marketing">

                        <Col lg="5" md="4" sm="6" xs="12" className="casebox offset-lg-1">
                              <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/1.png`} width="100%" alt="" />
                        </Col>

                        <Col lg="5" md="4" sm="6" className="evok">

                            <h3 className="d-none d-sm-block ">What brings<br /> you Luck?</h3>
                            <h3 className="d-block d-sm-none mbpdt30 mgl0">What brings you Luck?</h3>
                            <p className="p2 text-left marbxst mbmt0">
                                A campaign that encapsulates the traditions in the sport of cricket - Lucky Charm Sofa. The video revolves around the idea that luck can sometimes truly play in your favour if you possess your lucky charm. The only question is if you’ve found it yet. Watch the protagonist of this ad find his lucky charm in this quirky campaign for Sofas.
                            </p>
                        </Col>

                    </Row>
                </div>
            </Container>
            {/* how bored are you */}
            {/* <Container fluid className=" lgbox padbmdt text-left">
                <div className="seomatters ">

                    <Row className=" mbpdt30 marketing">

                        <Col lg="4" md="4" sm="6" xs="12" className="casebox offset-lg-1">
                              <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/2.png`} width="100%" alt="" />
                        </Col>

                        <Col lg="6" md="4" sm="6" className="offset-lg-0 evok">
                            <h3 className="mbpdt30 mgl0">How bored are you with <br />your old furniture?</h3>
                            <p className="p2 text-left marssmt mbmt0">
                                The common Indian household tries to extend the life of a product by using whatever means possible i.e. “Jugaad”. With the Get out of Jugaad campaign, we intended to get people to share their Jugaad techniques with us and also offer them a chance to get out of it, as they were given website coupons and also stood a chance to win a Rocking Chair.
                            </p>
                            <p className="p2 text-left">We announced the contest #GetOutOfJugaad where we asked people to share images of the Jugaad they have applied in their furniture, home decor and interior design.</p>
                            <p className="relevantbox">
                                <a href="https://www.instagram.com/s/aGlnaGxpZ2h0OjE4MDk1OTcxNzQ0MDgwMTcz?igshid=1sknn0hfyeyis&story_media_id=2258833632622032466_3112203032" target="blank">
                                    Check out the entire campaign here
                                </a>
                            </p>
                        </Col>

                    </Row>
                    <Row className="padsmdt padsmdb mbpdt30 marketing">

                        <Col lg="3" md="4" sm="6" xs="12" className="offset-lg-3">
                            <div className="circleboxfirst  padssmb">
                                  <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="boxsm" width="50%" alt="" />
                                <div className="boxcentered ">
                                    <h1 className="boxtitle"><br /><br /><br />184k Reach<br /><br /> </h1>

                                </div>
                            </div>

                        </Col>

                        <Col lg="3" md="4" sm="6" >
                            <div className="circleboxfirst  padssmb">
                                  <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="boxsm" width="50%" alt="" />
                                <div className="boxcentered">
                                    <h1 className="boxtitle"><br /><br />51k <br />Engagement <br /> </h1>

                                </div>
                            </div>

                        </Col>

                    </Row>
                </div>
            </Container> */}
            {/* Marketing Insights  */}

            <Container fluid className=" box padmsmt padmsmb">
                <div className="seomatters ">
                    <Container>
                        {/* desktop */}
                        <div className="d-none d-sm-block">
                            <Row className=" padsmdb mbpdt30 marketing ">

                                <Col lg="4" md="4" sm="4" xs="12" >
                                      <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/3.png`} className="img-fluid mx-auto" alt="" />
                                </Col>

                                <Col lg="4" md="4" sm="4" >
                                      <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/4.png`} className="img-fluid mx-auto" alt="" />
                                </Col>
                                <Col lg="4" md="4" sm="4">
                                      <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/5.png`} className="img-fluid mx-auto" alt="" />
                                </Col>

                            </Row>
                        </div>
                        {/* mobile */}
                        <div className="d-block d-sm-none">
                            <Happyhome />
                        </div>
                    </Container>
                    <Row className=" mbpdt30 marketing">

                        <Col lg="5" md="4" sm="6" xs="12" className="offset-lg-2 evok">
                            <h3 className="mgl0">What makes you<br /> happy at home?</h3>
                            <p className="p2 text-left  padmsmt pdt20">
                                A video production campaign - <strong>My Home Makes Me Happy</strong>, created for International Day of Happiness, shared different scenarios showing how happiness at home had changed for us over the years. We created 3 short videos showing ‘Before and After’ shots at home that relate to the furniture elements of the house.
                            </p>

                            <p className="p2  text-left">
                                We added the element of a contest to the campaign where we asked people to send in their entries stating what makes them happy at home and received a total of 376 entries.
                            </p>
                        </Col>

                        <Col lg="4" md="4" sm="6" className="evok ">
                            <Row>
                                <Col lg="12" md="12" sm="12" >
                                    <div className="circleboxfirst  ">
                                          <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} width="40%" alt="" />
                                        <div className="boxcentered">
                                            <h1 className="boxtitle">  <br />152k  reach <br /> <br /></h1>

                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="marsmdt">
                                <Col lg="12" md="12" sm="12" >
                                    <div className="circleboxfirst  ">
                                          <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} width="40%" alt="" />
                                        <div className="boxcentered">
                                            <h1 className="boxtitle">16.9k<br />Engagement<br /></h1>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>


                    </Row>


                </div>


            </Container>
            {/* House home */}
            <div className="lgbox build">
                <Container fluid className="lgbox padmsmt padmsmb mbpdt30">
                    <Container>
                        <div className=" d-none d-sm-block">
                            <Row className=" mbpdt30 marketing">

                                <Col lg="4" md="4" sm="4" xs="12">
                                      <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/6.png`} className="img-fluid" alt="" />
                                </Col>

                                <Col lg="4" md="4" sm="4">
                                      <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/7.png`} className="img-fluid" alt="" />
                                </Col>

                                <Col lg="4" md="4" sm="4">
                                      <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/8.png`} className="img-fluid" alt="" />
                                </Col>
                            </Row>
                        </div>
                    </Container>
                    <Container>
                        {/* home house carousel mobile */}
                        <div className=" d-block d-sm-none">
                            <Homehouse />
                        </div>
                        <Row className="padmsmt mbpdt30 marketing ">

                            <Col lg="4" md="4" sm="4" xs="12" className=" d-none d-sm-block">
                                  <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/9.png`} className="img-fluid" alt="" />
                            </Col>

                            <Col lg="4" md="4" sm="4" className=" d-none d-sm-block">
                                  <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/10.png`} className="img-fluid" alt="" />
                            </Col>

                            <Col lg="4" md="4" sm="4" className="padmxst evok">
                                <h3 className="mgl0"> Who makes the <br /> house a home?</h3>
                                <p className="p2 text-left hg  padmxst">
                                    The campaign for <span>Women’s Day 2020</span>, talks about the different roles women play in making a house a home and how she adds value to the ones around her.
                                </p>
                            </Col>
                        </Row>
                        <Row className="padsmdt  mbpdt30 marketing">

                            <Col lg="4" md="4" sm="6" xs="12" className="offset-lg-2">
                                <div className="circleboxfirst  padssmb evok">
                                      <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} width="50%" alt="" />
                                    <div className="boxcentered">
                                        <h1 className="boxtitle d-none d-sm-block">84.9k Reach<br /><br /> </h1>
                                        <h1 className="boxtitle d-block d-sm-none"><br />84.9k Reach<br /> </h1>

                                    </div>
                                </div>

                            </Col>

                            <Col lg="4" md="4" sm="6" >
                                <div className="circleboxfirst  padssmb evok">
                                      <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} width="50%" alt="" />
                                    <div className="boxcentered">
                                        <h1 className="boxtitle d-none d-sm-block"> 1.1k <br />Engagement <br /><br /> </h1>
                                        <h1 className="boxtitle d-block d-sm-none">1.1k <br />Engagement <br /> </h1>

                                    </div>
                                </div>
                            </Col>

                        </Row>
                    </Container>

                </Container>
            </div>

            <div className="box build">
                <Container fluid className="padmsmb mbpdt30">
                    <Row className="  mbpdt30">

                        <Col lg="5" sm="5" className="casebox offset-lg-1 offset-sm-1">

                            <h3 className="marmsmt mgl0 mbmt0 d-none d-sm-block">
                                What’s the colour of the day?
                            </h3>
                            <h3 className="marmsmt mgl0 mbmt0  d-block d-sm-none">
                                What’s the colour<br /> of the day?
                            </h3>
                        </Col>
                    </Row>
                    <Container className="d-block d-sm-none ">
                        <Color />
                    </Container>
                    <Row className="padmmdb ">

                        <Col lg="5" sm="5" className="casebox offset-lg-1 offset-sm-1 marbxst mbmt0">

                            <div className="d-none d-sm-block">
                                <Row>
                                    <Col className="pdlr5" lg={{ span: 4, order: 'last' }} md="4" sm={{ span: 4, order: 'last' }} xs={{ span: 12, order: 'first' }}>

                                          <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/11.png`} className="padmxst  mbpdt30 mx-auto img-fluid text-right" />
                                    </Col>
                                    <Col className="pdlr5" lg={{ span: 4, order: 'last' }} md="4" sm={{ span: 4, order: 'last' }} xs={{ span: 12, order: 'first' }}>

                                          <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/12.png`} className="padmxst  mbpdt30 mx-auto img-fluid text-right" />
                                    </Col>
                                    <Col className="pdlr5" lg={{ span: 4, order: 'last' }} md="4" sm={{ span: 4, order: 'last' }} xs={{ span: 12, order: 'first' }}>

                                          <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/13.png`} className="padmxst  mbpdt30 mx-auto img-fluid text-right" />
                                    </Col>
                                    <Col className="pdlr5" lg={{ span: 4, order: 'last' }} md="4" sm={{ span: 4, order: 'last' }} xs={{ span: 12, order: 'first' }}>

                                          <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/14.png`} className="padmxst  mbpdt30 mx-auto img-fluid text-right" />
                                    </Col>
                                    <Col className="pdlr5" lg={{ span: 4, order: 'last' }} md="4" sm={{ span: 4, order: 'last' }} xs={{ span: 12, order: 'first' }}>

                                          <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/15.png`} className="padmxst  mbpdt30 mx-auto img-fluid text-right" />
                                    </Col>
                                    <Col className="pdlr5" lg={{ span: 4, order: 'last' }} md="4" sm={{ span: 4, order: 'last' }} xs={{ span: 12, order: 'first' }}>

                                          <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/16.png`} className="padmxst  mbpdt30 mx-auto img-fluid text-right" />
                                    </Col>
                                    <Col className="pdlr5" lg={{ span: 4, order: 'last' }} md="4" sm={{ span: 4, order: 'last' }} xs={{ span: 12, order: 'first' }}>

                                          <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/17.png`} className="padmxst  mbpdt30 mx-auto img-fluid text-right" />
                                    </Col>
                                    <Col className="pdlr5" lg={{ span: 4, order: 'last' }} md="4" sm={{ span: 4, order: 'last' }} xs={{ span: 12, order: 'first' }}>

                                          <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/18.png`} className="padmxst  mbpdt30 mx-auto img-fluid text-right" />
                                    </Col>
                                    <Col className="pdlr5" lg={{ span: 4, order: 'last' }} md="4" sm={{ span: 4, order: 'last' }} xs={{ span: 12, order: 'first' }}>

                                          <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/19.png`} className="padmxst  mbpdt30 mx-auto img-fluid text-right" />
                                    </Col>

                                </Row>
                            </div>
                        </Col>

                        <Col className="evok marbxst mbmt0" lg={{ span: 4, order: 'last' }} md="5" sm={{ span: 6, order: 'last' }}  >

                            <p className="p2 marmxst text-left">
                                For the Navratri 2019 campaign, we gave highlights to the colour of the day by using it as the background colour. With the 9 Days of Festivities, we also highlighted the number of the day which corresponded to the furniture shown in the frame.
                            </p>
                            <Row>
                                <Col lg="12" md="12" sm="12" >
                                    <div className="circleboxfirst  marketing">
                                          <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} width="40%" alt="" />
                                        <div className="boxcentered evok">
                                            <h1 className="boxtitle tcgold">  <br />12.1k Reach<br /> <br /></h1>

                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="marmsmt">
                                <Col lg="12" md="12" sm="12" >
                                    <div className="circleboxfirst  marketing">
                                          <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} width="40%" alt="" />
                                        <div className="boxcentered evok">
                                            <h1 className="boxtitle tcgold">5.3k <br />Engagement<br /></h1>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>



                </Container>


            </div>
            {/* monsters fold */}
            <Container fluid className="lgbox padmsmt padmmdb mbpdt30 pdlr0 ">
                <Container>
                    <div className="d-none d-sm-block">
                        <Row className="pdlr0 ">

                            <Col lg="4" sm="4" className="pdlr5">

                                  <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/20.png`} className="mx-auto img-fluid text-right" />
                            </Col>
                            <Col lg="4" sm="4" className="pdlr5">

                                  <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/21.png`} className="mx-auto img-fluid text-right" />
                            </Col>
                            <Col lg="4" sm="4" className="pdlr5">

                                  <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/22.png`} className="mx-auto img-fluid text-right" />
                            </Col>

                        </Row>
                    </div>
                    <Container className="d-block d-sm-none">
                        <Monster />
                    </Container>
                    <Row className="pdlr0 text-left">

                        <Col lg="4" sm="5" className="pdlr5 build evok">
                            <h3 className="marmsmt mgl0   tcgold">
                                What’s scarier<br /> than monsters?
                            </h3>

                            <p className="p2">
                                The campaign for <strong>Halloween 2019</strong> highlighted the real-life scares  - “The True Horrors”,  that people face in their daily lives at their homes.
                            </p>
                        </Col>
                        <Col lg="4" sm="3" className="pdlr5 marmsmt marketing">

                            <div className="circleboxfirst ">
                                  <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1 className="boxtitle tcgold d-none d-sm-block">114.9k Reach <br /> <br /><br /></h1>
                                    <div className=" evok ">
                                        <h1 className="boxtitle tcgold  d-block d-sm-none"><br /><br /><br /><br />114.9k  Reach<br /> <br /></h1>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg="4" sm="3" className="pdlr5 marmsmt  marketing">

                            <div className="circleboxfirst evok ">
                                  <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1 className="boxtitle tcgold d-none d-sm-block"> 57.5k Engagement<br /><br /> <br /></h1>
                                    <div className=" evok ">
                                        <h1 className="boxtitle tcgold d-block d-sm-none"><br /><br /> 57.5k<br /> Engagement<br /> <br /></h1>
                                    </div>
                                </div>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </Container>

            {/* dinner table fold */}
            <Container fluid className="box  padbmdt padbmdb pdlr0 mbpdt30">
                <Container>
                    <Row className="  marketing" >

                        <Col lg="5" md="5" sm="5" className="d-none d-sm-block " >
                              <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/23.png`} className="mx-auto img-fluid text-right" />

                        </Col>
                        <Col lg="7" md="5" sm="6" className="evok">

                            <h3>Who’s missing at<br /> the dinner table?</h3>
                            <br />
                              <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/23.png`} className="d-block d-sm-none mx-auto img-fluid pdtb20" />

                            <p className="para2 hg ">
                                Every single day of the year belongs as much to women as it does to men. But is that the actual case in our daily lives? In the campaign for <span>Women’s Day 2019</span>, we addressed an issue faced by Indian women Every Single Day.
                            </p>
                            <Row>
                                <Col lg="6" sm="6" className="pdlr5 marmsmt">

                                    <div className="circleboxfirst  evok">
                                          <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} width="50%" alt="" />
                                        <div className="boxcentered">
                                            <h1 className="boxtitle tcgold"><br /><br />70.6k Reach<br /> <br /></h1>

                                        </div>
                                    </div>
                                </Col>
                                <Col lg="6" sm="6" className="pdlr5 marmsmt">

                                    <div className="circleboxfirst evok ">
                                          <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} width="50%" alt="" />
                                        <div className="boxcentered">
                                            <h1 className="boxtitle tcgold"><br /> 8.1k<br /> Engagement<br /> <br /></h1>

                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>

                    </Row>
                </Container>
            </Container>
            {/* santa fold */}
            <Container fluid className="lgbox  padbmdt padbmdb pdlr0 mbpdt30">
                <Container>
                    <Row className="  marketing" >

                        <Col lg="5" md="5" sm="5" className="d-none d-sm-block " >
                              <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/24.png`} className="mx-auto img-fluid text-right" />

                        </Col>
                        <Col lg="7" md="5" sm="6" className="evok">

                            <h3>Where’s Santa<br /> when we need him?</h3>
                            <br />
                              <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/24.png`} className="d-block d-sm-none mx-auto img-fluid pdtb20" />

                            <p className="para2 hg">
                                For the Christmas campaign, we uploaded a 9-post grid for the <span className="tcgold">Find Santa With Evok</span> Campaign with Christmas themed GIFs that had clues about Santa’s whereabouts.
                            </p>
                            <p className="para2 hg">
                                People had to solve the clues from one post which led them to the next post, ultimately giving them the letters <span className="tcgold">U N I T R F R U E</span>, which formed the word FURNITURE.
                            </p>
                            <Row>
                                <Col lg="6" sm="6" className="pdlr5 marmsmt">
                                    <div className="circleboxfirst  ">
                                          <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} width="50%" alt="" />
                                        <div className="boxcentered">
                                            <h1 className="boxtitle tcgold"><br />9.1k<br /> Reach<br /> <br /></h1>

                                        </div>
                                    </div>
                                </Col>

                            </Row>
                        </Col>

                    </Row>
                </Container>
            </Container>
            {/* carousels fold*/}
            <Container fluid className="box padmmdt  padbmdb mbpdt30 pdlr0 ">
                <Row className=" padbmdb ">
                    <Col lg="10" className="offset-lg-1">
                        <Row>
                            <p className="text-left p1 marmsmb "> <span class="spcase">CAR</span>OUSELS</p>
                        </Row>
                        <Row className="marketing">
                            <h3 className="marmsmt marmsmb mrg30">
                                Clear the air!
                            </h3>
                        </Row>
                        <Container className="d-block d-sm-none">
                            <Clearair />
                        </Container>

                        <Row className="pdlr0 evok ">

                            <Col lg="3" sm="3" className="d-none d-sm-block">

                                  <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/25.png`} className="mx-auto img-fluid text-right" />
                            </Col>
                            <Col lg="3" sm="3" className="d-none d-sm-block">

                                  <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/26.png`} className="mx-auto img-fluid text-right" />
                            </Col>
                            <Col lg="3" sm="3" className="d-none d-sm-block">

                                  <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/27.png`} className="mx-auto img-fluid text-right" />
                            </Col>

                            <Col lg="3" sm="3" >
                                <p className="para2 hg">
                                    The post aims to show the viewer how the air around them can be turned back to normal with the help of an Air purifier.
                                </p>
                                <Row>
                                    <Col lg="12" sm="12" className="pdlr5 marketing">
                                        <div className="circleboxfirst  evok">
                                              <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} width="50%" alt="" />
                                            <div className="boxcentered">
                                                <h1 className="boxtitle tcgold"><br />44.9k  Reach<br /> <br /></h1>

                                            </div>
                                        </div>
                                    </Col>

                                </Row>
                            </Col>
                        </Row>


                    </Col>
                </Row>
            </Container>

            {/* furniture match maker */}
            <Container fluid className="lgbox  padbmdt padbmdb pdlr0 mbpdt30">

                <Row className="  marketing" >
                    <Col lg="10" className="offset-lg-1">

                        <Row>
                            <Col lg="7" md="7" sm="7" className="d-none d-sm-block " >
                                <Row>
                                    <Col lg="4" md="4" sm="4" className=" " >
                                          <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/28.png`} className="mx-auto img-fluid text-right" />

                                    </Col>
                                    <Col lg="4" md="4" sm="4" className=" " >
                                          <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/29.png`} className="mx-auto img-fluid text-right" />

                                    </Col>
                                    <Col lg="4" md="4" sm="4" className=" " >
                                          <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/30.png`} className="mx-auto img-fluid text-right" />
                                    </Col>

                                    <Col lg="4" md="4" sm="4" className=" " >
                                          <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/31.png`} className="mx-auto img-fluid text-right" />

                                    </Col>
                                    <Col lg="4" md="4" sm="4" className=" " >
                                          <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/32.png`} className="mx-auto img-fluid text-right" />

                                    </Col>
                                    <Col lg="4" md="4" sm="4" className=" " >
                                          <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/33.png`} className="mx-auto img-fluid text-right" />

                                    </Col>
                                </Row>
                            </Col>
                            <Col lg="5" md="5" sm="5" className="evok">

                                <h3 className="mgl0">Become the<br /> Furniture Matchmaker</h3>
                                <br />
                                <Container className="d-block d-sm-none">
                                    <Furniture />
                                </Container>
                                <p className="para2 hg">
                                    We created a dating app themed experience for <span className="tcgold">Valentine’s day 2021</span> in a Carousel format. The initial slides show ineligible candidates for the sofa but swiping the last one (coffee table) gives a match. The emailer took the users to a landing page with a short quiz (which further required them to be Furniture Matchmakers) which upon submitting, gave them website vouchers.
                                </p>

                                <Row>
                                    <Col lg="8" sm="8" className="pdlr5 marmsmt">
                                        <div className="circleboxfirst  ">
                                              <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} width="50%" alt="" />
                                            <div className="boxcentered">
                                                <h1 className="boxtitle tcgold"><br />3.4k<br /> Reach<br /> <br /></h1>

                                            </div>
                                        </div>
                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>

            </Container>
            {/* hoist the flag */}
            <Container fluid className="box  padbmdt padbmdb pdlr0 mbpdt30">

                <Row className="  marketing" >
                    <Col lg="10" className="offset-lg-1">

                        <Row>
                            <Col lg="7" md="7" sm="7" className=" d-none d-sm-block" >
                                <Row>
                                    <Col lg="4" md="4" sm="4" className=" " >
                                          <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/34.png`} className="mx-auto img-fluid text-right" />

                                    </Col>
                                    <Col lg="4" md="4" sm="4" className=" " >
                                          <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/35.png`} className="mx-auto img-fluid text-right" />

                                    </Col>
                                    <Col lg="4" md="4" sm="4" className=" " >
                                          <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/36.png`} className="mx-auto img-fluid text-right" />
                                    </Col>

                                    <Col lg="4" md="4" sm="4" className=" " >
                                          <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/37.png`} className="mx-auto img-fluid text-right" />

                                    </Col>
                                    <Col lg="4" md="4" sm="4" className=" " >
                                          <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/38.png`} className="mx-auto img-fluid text-right" />

                                    </Col>

                                </Row>
                            </Col>
                            <Col lg="5" md="5" sm="5" className="evok">

                                <h3 className="mgl0">Hoist the flag <br /> from your home</h3>
                                <br />
                                <Container className="d-block d-sm-none">
                                    <Hostingflag />
                                </Container>
                                <p className="para2 hg">
                                    A creative take on the Instagram carousel format. The post created for <span className="tcgold">Republic Day 2021</span> gives a flag hoisting experience to those who are staying indoors.
                                </p>

                                <Row>
                                    <Col lg="8" sm="8" className="pdlr5 marmsmt">
                                        <div className="circleboxfirst  ">
                                              <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} width="50%" alt="" />
                                            <div className="boxcentered">
                                                <h1 className="boxtitle tcgold"><br />2.8k<br /> Reach<br /> <br /></h1>

                                            </div>
                                        </div>
                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>

            </Container>

            <Container fluid className="lgbox  padbmdt padbmdb pdlr0 mbpdt30">
                <Row>

                    <Col lg="5" sm="6" className="casebox offset-lg-1 offset-sm-1">
                        <p className="text-left p1 marmsmb "> <span class="spcase">IMA</span>GES/GIFS</p>
                    </Col>
                </Row>
                <Row className="  marketing" >
                    <Col lg="10" className="offset-lg-1 ">
                        <Row className="">
                            <Col lg="6" md="6" xs="12" className=" mbpdlr0" >
                                  <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/39.png`} className="mx-auto img-fluid text-right" />
                                <p className="p2 text-left padssmt padssmb">
                                    A post with meaning more than just one, created for a <span>topical trend</span> - ‘Seduce someone in four words’.
                                </p>
                            </Col>
                            <Col lg="6" md="6" xs="12" className=" mbpdlr0" >
                                  <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/40.png`} className="mx-auto img-fluid text-right" />
                                <p className="p2 text-left padssmt padssmb">
                                    A post for the Knights of Exemplary Service on <span>Retail Employees Day</span>.
                                </p>
                            </Col>
                            <Col lg="6" md="6" xs="12" className=" mbpdlr0" >
                                  <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/41.png`} className="mx-auto img-fluid text-right" />
                                <p className="p2 text-left padssmt padssmb">
                                    A post for Chocolate Day shows that <span>chocolate bars</span> are not the only things that are smooth.
                                </p>
                            </Col>
                            <Col lg="6" md="6" xs="12" className=" mbpdlr0" >
                                  <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/42.png`} className="mx-auto img-fluid text-right" />
                                <p className="p2 text-left padssmt padssmb">
                                    A post on <span>Janmashtami</span>, urging people to stay inside and stay safe.
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="6" md="6" xs="12" className=" mbpdlr0" >
                                  <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/43.png`} className="mx-auto img-fluid text-right" />

                            </Col>
                            <Col lg="6" md="6" xs="12" className=" mbpdlr0" >
                                <p className="p2 text-left padssmt padssmb">
                                    A GIF on <span>Valentine’s Day 2019</span> that tells us that our furniture doesn’t look at our differences, it accepts us for who we are.
                                </p>
                            </Col>

                        </Row>
                    </Col>
                </Row>
            </Container>
            {/* client word */}
            <div className="bgfooter whyus  padsmdt padbmdb">
            <Container fluid className="reasons  casestudy ">
                <Row>
                    <Col className="offset-lg-1 offset-md-0" lg={{ span: 10, order: 'first' }} md={{ span: 12, order: 'first' }} sm={{ span: 8, order: 'last' }} xs={{ span: 12, order: 'last' }}>
                        <h3 className="text-center htitle"><span>A word from the Client -</span></h3>
                         <div className="feedbackbox marmsmt padmsmt padmsmb">
                              <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/leftcoma.png`} className="mgt40 img-fluid mx-auto d-block" />
                            <br />

                            <h4 className="">“We wanted Evok to be a household name for Furniture, Decor & Home Appliances and A&B has helped us tremendously towards that goal by building Evok as a brand. As an e-commerce company, sales had always been on the forefront for us, but A&B devised a plan where the objective of our Social Media Content was the best of both worlds - Brand Building and Website Conversions.”
                            </h4>

                        </div>
                        <div className="clientname text-center" >
                              <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/clients/evok.png`} className="img-fluid mx-auto" width="160px" /> <br /><br />
                            <p className="text-center"> <i>- Sanchayan Das Roy, HSIL Ltd.</i></p>
                        </div>
                    </Col>

                </Row>
            </Container>
            </div>
            {/* case study prev end */}
            <div className="lgbox build endbox">
                <Container fluid className="pdlr0">
                    <Row className="text-left  mglr0">

                        <Col lg="6" sm="6" xs="12">
                            <Link to={`${process.env.PUBLIC_URL}/case-studies/independence-day-social-media-campaign-tiranga-dabba.html`}>
                                <Row className="marssmt marssmb mglr0">
                                    <Col lg="3" sm="3" xs="3" >
                                          <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/footerlogo/tiranga.png`} className="mx-auto img-fluid text-right" />
                                    </Col>
                                    <Col lg="5" sm="6" xs="7">

                                        <h4 className="tcgold">Tiranga </h4>
                                        <p>Restoring the dignity of hundreds of national flags</p>
                                    </Col>
                                    <Col lg="2" sm="3" xs="2" className="marbxst">
                                          <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/righticon.png`} width="40%" className=" text-right" />
                                    </Col>
                                </Row>
                            </Link>
                        </Col>
                        <Col lg="6" sm="6" xs="12" className="bgbl " >
                        <Link to={`${process.env.PUBLIC_URL}/case-studies/digital-advertising-sales-growth-case-study.html`}>
                            <Row className="marssmt marssmb pdlr0 mglr0">
                                <Col lg="3" sm="3" xs="3" >
                                      <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/footerlogo/novakid-logo.jpeg`} className="mx-auto img-fluid text-right" />
                                </Col>
                                <Col lg="4" sm="6" xs="7"> 
                                    <h4 className="tcgold">NovaKid</h4>
                                    <p> 418% jump in Revenue via Advertising,<br />  
                                        highest revenue post launch in India ever.</p>
                                </Col>
                                <Col lg="2" sm="3" xs="2" className="marbxst">
                                      <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/righticon.png`} width="40%" className=" text-right" />
                                </Col>
                            </Row>
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </div>

        </div>
    );
}
// makes home 
function Happyhome() {
    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
        <button
            {...props}
            className={
                "slick-prev slick-arrow" +
                (currentSlide === 0 ? " slick-disabled" : "")
            }
            aria-hidden="false"
            aria-disabled={currentSlide === 0 ? true : false}
            type="button"
        >
            Previous
        </button>
    );
    const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
        <button
            {...props}
            className={
                "slick-next slick-arrow" +
                (currentSlide === slideCount - 1 ? " slick-disabled" : "")
            }
            aria-hidden="true"
            aria-disabled={currentSlide === slideCount - 1 ? true : false}
            type="button"
        >
            Next
        </button>
    );
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        adaptiveHeight: true,
        prevArrow: <SlickArrowLeft />,
        nextArrow: <SlickArrowRight />,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    adaptiveHeight: false,
                }
            },]
    };
    const next = () => {
        Slider.current.slickNext();
    }
    const previous = () => {
        Slider.current.slickPrev();
    }
    return (
        <Container fluid className="pdlr0 culture ">

            <Slider {...settings}>
                <div>
                    <Col className="mx-auto " lg="9" md="9" sm="9" xs="12" >

                          <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/3.png`} className="img-fluid mx-auto" alt="" />
                        <br />
                    </Col>
                </div>
                <div>
                    <Col className="mx-auto " lg="9" md="9" sm="9" xs="12" >

                          <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/4.png`} className="img-fluid mx-auto" alt="" />
                        <br />
                    </Col>
                </div>
                <div>
                    <Col className="mx-auto " lg="9" md="9" sm="9" xs="12" >

                          <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/5.png`} className="img-fluid mx-auto" alt="" />
                        <br />
                    </Col>
                </div>


            </Slider>


        </Container>
    )
}
// homehouse 
function Homehouse() {

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    adaptiveHeight: false,
                }
            },]
    };
    const next = () => {
        Slider.current.slickNext();
    }
    const previous = () => {
        Slider.current.slickPrev();
    }
    return (
        <Container fluid className="pdlr0 culture ">

            <Slider {...settings}>
                <div>
                    <Col className="mx-auto " lg="9" md="9" sm="9" xs="12" >

                          <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/6.png`} className="img-fluid mx-auto" alt="" />
                        <br />
                    </Col>
                </div>
                <div>
                    <Col className="mx-auto " lg="9" md="9" sm="9" xs="12" >

                          <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/7.png`} className="img-fluid mx-auto" alt="" />
                        <br />
                    </Col>
                </div>
                <div>
                    <Col className="mx-auto " lg="9" md="9" sm="9" xs="12" >

                          <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/8.png`} className="img-fluid mx-auto" alt="" />
                        <br />
                    </Col>
                </div>
                <div>
                    <Col className="mx-auto " lg="9" md="9" sm="9" xs="12" >

                          <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/9.png`} className="img-fluid mx-auto" alt="" />
                        <br />
                    </Col>
                </div>


            </Slider>


        </Container>
    )
}
// color 
function Color() {

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    adaptiveHeight: false,
                }
            },]
    };
    const next = () => {
        Slider.current.slickNext();
    }
    const previous = () => {
        Slider.current.slickPrev();
    }
    return (
        <Container fluid className="pdlr0 culture ">

            <Slider {...settings}>
                <div>
                    <Col className="mx-auto " lg="9" md="9" sm="9" xs="12" >

                          <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/11.png`} className="img-fluid mx-auto" alt="" />
                        <br />
                    </Col>
                </div>
                <div>
                    <Col className="mx-auto " lg="9" md="9" sm="9" xs="12" >

                          <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/12.png`} className="img-fluid mx-auto" alt="" />
                        <br />
                    </Col>
                </div>
                <div>
                    <Col className="mx-auto " lg="9" md="9" sm="9" xs="12" >

                          <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/13.png`} className="img-fluid mx-auto" alt="" />
                        <br />
                    </Col>
                </div>
                <div>
                    <Col className="mx-auto " lg="9" md="9" sm="9" xs="12" >

                          <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/14.png`} className="img-fluid mx-auto" alt="" />
                        <br />
                    </Col>
                </div>
                <div>
                    <Col className="mx-auto " lg="9" md="9" sm="9" xs="12" >

                          <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/15.png`} className="img-fluid mx-auto" alt="" />
                        <br />
                    </Col>
                </div>
                <div>
                    <Col className="mx-auto " lg="9" md="9" sm="9" xs="12" >

                          <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/16.png`} className="img-fluid mx-auto" alt="" />
                        <br />
                    </Col>
                </div>
                <div>
                    <Col className="mx-auto " lg="9" md="9" sm="9" xs="12" >

                          <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/17.png`} className="img-fluid mx-auto" alt="" />
                        <br />
                    </Col>
                </div>
                <div>
                    <Col className="mx-auto " lg="9" md="9" sm="9" xs="12" >

                          <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/18.png`} className="img-fluid mx-auto" alt="" />
                        <br />
                    </Col>
                </div>
                <div>
                    <Col className="mx-auto " lg="9" md="9" sm="9" xs="12" >

                          <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/19.png`} className="img-fluid mx-auto" alt="" />
                        <br />
                    </Col>
                </div>

            </Slider>


        </Container>
    )
}
// Monster
function Monster() {

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    adaptiveHeight: false,
                }
            },]
    };
    const next = () => {
        Slider.current.slickNext();
    }
    const previous = () => {
        Slider.current.slickPrev();
    }
    return (
        <Container fluid className="pdlr0 culture ">

            <Slider {...settings}>
                <div>
                    <Col className="mx-auto " lg="9" md="9" sm="9" xs="12" >

                          <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/20.png`} className="img-fluid mx-auto" alt="" />
                        <br />
                    </Col>
                </div>
                <div>
                    <Col className="mx-auto " lg="9" md="9" sm="9" xs="12" >

                          <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/21.png`} className="img-fluid mx-auto" alt="" />
                        <br />
                    </Col>
                </div>
                <div>
                    <Col className="mx-auto " lg="9" md="9" sm="9" xs="12" >

                          <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/22.png`} className="img-fluid mx-auto" alt="" />
                        <br />
                    </Col>
                </div>


            </Slider>


        </Container>
    )
}
// clear air
function Clearair() {

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    adaptiveHeight: false,
                }
            },]
    };
    const next = () => {
        Slider.current.slickNext();
    }
    const previous = () => {
        Slider.current.slickPrev();
    }
    return (
        <Container fluid className="pdlr0 culture ">

            <Slider {...settings}>
                <div>
                    <Col className="mx-auto " lg="9" md="9" sm="9" xs="12" >

                          <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/25.png`} className="img-fluid mx-auto" alt="" />
                        <br />
                    </Col>
                </div>
                <div>
                    <Col className="mx-auto " lg="9" md="9" sm="9" xs="12" >

                          <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/26.png`} className="img-fluid mx-auto" alt="" />
                        <br />
                    </Col>
                </div>
                <div>
                    <Col className="mx-auto " lg="9" md="9" sm="9" xs="12" >

                          <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/27.png`} className="img-fluid mx-auto" alt="" />
                        <br />
                    </Col>
                </div>


            </Slider>


        </Container>
    )
}
// furniture
function Furniture() {

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    adaptiveHeight: false,
                }
            },]
    };
    const next = () => {
        Slider.current.slickNext();
    }
    const previous = () => {
        Slider.current.slickPrev();
    }
    return (
        <Container fluid className="pdlr0 culture ">

            <Slider {...settings}>
                <div>
                    <Col className="mx-auto " lg="9" md="9" sm="9" xs="12" >

                          <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/28.png`} className="img-fluid mx-auto" alt="" />
                        <br />
                    </Col>
                </div>
                <div>
                    <Col className="mx-auto " lg="9" md="9" sm="9" xs="12" >

                          <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/29.png`} className="img-fluid mx-auto" alt="" />
                        <br />
                    </Col>
                </div>
                <div>
                    <Col className="mx-auto " lg="9" md="9" sm="9" xs="12" >

                          <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/30.png`} className="img-fluid mx-auto" alt="" />
                        <br />
                    </Col>
                </div>

                <div>
                    <Col className="mx-auto " lg="9" md="9" sm="9" xs="12" >

                          <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/31.png`} className="img-fluid mx-auto" alt="" />
                        <br />
                    </Col>
                </div>
                <div>
                    <Col className="mx-auto " lg="9" md="9" sm="9" xs="12" >

                          <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/32.png`} className="img-fluid mx-auto" alt="" />
                        <br />
                    </Col>
                </div>
                <div>
                    <Col className="mx-auto " lg="9" md="9" sm="9" xs="12" >

                          <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/33.png`} className="img-fluid mx-auto" alt="" />
                        <br />
                    </Col>
                </div>
            </Slider>


        </Container>
    )
}
// hostingflag
function Hostingflag() {

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    adaptiveHeight: false,
                }
            },]
    };
    const next = () => {
        Slider.current.slickNext();
    }
    const previous = () => {
        Slider.current.slickPrev();
    }
    return (
        <Container fluid className="pdlr0 culture ">

            <Slider {...settings}>
                <div>
                    <Col className="mx-auto " lg="9" md="9" sm="9" xs="12" >

                          <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/34.png`} className="img-fluid mx-auto" alt="" />
                        <br />
                    </Col>
                </div>
                <div>
                    <Col className="mx-auto " lg="9" md="9" sm="9" xs="12" >

                          <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/35.png`} className="img-fluid mx-auto" alt="" />
                        <br />
                    </Col>
                </div>
                <div>
                    <Col className="mx-auto " lg="9" md="9" sm="9" xs="12" >

                          <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/36.png`} className="img-fluid mx-auto" alt="" />
                        <br />
                    </Col>
                </div>

                <div>
                    <Col className="mx-auto " lg="9" md="9" sm="9" xs="12" >

                          <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/37.png`} className="img-fluid mx-auto" alt="" />
                        <br />
                    </Col>
                </div>
                <div>
                    <Col className="mx-auto " lg="9" md="9" sm="9" xs="12" >

                          <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/evok-aor/38.png`} className="img-fluid mx-auto" alt="" />
                        <br />
                    </Col>
                </div>

            </Slider>


        </Container>
    )
}


export default evokaor;