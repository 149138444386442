import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container, Button, Col, Row } from 'react-bootstrap';
import CountUp from 'react-countup';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import VisibilitySensor from "react-visibility-sensor";
function NovaKid() {
    window.scrollTo(0, 0);
    const [focus, setFocus] = React.useState(false);
    return (
        <div>
            <Helmet>
                 <title>418% jump in Revenue via Advertising, highest revenue post launch in India ever.</title>
                <meta name="description" content="" />
                <link rel="canonical" href="https://www.aandb.xyz/case-studies/digital-advertising-sales-growth-case-study.html" />
            </Helmet>
            {/* first fold */}
            <div className="casestudy">
                <section className="box padmmdt padmmdb"> 
                    <Container>

                        <p className="text-left p1 pdl10"> <span class="spcase">No</span>vaKid</p>
                    </Container>

                    <Container fluid className="padbsmt padbsmb">
                        <Row>
                            <Col lg="2"></Col>
                            <Col lg="8">
                                <div className="circleboxfirst  padssmb">
                                       <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="roundbx" width="30%" height="30%" alt="" />

                                    <div className="boxcentered">
                                        <h4>  <span className="cuspn"> 418%</span><br /> jump in Revenue via Advertising,<br />  
                                        highest revenue post launch in India ever.</h4>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="2"></Col>
                        </Row>
                    </Container>
                </section>
            </div>
            {/* The problem  and build fold */}
            <div className="lgbox  problem ">
               <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/novakid/1.jpeg`} className="d-block d-sm-none marmsmb " width="100%" height="100%"  />
                <Container fluid className="pdr0 ">
                    <Row>

                        <Col lg="4" md="4" sm="6" xs="12" className="padmmdt mbpt0 casebox offset-lg-1 ">
                            <p className="text-left p1 marmsmb mbb50"> <span class="spcase">THE</span> <strong>Brand</strong> </p>
                            <p className="p2">
                                <span>Novakid</span> is an online English school for children aged 4-12 that provides lessons with native speakers using a syllabus that corresponds to the European Standards CEFR. 
                            </p>
                            <p className="text-left p1 mbt50 marbmdt  marmsmb mbb50"> <span class="spcase">THE</span> <strong>Problem</strong> </p>
                            <p className="p2">
                            Demand Generation
                            </p>
                            <p className="p2">
                                <ul>
                                    <li>Poor quality traffic leading to no trials</li>
                                    <li>No income level filter leading to poor final subscriptions</li>
                                    <li>No filtering of bot/spam traffic Demand Conversion</li>
                                    <li>Poor communication on landing page</li>
                                    <li>Localisation of Landing Page not done</li>
                                </ul>
                            </p>

                        </Col>

                        <Col lg={{ span: 7, order: 'last' }} md="6" sm={{ span: 6, order: 'last' }} xs={{ span: 12, order: 'first' }} className="pdr0 mbppr0 marmsmt marmsmb mdpdt80 d-none d-sm-block">

                               <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/novakid/1.jpeg`} className="" width="100%" height="100%"  />
                        </Col>

                    </Row>
                </Container>
            </div>
            {/* Marketing Insights  */}

            <Container fluid className=" box padbmdt padbmdb">
                <div className="seomatters ">
                    <Row>
                        <Col lg="11" className="casebox offset-lg-1">
                            <p className="text-left p1 marmsmb "> <span class="spcase">THE</span> <strong>Insight</strong></p>
                        </Col>
                    </Row>
                    <Container>
                        <Row className="padsmdt padsmdb mbpdt30 marketing">

                            <Col lg="6" md="4" sm="6" xs="12">
                                <div className="circleboxfirst pdtb20  padssmb">
                                       <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} width="50%" height="50%" alt="" />
                                    <div className="boxcentered">
                                        <h1 className="boxtitle"><br />Novakid’s <br /> revenue depended<br /> on three factors:-<br /><br /> </h1>

                                    </div>
                                </div>
                                <ul className="text-left mbpt0 mgbx50">
                                    <li>Quality of traffic coming to the website.</li>
                                    <li className="padsxst">Experience of the website after a user<br /> lands on it.</li>
                                    <li className="padsxst">Sales team pitch.</li>
                                </ul>
                            </Col>

                            <Col lg="6" md="4" sm="6" >
                                <div className="circleboxfirst pdtb20   padssmb">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} width="50%" height="50%" alt="" />
                                    <div className="boxcentered"> 
                                        <h1 className="boxtitle">Novakid had a fantastic<br/> sales team, but the other two factors were broken.</h1>
                            </div>
                                </div>
                                {/* <ul className="text-left mbpt0 mgbx50">
                                    <li>Quality of traffic was poor because<br /> digital advertising was driving irrelevant<br /> traffic to the website.
                                    </li> 
                                    <li className="padsxst">Experience of the website was sub-par<br /> despite the site being visually appealing
                                    </li>
                                </ul> */}
                               <ol className="text-left mbpt0 mgbx50">
                                <br/>
                                <li>Quality of traffic was poor because digital advertising was driving irrelevant traffic to the website. No income level filter leading to poor final subscriptions</li> <br/>
                                <li>Funnel to get leads & conversion was complicated & lengthy. UI was great was User Experience was a challenge</li>
                            </ol>
                            </Col>

                        </Row>
                    </Container>

                </div>

            </Container>
            {/* The Build */}
            <div className="lgbox build">
                <Container fluid className="lgbox padmmdt padmmdb mbpdt30">
                <Row>
                        <Col lg="11" className="casebox offset-lg-1">
                        <p className="text-left p1 marmmdb mgbtom30 margb30"> <span class="spcase">THE</span> <strong>BUILD</strong></p>
                        </Col>
                    </Row>
                  
                    <Row className=" ">

                        <Col lg="10" sm="8" xs="12" className="casebox offset-lg-1 offset-sm-1">

                            <div className="d-none d-sm-block"><span className="aplabox ">a</span></div>
                            <h3 className=" marmsmb margtab30tb mdmt0 mgl0">
                            High Intent Quality Traffic through Advertising & Using Ad Platforms where parents are more present.
                            </h3>
                            <p className="p2">
                            <ul>
                                <li>Novakid has a premium product compared to their competitors in India. What Novakid was targeting earlier was all parents in India. However, their audience was parents in Tier 1 cities who want their kids to go study abroad and have a good accent</li>
                                <li>76% Indian Parents use YouTube as a consumption platform, more than Instagram/Facebook. Novakid was missing out on capturing their TG in the right place.</li>
                            </ul>
                            </p> 
                            <p className="p2">
                            We took this insight and paused all existing campaigns and created new ones with the parents who have the income & intent for a Novakid subscription, at the right place. 
                            </p>
                            <p className='p2'>Below is the lift in revenue after the changes- </p>
                               <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/novakid/1image.png`} className=" mx-auto img-fluid mgbtom30 " />
                        </Col>
 

                    </Row>
                </Container>
            </div>
            {/* b fold */}
            <div className="lgbox build">
                <Container fluid className="pdlr0">
                    <Row className="text-left  mbpdt30 mpdt0">
                        <Col lg="10" md="10" sm="10" className="offset-lg-1 offset-sm-1">
                            <div className="d-none d-sm-block">  <span className="aplabox">b</span></div>

                            <h3 className="marmsmt marmsmb  mbmt0">
                                Website Experience
                            </h3>
                            <ul className="pdl20 marbxsb">
                                <li><h4 className="cultitle">The process & fundamentals.</h4></li>
                            </ul>
                            <ol className="cuulb">
                                <li><span>Localisation</span>- Indian parents would see the website in Turkish language and Chatbot in Indonesian. We got this fixed & localized to English & Hindi both the languages.</li>
                                <li className="padmxst padmxsb">
                                    <span>Lead to trial to booking process </span> - The booking process was broken & after getting the inquiry, parents didn’t know where to navigate to book trial, video tutorials & prompts were then added to make the booking process smooth & hassle free for parents.
                                </li>
                                <li>
                                    <span>Communication</span> - Novakid’s USP was native english speaking teachers & making kids confident to speak english, this communication was buried deep inside the landing page, we put out the hero communication & USPs at the start, highlighting why choose Novakid over the competition. 
                                </li>
                            </ol>
                        </Col>
                    </Row>
                    <Row className="text-left padmmdt  mbpdt30 mpdt0">
                        <Col lg="10" md="10" sm="10" className="offset-lg-1 offset-sm-1">
                            <ul className="pdl20 ">
                                <li><h4 className="cultitle">Technical issues on the website</h4></li>
                            </ul>
                            <h3 className="marmxst marmxsb mbmtb0">
                                Page Speed
                            </h3>
                            <p className="para2">The website speed score was below <span className="parasco">50</span>, this was then optimized to improve. The mobile score went up to <span className="parasco">65</span> and desktop to a whooping <span className="parasco">85</span> from <span className="parasco">44</span>.</p>
                               <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/novakid/2image.png`} className="mx-auto img-fluid text-right mgbtom30" /><br/><br/>
                               <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/novakid/3image.png`} className="mx-auto img-fluid text-right mgbtom30" />
                        </Col>
                    </Row>
                    <Row className="text-left padmmdt mbpt0  mbpdt30 mpdt0">
                        <Col lg="10" md="10" sm="10" className="offset-lg-1 offset-sm-1">

                            <h3 className="marmxst marmxsb mbmtb0">
                                Landing page experience
                            </h3>
                            <p className="para2">The booking process on the landing page was confusing & not easy to navigate. We solved this with prompts and changes in the Interface to make it more seamless.</p>
                               {/* <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/atmantan-digitalmedia/4.png`} className="mx-auto img-fluid text-right mgbtom30 marmxst " /> */}
                        </Col>
                    </Row>
                    <Row className="text-left padmmdt  mbpdt30 mpdt0">
                        <Col lg="10" md="10" sm="10" className="offset-lg-1 offset-sm-1">
                        <h3 className="marmxst marmxsb mbmtb0">
                        Trust
                            </h3>
                             
                            <p className="para2">Novakid had no elements on the website to drive trust. We added:-</p>
                            <p className='p2 text-center'>Customer & Mom content creators reviews on the site.  </p>
                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/novakid/4image.png`} className="mx-auto img-fluid text-right mgbtom30 marmxst " />
                        </Col>
                    </Row>
                      
                    
                </Container>

            </div>
            <div className="box  problem">
                {/* first fold */}
                   <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/atmantan-digitalmedia/5.png`} width="100%" height="100%" className="mbpdtb30 d-block d-sm-none text-right pdlr0 " />
                <Container fluid className="impact pdr0 mbpdb70">
                    <Row>
                    
                        <Col lg="11" md="12" sm="12" xs="12" className="casebox offset-lg-1 padmmdt mbpt0 ">
                            <p className="text-left p1 mbb50"> <span class="spcase">THE</span> IMPACT</p><br />
                            <ul className="cuulb  marbmdb">
                            <li><h4> Overall Revenue went up<span> by +418%</span></h4></li>
                            <li><h4>Revenue via advertising went up<span> by +1000%</span></h4></li>
                            </ul>

                        </Col>

                        {/* <Col lg={{ span: 7, order: 'last' }} md="6" sm={{ span: 6, order: 'last' }} xs={{ span: 12, order: 'first' }} className=" pdr0">
                               <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/atmantan-digitalmedia/5.png`} width="100%" height="100%" className="d-none d-sm-block text-right" />

                        </Col> */}

                    </Row>
                </Container>
            </div>
            <div className="lgbox build endbox">
                <Container fluid className="pdlr0">
                    <Row className="text-left  mglr0">


                        <Col lg="6" sm="6" xs="12" className=" offset-lg-0 bgbl ">
                            <Link to={`${process.env.PUBLIC_URL}/case-studies/content-marketing-case-study-increase-in-pageviews.html`}>
                                <Row className="marssmt marssmb pdlr0 mglr0">
                                    <Col lg="3" sm="3" xs="3" >
                                           <img src={`${process.env.PUBLIC_URL}/img/casestudy/footerlogo/blabel.png`} width="97" height="97" className="mx-auto img-fluid text-right" />
                                    </Col>
                                    <Col lg="4" sm="6" xs="7">
                                        
                                        <h4 className="tcgold">Blabel </h4>
                                        <p>1043% increase in unique blogs pageviews </p>
                                    </Col>
                                    <Col lg="2" sm="3" xs="2" className="marbxst">
                                           <img src={`${process.env.PUBLIC_URL}/img/righticon.png`} width="34" height="34" className=" text-right" />
                                    </Col>
                                </Row>
                            </Link>
                        </Col>
                        
                        <Col lg="6" sm="6" xs="12" >
                          
                        <Link to={`${process.env.PUBLIC_URL}/case-studies/social-media-case-study-evok-furnitures.html`}>
                            <Row className="marssmt marssmb pdlr0 mglr0">
                                <Col lg="3" sm="3" xs="3" >
                                      <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/footerlogo/evok.png`} className="mx-auto img-fluid text-right" />
                                </Col>
                                <Col lg="4" sm="6" xs="7">
                                    {/* <p>Casestudy 9</p> */}
                                    <h4 className="tcgold">Evok Social Media Case Study</h4>
                                    <p> 1.47 Million Targeted Users Engaged</p>
                                </Col>
                                <Col lg="2" sm="3" xs="2" className="marbxst">
                                      <img src={`${process.env.PUBLIC_URL}/img/righticon.png`} width="40%" className=" text-right" />
                                </Col>
                            </Row>
                            </Link> 
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>

    )
}
export default NovaKid;