import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container, Button, Col, Row } from 'react-bootstrap';
import Slider from "react-slick";
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";
import { LazyLoadImage } from 'react-lazy-load-image-component';

function Isaia() {
    window.scrollTo(0, 0);
    return (
        <div>
            <Helmet>

                <title>Retail Store Digital Transformation - ISAIA Footfalls increased by 100%</title>
                <meta name="description" content="Isaia Casestudy" />
                <link rel="canonical" href="https://www.aandb.xyz/case-studies/isaia-retail-store-digital-transformation.html" />

            </Helmet>
            {/* first fold */}
            <div className="casestudy">
                <div className="box padmmdt padmmdb">

                    <Container>

                        <p className="text-left p1 pdl10"> <span class="spcase">IS</span>AIA</p>
                    </Container>

                    <Container fluid className="padbsmt padbsmb">
                        <Row>
                            <Col lg="2"></Col>
                            <Col lg="8">
                                <div className="circleboxfirst  padssmb">
                                      <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="roundbx"  width="30%" alt="" />

                                    <div className="boxcentered">
                                        <h4 className="d-none d-sm-block">  ISAIA Store Footfalls<br /> increased by <br /><span className="cuspn">  100%</span> in 4 months. 
                                        </h4>
                                        <h4 className="d-block d-sm-none">   ISAIA Store Footfalls increased by
                                            <span className="cuspn">  100%</span> in 4 months.
                                        </h4>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="2"></Col>
                        </Row>
                    </Container>
                </div>
            </div>
            {/* The problem  and build fold */}
            <div className="lgbox  problem">
              <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/isaia/1.png`} className="d-block d-sm-none marmsmb " width="100%" height="100%" />
                <Container fluid className="pdr0 ">
                    <Row>

                        <Col  lg="5" md="4" sm="6" xs="12" className="padmmdt mbpt0 casebox offset-lg-1 ">

                            <p className="text-left p1 mrg30 " > <span class="spcase">THE</span> Brand </p>
                            <p className="p2 mb60t  marbmdt  ">
                                <span> ISAIA</span> is a luxury menswear<br /> brand founded in <span>Naples, Italy</span>.
                            </p>

                        </Col>

                        <Col lg={{ span: 6, order: 'last' }} md="6" sm={{ span: 6, order: 'last' }} xs={{ span: 12, order: 'first' }} className="pdr0 mbppr0  mdpdt80 d-none d-sm-block">

                              <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/isaia/1.png`} className="img-fluid text-right" />
                        </Col>

                    </Row>
                </Container>
            </div>
            {/* Problem/Opportunity  */}

            <Container fluid className="box padbmdt padbmdb">
                <div className="seomatters ">
                    <Row>
                        <Col lg="11" className="casebox offset-lg-1">
                            <p className="text-left p1 marmsmb "> <span class="spcase">THE</span> Problem/Opportunity </p>
                        </Col>
                    </Row>
                    <Container>
                        <Row className="  mbpdt30 marketing">

                            <p className="p2">
                                They were present in India most luxurious mall, <strong>DLF Emporio in South Delhi.</strong> Still, They were struggling to get footfalls because of the obscure store location and lack of brand awareness & aspiration.

                            </p>
                            <Col lg="2"></Col>
                            <Col lg="8">
                                <div className="circleboxfirst  padssmt padssmb">
                                      <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="roundbx" width="30%" alt="" />

                                    <div className="boxcentered">
                                        <h4 className="custboxh4"> <br /> <br /> <br /> People were asking, <br /> “what is ISAIA?” “Why should I buy ISAIA <br /> when I can buy Armani.”
                                        </h4>

                                    </div>
                                </div>
                            </Col>
                            <Col lg="2"></Col>
                        </Row>
                    </Container>

                </div>

            </Container>

            {/* insights fold*/}
            <div className="lgbox build insights">
                <Container fluid className="pdlr0">
                    <Row className="text-left">
                        <Col lg="5" md="5" sm="5" className="padmmdt mbpt0  mpdt0 offset-lg-1 offset-sm-1">
                            <p className="text-left p1 d-none d-sm-block"> <span class="spcase">THE</span> INSIGHTS</p><br />

                            <p className="p2 padmsmt padmsmb pdt20 pdtb20">The luxury clothing industry is greatly influenced by celebrities (Hollywood stars, models etc.)
                            </p>
                            <p className="p2 padmsmb pdtb20">While ISAIA were worn by all the top stars, including <span>Robert Downey Jr., Dwayne Johnson</span>, Robert Pattinson etc. they did not talk about this fact.
                            </p>
                            <p className="p2 padmsmb pdtb20">We understood that if we solve the problem of awareness and aspiration to the brand, people would come seeking the store and the problem of store location would be solved automatically.
                            </p>
                        </Col>
                        <Col lg={{ span: 5, order: 'last' }} md="5" sm={{ span: 5, order: 'last' }} xs={{ span: 12, order: 'first' }} className="offset-lg-1 offset-sm-1">
                            <p className="text-left p1 d-block d-sm-none  mrg30  "> <span class="spcase">THE</span> INSIGHTS</p><br />

                              <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/isaia/2.png`} className="mx-auto mbpdtb0  img-fluid text-right" />
                        </Col>
                    </Row>

                </Container>

            </div>
            <div className="box  bgb">
                <Row className="text-left padmmdt  mpdt0">
                    <Col lg="5" md="5" sm="5" className="offset-lg-1 offset-sm-1">
                        <p className="text-left p1 tcwhite "> <span class="spcase">THE </span>Build/Communication</p><br />
                    </Col>
                </Row>
                <Container className=" seoservices took">

                    <p className="p2 text-center padmsmt padmsmb  mbpdt30">We took the insight from the industry and developed communication around the celebrities. Every piece of communication was around this itself.</p>
                    <Row className="padsmdb" xs={12}>
                        <Col lg="4" md="4" sm="4" xs="6" >
                            <div className="circleboxfirst  padssmb">
                                  <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1> <br /> <br />Instagram <br /> posts</h1>
                                </div>
                            </div>

                        </Col>
                        <Col lg="4" md="4" sm="4" xs="6" >
                            <div className="circleboxfirst  padssmb">
                                  <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1>  <br /><br />Advertising<br /></h1>
                                </div>
                            </div>

                        </Col>
                        <Col lg="4" md="4" sm="4" xs="6" >
                            <div className="circleboxfirst  padssmb">
                                  <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1>  <br /><br />Instagram <br />Highlights</h1>
                                </div>
                            </div>

                        </Col>
                    
                        <Col lg="4" md="4" sm="4" xs="6" >
                            <div className="circleboxfirst  padssmb">
                                  <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1> <br /> <br />Remarketing <br />  Ads</h1>
                                </div>
                            </div>

                        </Col>
                        <Col lg="4" md="4" sm="4" xs="6" >
                            <div className="circleboxfirst  padssmb">
                                  <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1>  <br /><br />In-Store TV <br /> Screens</h1>
                                </div>
                            </div>

                        </Col>
                        <Col lg="4" md="4" sm="4" xs="6" >
                            <div className="circleboxfirst  padssmb">
                                  <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1> <br /> <br />Mall <br />  installations etc.</h1>
                                </div>
                            </div>


                        </Col>
                    </Row>

                </Container>
            </div>
            <div className="lgbox padmmdt padmmdb problem">
                {/* first fold */}

                <Container fluid >
                    <Row>

                        <Col lg="4" md="4" sm="4" xs="12" className="casebox offset-lg-2 cs">

                            <p className="p2 text-center">People went from
                            </p>
                              <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/isaia/5.png`} width="100%" className="mx-auto" />

                        </Col>
                        <Col lg="4" md="4" sm="4" xs="12" className="casebox  ">
                              <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/isaia/3.png`} width="100%" className="mx-auto" />

                        </Col>
                        <span className="relevantbox mx-auto mgtop"> But the number of people who would see this was limited, hence the next step - <strong>Advertising.</strong>
                        </span>


                    </Row>
                </Container>
                <br />
            </div>
            {/* adversting */}
            <div className="box build endbox">
                <Container fluid className="pdlr0">
                    <Row className="text-left  mglr0 padbsmt padbsmb">
                        <Col lg="10" sm="12" xs="12" className="offset-lg-1">
                            <p className="text-left p1 "> <span class="spcase">THE </span>Build/<strong>Advertising</strong></p><br />
                        </Col>
                        <Container>
                            <Row>
                        <Col lg="4" sm="3" xs="12" className="padbsmt mbpt0">
                            <h3>In Mall Audience</h3>
                            <p className="p2">
                                The best catchment we had was the audience in the mall itself; the people who were shopping from the most luxurious brands available in India. These people were pausing to catch up on Instagram or check their feeds while waiting for food.

                                And we were ready to speak to them, by radius targeting in the mall.

                            </p>
                        </Col>
                        <Col lg="4" sm="3" xs="12" className="padbsmt twoboxbord ">
                            <h3>Nearby Catchment</h3>
                            <p className="p2">
                                The South Delhi catchment where luxury shoppers live.
                            </p>
                        </Col>
                        <Col lg="4" sm="3" xs="12" className="padbsmt" >
                            <h3>Luxury Shoppers in Delhi </h3>
                            <p className="p2">
                                People who come to DLF Emporio from all over Delhi interested in luxury goods.
                            </p>
                        </Col>
                        </Row>
                        </Container>
                    </Row>
                </Container>
            </div>
            <div className="lgbox build endbox">
                <Container fluid className="pdlr0">

                    <Row className="text-left pdr0  mglr0">

                      <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/isaia/4.png`} width="100%" className="mbpdtb30 d-block d-sm-none text-right pdlr0 " />
                        <Col lg="6" sm="6" xs="12" className="numbers padmmdt  mpdt0 mbpt0  offset-lg-1">
                            <p className="text-left p1 "> <span class="spcase">THE</span> IMPACT</p><br />

                            <ul>
                                <li>
                                    Increase in-store Revenue by <span className="tcgold">76.34%</span><br /> from Feb (when store launched) to <br />September.
                                </li>
                                <li>
                                    Store Footfalls increased by <span className="tcgold">100% in 4 months.</span>
                                </li>
                                <li>
                                    Increase in the number of calls from Digital by <span className="tcgold">100%</span>
                                </li>
                                <li>
                                    Call Inquiries turned to walk-ins and generated leads
                                </li>
                            </ul>
                            <br />
                              <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/isaia/7.png`} className="img-fluid" />

                        </Col>
                        <Col lg="5" sm="6" xs="12" className="pdr0">
                              <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/isaia/4.png`} className="d-none d-sm-block img-fluid" />
                        </Col>

                    </Row>
                </Container>
            </div>
            <div className="lgbox build endbox">
                <Container fluid className="pdlr0">
                    <Row className="text-left  mglr0">
                    <Col lg="6" sm="6" xs="12">
                        <Link to={`${process.env.PUBLIC_URL}/case-studies/seo-case-study-for-event-ticketing-company.html`}>
                            <Row className="marssmt marssmb mglr0">
                                <Col lg="3" sm="3" xs="3" >
                                      <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/footerlogo/insider.png`} className="mx-auto img-fluid text-right" />
                                </Col>
                                <Col lg="5" sm="6" xs="7">
                                     
                                    <h4 className="tcgold">INSIDER </h4>
                                    <p>430% Increase in Organic Traffic</p>
                                </Col>
                                <Col lg="2" sm="3" xs="2" className="marbxst">
                                      <img src={`${process.env.PUBLIC_URL}/img/righticon.png`} width="40%" className=" text-right" />
                                </Col>
                            </Row>
                            </Link>
                        </Col>
                        <Col lg="6" sm="6" xs="12" className="bgbl " >
                        <Link to={`${process.env.PUBLIC_URL}/case-studies/parfait-snapchat-instagram-case-study.html`}>
                            <Row className="marssmt marssmb pdlr0 mglr0">
                                <Col lg="3" sm="3" xs="3" >
                                      <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/footerlogo/parfait.png`} className="mx-auto img-fluid text-right" />
                                </Col>
                                <Col lg="4" sm="6" xs="7">
                                     
                                    <h4 className="tcgold">Parfait </h4>
                                    <p>Magazine covers became size inclusive with just a lens! </p>
                                </Col>
                                <Col lg="2" sm="3" xs="2" className="marbxst">
                                      <img src={`${process.env.PUBLIC_URL}/img/righticon.png`} width="40%" className=" text-right" />
                                </Col>
                            </Row>
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>

    )
}
export default Isaia;