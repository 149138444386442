import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Nav, Container, Accordion, Card, Button, Col, Row, Tab, Carousel } from 'react-bootstrap';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Slider from "react-slick";
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";
import Services from './Services';
function Ecommerceservices() {
    window.scrollTo(0, 0);
    const [focus, setFocus] = React.useState(false);
    return (

        <div>
            <Helmet>

                <title>Best Ecommerce Digital Marketing Agency for *Luxury* Brands | A&B</title>
                <meta name="description" content="An E-Commerce Sales Growth Agency that puts you in front of 250,000 luxury online shoppers." />
                <link rel="canonical" href="https://www.aandb.xyz/luxury-e-commerce-agency.html" /> 

            </Helmet>
            <div className="firstfoldseo ">
                <div className="box padmmdb">
                    {/* first fold */}
                    <Container fluid className="padbsmt padbsmb">
                        <Row>
                            <Col lg="2"></Col>
                            <Col lg="8">
                                <div className="circleboxfirst  padsmdt padsmdb">
                                    <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="roundbx" width="30%" alt="" />

                                    <div className="boxcentered">
                                        <h4>
                                            An E-Commerce Sales Growth Agency that puts you in front of <span className="cuspn"> 
                                            250,000</span> luxury online shoppers.
                                        </h4>

                                    </div>

                                </div>
                                <AnchorLink href="#growth" className="btn btn-seo" >Grow your Online Sales </AnchorLink>
                            </Col>
                            <Col lg="2"></Col>

                        </Row>
                    </Container>
                </div>
            </div>
            {/* Seo matters */}
            <div id="growth" className="seomatters  bgb">
                <Container className="padbmdt padbmdb">

                    <h3 className="text-center txtcolor  padsmdb mgb50">Why <span>E-Commerce is Critical</span> for Luxury Brands?</h3>
                    <Row className="padsmdb">
                        <Col lg="4" md="4" sm="4">
                            <div className="circleboxfirst  padssmb pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="servicescirbox" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1>22%</h1>
                                </div>
                            </div>
                            <p>Growth in the online luxury<br /> market in 2020</p><br />
                        </Col>
                        <Col lg="4" md="4" sm="4">
                            <div className="circleboxfirst  padssmb   pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="servicescirbox" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1>30%</h1>
                                </div>
                            </div>

                            <p>Contribution of e-commerce <br />in Luxury goods.</p>
                            <br />
                        </Col>
                        <Col lg="4" md="4" sm="4">
                            <div className="circleboxfirst  padssmb  pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="servicescirbox" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1>50%</h1>
                                </div>
                            </div>
                            <p>Of the luxury market are <br />millennials using e-com.</p>

                        </Col>
                    </Row>
                    <AnchorLink href="#letsdo" className="btn btn-seo" >Grow your Online Sales</AnchorLink>
                </Container>
            </div>
            {/* our approach seo fold */}
            <div id="letsdo" className="seoapproach bgbl padmmdt padmmdb ">
                <Container>
                    <div className="appbox  padssmb d-none d-sm-block">
                        <img src={`${process.env.PUBLIC_URL}/img/approach2.png`} className="imgwidt img-fluid" alt="" />
                        <div className="top-left">
                            <h4><strong> Traffic, </strong><br /> <i>not likes </i></h4>
                            <p>We get traction on e-commerce, not just hearts on Instagram.</p>
                        </div>

                        <div className="top-right">
                            <h4><strong>Buyers,  </strong><br /> <i>not browsers</i></h4>
                            <p>We target customers with the right purchasing power & intent.</p>
                        </div>

                        <div className="bottom-left">
                            <h4> <strong>Loyalty,</strong><br /> <i>not single Transactions</i></h4>
                            <p>We use data to remarket, building relationships & repeat purchases.</p>
                        </div>


                        <div className="bottom-right">
                            <h4><strong>Tracking Metrics, </strong><br /> <i>that Matters</i></h4>
                            <p>Eyeballs are okay but we focus on Revenue, AOV & COA</p>
                        </div>
                        <div className="centered"><h1>Our Approach <br />to <span>E-commerce Sales Growth</span></h1></div>
                    </div>
                    <div className="appbox  padssmb d-block d-sm-none">
                        <h1>OUR <span>APPROACH</span> <br />E-commerce Service</h1> <br />

                        <h4><strong> Traffic, </strong><br /> <i>not likes </i></h4>
                        <p>We get traction on e-commerce, <br />not just hearts on Instagram.</p>
                        <img src={`${process.env.PUBLIC_URL}/img/straightline.png`} className="img-fluid" />
                        <h4><strong>Buyers,  </strong><br /> <i>not browsers</i></h4>
                        <p>We target customers with the right<br /> purchasing power & intent.</p>

                        <img src={`${process.env.PUBLIC_URL}/img/straightline.png`} className="img-fluid" />
                        <h4><strong>Loyalty,</strong><br /> <i>not single Transactions</i></h4>
                        <p>We use data to remarket, building <br />relationships & repeat purchases.</p>
                        <img src={`${process.env.PUBLIC_URL}/img/straightline.png`} className="img-fluid" />
                        <h4><strong>Tracking Metrics, </strong><br /> <i>that Matters</i></h4>
                        <p>Eyeballs are okay but we focus on <br />Revenue, AOV & COA.</p>
                    </div>

                    <Accordion defaultActiveKey="0" className="seoapproach1 marbxst">

                        <Accordion.Toggle as={Button} variant="read" eventKey="1">
                            Our approach in detail
                        </Accordion.Toggle>

                        <Accordion.Collapse eventKey="1">
                            <Card.Body>
                                <h3 className="text-center txtcolor  padsmdb"> <span>E-commerce Service</span></h3>
                                <Row>
                                    <ol>
                                        <li>
                                            Selling luxury goods, services or experiences isn’t the same thing as selling the low and mid-tier alternatives. We understand the difference and what it takes to convert a potential customer.
                                        </li>
                                        <li>Search Engine Optimization </li>
                                        <li>Social Media Strategy & Creative</li>
                                        <li>Search Engine Marketing </li>
                                        <li>Content Marketing</li>
                                        <li>Lead Generation</li>
                                    </ol>

                                </Row>
                            </Card.Body>
                        </Accordion.Collapse>

                    </Accordion>
                </Container>
                {/* <img src={`${process.env.PUBLIC_URL}/img/approach.png`} className="img-fluid" /> */}
            </div>
            {/* seo services */}
            {/* <div className="seoservices  bgb">
                <Container className="padmmdt  ">

                    <h3 className="text-center txtcolor  padsmdb pdtb20">SUB <span>SERVICES</span></h3>
                    <Row className="padsmdb" xs={12}>
                        <Col lg="4" md="4" sm="4" xs="6" >
                            <div className="circleboxfirst  padssmb ">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1><br /> <br />  Conversion<br /> Rate Optimization </h1>
                                </div>
                            </div>

                        </Col>
                        <Col lg="4" md="4" sm="4" xs="6" >
                            <div className="circleboxfirst  padssmb ">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1><br /> <br /> Search Engine  <br /> Optimization </h1>
                                </div>
                            </div>

                        </Col>
                        <Col lg="4" md="4" sm="4" xs="6" >
                            <div className="circleboxfirst  padssmb ">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1><br /> <br /> Social Media  <br /> Strategy & Creative</h1>
                                </div>
                            </div>

                        </Col>
                        <Col lg="4" md="4" sm="4" xs="6" >
                            <div className="circleboxfirst  padssmb ">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1><br /> <br />Search Engine <br /> Marketing </h1>
                                </div>
                            </div>

                        </Col>
                        <Col lg="4" md="4" sm="4" xs="6" >
                            <div className="circleboxfirst  padssmb ">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1><br /> <br />  Content <br />  Marketing</h1>
                                </div>
                            </div>

                        </Col>
                        <Col lg="4" md="4" sm="4" xs="6" >
                            <div className="circleboxfirst  padssmb ">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1><br /> <br />  Lead <br /> Generation</h1>
                                </div>
                            </div>

                        </Col>
                    </Row>
                    

                </Container>
            </div> */}
            {/* case study */}

            <div className="bgfooter whyus padsmdt padbmdb">
                <Col lg="8" className="offset-lg-1" >
                    <h3 className=" txtcolor fwr"><span className="spund">CASE</span> STUDY</h3>

                </Col>
                <Casestudy />
            </div>

            {/* 3 Reasons to choose */}

            <div className="bgbl  casestudy">

                <Reasons />
            </div>

            {/* our services */}
            <Services />
        </div>
    );
}


export default Ecommerceservices;

function Casestudy() {
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,

        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    adaptiveHeight: true,
                }
            },]
    };
    const next = () => {
        Slider.current.slickNext();
    }
    const previous = () => {
        Slider.current.slickPrev();
    }
    return (
        <Container fluid className="padmmdt casestudy ">
            <Row>
                <Col lg="10" className="offset-lg-1">
                    <Slider {...settings}>
                        <div>
                            <Row>
                                <Col className="offset-lg-1" lg={{ span: 4, order: 'first' }} md="6" sm={{ span: 6, order: 'last' }} xs={{ span: 12, order: 'first' }}><img src={`${process.env.PUBLIC_URL}/img/evokcarousel.png`} width="100%" className="  mx-auto d-block" /></Col>
                                <Col lg={{ span: 6, order: 'first' }} sm={{ span: 6, order: 'first' }} md="6" xs={{ span: 12, order: 'last' }} className="mbtxtcenter   offset-lg-1 padbsmb">
                                    {/* <h1 className=" padbxsb">&nbsp;</h1> */}
                                    <h2 className="padslgt padbxsb text-left "><strong>Scaled Ecommerce<br /> <span className="prs">Revenue by 2404%</span></strong></h2>

                                    {/* <a href="" className="btn btn-primary ">Read Case Study</a> */}
                                    <Link to={`${process.env.PUBLIC_URL}/case-studies/scaling-evok-ecommerce-revenue.html`} className="btn btn-seo ">Read Case Study</Link>

                                </Col>

                            </Row>

                        </div>


                    </Slider>
                </Col>
            </Row>
        </Container>
    );
}

// reasons
function Reasons() {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        adaptiveHeight: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false,
                    adaptiveHeight: true,
                }
            },],
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    adaptiveHeight: false,
                }
            },]
    };
    const next = () => {
        Slider.current.slickNext();
    }
    const previous = () => {
        Slider.current.slickPrev();
    }
    return (
        <Container fluid className="reasons padslgt padslgb casestudy ">
            <Row>
                <Col lg="10" className="offset-lg-1">
                    <Slider {...settings}>
                        <div>
                            <Row>
                                <Col className="offset-lg-1 offset-sm-1" lg={{ span: 4, order: 'first' }} md="4" sm={{ span: 3, order: 'first' }} xs={{ span: 12, order: 'first' }}><img src={`${process.env.PUBLIC_URL}/img/reasons3.png`} className="custmt80 padt130 padmmdt mimgw padmsmb marbsmt img-fluid mx-auto d-block" /></Col>
                                <Col className="marmlgt mt0 " lg={{ span: 7, order: 'first' }} sm={{ span: 6, order: 'last' }} md="7" xs={{ span: 12, order: 'last' }}  >

                                    <h2 className="marmlgt"> Reasons to choose A&B </h2> <br />

                                </Col>

                            </Row>

                        </div>
                        
                        <div>
                            <Row>

                                <Col className="offset-lg-1 offset-sm-1" lg={{ span: 5, order: 'first' }} sm={{ span: 6, order: 'first' }} xs={{ span: 12, order: 'last' }} >

                                    <h2 className="padt190"><span>A&B Commitment</span></h2>
                                    <h3 className=" padmsmb">  If we don't generate <strong>growth</strong> in 3 months, <strong><i>we fire ourselves.</i></strong><br /> There are 9 more weird things about us, <br />read them here.</h3>

                                </Col>
                                <Col className="offset-lg-1 mgm90" lg={{ span: 4, order: 'first' }} sm={{ span: 4, order: 'last' }} xs={{ span: 12, order: 'first' }}>
                                    <img src={`${process.env.PUBLIC_URL}/img/commitment.png`} className="custmt80  padslg125 mimgw img-fluid mx-auto d-block" />
                                </Col>

                            </Row>
                        </div>
                        <div>
                            <Row>

                                <Col className="offset-lg-1 offset-sm-1" lg={{ span: 5, order: 'first' }} sm={{ span: 5, order: 'first' }} xs={{ span: 12, order: 'last' }} >

                                    <h3 className="marblgt padmsmb padslg125 "> Specialised E-Commerce agency trusted by global brands</h3>

                                </Col>
                                <Col className=" mgm90" lg={{ span: 5, order: 'first' }} sm={{ span: 5, order: 'last' }} xs={{ span: 12, order: 'first' }}>
                                    <img src={`${process.env.PUBLIC_URL}/img/worldmap.png`} className="custmt80 padslg125 padbsmb img-fluid mx-auto d-block" />
                                    <img src={`${process.env.PUBLIC_URL}/img/clientslogo.png`} className=" img-fluid mx-auto d-block" />
                                </Col>

                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col className="offset-lg-1 offset-md-0" lg={{ span: 10, order: 'first' }} md={{ span: 12, order: 'first' }} sm={{ span: 8, order: 'last' }} xs={{ span: 12, order: 'last' }}>
                                    <h3 className="text-center htitle"><span>Don't believe us?</span></h3>
                                    <p className="text-center custml ">  Hear it from our partners.</p>
                                    <div className="feedbackbox marmsmt padmsmt padmsmb">
                                        <img src={`${process.env.PUBLIC_URL}/img/leftcoma.png`} className="mgt40 img-fluid mx-auto d-block" />
                                        <br />
                                        {/* <img src={`${process.env.PUBLIC_URL}/img/paytminsiderlogo.png`} className="img-fluid mx-auto d-block padmsmt padmsmb" /> */}
                                        <h4 className="">In Feb 2020, we were barely even recovering the hosting cost of our website, now we retail a few crores online. A&B handled our budgets in such a stingy manner that often we remark that you guys are underspending :)
                                        </h4>
                                        {/* <img src={`${process.env.PUBLIC_URL}/img/1seagallery.png`} className="rounded-circle img-fluid mx-auto d-block" /> */}
                                        {/* <img src={`${process.env.PUBLIC_URL}/img/rightcoma.png`}  className="flr" /> */}
                                    </div>
                                    <div className="clientname">
                                        <img src={`${process.env.PUBLIC_URL}/img/clients/saundh_roundedlogo.png`} className="img-fluid mx-auto caseimgwd" width="160px" /> <br />
                                        <p className="text-center"> <i>- Sabby Saluja, Founder, Saundh</i></p>
                                    </div>
                                </Col>

                            </Row>
                        </div>
                    </Slider>
                </Col>
            </Row>
        </Container>
    );
}