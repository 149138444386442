import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Nav, Container, Accordion, Card, Button, Col, Row, Tab, Carousel } from 'react-bootstrap';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Slider from "react-slick";
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";
import Services from './Services';
function InfluencerMarketing() { 
    window.scrollTo(0, 0); 
    const [focus, setFocus] = React.useState(false);
    return (
        <div>
            <Helmet>
                <title>Best Influencer Marketing Agency for *Luxury* Brands | A&B</title>
                <meta name="description" content="A Influencer marketing agency for luxury brands in Mumbai. People trust a recommendation from influencers over brands, get discovered and break the clutter." />
                <link rel="canonical" href="https://www.aandb.xyz/luxury-influencer-marketing-agency.html" /> 

            </Helmet>
            <div className="firstfoldseo ">
                <div className="box padmmdb">
                    {/* first fold */}
                    <Container fluid className="padbsmt padbsmb">
                        <Row>
                            <Col lg="2"></Col>
                            <Col lg="8">
                                <div className="circleboxfirst  padsmdt padsmdb">
                                    <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="roundbx"  width="30%" alt="" />

                                    <div className="boxcentered">
                                        <h4>
                                            <span className="cuspn"><CountUp start={focus ? 0 : null} end={85} redraw={true}>
                                            {({ countUpRef }) => (
                                                <VisibilitySensor onChange={(isVisible) => {
                                                    if (isVisible) { setFocus(true); }
                                                }}>
                                                    <span ref={countUpRef} />
                                                </VisibilitySensor>
                                            )}
                                        </CountUp>%</span> of all influencer marketing campaigns for luxury brands end up reaching the wrong audience.

                                     </h4>

                                    </div>

                                </div>
                                <AnchorLink href="#iwantto" className="btn btn-seo" >Fix your Influencer Marketing </AnchorLink>
                            </Col>
                            <Col lg="2"></Col>

                        </Row>
                    </Container>
                </div>
            </div>
            {/* Seo matters */}
            <div id="iwantto" className="seomatters  bgb">
                <Container className="padbmdt padbmdb">

                    <h3 className="text-center txtcolor  padsmdb">Why does your brand need <span>Influencer Marketing </span></h3>
                    <Row className="padsmdb">
                        <Col lg="4" md="4" sm="4">
                            <div className="circleboxfirst  padssmb  pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="servicescirbox" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1>87%</h1> 
                                </div>
                            </div>
                            <p>of people admit they’ve made a purchase because an influencer prompted them to.</p><br />
                        </Col>
                        <Col lg="4" md="4" sm="4">
                            <div className="circleboxfirst  padssmb  pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="servicescirbox" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1>6X</h1><br />
                                </div>
                            </div>

                            <p>more engagement on Influencer content vs on a brand post. </p><br />

                        </Col>
                        <Col lg="4" md="4" sm="4">
                            <div className="circleboxfirst  padssmb  pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="servicescirbox" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1>92%</h1><br />
                                </div>
                            </div>
                            <p>of people trust an influencer more than an advertisement or a celebrity endorsement. </p> 

                        </Col>
                    </Row>
                    <AnchorLink href="#letsdo" className="btn btn-seo" >Let's do it</AnchorLink>
                </Container>
            </div>
            {/* our approach seo fold */}
            <div id="letsdo" className="seoapproach bgbl padmmdt padmmdb ">
                <Container>
                    <div className="appbox  padssmb d-none d-sm-block">
                        <img src={`${process.env.PUBLIC_URL}/img/approach2.png`} className="imgwidt img-fluid" alt="" />
                        <div className="top-left">
                            <h4><strong> Engagement, </strong><br /> <i>not followers </i></h4>
                            <p>We focus on getting the right kind of engagement, instead of chasing the number of followers an influencer has. (High followers ≠ High engagement) </p>
                        </div>

                        <div className="top-right">
                            <h4><strong>Multi-layer content strategy, </strong><br /> <i>not a selfie</i></h4>
                            <p>We come up with campaign ideas that can generate high engagement and returns, instead of getting the influencers to post a selfie with the product</p>
                        </div>

                        <div className="bottom-left">
                            <h4> <strong>Real Metrics,</strong><br /> <i> not BS</i></h4>
                            <p>We track a deeper engagement beyond likes, which is community interaction, brand community growth, action on the branded content & brand lift. </p>
                        </div>


                        <div className="bottom-right">
                            <h4><strong>Influencer</strong><br /> <i>research </i></h4>
                            <p>We deep dive into the influencers audience demographics and whether they are the right fit for the brand or not before recommending them to you.</p>
                        </div>
                        <div className="centered"><h1>Our Approach <br />to <span>Influencer Marketing</span></h1></div>
                    </div>
                    <div className="appbox  padssmb d-block d-sm-none">
                        <h1>OUR <span>APPROACH</span> <br />to Influencer Marketing</h1> <br />

                        <h4><strong> Engagement,</strong><br /> <i> not followers</i></h4>
                        <p>We focus on getting the right kind of engagement, instead of chasing the number of followers an influencer has. (High followers ≠ High engagement) </p>
                        <img src={`${process.env.PUBLIC_URL}/img/straightline.png`} className="img-fluid" />
                        <h4><strong>Multi-layer content strategy,</strong><br /> <i>not a selfie</i></h4>
                        <p>We come up with campaign ideas that can generate high engagement and returns, instead of getting the influencers to post a selfie with the product</p>

                        <img src={`${process.env.PUBLIC_URL}/img/straightline.png`} className="img-fluid" />
                        <h4><strong> Real Metrics,</strong><br /> <i>not BS</i></h4>
                        <p>We track a deeper engagement beyond likes, which is community interaction, brand community growth, action on the branded content & brand lift. </p>
                        <img src={`${process.env.PUBLIC_URL}/img/straightline.png`} className="img-fluid" />
                        <h4><strong>Influencer </strong><br /> <i>research</i></h4>
                        <p>We deep dive into the influencers audience demographics and whether they are the right fit for the brand or not before recommending them to you.</p>
                    </div>

                     
                </Container>
                {/* <img src={`${process.env.PUBLIC_URL}/img/approach.png`} className="img-fluid" /> */}
            </div>

{/* case study */}

<div className="bgfooter whyus padsmdt padbmdb">
                <Col lg="8" className="offset-lg-1" >
                <h3 className=" txtcolor fwr"><span className="spund">CASE</span> STUDY</h3>
                </Col>
                <Casestudy />
            </div>
            {/* 3 Reasons to choose */}

            <div className="bgbl casestudy">

                <Reasons />
            </div>

            {/* our services */}
            <Services /> 
        </div>
    );
}


export default InfluencerMarketing;

//casestudy

function Casestudy() {
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,

        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    adaptiveHeight: true,
                }
            },]
    };
    const next = () => {
        Slider.current.slickNext();
    }
    const previous = () => {
        Slider.current.slickPrev();
    }
    return (
        <Container fluid className="padmmdt casestudy ">
            <Row>
                <Col lg="10" className="offset-lg-1">
                    <Slider {...settings}>
                    <div>
                            
                            <Row>
                                <Col className="offset-lg-1" lg={{ span: 4, order: 'first' }} md="6" sm={{ span: 6, order: 'last' }} xs={{ span: 12, order: 'first' }}><img src={`${process.env.PUBLIC_URL}/img/myntracasestudy.png`} width="100%" className=" mx-auto d-block" /></Col>
                                <Col lg={{ span: 6, order: 'first' }} sm={{ span: 6, order: 'first' }} md="6" xs={{ span: 12, order: 'last' }} className="offset-lg-1 padbsmb">
                                    <h1 className="padslgt padbxsb"></h1>
                                    <h2 className="padbxsb"><strong><span>Launching Myntra’s Loyalty Programme in collaboration with Vice Media
                                    </span></strong></h2>
                                    {/* <a href="" className="btn btn-primary ">Read Case Study</a> */}
                                    
                                </Col>

                            </Row>
                            

                        </div>
                        

                    </Slider>
                </Col>
            </Row>
        </Container>
    );
}

// reasons
function Reasons() {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        adaptiveHeight: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false,
                    adaptiveHeight: true,
                }
            },],
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    adaptiveHeight: false,
                }
            },]
    };
    const next = () => {
        Slider.current.slickNext();
    }
    const previous = () => {
        Slider.current.slickPrev();
    }
    return (
        <Container fluid className="reasons padslgt padslgb casestudy ">
            <Row>
                <Col lg="10" className="offset-lg-1">
                    <Slider {...settings}>
                        <div>
                        <Row>
                                <Col className="offset-lg-1 offset-sm-1" lg={{ span: 4, order: 'first' }} md="4" sm={{ span: 3, order: 'first' }} xs={{ span: 12, order: 'first' }}><img src={`${process.env.PUBLIC_URL}/img/reasons3.png`} className="custmt80 padt130 padmmdt mimgw padmsmb marbsmt img-fluid mx-auto d-block" /></Col>
                                <Col className="marmlgt mt0" lg={{ span: 7, order: 'first' }} sm={{ span: 6, order: 'last' }} md="7" xs={{ span: 12, order: 'last' }}  >

                                    <h2 className="marmlgt"> Reasons to choose A&B </h2> <br />

                                </Col>

                            </Row>

                        </div>
                        <div>
                            <Row>

                            <Col className="offset-lg-1 offset-sm-1" lg={{ span: 5, order: 'first' }} sm={{ span: 6, order: 'first' }}  xs={{ span: 12, order: 'last' }} >

                                <h2 className="padt190"><span>A&B Commitment</span></h2>
                                <h3 className=" padmsmb"> Our influencer campaigns will generate a minimum of 20% more impact than any of your past ones.</h3>

                            </Col>
                            <Col className="offset-lg-1 mgm90" lg={{ span: 4, order: 'first' }}  sm={{ span: 4, order: 'last' }} xs={{ span: 12, order: 'first' }}>
                                <img src={`${process.env.PUBLIC_URL}/img/commitment.png`} className="custmt80  padslg125 mimgw img-fluid mx-auto d-block" />
                            </Col>

                            </Row>
                           
                        </div>
                        <div>
                          <Row>

                            <Col className="offset-lg-1 offset-sm-1" lg={{ span: 5, order: 'first' }} sm={{ span: 5, order: 'first' }} xs={{ span: 12, order: 'last' }} >

                                <h3 className="marblgt padmsmb padslg125 "> Specialised influencer agency trusted by global brands</h3>

                            </Col>
                            <Col className=" mgm90" lg={{ span: 5, order: 'first' }}  sm={{ span: 5, order: 'last' }} xs={{ span: 12, order: 'first' }}>
                                <img src={`${process.env.PUBLIC_URL}/img/worldmap.png`} className="custmt80 padslg125 padbsmb img-fluid mx-auto d-block" />
                                <img src={`${process.env.PUBLIC_URL}/img/clientslogo.png`} className=" img-fluid mx-auto d-block" />
                            </Col>
                            
                            </Row>
                            
                        </div>
                        
                        <div>
                        <Row> 
                                <Col className="offset-lg-1 offset-md-0" lg={{ span: 10, order: 'first' }}  md={{ span: 12, order: 'first' }}  sm={{ span: 8, order: 'last' }} xs={{ span: 12, order: 'last' }}>
                                <h3 className="text-center htitle"><span>Don't believe us?</span></h3>
                                    <p className="text-center custml ">  Hear it from our partners.</p>
                                    <div className="feedbackbox marmsmt padmsmt padmsmb">
                                     <img src={`${process.env.PUBLIC_URL}/img/leftcoma.png`}  className="mgt40 img-fluid mx-auto d-block"/>
                                     <br />
                                        {/* <img src={`${process.env.PUBLIC_URL}/img/paytminsiderlogo.png`} className="img-fluid mx-auto d-block padmsmt padmsmb" /> */}
                                        <h4 className="">Really good work by team A&B on the influencer project.<br /> Looking forward to a long partnership ahead. Kudos
                                        </h4>  
                                        {/* <img src={`${process.env.PUBLIC_URL}/img/1seagallery.png`} className="rounded-circle img-fluid mx-auto d-block" /> */}
                                        {/* <img src={`${process.env.PUBLIC_URL}/img/rightcoma.png`}  className="flr" /> */}
                                    </div>
                                    <div className="clientname">
                                    <img src={`${process.env.PUBLIC_URL}/img/clients/Marico_roundedlogo.png`} className="img-fluid mx-auto" width="160px" /> <br />
                                    <p className="text-center"> <i>- Siddharth Mehta, Marketing Manager, Marico</i></p>
                                    </div>
                                </Col>

                            </Row>
                            
                        </div>
                    </Slider>
                </Col>
            </Row>
        </Container>
    );
}