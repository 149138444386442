import React from 'react';
import { Link,Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container, Button, Col, Row } from 'react-bootstrap'; 
import { StatusCode } from "react-http-status-code";

const NotFound = () => (
  <StatusCode code={404}>
  <div> <Helmet>

    <title>404</title>
    <meta name="description" content="404" />
    <meta name="prerender-status-code" content="404" />    
    <meta name="robots" content="noindex" />
    <link rel="canonical" href="https://www.aandb.xyz/404.html" /> 

  </Helmet>
  
    <Container fluid className="box404">
      <Row>
        <Col className="text-center marmsmt marmlgb">
          <img src={`${process.env.PUBLIC_URL}/img/404coco.png`} className="img-fluid" alt="" />

          <h3 className="text-center marmsmt marmsmb">Sorry, Coco ate this page.<br />
            Check if our homepage has survived.</h3>
          <div className="footer text-center marmlgb">
            <Link to={`${process.env.PUBLIC_URL}/`} className="btn btn-404" > Back to home</Link>
          </div>
          
          <Link style={{display:"none"}} to={`${process.env.PUBLIC_URL}/thank-you.html`}>thankyou
                        </Link> 
        </Col>
      </Row>
    </Container>
  </div>
  </StatusCode>
);

export default NotFound;
