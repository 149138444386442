import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Nav, Container, Accordion, Card, Button, Col, Row, Tab, Carousel } from 'react-bootstrap';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Slider from "react-slick"; 
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";
import Services from './Services';
function FacebookScore() {
     window.scrollTo(0, 0);
     const [focus, setFocus] = React.useState(false);
    return (
        <div>
            <Helmet>
                <title>Best Marketing Automation Agency for *Luxury* Brands | A&B</title>
                <meta name="description" content="Marketing automation increases the conversion rate of luxury & premium brands by 53%" />
                <link rel="canonical" href="https://www.aandb.xyz/marketing-automation-agency-luxury.html" /> 
            </Helmet>
            <div className="firstfoldseo ">
                <div className="box  padmmdb">
                    {/* first fold */}
                    <Container fluid className="padbsmt padbsmb">
                        <Row>
                            <Col lg="1"></Col>
                            <Col lg="5">
                                <div className="circleboxfirst  padsmdt padsmdb">
                                    <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="roundbx"  width="30%" alt="" />
                                    

                                    <div className="boxcentered">
                                        <h4>Improve Facebook <br/> Feedback Score in <br/> <span className='cuspn'>2 weeks</span>, safely.</h4>
                                    </div>

                                </div>
                               
                            </Col>
                            <Col lg="4">
                            <img className='img-fluid' src={`${process.env.PUBLIC_URL}/img/facebookfeedbackcasestudy.png`} alt="" />
                            </Col>
                           
                        </Row>
                        <br/>
                        <br/>
                        <AnchorLink href="#automate" className="btn btn-seo" >Speak to an Expert</AnchorLink>
                    </Container>
                </div>
            </div>
            {/* Seo matters */}
            <div id="automate" className="seomatters  bgb">
                <Container className="padbmdt padbmdb">

                    <h3 className="text-center txtcolor  padsmdb">Impact of a  <span>low Facebook Feedback</span> Score</h3>
                    <Row className="padsmdb">
                        <Col lg="4" md="4" sm="4">
                            <div className="circleboxfirst  padssmb pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`}  className="servicescirbox" width="40%" alt="" />
                                <div className="boxcentered">
                                <h1>200%<br /><br /></h1>
                                </div>
                            </div>
                            <p>Increase in CPM, leading to higher costs of ads.  </p><br />
                        </Col>
                        <Col lg="4" md="4" sm="4">
                            <div className="circleboxfirst  padssmb pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`}  className="servicescirbox" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1>300<br /><br /></h1>
                                </div>
                            </div>

                            <p>More than 300 advertisers are affected globally every month. </p><br />

                        </Col>
                        <Col lg="4" md="4" sm="4">
                            <div className="circleboxfirst  padssmb pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`}  className="servicescirbox" width="40%" alt="" />
                                <div className="boxcentered"> 
                                    <h1>100%<br /><br /></h1>
                                </div>
                            </div>

                            <p>Ban from Facebook Advertising, forever.</p>

                        </Col>
                    </Row>
                    
                </Container>
            </div>
            <div id="expert" className="seomatters  bgbl">
                <Container className="padbmdt padbmdb">

                    <h3 className="text-center txtcolor  padsmdb">Impact of  <span> &gt; 3.5 Facebook Feedback</span> Score</h3>
                    <Row className="padsmdb">
                        <Col lg="4" md="4" sm="4">
                            <div className="circleboxfirst  padssmb pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`}  className="servicescirbox" width="40%" alt="" />
                                <div className="boxcentered">
                                <h1>30%<br /><br /></h1>
                                </div>
                            </div>
                            <p>Cheaper CPMs compared to the industry.  </p><br />
                        </Col>
                        <Col lg="4" md="4" sm="4">
                            <div className="circleboxfirst  padssmb pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`}  className="servicescirbox" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1>0<br /><br /></h1>
                                </div>
                            </div>

                            <p>Risk of Facebook penalty. </p><br />

                        </Col>
                        <Col lg="4" md="4" sm="4">
                            <div className="circleboxfirst  padssmb pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`}  className="servicescirbox" width="40%" alt="" />
                                <div className="boxcentered"> 
                                    <h1>100%<br /><br /></h1>
                                </div>
                            </div>

                            <p>Correct delivery time of feedback forms.</p>

                        </Col>
                    </Row>
                    
                </Container>
            </div>
            {/* our approach seo fold */}
           
            {/* seo services */}
            <div className="seoservices  bgb">
                <Container className="padmmdt  ">

                    <h3 className="text-center txtcolor  padsmdb">How will this work? </h3>
                    <Row className="padsmdb feedback process" xs={12}>
                        <Col lg="2" md="2" sm="2" xs="12" className='offset-lg-1' >
                            <div className="circleboxfirst  padssmb">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`}  className="imgbw" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1>Inquire</h1>
                                </div>
                                <p class="pca">Place an inquiry on the Chatbot.</p>
                            </div>
                           
                        </Col>
                        <Col lg="2" md="2" sm="2" xs="12" >
                            <div className="circleboxfirst  padssmb">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`}  className="imgbw" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1>20 min Call</h1>
                                </div>
                                <p class="pca">Get on a 20 min call with our experts.</p>
                            </div>
                            
                        </Col>
                        <Col lg="2" md="2" sm="2" xs="12" >
                            <div className="circleboxfirst  padssmb">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`}  className="imgbw" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1>Proposal</h1>
                                </div>
                                <p class="pca">Get an estimate for score fixing & maintenance.</p>
                            </div>
                            
                        </Col>
                        <Col lg="2" md="2" sm="2" xs="12" >
                            <div className="circleboxfirst  padssmb">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`}  className="imgbw" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1>Sign up</h1>
                                </div>
                                <p class="pca">Subscribe to the proposal & get started.</p>
                            </div>
                            
                        </Col>
                        <Col lg="2" md="2" sm="2" xs="12" >
                            <div className="circleboxfirst  padssmb">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`}  className="imgbw" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1>Impact</h1>
                                </div>
                                <p class="pca">See the score increase in 2 weeks, safely.</p>
                            </div>
                            
                        </Col>
                      
                    </Row>


                    <Accordion defaultActiveKey="0" className="seoapproach1 ">


                    <Container>
                        <Accordion.Toggle as={Button} variant="read" eventKey="1">
                            Read here
                            {/* <i className="fa fa-chevron-circle-down"></i> */}
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body>
                                 <h3 className='text-center txtcolor'>
                                 WHAT IS FACEBOOK FEEDBACK SCORE?
                                 </h3>
                                <Row>
                                <p>Facebook feedback score is a score provided by Facebook to users who run businesses and ads on its platform. The feedback score is calculated by sending a survey to customers who have shopped through your ads.</p>
                                <p>Facebook calculates this score to understand how businesses are providing purchase experience to its users. It is based on how satisfied a user is with your ad or product.</p>
                                <p>The score ranges from 0 to 5. If your score exceeds 3, it indicates that customers are satisfied with your business or that your ads meet customer expectations. If your score is less than 3, a customer is not happy with the purchase they made from your store.</p>
                                <p>If your business account feedback score drops below 1, you may face a penalty from Facebook that may prevent you from running ads or selling products on its platform.</p>
                                <h3 className='txtcolor'>
                                HOW DOES IT AFFECT YOUR BUSINESS?
                                 </h3>
                                 <p>Your Facebook Feedback Score directly affects your CPM. If a business page receives a poor feedback score, it will be under penalty and the ads will cost more and reach a smaller audience.</p>
                                 <p>If the page repeatedly receives a low feedback score, Facebook might penalize and restrict the page from running ads or selling on its platform. This means the business will not be able to sell through its existing or new shops.</p>
                                 <p>In some scenarios, a business will not be able to run ads if its page score is satisfactory but its business account score is low.</p>
                                 <p>Your business account score depends on the feedback score your business receives. Therefore, to improve your business account score, you should focus on improving the feedback score.</p>
                                 <h3 className='txtcolor'>
                                 WHAT DOES YOUR FACEBOOK FEEDBACK SCORE MEAN?
                                 </h3>
                                 <p>4 to 5: Good customer feedback: The customers are satisfied with their purchase and a majority of them provided the business with positive feedback.</p>
                                 <p>3 to 4: Average customer feedback: Your business meets customer expectations.</p>
                                 <p>2 to 3: Poor customer feedback: Customers provided poor feedback to the business. The page is likely to receive a delivery penalty.</p>
                                 <p>1 to 2: Under penalty: The ads will reach fewer people and cost more.</p>
                                 <p>0 to 1: Advertising disabled: The page can no longer advertise on Facebook. This usually happens due to extremely poor customer feedback.</p>
                                 <p>If you want to reach a large audience and spend less, always try to keep your score between 3 to 5.</p>
                                 <p>If a page is close to receiving an ad delivery restriction, Facebook will contact the page owner through email and notifications. Facebook will warn sellers 30 days before it takes any action on the page. Businesses are able to request a review after they receive a warning.</p>
                                 <p>To request a review,<br/>
                                 1. Go to Account Quality.<br/>
                                 2. Select Meta business accounts.<br/>
                                 3. Review your business account score under Meta business account.<br/>
                                 4. Click on Request review to submit a review.
                                 </p>
                                 <h3 className='txtcolor'>PARAMETERS THAT AFFECT YOUR SCORE</h3>
                                 <p>Parameters that affect your score Screenshot of Feedback Form.</p>
                                 <p>Facebook gives a Feedback Score based on certain factors, which include Product Quality, Delivery, Refund Policy of the brand, after-sale Customer Service etc. Based on these parameters, facebook decides if the customer is satisfied with the brand’s service or not.</p>
                                 <p>Below are the Facebook Feedback Score parameters:</p>
                                 <p>1. Products Details When advertising any product on Facebook, your product images, videos and descriptions should match the actual product. Customers rely on these details while buying a product, so it is necessary that advertisers provide them with the most accurate details.</p>
                                 <p>If your product varies in size for different countries, you need to specify that to your audience, e.g. sizes and weights of the product will be different for USA, UK & India.</p>
                                 <p>If you are a marketplace like Amazon or Flipkart that sells products from different brands, you should have an internal quality test for the products.</p>
                                 <p>2. Order Accuracy Businesses should ensure that they deliver right products to the right customer. Inaccurate product deliveries lead to poor customer satisfaction.</p>
                                 <p>Additionally, there should be no extra charges hidden from customers.</p>
                                 <p>3. Delivery You should clearly communicate your product delivery timelines with your customers and not mislead them about fast or free delivery.</p>
                                 <p>The customers should also be informed about the delivery and shipment charges for each delivery. Providing customers with tracking details can be beneficial too.</p>
                                 <p>4. Refund or Exchange The return, refund and exchange policy should be flexible and feasible for customers.</p>
                                 <p>Businesses should respect and gracefully accept customers' decision to exchange or return the product and ensure that the process hassle-free for customers.</p>
                                 <p>5. Communication Businesses should have clear and helpful communication with customers.</p>
                                 <p>In case queries or doubts from customers, businesses should be prompt with their responses. Resolving customer queries daily would increase customer satisfaction.</p>
                                 <p>If the your business operates in a different country and time zone from your customer, you should provide all details about your availability and communication process.</p>
                                 <h3 className='txtcolor'>HOW CAN YOU CHECK YOUR FACEBOOK FEEDBACK SCORE?</h3>
                                 <p>You can check your Facebook Feedback Score inside your ad account. Log into your Facebook Business Manager account and follow these steps to view your feedback score. To check your score, visit https://www.facebook.com/ads/customer_feedback/ and choose your Facebook Page.</p>
                                 <p>1. Go to the Business Manager.<br/>
                                 2. Go to Account Quality in your Facebook account.<br/>
                                 3. Select your Meta business account.<br/>
                                 4. Under Business account feedback score, you will be able to check your feedback score.
                                 </p>
                                <img src={`${process.env.PUBLIC_URL}/img/score2.png`} alt='' className='img-fluid mx-auto' />
                                 

                                </Row>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Container>
                </Accordion>
                     

                </Container>
            </div>
            

            {/* 3 Reasons to choose */}

            <div className="bgbl casestudy ">

                <Reasons />
            </div>

            {/* our services */}
            <Services /> 
        </div>
    );
}


export default FacebookScore;


// reasons
function Reasons() {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        adaptiveHeight: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false,
                    adaptiveHeight: true,
                }
            },],
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    adaptiveHeight: false,
                }
            },]
    };
    const next = () => {
        Slider.current.slickNext();
    }
    const previous = () => {
        Slider.current.slickPrev();
    }
    return (
        <Container fluid className="reasons padslgt padslgb casestudy ">
            <Row>
                <Col lg="10" className="offset-lg-1">
                    <Slider {...settings}>
                        <div>
                        <Row>
                                <Col className="offset-lg-1 offset-sm-1" lg={{ span: 4, order: 'first' }} md="4" sm={{ span: 3, order: 'first' }} xs={{ span: 12, order: 'first' }}><img src={`${process.env.PUBLIC_URL}/img/reasons4.png`} className="custmt80 padt130 padmmdt mimgw padmsmb marbsmt img-fluid mx-auto d-block" /></Col>
                                <Col className="marmlgt mt0" lg={{ span: 7, order: 'first' }} sm={{ span: 6, order: 'last' }} md="7" xs={{ span: 12, order: 'last' }}  >

                                    <h2 className="marmlgt"> Reasons Why choose <br/> us <br/>
                                    for your Facebook <br/> Feedback <br/>
                                    Score Improvement?</h2> <br />

                                </Col>

                            </Row>
                            

                        </div>
                        <div>
                            <Row>

                                <Col className="offset-lg-1 offset-sm-1" lg={{ span: 5, order: 'first' }} sm={{ span: 6, order: 'first' }}  xs={{ span: 12, order: 'last' }} >

                                <h2 className="padt190"><span>A&B Commitment</span></h2>
                                    <h3 className=" padmsmb"> If the score does not improve in  <strong>4 weeks</strong>, get your<strong><i> money back.</i></strong></h3>

                                </Col>
                                <Col  className="offset-lg-1 mgm90" lg={{ span: 4, order: 'first' }}  sm={{ span: 4, order: 'last' }} xs={{ span: 12, order: 'first' }}>
                                    <img src={`${process.env.PUBLIC_URL}/img/commitment4.png`} className="custmt80  padslg125 mimgw img-fluid mx-auto d-block" />
                                </Col>

                            </Row>
                        </div>
                        <div>
                            <Row>

                            <Col className="offset-lg-1 offset-sm-1" lg={{ span: 5, order: 'first' }} sm={{ span: 5, order: 'first' }} xs={{ span: 12, order: 'last' }} >

                                    <h3 className="marblgt padmsmb padslg125 ">Our Facebook Ads experts manage a <br/> budget of a few million dollars each year. </h3>

                                </Col>
                                <Col className=" mgm90" lg={{ span: 5, order: 'first' }}  sm={{ span: 5, order: 'last' }} xs={{ span: 12, order: 'first' }}>
                                <br/>
                                <br/>
                                    <img src={`${process.env.PUBLIC_URL}/img/facebook-marketing-partner-vector-logo.jpg`} className="padslg125 padmsmb img-fluid mx-auto d-block" />
                                </Col>
                            </Row>
                        </div>
                       
                        <div>
                        <Row> 
                                <Col className="offset-lg-1 offset-md-0" lg={{ span: 10, order: 'first' }}  md={{ span: 12, order: 'first' }}  sm={{ span: 8, order: 'last' }} xs={{ span: 12, order: 'last' }}>
                                <h3 className="text-center htitle"><span>Don't believe us?</span></h3>
                                    <p className="text-center custml ">  Hear it from our partners.</p>
                                    <div className="feedbackbox marmsmt padmsmt padmsmb">
                                     <img src={`${process.env.PUBLIC_URL}/img/leftcoma.png`}  className="mgt40 img-fluid mx-auto d-block"/>
                                     <br />
                                        {/* <img src={`${process.env.PUBLIC_URL}/img/paytminsiderlogo.png`} className="img-fluid mx-auto d-block padmsmt padmsmb" /> */}
                                        <h4 className="">“We panicked when we realised our Facebook page was restricted from advertising suddenly without any warning. Thankfully, A&B came in and fixed it up so quickly. Our CPMs have improved drastically since then, and we are ROAS positive again, whew!”</h4>  
                                        {/* <img src={`${process.env.PUBLIC_URL}/img/1seagallery.png`} className="rounded-circle img-fluid mx-auto d-block" /> */}
                                        {/* <img src={`${process.env.PUBLIC_URL}/img/rightcoma.png`}  className="flr" /> */}
                                    </div>
                                    <div className="clientname">
                                    <p className="text-center"> <i>- Head of Marketing, a global furniture brand.</i></p>
                                    </div>
                                </Col>

                            </Row>

                             
                        </div>

                        <div>
                            <Row>

                                <Col className="offset-lg-1 offset-sm-1" lg={{ span: 4, order: 'first' }} sm={{ span: 6, order: 'first' }}  xs={{ span: 12, order: 'last' }} >

                                <h2 className="padt190"><span>Numerous case studies like this</span></h2>
                                    <h3 className=" padmsmb"> From a penalised feedback to a score of <strong><i>4.2 under 3 weeks.</i></strong></h3>

                                </Col>
                                <Col  className="offset-lg-1 mgm90" lg={{ span: 5, order: 'first' }}  sm={{ span: 4, order: 'last' }} xs={{ span: 12, order: 'first' }}>
                                    <img src={`${process.env.PUBLIC_URL}/img/facebookfeedbackcasestudy.png`} className="custmt80 padslg125 img-fluid mx-auto d-block" />
                                </Col>

                            </Row>
                        </div>
                    </Slider>
                </Col>
            </Row>
        </Container>
    );
}