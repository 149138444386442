import React from 'react';
import ReactDOM from 'react-dom';
import Menu from './Navbar.js';
import Home from './component/Home.js';
import Seo from './component/Seo.js';
import Conversionoptimization from './component/Conversion-Rate-Optimization.js';
import Digitalleadgeneration from './component/Digitalleadgeneration.js'
import contentmarketing from './component/Content-Marketing.js';
import Retailstoregrowth from './component/Retailstoregrowth.js';
import Ecommerceservices from './component/Ecommerceservices.js';
import digitalmediaplanning from './component/Digitalmediaplanning.js';
import InfluencerMarketing from './component/InfluencerMarketing.js';
import MarketingAutomation from './component/Marketingautomation.js';
import cameraeffectslens from './component/Camera-effect-ar-lenses.js';
import orm from './component/orm.js'; 
import digitalbrandbuilding from './component/digitalbrandbuilding.js';
import NotFound from './component/NotFound.js';
import Work from './component/Work.js';
import social from './component/Social.js';
import evok from './casestudy/evok.js';
import Atmantan from './casestudy/atmantan.js';
import Insider from './casestudy/insider.js';
import Isaia from './casestudy/isaia.js';
import Saundh from './casestudy/saundh.js';
import Parfait from './casestudy/parfait.js';
import Casaire from './casestudy/casaire.js';
import Tirangadabba from './casestudy/Tirangadabba.js';
import blabel from './casestudy/blabel.js';
import evokaor from './casestudy/evok-aor.js';
import NovaKid from './casestudy/novakid.js';
import Limelight from './casestudy/Limelight.js';
import thankyou from './component/thanks.js'
import Footer from './Footer.js'; 
import Career from './component/Career.js'; 
import { withRouter, Route, Switch, Link, Redirect } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css'; 
import "video-react/dist/video-react.css"; 
import privacy from './component/privacy.js';
import FacebookScore from './component/FacebookScore.js';
import Dubai from './component/Dubai.js';
import London from './component/London.js';
import ApriliaRs457 from './casestudy/aprilia-rs457.js';


const App = (props) => { 
  return (
    <div className="App">
      <Menu />
      <Switch>
        <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home} />
        <Route exact path={`${process.env.PUBLIC_URL}/luxury-seo-agency.html`} component={Seo} />
        <Route exact path={`${process.env.PUBLIC_URL}/conversion-rate-optimization-agency-luxury.html`} component={Conversionoptimization} />
        <Route exact path={`${process.env.PUBLIC_URL}/content-marketing-agency-luxury.html`} component={contentmarketing} />
        <Route exact path={`${process.env.PUBLIC_URL}/digital-lead-generation-agency-luxury.html`} component={Digitalleadgeneration} />
        <Route exact path={`${process.env.PUBLIC_URL}/retail-digital-marketing-agency-luxury.html`} component={Retailstoregrowth} />
        <Route exact path={`${process.env.PUBLIC_URL}/luxury-e-commerce-agency.html`} component={Ecommerceservices} />
        <Route exact path={`${process.env.PUBLIC_URL}/media-buying-agency-luxury.html`} component={digitalmediaplanning} />
        <Route exact path={`${process.env.PUBLIC_URL}/luxury-influencer-marketing-agency.html`} component={InfluencerMarketing} />
        <Route exact path={`${process.env.PUBLIC_URL}/marketing-automation-agency-luxury.html`} component={MarketingAutomation} />
        <Route exact path={`${process.env.PUBLIC_URL}/facebook-feedback-score.html`} component={FacebookScore} />
        <Route exact path={`${process.env.PUBLIC_URL}/camera-effects-AR-lens-agency-luxury.html`} component={cameraeffectslens} /> 
        <Route exact path={`${process.env.PUBLIC_URL}/online-reputation-management-agency-luxury.html`} component={orm} />
        <Route exact path={`${process.env.PUBLIC_URL}/luxury-digital-branding-agency.html`} component={digitalbrandbuilding} />
        <Route exact path={`${process.env.PUBLIC_URL}/luxury-social-media-marketing-agency.html`} component={social} />
        <Route exact path={`${process.env.PUBLIC_URL}/work.html`} component={Work} />
        <Route exact path={`${process.env.PUBLIC_URL}/career.html`} component={Career} />
        <Route exact path={`${process.env.PUBLIC_URL}/thank-you.html`} component={thankyou} />
        <Route exact path={`${process.env.PUBLIC_URL}/privacy-policy.html`} component={privacy} />
        <Route exact path={`${process.env.PUBLIC_URL}/case-studies/scaling-evok-ecommerce-revenue.html`} component={evok} />
        <Route exact path={`${process.env.PUBLIC_URL}/case-studies/online-lead-generation-case-study.html`} component={Atmantan} />
        <Route exact path={`${process.env.PUBLIC_URL}/case-studies/seo-case-study-for-event-ticketing-company.html`} component={Insider} />
        <Route exact path={`${process.env.PUBLIC_URL}/case-studies/isaia-retail-store-digital-transformation.html`} component={Isaia} />
        <Route exact path={`${process.env.PUBLIC_URL}/case-studies/ecommerce-conversion-rate-case-study.html`} component={Saundh} />
        <Route exact path={`${process.env.PUBLIC_URL}/case-studies/parfait-snapchat-instagram-case-study.html`} component={Parfait} />
        <Route exact path={`${process.env.PUBLIC_URL}/case-studies/dubai-luxury-furniture-store-walkin-case-study.html`} component={Casaire} />
        <Route exact path={`${process.env.PUBLIC_URL}/case-studies/independence-day-social-media-campaign-tiranga-dabba.html`} component={Tirangadabba} />
        <Route exact path={`${process.env.PUBLIC_URL}/case-studies/content-marketing-case-study-increase-in-pageviews.html`} component={blabel} />
        <Route exact path={`${process.env.PUBLIC_URL}/case-studies/social-media-case-study-evok-furnitures.html`} component={evokaor} />
        <Route exact path={`${process.env.PUBLIC_URL}/case-studies/digital-advertising-sales-growth-case-study.html`} component={NovaKid} />
        <Route exact path={`${process.env.PUBLIC_URL}/case-studies/ecommerce-advertising-case-study.html`} component={Limelight} /> 
        <Route exact path={`${process.env.PUBLIC_URL}/case-studies/aprilia-rs457-launch-case-study.html`} component={ApriliaRs457} /> 
        <Route exact path={`${process.env.PUBLIC_URL}/luxury-digital-agency-dubai.html`} component={Dubai} /> 
        <Route exact path={`${process.env.PUBLIC_URL}/luxury-digital-agency-london.html`} component={London} /> 
 
        <Route path={`${process.env.PUBLIC_URL}/404.html`} component={NotFound} status={404} />
 
        <Redirect from='*' to={`${process.env.PUBLIC_URL}/404.html`} status={404} />
        
        
        <Footer />
      </Switch>
        
      {/* for side hide us this method => props.location.pathname!=='/casestudy/evok' ? <Footer/>:null,  */}
      {props.location.pathname !== '/thank-you.html' &&
        props.location.pathname !== '/404.html' && 
        <Footer />}

    </div>
  );

};
export default withRouter(App);

