import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container, Button, Col, Row } from 'react-bootstrap';
import Slider from "react-slick";
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";
import { LazyLoadImage } from 'react-lazy-load-image-component';

function Blabel() {
    window.scrollTo(0, 0);
    const [focus, setFocus] = React.useState(false);
    return (
        <div>
            <Helmet>

                <title>Content Marketing Case Study - 1043% Increase in Unique Pageviews</title>
                <meta name="description" content="" />
                <link rel="canonical" href="https://www.aandb.xyz/case-studies/content-marketing-case-study-increase-in-pageviews.html" />
            </Helmet>
            {/* first fold */}
            <div className="casestudy">
                <div className="box padmmdt padmmdb">

                    <Container>

                        <p className="text-left p1 pdl10"> <span class="spcase">B L</span>ABEL</p>
                    </Container>

                    <Container fluid className="padbsmt padbsmb">
                        <Row>
                            <Col lg="2"></Col>
                            <Col lg="8">
                                <div className="circleboxfirst  padssmb">
                                     <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="roundbx"  width="30%" alt="" />

                                    <div className="boxcentered tpfold">
                                        <h4 className="d-none d-sm-block "> 
                                            <span className="cuspn"> 1043%</span> increase <br />in unique blogs<br /> pageviews

                                        </h4>
                                        <h4 className="d-block d-sm-none">  <br />
                                            <span className="cuspn"> 1043%</span> increase in unique blogs pageviews
                                        </h4>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="2"></Col>
                        </Row>
                    </Container>
                </div>
            </div>
            {/* The problem  and build fold */}
            <div className="lgbox  problem">

                <Container fluid className="tpfold pdr0">

                    <Row>

                        <Col lg="4" md="4" sm="6" xs="12" className="padmmdt mbpt0 casebox offset-lg-1 ">

                            <p className="text-left p1 mrg30 " > <span class="spcase">THE</span> <strong>Opportunity</strong> </p>
                            <p className="p2 marmsmt  mbt20 ">
                                <span>B Label</span> is one of <span>India’s first organic fashion brands</span> that specialize in hemp clothing & accessories.
                            </p>
                            <p className="p2  ">
                                When B Label was launched, our aim was to <span>bring relevant organic traffic</span> to generate more sales or conversions. <br />
                                Since it was a new domain, the trust factor and authority was 0 for the search engines.
                            </p>
                            <p className="p2">We wanted to target every <span>user who is searching for sustainable fashion or hemp clothing</span> & give them the most helpful information.</p>
                            <p className="p2">The searches for hemp clothing were far lesser than generic keywords like <span>‘organic clothing’</span>, but hemp searches could convert into conversions for us.</p>
                        </Col>
                        <Col  lg={{ span: 1, order: 'last' }} md="1" sm={{ span: 1, order: 'last' }} xs={{ span: 12, order: 'first' }} className="mdpdt80 d-none d-sm-block">
                           
                        </Col>
                        <Col  lg={{ span: 6, order: 'last' }} md="6" sm={{ span: 6, order: 'last' }} xs={{ span: 12, order: 'first' }} className="pdr0 mbppr0   mdpdt80 d-none d-sm-block">
                             <LazyLoadImage effect="blur"  src={`${process.env.PUBLIC_URL}/img/casestudy/blabel/1.png`} className="mbppr0 img-fluid text-right" width="100%" height="100%" />
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* Insights */}
            <Container fluid className=" box padbsmt padbsmb">
                <div className="seomatters tpfold">
                    <Row>
                        <Col lg="11" className="casebox offset-lg-1">
                            <p className="text-left p1 marmsmb "> <span class="spcase">THE</span> <strong>INSIGHT</strong> </p>
                        </Col>
                    </Row>
                    <Container>
                        <Row className=" mbmt0 marketing ">
                            <Col lg="6" md="6" sm="10" xs="12">
                                <p className="p2 text-left"> Our relevant keywords had fewer search volumes - another challenge to address. But we knew that the natural clothing trend is increasing in India & in the global market.  </p>

                                <p className="p2 text-left"><span>Consumers are more concerned about their choice of products</span> - if they are ethical or sustainable, how their spending affects climate change & global warming. And that concern is growing.

                                </p>
                            </Col>
                            <Col lg="6" md="6" sm="6" xs="6" >

                                 <LazyLoadImage effect="blur"  src={`${process.env.PUBLIC_URL}/img/casestudy/blabel/2.png`} className="img-fluid" alt="" />
                            </Col>
                        </Row>
                        <Row className="marsmdt  mbmt0 marketing">
                            <Col lg="10" md="10" sm="10" xs="12">
                                <p className="p2 text-left"><span>Environment</span>-conscious consumers are against leather products that utilize the animal’s skin fabric. These users were our primary target & we did research on the keywords opportunities accordingly.</p>
                                <p className="p2 text-left"> Though our products were only hemp-made, we did not refrain from targeting the generic or broader keywords like organic clothing, vegan clothing, natural clothing, etc.
                                </p>

                            </Col>
                        </Row>

                    </Container>

                </div>

            </Container>
            {/* The Build */}
            <div className="lgbox build">
                <Container fluid className="lgbox mbpdt30">
                    <Row className="padssmt   ">

                        <Col lg="10" sm="6" xs="12" className=" pdtb20 casebox offset-lg-1">
                            <p className="text-left p1 marssmb  mbb50"> <span class="spcase">THE</span> <strong>BUILD</strong> </p>

                        </Col>
                    </Row>
                    <Row className="  mbpt0 ">

                        <Col lg="10" sm="10" xs="12" className=" pdtb20 casebox offset-lg-1">
                            <p className="p2 ">We researched keywords from <strong>People Also Ask</strong> Box, <strong>Quora, Reddit,</strong> Answer the Public & other<br /> keyword research tools like <strong>Google Keyword Planner</strong> and <strong>Google Trends.</strong></p>

                            <p className="p2 marssmt marssmb"><i>Our approach was :-</i></p>

                            <h3>Identify Keyword Themes</h3>
                        </Col>
                    </Row>
                    <Row className="padssmt mbpt0 text-left blabel">

                        <Col lg={{ span: 5, order: 'last' }} md="5" sm={{ span: 6, order: 'last' }} xs="12" className="offset-lg-2 offset-md-1  offset-sm-1">
                         
                            <ul className="p1 tcgold">
                                <li>Hemp</li>
                            </ul>
                            <ul className=" p2">
                                <li> Queries & Information <strong>Seeking → Topics → Keywords</strong> </li>
                                <li>
                                    <strong> Hemp + Environment -</strong>
                                </li>
                                <li className="padsxst padsxsb lsn">  Example - <strong>5 ways Hemp is 100 times better than<br /> cotton for the environment</strong>
                                </li>
                                <li>  <strong>Hemp + Comfort</strong>
                                </li>
                                <li>  <strong>Hemp + Style</strong>
                                </li>
                                <li>  <strong>Hemp + Future</strong>
                                </li>
                            </ul>
                        </Col>
                        <Col lg={{ span: 5, order: 'last' }} md="5" xs="12" sm={{ span: 6, order: 'last' }} className="">
                        <ul className="p1 tcgold">
                                <li>Sustainable</li>
                            </ul>
                           
                            <ul className=" p2">
                                <li><strong>Sustainable + Benefits</strong></li>
                                <li><strong>Sustainable + Celebrities</strong></li>
                                <li><strong>Sustainable + Farmers</strong></li>
                                <li><strong>Sustainable + Vegan</strong></li>
                                <li  className="padsxst padsxsb lsn">Example -
                                    <strong> How Sustainable Fashion<br /> Can Help Us Move Beyond Fast Fashion</strong>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                    <Row className="padssmt padssmb  mbpt0 text-left blabel">
                        <Col lg={{ span: 5, order: 'last' }} md="5" xs="12" sm={{ span: 6, order: 'last' }} className="offset-lg-2 offset-md-1 offset-sm-1 offset-xs-0">
                        <ul className="p1 tcgold">
                                <li>Fast Fashion</li>
                            </ul>
                          
                            <ul className=" p2">
                                <strong><li>Fast Fashion + Environment</li>
                                    <li>Fast Fashion + Animals</li>
                                    <li>Fast Fashion + farmers</li>
                                    <li>Fast Fashion + Child Labour</li></strong>

                                <li  className="padsxst padsxsb lsn">Example - <strong>8 Environmental Impacts<br /> Of Fast Fashion</strong></li>
                            </ul>
                        </Col>
                    </Row>

                </Container>
            </div>
            {/* Content on the themes*/}
            <Container fluid className="build box">

                <Row>

                    <Col lg="10" md="10" sm="10" xs="12" className="offset-lg-1  text-left blabel padbsmt padbsmb">

                        <h3 className="tcgold marbxsb mgl0">Create Content on the Themes</h3>
                        <p className="p2 text-left">
                            Focused on publishing well-researched blogs that address the following points to create awareness -
                        </p>
                        <ul className="cuulb">
                            <li>demystifying the benefits & facts of organic hemp fabric </li>
                            <li>Shed light on the drawbacks of fast fashion, hence helping users to adopt organic clothing</li>
                            <li>The environmental importance of fair-trade clothing</li>
                            <li>Environmental benefits of vegan & ethical fashion</li>
                            <li>History & origin of hemp fabric</li>
                            <li>Sustainability & the boom of hemp fabric</li>
                            <li>Telling why global celebrities are endorsing organic hemp clothing</li>
                        </ul>
                    </Col>

                </Row>
                <Row>

                    <Col lg="7" sm="7" xs="12" className="offset-lg-1  text-left blabel">

                        <h3 className="d-none d-sm-block tcgold text-left   padbxsb">
                            Focused on Following On-Page Optimization<br /> & User Experience Improvement -
                        </h3>
                        <h3 className="d-block d-sm-none tcgold text-left mgl0  padbxsb">
                            Focused on Following On-Page Optimization & User Experience Improvement -
                        </h3>
                        <h4 className="padbxsb cultitle">Sustainable Clothing And The Indian Market</h4>
                         <LazyLoadImage effect="blur"  src={`${process.env.PUBLIC_URL}/img/casestudy/blabel/3.png`} className="d-block d-sm-none mx-auto img-fluid text-right" />

                        <ul className="cuulb padbxsb marbb0">
                            <li>Added high-quality & eye-catching lifestyle images with relevant alt text </li>
                            <li>Improved internal linking of the blogs to pass the link equity</li>
                            <li>Brokedown the long paragraphs into bullet points & lists for good readability</li>
                            <li>Added keywords optimized headings or H1 & H2 tags</li>

                        </ul>
                        <div className="mx-auto">
                             <LazyLoadImage effect="blur"  src={`${process.env.PUBLIC_URL}/img/casestudy/blabel/4.png`} className="wd offset-lg-1" />
                        </div>  
                        <ul className="padbxst padbxsb marbb0">
                            <li>Added an engaging CTA to take our blog readers to the product pages</li>
                        </ul>
                         <LazyLoadImage effect="blur"  src={`${process.env.PUBLIC_URL}/img/casestudy/blabel/5.png`} className="wd offset-lg-1" />
                    </Col>

                    <Col lg="4" sm="4" >
                         <LazyLoadImage effect="blur"  src={`${process.env.PUBLIC_URL}/img/casestudy/blabel/3.png`} className="d-none d-sm-block mx-auto img-fluid text-right" />

                    </Col>

                </Row>
                <br />
            </Container>
            <Container fluid className="lgbox build box">
                <Row>

                    <Col lg="10" md="10" sm="10" xs="12" className="offset-lg-1  text-left blabel">

                        <p className="text-left p1 marmsmb padbxst "> <span className="spcase">THE</span> <strong>IMPACT</strong></p>

                        <h3 className="tcgold mgl0 offset-lg-1"><strong>Traffic Improvement</strong></h3>
                        <br />
                         <LazyLoadImage effect="blur"  src={`${process.env.PUBLIC_URL}/img/casestudy/blabel/6.png`} className="wd offset-lg-1" />

                        <div className="mx-auto">
                             <LazyLoadImage effect="blur"  src={`${process.env.PUBLIC_URL}/img/casestudy/blabel/7.png`} className="wd offset-lg-1" />
                           
                            <p className="p2 offset-lg-1 padbxst padbxsb marbb0">
                                <strong>1043% increase</strong> in unique blogs pageviews through organic traffic
                            </p>
                        </div>
                    </Col>


                </Row>
                <Row className="marssmt marssmb">

                    <Col lg="4" sm="4" xs="12" className="text-left blabel">
                    </Col>
                    <Col lg="6" sm="8" xs="12" className="offset-lg-1  text-left blabel">
                    <h3 className="tcgold mgl0 marmxsb"><strong>Quality Metrics Improvement</strong></h3>
                       

                         <LazyLoadImage effect="blur"  src={`${process.env.PUBLIC_URL}/img/casestudy/blabel/8.png`} className="img-fluid  " />
                        <br />
                        <p className="p2 padbxst padbxsb marbb0"><strong>60% increase</strong> in average time spent on the page. It increased from 1:55 minutes to 3:55 minutes</p>

                    </Col>


                </Row>
                <Row className="marssmt marssmb">
                    <Col lg="6" sm="8" xs="12" className="offset-lg-1  text-left blabel">
                    <h3 className="tcgold mgl0 marmxsb"><strong>Our Blogs Started to Appear<br /> in Google Discover</strong></h3>
                        
                         <LazyLoadImage effect="blur"  src={`${process.env.PUBLIC_URL}/img/casestudy/blabel/9.png`} className="img-fluid  " />
                        <br />
                        <p className="p2 padbxst padbxsb marbb0">The blogs generated clicks in Google Discover too. </p>

                    </Col>
                    <Col lg="4" sm="4" xs="12" className="text-left blabel">
                    </Col>
                </Row>
                <Row className="marssmt marssmb">

                    <Col lg="4" sm="4" xs="12" className="text-left blabel">
                    </Col>
                    <Col lg="6" sm="8" xs="12" className="offset-lg-1  text-left blabel">
                        
                        <h3 className="tcgold mgl0 marmxsb"><strong>Got Traffic From Image Searches as Well</strong></h3> 

                         <LazyLoadImage effect="blur"  src={`${process.env.PUBLIC_URL}/img/casestudy/blabel/10.png`} className="img-fluid  " />
                        <br />
                         <LazyLoadImage effect="blur"  src={`${process.env.PUBLIC_URL}/img/casestudy/blabel/11.png`} className="img-fluid  " />
                        <br />
                        <p className="p2 padbxst padbxsb marbb0">Adding relevant alt text helped in the blog images ranking in Google Image search results, generating clicks to the website. </p>

                    </Col>


                </Row>
                <Row className="marssmt marssmb">
                    <Col lg="6" sm="8" xs="12" className="offset-lg-1  text-left blabel">
                        
                    <h3 className="tcgold mgl0 marmxsb"><strong>Country-wise Performance </strong></h3> 

                         <LazyLoadImage effect="blur"  src={`${process.env.PUBLIC_URL}/img/casestudy/blabel/12.png`} className="img-fluid  " />
                        <br />
                        <p className="p2 padbxst padbxsb marbb0">The blog rankings were not limited to India but we also generated international high intent traffic from the US, UAE, UK etc.</p>

                    </Col>
                    <Col lg="4" sm="4" xs="12" className="text-left blabel">
                    </Col>
                </Row>
                <Row >

                    <Col lg="4" sm="4" xs="12" className="text-left blabel">
                    </Col>
                    <Col lg="6" sm="8" xs="12" className="offset-lg-1  text-left blabel">
                    <h3 className="tcgold mgl0 marmxsb"><strong>Started Appearing in Featured Snippets </strong></h3> 
 
                        <p className="p2 padbxsb marbb0">The on-page optimization <strong>helped the blogs to rank</strong> with a featured snippet </p>

                         <LazyLoadImage effect="blur"  src={`${process.env.PUBLIC_URL}/img/casestudy/blabel/13.png`} className="img-fluid  " />
                        <br />
                         <LazyLoadImage effect="blur"  src={`${process.env.PUBLIC_URL}/img/casestudy/blabel/14.png`} className="img-fluid  " />
                        <br />

                    </Col>


                </Row>
                <br />
            </Container>
            <div className="lgbox build endbox">
                <Container fluid className="pdlr0">
                    <Row className="text-left  mglr0">
                        <Col lg="6" sm="6" xs="12">
                            <Link to={`${process.env.PUBLIC_URL}/case-studies/online-lead-generation-case-study.html`}>
                                <Row className="marssmt marssmb mglr0">

                                    <Col lg="3" sm="3" xs="3" >
                                         <LazyLoadImage effect="blur"  src={`${process.env.PUBLIC_URL}/img/casestudy/footerlogo/atmantan.png`} className="mx-auto img-fluid text-right" />
                                    </Col>
                                    <Col lg="5" sm="6" xs="7">
                                        
                                        <h4 className="tcgold">Atmantan</h4>
                                        <p>438% jump in qualified inquiries in 12 months</p>
                                    </Col>
                                    <Col lg="2" sm="3" xs="2" className="marbxst">
                                         <img src={`${process.env.PUBLIC_URL}/img/righticon.png`} width="40%" className=" text-right" />
                                    </Col>
                                </Row>
                            </Link>
                        </Col>

                        <Col lg="6" sm="6" xs="12" className="bgbl " >
                            <Link to={`${process.env.PUBLIC_URL}/case-studies/scaling-evok-ecommerce-revenue.html`}>
                                <Row className="marssmt marssmb mglr0">
                                    <Col lg="3" sm="3" xs="3" >
                                         <LazyLoadImage effect="blur"  src={`${process.env.PUBLIC_URL}/img/casestudy/footerlogo/evok.png`} className="mx-auto img-fluid text-right" />
                                    </Col>
                                    <Col lg="5" sm="6" xs="7">
                                       
                                        <h4 className="tcgold">EVOK </h4>
                                        <p>Scaled Ecommerce Revenue by 2404% </p>
                                    </Col>
                                    <Col lg="2" sm="3" xs="2" className="marbxst">
                                         <img src={`${process.env.PUBLIC_URL}/img/righticon.png`} width="40%" className=" text-right" />
                                    </Col>
                                </Row>
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>

    )
}
export default Blabel;