import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container, Button, Col, Row } from 'react-bootstrap';
import Slider from "react-slick";
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";
import { LazyLoadImage } from 'react-lazy-load-image-component';

function Casaire() {
    window.scrollTo(0, 0);
    return (
        <div>
            <Helmet>

                <title>Luxury Furniture Brand Dubai - Walkins up by 400%</title>
                <meta name="description" content="" />
                <link rel="canonical" href="https://www.aandb.xyz/case-studies/dubai-luxury-furniture-store-walkin-case-study.html" />

            </Helmet>
            {/* first fold */}
            <div className="casestudy">
                <div className="box padmmdt padmmdb">

                    <Container>

                        <p className="text-left p1 pdl10"> <span class="spcase">CA</span>SAIRE</p>
                    </Container>

                    <Container fluid className="padbsmt padbsmb">
                        <Row>
                            <Col lg="2"></Col>
                            <Col lg="8">
                                <div className="circleboxfirst  padssmb">
                                    <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="roundbx" width="30%" alt="" />

                                    <div className="boxcentered tpfold">
                                        <h4 className="d-none d-sm-block "> Million AED deals every week, <br />  success story of an<br />   <span class="cuspn"> Italian luxury furniture brand in Dubai.</span> <br />
                                        </h4>
                                        <h4 className="d-block d-sm-none">  <br /> Million AED deals every week, <br />  success story of an <span>Italian luxury furniture brand in Dubai.</span>

                                        </h4>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="2"></Col>
                        </Row>
                    </Container>
                </div>
            </div>
            {/* The brand fold */}
            <div className="lgbox  problem">

                <Container fluid className="tpfold pdr0 mbpdr15">
                    <Row>

                        <Col lg="4" md="4" sm="6" xs="12" className="padmmdt mbpt0 casebox offset-lg-1 ">

                            <p className="text-left p1 mrg30 " > <span class="spcase">THE</span> <strong>BRAND</strong>  </p>
                            <p className="p2   marbmdt  mbt20 ">


                                <span>Casaire</span>  is a <span> modern luxury Italian furniture brand</span> set amidst art galleries, elite restaurants, and supercar showrooms in Dubai’s sought-after Al Quoz area.

                            </p>
                            <p className="p2  ">
                                Its <span>lavish 10,000+ sq ft of experiential space</span> displays the finest Italian furniture craftsmanship and design, perfected over generations.

                            </p>
                        </Col>

                        <Col lg={{ span: 6, order: 'last' }} sm={{ span: 6, order: 'last' }} xs={{ span: 12, order: 'first' }} className="offset-lg-1 pdr0 mbpdr15">
                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/casaire/casaire1.webp`} width="100%" height="100%" className="img-fluid text-right" />
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* The opportunity */}
            <div className="box  problem padbxsb ">

                <Container fluid className="tpfold">
                    <Row>

                        <Col lg="8" md="10" sm="10" xs="12" className="padmmdt mbpt0 casebox offset-lg-1 ">

                            <p className="text-left p1 mrg30 " style={{ marginBottom: '50px' }}> <span class="spcase">THE</span> <strong>PROBLEM</strong> </p>
                            <p className="">1. Low brand awareness: Casaire struggled to attract potential high-value customers. There were leads, but they weren’t luxury buyers.</p>
                            <p className="">2. Limited digital presence: Casaire needed a more robust digital presence to boostonline discoverability and engagement.</p>
                            <p className="">3. Scarce foot traffic: The store experienced minimal qualified walk-ins due to low awareness amongst the luxury shoppers of Dubai.</p>
                            <p className="">4. Difficulty reaching target audience: Casaire needed to establish connections with architects and interior designers, crucial influencers in their market.</p>

                        </Col>
                    </Row>
                    <Row>

                        <Col lg="8" md="10" sm="10" xs="12" className="padmmdt mbpt0 casebox offset-lg-1 ">
                            <p className="text-left p1 mrg30 " > <span class="spcase">THE</span> <strong>OPPORTUNITY</strong> </p>
                            <p className="p2   marbsmt  mbt20 ">
                                Digital Discovery: There is a high-intent audience looking for Italian furniture in UAE, but the problem is that people with limited budgets also look for luxury furniture, leading to non-qualified inquiries.
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="10" md="10" sm="10" xs="12" className="mbpt0 casebox offset-lg-1 ">
                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/casaire/opportunity.webp`} className="img-fluid text-right" />
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* Process */}
            <div className="lgbox  problem padbxsb">

                <Container fluid className="tpfold ">
                    <Row>

                        <Col lg="9" md="10" sm="10" xs="12" className="padmmdt mbpt0 casebox offset-lg-1 ">

                            <p className="text-left p1 mrg30 " > <span class="spcase">THE</span><strong> PROCESS</strong> </p>

                            <h2 className="tcgold marbxst marbxsb padssmt">OSIDE:</h2>
                            <p className="p2  mbt20 ">
                                Through our accurate database and precise audience analysis and targeting, we started getting leads from luxury buyers who were not just interested in buying a single piece of furniture but wanted to do an entire project revamp project with Casaire. This was done by mapping the locations where these buyers live and narrowing them down with our proprietary media tool’s targeting.
                            </p>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="4" md="6" sm="6" xs="12" className="mbpt0 casebox offset-lg-1 ">

                            <h2 className="tcgold marbxst marbxsb">Architects and Interior Designers:</h2>
                            <p className="p2  mbt20 ">
                                Using OSIDE’s targeting capabilities, we targeted top architects in Dubai and MENA. We introduced the brand to architects and interior designers who place repeat orders, driving a sustainable business scaling. Over 50% of all leads came from architects.
                            </p>

                           
                        </Col>

                        <Col lg={{ span: 5, order: 'last' }} sm={{ span: 6, order: 'last' }} xs={{ span: 12, order: 'first' }} className="offset-lg-1">
                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/casaire/process.webp`} className="img-fluid text-right" />
                        </Col>
                    </Row>

                    <Row>

                    <Col lg="9" md="10" sm="10" xs="12" className="padmmdt mbpt0 casebox offset-lg-1 ">

                    <h2 className="tcgold marbxst marbxsb">Smoothening sales process:</h2>
                    <p className="p2  mbt20 ">
                        We implemented a WhatsApp business tool for the sales team to get qualified leads directly on WhatsApp, so the conversation is seamless and professional without having to struggle to get the client's phone number in the first place.
                    </p>
                    </Col>
                </Row>
                </Container>
            </div>
            {/* the impact */}
            <div className="lgbox  problem padbxsb">

                <Container fluid className="tpfold ">
                    <Row>

                        <Col lg="9" md="10" sm="10" xs="12" className="padmmdt mbpt0 casebox offset-lg-1 ">

                            <p className="text-left p1 mrg30 " > <span class="spcase">THE</span> <strong>IMPACT</strong> </p>

                            <p className="p2  mbt20 ">
                            Within the first week of starting the digital activities, the brand closed the first deal of 1 Million AED and has been scaling ever since. 

                            </p>
                        </Col>
                    </Row>
                    <Row>

                        <Col lg={{ span: 10, order: 'last' }} sm={{ span: 10, order: 'last' }} xs={{ span: 12, order: 'first' }} className="offset-lg-1">
                            

                            <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/casaire/impact.webp`} className="img-fluid text-right" />
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="lgbox build endbox">
                <Container fluid className="pdlr0">
                    <Row className="text-left  mglr0">

                        <Col lg="6" sm="6" xs="12">
                            <Link to={`${process.env.PUBLIC_URL}/case-studies/isaia-retail-store-digital-transformation.html`}>
                                <Row className="marssmt marssmb mglr0">

                                    <Col lg="3" sm="3" xs="3" >
                                        <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/footerlogo/isaia.png`} className="mx-auto img-fluid text-right" />
                                    </Col>
                                    <Col lg="5" sm="6" xs="7">

                                        <h4 className="tcgold">ISAIA </h4>
                                        <p>Store Footfalls increased by 100% in 4 months </p>
                                    </Col>
                                    <Col lg="2" sm="3" xs="2" className="marbxst">
                                        <img src={`${process.env.PUBLIC_URL}/img/righticon.png`} width="40%" className=" text-right" />
                                    </Col>
                                </Row>
                            </Link>
                        </Col>

                        <Col lg="6" sm="6" xs="12" className="bgbl " >
                            <Link to={`${process.env.PUBLIC_URL}/case-studies/ecommerce-conversion-rate-case-study.html`}>
                                <Row className="marssmt marssmb mglr0">
                                    <Col lg="3" sm="3" xs="3" >
                                        <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/footerlogo/saundh.png`} className="mx-auto img-fluid text-right" />
                                    </Col>
                                    <Col lg="5" sm="6" xs="7">

                                        <h4 className="tcgold">Saundh </h4>
                                        <p>5X Improvement in E-Commerce Conversion Rate.</p>
                                    </Col>
                                    <Col lg="2" sm="3" xs="2" className="marbxst">
                                        <img src={`${process.env.PUBLIC_URL}/img/righticon.png`} width="40%" className=" text-right" />
                                    </Col>
                                </Row>
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>

    )
}
export default Casaire;