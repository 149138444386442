import React, { Component } from 'react';
import { withRouter,Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container } from 'react-bootstrap';

class thankyou extends Component { 
    //window.scrollTo(0, 0);
    render(){
        setTimeout(()=>{
            window.open("https://www.aandb.xyz/aandb.pdf","_self");
        },5000)
    return ( 
        <div>
            <Helmet>

                <title>Thanks for your enquiry</title>
                <meta name="description" content="Thankyou" />
                <link rel="canonical" href="https://www.aandb.xyz/thank-you.html" /> 

            </Helmet>

            <div className="firstfoldseo ">
                <div className="box padb250">
                    {/* first fold */}
                    <Container fluid className="padbsmt padbsmb">
                  
                        <h5 className="padbsmt padbsmb">Thanks for expressing interest. <br />Someone from our team will contact you<br /> in the next 24 hours.</h5>

                        <p>In the meanwhile, why don't you land on our Hello Deck?</p>
                        <a href="https://www.aandb.xyz/aandb.pdf" className="btn btn-thankyou ">View Hello Deck</a>
                       <br/>
                       <Link style={{display:"none"}} to={`${process.env.PUBLIC_URL}/404.html`}>404 </Link>  
                        <br />
                        <br />
                    </Container>
                </div>
            </div>
        </div>

    );

}
}


export default withRouter(thankyou);