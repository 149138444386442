import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Nav, Container, Accordion, Card, Button, Col, Row, Tab, Carousel } from 'react-bootstrap';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Slider from "react-slick"; 
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";
import Services from './Services';
function Seo() {
     window.scrollTo(0, 0); 
     const [focus, setFocus] = React.useState(false);
    return (
        <div>
            <Helmet>

                <title>Best SEO Agency for *Luxury* Brands | A&B</title>
                <meta name="description" content="An SEO Agency for luxury brands in Mumbai. Make your way through the clutter to the top of Google rankings using white hat SEO techniques. " />
                <link rel="canonical" href="https://www.aandb.xyz/luxury-seo-agency.html" /> 

            </Helmet>
            <div className="firstfoldseo ">
                <div className="box padmmdb">
                    {/* first fold */}
                    <Container fluid className="padbsmt padbsmb">
                        <Row>
                            <Col lg="2"></Col>
                            <Col lg="8">
                                <div className="circleboxfirst padsmdt padsmdb">
                                    <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="roundbx"  width="30%" alt="" />

                                    <div className="boxcentered">
                                        <h4 className="d-none d-sm-block">
                                            <span  className="cuspn">
                                            <CountUp start={focus ? 0 : null} end={75} redraw={true}>
                                            {({ countUpRef }) => (
                                                <VisibilitySensor onChange={(isVisible) => {
                                                    if (isVisible) { setFocus(true); }
                                                }}>
                                                    <span ref={countUpRef} />
                                                </VisibilitySensor>
                                            )}
                                        </CountUp>%</span> of the users click on the<br /> <span>top 5 Google search results</span>,<br /> are you there yet?
                                     </h4>
                                     <h4 className="d-block d-sm-none">
                                            <span  className="cuspn">
                                            <CountUp start={focus ? 0 : null} end={75} redraw={true}>
                                            {({ countUpRef }) => (
                                                <VisibilitySensor onChange={(isVisible) => {
                                                    if (isVisible) { setFocus(true); }
                                                }}>
                                                    <span ref={countUpRef} />
                                                </VisibilitySensor>
                                            )}
                                        </CountUp>%</span><br /> of the users click on the <span>top 5 Google search results</span>, are you there yet?
                                     </h4>
                                    </div>

                                </div>
                                <AnchorLink href="#iwantto" className="btn btn-seo" >No, But I Want To </AnchorLink>
                            </Col>
                            <Col lg="2"></Col>

                        </Row>
                    </Container>
                </div>
            </div>
            {/* Seo matters */}
            <div id="iwantto" className="seomatters  bgb">
                <Container className="padbmdt padbmdb"> 
                    <h3 className="text-center txtcolor  padsmdb">Why <span>SEO</span> Matters?</h3>
                    <Row className="padsmdb ">
                        <Col lg="4" md="4" sm="4">
                            <div className="circleboxfirst  padssmb  pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`}  className="servicescirbox" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1 className="d-none d-sm-block">82% <br /><br /></h1>
                                    <h1 className="d-block d-sm-none">82%</h1>
                                </div>
                            </div>
                            <p>of Smartphone <br /> shoppers conduct <br /> <strong>“near me”</strong> searches </p><br />
                        </Col>
                        <Col lg="4" md="4" sm="4">
                            <div className="circleboxfirst  padssmb  pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`}  className="servicescirbox" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1 className="d-none d-sm-block">91% <br /><br /></h1>
                                    <h1 className="d-block d-sm-none">91%</h1>

                                </div>
                            </div>

                            <p>of Content Gets No <br /> Traffic From Google <br /> <strong>(Be in the 9%)</strong> </p><br />

                        </Col>
                        <Col lg="4" md="4" sm="4">
                            <div className="circleboxfirst  padssmb  pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`}  className="servicescirbox" width="40%" alt="" />
                                <div className="boxcentered">
                                    
                                    <h1 className="d-none d-sm-block">80% <br /><br /></h1>
                                    <h1 className="d-block d-sm-none">80%</h1>
                                </div>
                            </div>

                            <p>of users ignore <br /> paid ads in <br /> search results </p>

                        </Col>
                    </Row>
                    <AnchorLink href="#approach" className="btn btn-seo" >Let's do it!</AnchorLink>
                </Container>
            </div>
            {/* our approach seo fold */}
            <div id="approach" className="seoapproach bgbl padmmdt padmmdb ">
                <Container>
                    <div className="appbox  padssmb d-none d-sm-block">
                        <img src={`${process.env.PUBLIC_URL}/img/approach2.png`} className="imgwidt img-fluid" alt="" />
                        <div className="top-left">
                            <h4><strong>AUDIENCES,</strong><br /> <i>NOT KEYWORDS</i></h4>
                            <p>We sweat to understand more about your audience and their search behaviour before we start. We might surprise you here :)</p>
                        </div>

                        <div className="top-right">
                            <h4><strong>PROBLEMS,</strong><br /> <i>NOT CHECKBOXES</i></h4>
                            <p>Index bloat, load time, thin content, we get to the heart of the SEO pain points of your site & kill the Kraken.</p>
                        </div>

                        <div className="bottom-left">
                            <h4><strong>SOLUTIONS,</strong><br /> <i>NOT JARGON</i></h4>
                            <p>Our solutions will not look like a developer's handbook, it will be easy to understand, measure & growth linked.</p>
                        </div>


                        <div className="bottom-right"> <h4><strong>GROWTH,</strong><br /> <i>NOT BS</i></h4>
                            <p>We track growth metrics like Organic Revenue & Organic Traffic, not vanity metrics like link count & DA/PA.</p>
                        </div>
                        <div className="centered"><h1>OUR <span>APPROACH</span> <br />TO SEO</h1></div>
                    </div>
                    <div className="appbox  padssmb d-block d-sm-none">
                    <h1>OUR <span>APPROACH</span> <br />TO SEO</h1> <br />

                        <h4><strong>AUDIENCES,</strong><br /> <i>NOT KEYWORDS</i></h4>
                        <p>We sweat to understand more about your audience and their search behaviour before we start. We might surprise you here :)</p>
                        <img src={`${process.env.PUBLIC_URL}/img/straightline.png`} className="img-fluid"/>
                        <h4><strong>PROBLEMS,</strong><br /> <i>NOT CHECKBOXES</i></h4>
                        <p>Index bloat, load time, thin content, we get to the heart of the SEO pain points of your site & kill the Kraken.</p>

                        <img src={`${process.env.PUBLIC_URL}/img/straightline.png`} className="img-fluid"/>
                        <h4><strong>SOLUTIONS,</strong><br /> <i>NOT JARGON</i></h4>
                        <p>Our solutions will not look like a developer's handbook, it will be easy to understand, measure & growth linked.</p>
                        <img src={`${process.env.PUBLIC_URL}/img/straightline.png`} className="img-fluid"/>
                        <h4><strong>GROWTH,</strong><br /> <i>NOT BS</i></h4>
                        <p>We track growth metrics like Organic Revenue & Organic Traffic, not vanity metrics like link count & DA/PA.</p>


                    </div>

                    <Accordion defaultActiveKey="0"  className="seoapproach1 marbxst">

                        <Accordion.Toggle as={Button} variant="read" eventKey="1">
                            Our approach in detail
                        </Accordion.Toggle>

                        <Accordion.Collapse eventKey="1">
                            <Card.Body>
                                <h3 className="text-center txtcolor  padsmdb">SEO <span>SERVICES</span></h3>
                                <Row>
                                    <ol>
                                        <li>Technical SEO - <br /> <br />

                                        Do you know what percentage of your website is indexed in Google? <br /><br />
                                            <ol type="a">
                                                <li>
                                                    Why Technical SEO is important: Setting up your Technical SEO right is very crucial because your website won’t be discoverable by Search Engines (Google, Bing, etc.) if this goes wrong <br /><br />
                                                </li>
                                                <li>
                                                    Our Detailed Approach: <br /><br />
                                                    <ol type="i">
                                                        <li> Objective - We ensure that your website is crawlable, indexable in the search engines which will then make it rankable for keywords. <br /><br /></li>
                                                        <li> Audit - We conduct an audit and look at Crawling & Indexing, Structured Data, Redirects, JS rendering, Canonicals, HREF tags & more. <br /><br /></li>
                                                        <li> Execution - We work with your technology team to fill the gaps identified in the audit. Low hanging fruits first for maximum impact in the shortest time! <br /><br /></li>
                                                    </ol>
                                                </li>
                                                <li>
                                                    Impact for our client: By just focusing on the technical & on-page aspects for a brand, we improved their organic traffic by 400% with the same authority.
                                        </li>
                                            </ol>
                                        </li>
                                        <br /> <br />
                                        <li>eCommerce SEO - <br /> <br />

                                            Do you know that out of stocks products hit your rankings in Google? They really do :) <br /><br />
                                            <ol type="a">
                                                <li>
                                                    Why eCommerce SEO is important: eCommerce SEO is not just about ranking for money (buy-intent) keywords but also targeting your audience from the research stage. <br /><br />
                                                </li>
                                                <li>
                                                    Our Detailed Approach: <br /><br />
                                                    <ol type="i">
                                                        <li> Objective - To capture users from research stage and buy stage to make sure our net is cast wide enough but still converts. <br /><br /></li>
                                                        <li> Audit - Ecommerce websites usually have 1000’s of pages indexed and each product & category page has the ability to rank for relevant keywords. We audit the gaps in category structure, product page linking, competitor analysis, which helps in the overall ranking for the website. <br /><br /></li>
                                                        <li> Execution - We work with your technology team to fill the gaps identified in the audit. We give them exact steps of what to change where & how. <br /><br /></li>
                                                    </ol>
                                                </li>
                                                <li>
                                                    Impact for our client: By targeting the right set of keywords (less competitive+high intent) for Evok, we doubled their organic traffic & increased revenue by 80%  in less than 6 months.
                                        </li>
                                            </ol>
                                        </li>
                                        <br /> <br />
                                        <li>Google Penalty Recovery - <br /> <br />

                                            When did you last analyse the impact of Google Core update on your website?<br /><br />
                                            <ol type="a">
                                                <li>
                                                    Search Engine Algorithms Hit:<br /><br />
                                                    <ol type="i">
                                                        <li> You are already hit - You have to mend ways, fast! Never assume if you are already hit, you can’t get another one. This isn’t a traffic cop’s ticket :) <br /><br /></li>
                                                        <li> You aren’t impacted yet - You can’t stop Google from updating their algorithms but you can be better ready for it. The algorithm updates now happen almost daily and you need to keep up. <br /><br /></li>
                                                    </ol>
                                                </li>
                                                <li>
                                                    Our Detailed Approach: <br /><br />
                                                    <ol type="i">
                                                        <li> Objective - Determine why were you impacted, how can we fix it and how can we prevent a penalty to not strike you again? <br /><br /></li>
                                                        <li> Audit - Here we start with the pursuit of one answer, why? Why were you hit? Is it one factor or a mix of them? Is it you or Google? Major areas where we perform audits are technical, load time, user experience, content, EAT, etc.  <br /><br /></li>
                                                        <li> Execution - We will need to do a lot of execution, writing, experimentation, fixes and you need to eat patience for lunch, breakfast and dinner :) It takes time. <br /><br /></li>
                                                    </ol>
                                                </li>
                                                <li>
                                                    Impact for our client: One of our client’s falling under the YMYL category got hit by the 2019 September Core Update, we helped them recover 80% of their lost traffic by the next Core Update which was released in January!
                                                </li>
                                            </ol>
                                        </li>

                                        <br /> <br />
                                        <li>Analytics - <br /> <br />

                                            Do you know you can track Facebook ads cost in Google Analytics?<br /><br />
                                            <ol type="a">
                                                <li>
                                                    Why Analytics is important:<br /><br />
                                                    <ol type="i">
                                                        <li>  If the data which you are tracking is not correct, it will lead to wrong decisions and poor campaign performance. The $$ spent on any marketing activity will not have the targeted returns, that’s why setting up and analyzing data is crucial. <br /><br /></li>

                                                    </ol>
                                                </li>
                                                <li>
                                                    Our Detailed Approach: <br /><br />
                                                    <ol type="i">
                                                        <li> Objective - The first thing we do for any client is setting up and fixing tracking on the website so that we can set benchmarks and later analyse the growth. <br /><br /></li>
                                                        <li> Audit -  We use a variety of tools and combine data to look for growth opportunities for the website. Google Analytics, Omniture, Microsoft Clarity, Hotjar, Yandex, are some of the frequently used tools by our Analytics team! <br /><br /></li>
                                                        <li> Execution - Setting up your Analytics code properly on your website is the basic thing which needs to be done. Apart from	that, tracking destination goals, setting up e-commerce tracking with the data layer, CTA & button tracking, etc. is crucial to get granular data from your campaigns! <br /><br /></li>
                                                    </ol>
                                                </li>
                                                <li>
                                                    Impact for our client: For our client SOAL which is a Single Page Application (SPA), we were not able to track their pageviews because of Client-Side Rendering. We used the “history change” trigger in GTM to track the user journey navigating on the website which then provided the correct data and results from the campaigns.

                                                </li> <br />
                                                <li>
                                                    This will go in CRO: For a fashion client, through Analytics, we analysed that the website wasn’t performing well on Safari browsers. On investigation further, we found that image extensions were not supported in Safari because of which users were not able to see the content. Resolving this increased our conversions massively.

                                                </li>
                                            </ol>
                                        </li>

                                        <br /> <br />
                                        <li>App Store Optimisation - <br /> <br />

                                            70% of users check App screenshots on the App Store before downloading a new app. When did you last update yours?<br /><br />
                                            <ol type="a">
                                                <li>
                                                    Why App Store Optimisation is important:<br /><br />
                                                    <ol type="i">
                                                        <li>  Having a solid ASO strategy helps in driving installs and retaining them. <br /><br /></li>

                                                    </ol>
                                                </li>
                                                <li>
                                                    Our Detailed Approach: <br /><br />
                                                    <ol type="i">
                                                        <li> Objective - To drive installations from Organic searches and improve app page viewing to download ratio.<br /><br /></li>
                                                        <li> Audit -  Apps are updated on a weekly basis along with UI changes but a lot of developers miss updating the screenshots in the App & Play Store. Analysing outdated information, images, videos, reviews, organic searches provides a clear picture of the current loopholes. <br /><br /></li>
                                                        <li> Execution - Crafting your app listing title with your targeted keywords help potential searchers to find you easily. Choosing the correct primary category in your listing decides your organic reach. <br /><br /></li>
                                                    </ol>
                                                </li>
                                                <li>
                                                    Impact for our client: Our App store & Play store strategies have helped our client’s increase their installs by 2x. This is achieved purely through a user-centric approach and by providing great UX.
                                                </li> <br />

                                            </ol>
                                        </li>
                                        <br /> <br />
                                        <li>Local SEO - <br /> <br />

                                            Do you know that 28% of store transactions comes from “Near me” queries?<br /><br />
                                            <ol type="a">
                                                <li>
                                                    Why Local SEO is important:<br /><br />
                                                    <ol type="i">
                                                        <li>  Local search queries help in driving traffic to your offline store, it’s important to target the right set of keywords which have the offline intent of visiting the store. <br /><br /></li>

                                                    </ol>
                                                </li>
                                                <li>
                                                    Our Detailed Approach: <br /><br />
                                                    <ol type="i">
                                                        <li> Objective - To drive footfalls from searches happening from local searches. Not just Google My Business but other avenues too like Bing Listings, Tripadvisor Listings, Yandex Maps, etc. wherever your target audience is present.<br /><br /></li>
                                                        <li> Audit -  Unanswered reviews, old images, missing phone numbers, etc. are common issues with most of the local listings today, these small tasks have a huge impact on the reach and ranking of your listings. New features which are constantly added in GMB’s are often missed by store owners <br /><br /></li>
                                                        <li> Execution - Analysing platforms & getting your local listings verified is the first step towards your local success. Populating correct data, updating them constantly with new features, and techniques to get reviews helps in your brand reputation and local rankings in search results. <br /><br /></li>
                                                    </ol>
                                                </li>
                                                <li>
                                                    Impact for our client:  All our clients having a physical presence have seen a 40-50% increase in high-value90 footfalls.
                                                </li> <br />

                                            </ol>
                                        </li>

                                    </ol>

                                </Row>
                            </Card.Body>
                        </Accordion.Collapse>

                    </Accordion>
                </Container>
                {/* <img src={`${process.env.PUBLIC_URL}/img/approach.png`} className="img-fluid" /> */}
            </div>
            {/* seo services */}
            <div className="seoservices  bgb">
                <Container className="padmmdt  ">

                    <h3 className="text-center txtcolor  padsmdb pdtb20">SEO <span>SERVICES</span></h3>
                    <Row className="padsmdb text-center" xs={12}>
                        <Col lg="4" md="4" sm="4" xs="6" >
                            <div className="circleboxfirst  padssmb pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`}  className="imgbw" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1><br />Technical <br /> SEO</h1>
                                </div>
                            </div>
                            <p>&#60;a href=&#62;, rel=canonical, 3xx, &#60;alternate&#62;. Do not stress much on these, we will take care of it!</p>
                        </Col>
                        <Col lg="4" md="4" sm="4" xs="6" >
                            <div className="circleboxfirst  padssmb pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`}  className="imgbw" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1><br />Ecommerce  <br />  SEO</h1>
                                </div>
                            </div>

                            <p>Our keyword + topic optimisation approach drive actual revenue for your business. </p>

                        </Col>
                        <Col lg="4" md="4" sm="4" xs="6" >
                            <div className="circleboxfirst  padssmb pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`}  className="imgbw" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1><br />Google Penalty  <br /> Recovery</h1>
                                </div>
                            </div>

                            <p>Got hit by an algorithm update? We are the solicitors to get you of it. </p>

                        </Col>
                   
                        <Col lg="4" md="4" sm="4" xs="6" >
                            <div className="circleboxfirst  padssmb pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`}  className="imgbw" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1><br />Analytics</h1>
                                </div>
                            </div>
                            <p>Is your Analytics tool setup correct? We optimise &amp; drive insights that drive better decisions.</p>
                        </Col>
                        <Col lg="4" md="4" sm="4" xs="6" >
                            <div className="circleboxfirst  padssmb pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`}  className="imgbw" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1><br />App Store  <br />  Optimisation</h1>
                                </div>
                            </div>

                            <p>Our App store strategy will improve your app installs &amp; retention.</p>

                        </Col>
                        <Col lg="4" md="4" sm="4" xs="6" >
                            <div className="circleboxfirst  padssmb pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`}  className="imgbw" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1><br />Local SEO</h1>
                                </div>
                            </div>

                            <p>Our Local SEO strategy that drives customers &amp; sales for your Local brand! </p>

                        </Col>
                    </Row>

                </Container>
            </div>
            {/* case study */}

            <div className="bgfooter whyus  padsmdt padbmdb">
                <Col lg="8" className="offset-lg-1" >
                    <h3 className=" txtcolor"><span className="spund">CASE</span> STUDY</h3>
                </Col>
                <Casestudy />
            </div>

            {/* 3 Reasons to choose */}

            <div className="bgb  casestudy ">

                <Reasons />
            </div>

            {/* our services */}
            <Services /> 
        </div>
    );
}


export default Seo;

function Casestudy() {
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,

        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    adaptiveHeight: true,
                }
            },]
    };
    const next = () => {
        Slider.current.slickNext();
    }
    const previous = () => {
        Slider.current.slickPrev();
    }
    return (
        <Container fluid className="padmmdt casestudy ">
            <Row>
                <Col lg="10" className="offset-lg-1">
                    <Slider {...settings}>
                        <div>
                            <Row>
                                <Col className="offset-lg-1" lg={{ span: 4, order: 'first' }} md="6" sm={{ span: 6, order: 'last' }} xs={{ span: 12, order: 'first' }}><img src={`${process.env.PUBLIC_URL}/img/Insidercasestudy.png`} width="100%" className="mx-auto d-block" /></Col>
                                <Col lg={{ span: 6, order: 'first' }} sm={{ span: 6, order: 'first' }} md="6" xs={{ span: 12, order: 'last' }} className="mbtxtcenter offset-lg-1 padbsmb">
                                    <h1 className="padslgt padbxsb">400%</h1> 
                                    <h2 className="padbxsb"> Increase in <strong><span>Organic Search Traffic</span></strong></h2>
                                    {/* <a href="" className="btn btn-primary ">Read Case Study</a> */} 
                                    <Link to={`${process.env.PUBLIC_URL}/case-studies/seo-case-study-for-event-ticketing-company.html`} className="btn btn-seo " >Read Case Study</Link> 

                                </Col>

                            </Row>

                        </div>
                      

                    </Slider>
                </Col>
            </Row>
        </Container>
    );
}

// reasons
function Reasons() {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        adaptiveHeight: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false,
                    adaptiveHeight: true,
                }
            },],
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    adaptiveHeight: false,
                }
            },]
    };
    const next = () => {
        Slider.current.slickNext();
    }
    const previous = () => {
        Slider.current.slickPrev();
    }
    return (
        <Container fluid className="reasons padslgt padslgb casestudy ">
            <Row>
                <Col lg="10" className="offset-lg-1">
                    <Slider {...settings}>
                        <div>
                        <Row>
                                <Col className="offset-lg-1 offset-sm-1" lg={{ span: 4, order: 'first' }} md="4" sm={{ span: 3, order: 'first' }} xs={{ span: 12, order: 'first' }}><img src={`${process.env.PUBLIC_URL}/img/reasons3.png`} className="custmt80 padt130 padmmdt mimgw padmsmb marbsmt img-fluid mx-auto d-block" /></Col>
                                <Col className="marmlgt mt0" lg={{ span: 7, order: 'first' }} sm={{ span: 6, order: 'last' }} md="7" xs={{ span: 12, order: 'last' }}  >

                                    <h2 className="marmlgt"> Reasons to choose A&B for<strong> SEO</strong></h2> <br />

                                </Col>

                            </Row>
                             

                        </div>
                        <div>
                            <Row>

                                <Col className="offset-lg-1 offset-sm-1" lg={{ span: 5, order: 'first' }} sm={{ span: 6, order: 'first' }}  xs={{ span: 12, order: 'last' }}  >

                                <h2 className="padt190"><span>A&B Commitment</span></h2>
                                    <h3 className=" padmsmb">  If we don't generate <strong>growth</strong> in 3 months, <strong><i>we fire ourselves.</i></strong><br />There are 9 more weird things about us, <br />read them here.</h3>

                                </Col>
                                <Col className="offset-lg-1 mgm90" lg={{ span: 4, order: 'first' }}  sm={{ span: 4, order: 'last' }} xs={{ span: 12, order: 'first' }}>
                                    <img src={`${process.env.PUBLIC_URL}/img/commitment.png`} className="custmt80  padslg125 mimgw img-fluid  mx-auto d-block" />
                                </Col>

                            </Row>
                        </div>
                        <div>
                            <Row>

                                <Col  className="offset-lg-1 offset-sm-1" lg={{ span: 5, order: 'first' }} sm={{ span: 5, order: 'first' }} xs={{ span: 12, order: 'last' }} >

                                    <h3 className="marblgt padmsmb padslg125 "> Specialised SEO agency trusted by global brands</h3>

                                </Col>
                                <Col className=" mgm90" lg={{ span: 5, order: 'first' }}  sm={{ span: 5, order: 'last' }} xs={{ span: 12, order: 'first' }}>
                                    <img src={`${process.env.PUBLIC_URL}/img/worldmap.png`} className="custmt80 padslg125 padbsmb img-fluid mx-auto d-block" />
                                    <img src={`${process.env.PUBLIC_URL}/img/clientslogo.png`} className="img-fluid mx-auto d-block" />
                                </Col>
                            </Row>
                        </div>
                       
                        <div>
                        <Row> 
                                <Col className="offset-lg-1 offset-md-0" lg={{ span: 10, order: 'first' }}  md={{ span: 12, order: 'first' }}  sm={{ span: 8, order: 'last' }} xs={{ span: 12, order: 'last' }}>
                                <h3 className="text-center htitle"><span>Don't believe us?</span></h3>
                                    <p className="text-center custml ">  Hear it from our partners.</p>
                                    <div className="feedbackbox marmsmt padmsmt padmsmb">
                                     <img src={`${process.env.PUBLIC_URL}/img/leftcoma.png`}  className="mgt40 img-fluid mx-auto d-block"/>
                                     <br />
                                         
                                        <h4 className="">There are not many SEO agencies that can work on a really high volume traffic website and grow it manifold. We have seen a 4x bump in our organic traffic that<br /> has led to massive savings in ad budgets!
                                        </h4>  
                                        
                                    </div>
                                    <div className="clientname">
                                    <img src={`${process.env.PUBLIC_URL}/img/clients/PaytmInsider_roundedlogo.png`} className="img-fluid mx-auto" width="160px" /> <br />
                                    <p className="text-center"> <i>- Samidh Bhattacharya - Insider.in</i></p>
                                    </div>
                                </Col>

                            </Row>

                             
                        </div>
                    </Slider>
                </Col>
            </Row>
        </Container>
    );
}