import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container, Col, Row, Tab, Carousel } from 'react-bootstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function Work() {
    window.scrollTo(0, 0);
    return (

        <div>
            <Helmet>
                <title>Luxury Digital Marketing Agency Case Studies - A&B</title>
                <meta name="description" content="A&B Work Page" />
                <link rel="canonical" href="https://www.aandb.xyz/work.html" />

            </Helmet>

            <div className="work bgbl padmsmt padmsmb " >
                <Col lg="10" className="offset-lg-1" >
                    <h3><span className="spund">Ou</span>r Work</h3>
                </Col>
                {/* for desktop */}
                <Container className="work2 padmsmt ">
                    <div className="imgrow">
                        <div className="imgcolumn2">


                        <div className="bg">
                        <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/aprilia-rs457.png`} alt="" height="100%" width="100%" />
                        <div className="centeredbox"><h4>Thousands of prebookings and billions <br></br> of rupees of revenue.</h4>
                            <Link to={`${process.env.PUBLIC_URL}/case-studies/aprilia-rs457-launch-case-study.html`}>View Case Study &gt;</Link></div>
                        <div className="overlay">
                            <h1>
                            Aprilia 457’s Launch <br />
                            </h1>
                            <h2>
                                <Link to={`${process.env.PUBLIC_URL}/case-studies/aprilia-rs457-launch-case-study.html`}>
                                    <u>Thousands of prebookings and billions of rupees of revenue.</u>
                                </Link> </h2>

                            {/* <p>View Case Study</p> */}
                        </div>
                    </div>

                            <div className="bg">
                                <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casaire1.webp`} alt="" height="100%" width="100%" />
                                <div className="centeredbox"><h4>Luxury Furniture Brand Dubai - Walkins up by 400%</h4>
                                    <Link to={`${process.env.PUBLIC_URL}/case-studies/dubai-luxury-furniture-store-walkin-case-study.html`}>View Case Study &gt;</Link></div>
                                <div className="overlay">
                                    <h1>
                                        CASAIRE <br />
                                    </h1>
                                    <h2>
                                        <Link to={`${process.env.PUBLIC_URL}/case-studies/dubai-luxury-furniture-store-walkin-case-study.html`}>
                                            <u>Luxury Furniture Brand Dubai - Walkins up by 400%</u>
                                        </Link> </h2>

                                    {/* <p>View Case Study</p> */}
                                </div>
                            </div>

                          
                            <div className="bg">
                                <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/Parfait.png`} alt="" height="100%" width="100%" />
                                <div className="centeredbox"><h4>Magazine covers became size inclusive with<br /> just a lens!</h4>
                                    <Link to={`${process.env.PUBLIC_URL}/case-studies/parfait-snapchat-instagram-case-study.html`}>View Case Study &gt;</Link></div>
                                <div className="overlay">
                                    <h1>
                                        Parfait <br />
                                    </h1>
                                    <h2>
                                        <Link to={`${process.env.PUBLIC_URL}/case-studies/parfait-snapchat-instagram-case-study.html`}>
                                            <u>Magazine covers became size inclusive with just a lens!</u>
                                        </Link>
                                        <br /></h2>
                                    {/* <p>View Case Study</p> */}
                                </div>
                            </div>

                            <div className="bg">
                                <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/tiranga.png`} alt="" height="100%" width="100%" />
                                <div className="centeredbox"><h4>Restoring the dignity of hundreds of<br /> national flags</h4>
                                    <Link to={`${process.env.PUBLIC_URL}/case-studies/independence-day-social-media-campaign-tiranga-dabba.html`}>View Case Study &gt;</Link></div>
                                <div className="overlay">
                                    <h1>
                                        Tiranga Dabba <br />
                                    </h1>
                                    <h2>
                                        <Link to={`${process.env.PUBLIC_URL}/case-studies/independence-day-social-media-campaign-tiranga-dabba.html`}>
                                            <u>Restoring the dignity of hundreds of national flags</u>
                                        </Link>
                                        <br /></h2>
                                    {/* <p>View Case Study</p> */}
                                </div>
                            </div>

                            <div className="bg">
                                <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/nova.png`} alt="" height="100%" width="100%" />
                                <div className="centeredbox"><h4>418% jump in Revenue via Advertising, highest revenue post launch in India ever. </h4>
                                    <Link to={`${process.env.PUBLIC_URL}/case-studies/digital-advertising-sales-growth-case-study.html`}>View Case Study &gt;</Link></div>
                                <div className="overlay">
                                    <h1>
                                        NOVA KID<br />
                                    </h1>
                                    <h2>
                                        <Link to={`${process.env.PUBLIC_URL}/case-studies/digital-advertising-sales-growth-case-study.html`}>
                                            <u>418% jump in Revenue via Advertising, highest revenue post launch in India ever. </u>
                                        </Link> </h2>

                                    {/* <p>View Case Study</p> */}
                                </div>
                            </div>

                        </div>

                        <div className="imgcolumn2">

                        <div className="bg">
                        <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/1seagallery.png`} alt="" height="100%" width="100%" />
                        <div className="centeredbox"><h4>438% jump in qualified inquiries in 12 months</h4>
                            <Link to={`${process.env.PUBLIC_URL}/case-studies/online-lead-generation-case-study.html`}>View Case Study &gt;</Link></div>
                        <div className="overlay ">
                            <h1>
                                Atmantan<br />
                            </h1>
                            <h2>
                                <Link to={`${process.env.PUBLIC_URL}/case-studies/online-lead-generation-case-study.html`}>
                                    <u>438% jump in qualified inquiries in 12 months</u>
                                </Link>
                                <br />
                            </h2>

                        </div>
                    </div>

                          



                            <div className="bg">
                                <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/Isaia.png`} alt="" height="100%" width="100%" />
                                <div className="centeredbox"><h4>100% Jump in Walkin Revenue in 4 Months</h4>
                                    <Link to={`${process.env.PUBLIC_URL}/case-studies/isaia-retail-store-digital-transformation.html`}>View Case Study &gt;</Link></div>
                                <div className="overlay">
                                    <h1>
                                        ISAIA <br />
                                    </h1>
                                    <h2>
                                        <Link to={`${process.env.PUBLIC_URL}/case-studies/isaia-retail-store-digital-transformation.html`}>
                                            <u>100% Jump in Walkin Revenue in 4 Months</u>
                                        </Link>
                                    </h2>
                                    {/* <p>View Case Study</p> */}
                                </div>
                            </div>

                            <div className="bg">
                                <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/saundh-box.png`} alt="" height="100%" width="100%" />
                                <div className="centeredbox"><h4>Improvement in E-Commerce <br /> Conversion Rate.</h4>
                                    <Link to={`${process.env.PUBLIC_URL}/case-studies/ecommerce-conversion-rate-case-study.html`}>View Case Study &gt;</Link></div>
                                <div className="overlay">
                                    <h1>
                                        SAUNDH <br />
                                    </h1>
                                    <h2>
                                        <Link to={`${process.env.PUBLIC_URL}/case-studies/ecommerce-conversion-rate-case-study.html`}>
                                            <u>Improvement in E-Commerce <br /> Conversion Rate.</u>
                                        </Link>
                                    </h2>
                                    {/* <p>View Case Study</p> */}
                                </div>
                            </div>
                            <div className="bg">
                                <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/limelight.png`} alt="" height="100%" width="100%" />
                                <div className="centeredbox"><h4>780% ROI via Digital, scaled non existent<br /> e-commerce.</h4>
                                    <Link to={`${process.env.PUBLIC_URL}/case-studies/ecommerce-advertising-case-study.html`}>View Case Study &gt;</Link></div>
                                <div className="overlay">
                                    <h1>
                                        LIMELIGHT <br />
                                    </h1>
                                    <h2>
                                        <Link to={`${process.env.PUBLIC_URL}/case-studies/ecommerce-advertising-case-study.html`}>
                                            <u>780% ROI via Digital,<br /> scaled non existent e-commerce.</u>
                                        </Link>
                                    </h2>
                                    {/* <p>View Case Study</p> */}
                                </div>
                            </div>
                        </div>
                        <div className="imgcolumn2">

                        <div className="bg">
                        <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/paytmInsider.png`} alt="" height="100%" width="100%" />
                        <div className="centeredbox"><h4>430% increase in organic traffic, and 200% lift in conversions.</h4>
                            <Link to={`${process.env.PUBLIC_URL}/case-studies/seo-case-study-for-event-ticketing-company.html`}>View Case Study &gt;</Link></div>
                        <div className="overlay">
                            <h1>Insider<br /></h1>
                            <h2>
                                <Link to={`${process.env.PUBLIC_URL}/case-studies/seo-case-study-for-event-ticketing-company.html`}>
                                    <u>400% increase in organic traffic, and 200% lift in conversions.</u>
                                </Link></h2>
                            {/* <p>View Case Study</p> */}
                        </div>
                    </div>

                            <div className="bg">
                                <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/evok.png`} className="customtint" alt="" height="100%" width="100%" />
                                <div className="centeredbox"><h4>Scaled  Ecommerce Revenue by 2404%</h4>
                                    <Link to={`${process.env.PUBLIC_URL}/case-studies/scaling-evok-ecommerce-revenue.html`}>View Case Study &gt;</Link></div>
                                <div className="overlay">
                                    <h1>Evok <br /></h1>
                                    <h2>
                                        <Link to={`${process.env.PUBLIC_URL}/case-studies/scaling-evok-ecommerce-revenue.html`}>
                                            <u>Scaled  Ecommerce Revenue by 2404%</u>
                                        </Link></h2>
                                    {/* <p>View Case Study</p> */}
                                </div>
                            </div>



                            <div className="bg">
                                <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/blabel.png`} alt="" height="100%" width="100%" />
                                <div className="centeredbox"><h4>1043% increase in unique blogs pageviews</h4>
                                    <Link to={`${process.env.PUBLIC_URL}/case-studies/content-marketing-case-study-increase-in-pageviews.html`}>View Case Study &gt;</Link></div>
                                <div className="overlay">
                                    <h1>
                                        BLABEL <br />
                                    </h1>
                                    <h2>
                                        <Link to={`${process.env.PUBLIC_URL}/case-studies/content-marketing-case-study-increase-in-pageviews.html`}>
                                            <u>1043% increase in unique blogs pageviews</u>
                                        </Link> </h2>

                                    {/* <p>View Case Study</p> */}
                                </div>
                            </div>

                            <div className="bg">
                                <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/evokbox.png`} alt="" height="100%" width="100%" />
                                <div className="centeredbox"><h4>1.47 M targeted users engaged through social media.</h4>
                                    <Link to={`${process.env.PUBLIC_URL}/case-studies/social-media-case-study-evok-furnitures.html`}>View Case Study &gt;</Link></div>
                                <div className="overlay">
                                    <h1>
                                        EVOK <br />
                                    </h1>
                                    <h2>
                                        <Link to={`${process.env.PUBLIC_URL}/case-studies/social-media-case-study-evok-furnitures.html`}>
                                            <u>1.47 M targeted users engaged through social media.</u>
                                        </Link> </h2>

                                    {/* <p>View Case Study</p> */}
                                </div>
                            </div>








                        </div>

                    </div>
                </Container>

            </div>
        </div>
    )
}

export default Work;