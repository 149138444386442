import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Nav, Container, Accordion, Card, Button, Col, Row, Tab, Carousel } from 'react-bootstrap';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Slider from "react-slick"; 
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";
import Services from './Services';
function Marketingautomation() {
     window.scrollTo(0, 0);
     const [focus, setFocus] = React.useState(false);
    return (
        <div>
            <Helmet>
                <title>Best Marketing Automation Agency for *Luxury* Brands | A&B</title>
                <meta name="description" content="Marketing automation increases the conversion rate of luxury & premium brands by 53%" />
                <link rel="canonical" href="https://www.aandb.xyz/marketing-automation-agency-luxury.html" /> 
            </Helmet>
            <div className="firstfoldseo ">
                <div className="box  padmmdb">
                    {/* first fold */}
                    <Container fluid className="padbsmt padbsmb">
                        <Row>
                            <Col lg="2"></Col>
                            <Col lg="8">
                                <div className="circleboxfirst  padsmdt padsmdb">
                                    <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="roundbx"  width="30%" alt="" />

                                    <div className="boxcentered">
                                        <h4> 
                                        Marketing automation increases <br />the conversion rate of luxury <br /> & premium brands by <br /><span className="cuspn">
                                           <CountUp start={focus ? 0 : null} end={53} redraw={true}>
                                            {({ countUpRef }) => (
                                                <VisibilitySensor onChange={(isVisible) => {
                                                    if (isVisible) { setFocus(true); }
                                                }}>
                                                    <span ref={countUpRef} />
                                                </VisibilitySensor>
                                            )}
                                        </CountUp>%</span>
                                     </h4>

                                    </div>

                                </div>
                                <AnchorLink href="#automate" className="btn btn-seo" >Ready to automate? </AnchorLink>
                            </Col>
                            <Col lg="2"></Col>

                        </Row>
                    </Container>
                </div>
            </div>
            {/* Seo matters */}
            <div id="automate" className="seomatters  bgb">
                <Container className="padbmdt padbmdb">

                    <h3 className="text-center txtcolor  padsmdb">Why <span>Automation</span> Matters?</h3>
                    <Row className="padsmdb">
                        <Col lg="4" md="4" sm="4">
                            <div className="circleboxfirst  padssmb pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`}  className="servicescirbox" width="40%" alt="" />
                                <div className="boxcentered">
                                <h1>451%<br /><br /></h1>
                                </div>
                            </div>
                            <p>average increase in qualified leads by implementing a Marketing automation tool.  </p><br />
                        </Col>
                        <Col lg="4" md="4" sm="4">
                            <div className="circleboxfirst  padssmb pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`}  className="servicescirbox" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1>15%<br /><br /></h1>
                                </div>
                            </div>

                            <p>increase in sales productivity driven by marketing automation and <strong>12.2%</strong> reduction in marketing overhead.</p><br />

                        </Col>
                        <Col lg="4" md="4" sm="4">
                            <div className="circleboxfirst  padssmb pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`}  className="servicescirbox" width="40%" alt="" />
                                <div className="boxcentered"> 
                                    <h1>20%<br /><br /></h1>
                                </div>
                            </div>

                            <p>potential buyers converted to sales that were not ready to purchase earlier with the use of lead nurturing through marketing automation.</p>

                        </Col>
                    </Row>
                    <AnchorLink href="#approach" className="btn btn-seo" >Let's do it!</AnchorLink>
                </Container>
            </div>
            {/* our approach seo fold */}
            <div id="approach" className="seoapproach bgbl padmmdt padmmdb ">
                <Container>
                    <div className="appbox  padssmb d-none d-sm-block">
                        <img src={`${process.env.PUBLIC_URL}/img/approach2.png`} className="imgwidt img-fluid" alt="" />
                        <div className="top-left">
                            <h4><strong>Automation,</strong><br /> <i>not checkboxes</i></h4>
                            <p>Automation not for the sake of it, but driving real business results out of it.</p>
                        </div>

                        <div className="top-right">
                            <h4><strong> Journey,</strong><br /> <i>not hits</i></h4>
                            <p>Creating a story for the user to follow so that user experience is seamless across channels.</p>
                        </div>

                        <div className="bottom-left">
                            <h4><strong> Relationships,</strong><br /> <i>not one time</i></h4>
                            <p>Built long lasting relationships with your customers, not just one transaction.</p>
                        </div>


                        <div className="bottom-right"> <h4><strong>Insights,</strong><br /> <i>NOT BS</i></h4>
                            <p>Knowing & doing automation is not important, tracking & refining over time is.</p>
                        </div>
                        <div className="centered"><h1>OUR <span>APPROACH</span> <br />TO Automation</h1></div>
                    </div>
                    <div className="appbox  padssmb d-block d-sm-none">
                    <h1>OUR <span>APPROACH</span> <br />TO AUTOMATION</h1> <br />

                        <h4><strong>Automation,</strong><br /> <i>not hits</i></h4>
                        <p>Automation not for the sake of it, but driving real business results out of it.</p>
                        <img src={`${process.env.PUBLIC_URL}/img/straightline.png`} className="img-fluid"/>
                        <h4><strong> Journey,</strong><br /> <i>NOT CHECKBOXES</i></h4>
                        <p>Creating a story for the user to follow so that user experience is seamless across channels.</p>

                        <img src={`${process.env.PUBLIC_URL}/img/straightline.png`} className="img-fluid"/>
                        <h4><strong> Relationships,</strong><br /> <i>not one time</i></h4>
                        <p>Built long lasting relationships with your customers, not just one transaction.</p>
                        <img src={`${process.env.PUBLIC_URL}/img/straightline.png`} className="img-fluid"/>
                        <h4><strong>Insights,</strong><br /> <i>NOT BS</i></h4>
                        <p>Knowing & doing automation is not important, tracking & refining over time is.</p>


                    </div>

                    
                </Container>
                {/* <img src={`${process.env.PUBLIC_URL}/img/approach.png`} className="img-fluid" /> */}
            </div>
            {/* seo services */}
            <div className="seoservices  bgb">
                <Container className="padmmdt  ">

                    <h3 className="text-center txtcolor  padsmdb">AUTOMATION <span>SERVICES</span></h3>
                    <Row className="padsmdb" xs={12}>
                        <Col lg="4" md="4" sm="4" xs="6" >
                            <div className="circleboxfirst  padssmb">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`}  className="imgbw" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1><br /><br />Email <br /> automation</h1>
                                </div>
                            </div>
                            
                        </Col>
                        <Col lg="4" md="4" sm="4" xs="6" >
                            <div className="circleboxfirst  padssmb">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`}  className="imgbw" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1><br /><br />Lead<br />  nurturing</h1>
                                </div>
                            </div>
 
                        </Col>
                        <Col lg="4" md="4" sm="4" xs="6" >
                            <div className="circleboxfirst  padssmb">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`}  className="imgbw" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1><br /><br />Social media   <br /> automation</h1>
                                </div>
                            </div>

                          
                        </Col>
                    </Row>
                     

                </Container>
            </div>
            

            {/* 3 Reasons to choose */}

            <div className="bgbl casestudy ">

                <Reasons />
            </div>

            {/* our services */}
            <Services /> 
        </div>
    );
}


export default Marketingautomation;


// reasons
function Reasons() {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        adaptiveHeight: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false,
                    adaptiveHeight: true,
                }
            },],
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    adaptiveHeight: false,
                }
            },]
    };
    const next = () => {
        Slider.current.slickNext();
    }
    const previous = () => {
        Slider.current.slickPrev();
    }
    return (
        <Container fluid className="reasons padslgt padslgb casestudy ">
            <Row>
                <Col lg="10" className="offset-lg-1">
                    <Slider {...settings}>
                        <div>
                        <Row>
                                <Col className="offset-lg-1 offset-sm-1" lg={{ span: 4, order: 'first' }} md="4" sm={{ span: 3, order: 'first' }} xs={{ span: 12, order: 'first' }}><img src={`${process.env.PUBLIC_URL}/img/reasons3.png`} className="custmt80 padt130 padmmdt mimgw padmsmb marbsmt img-fluid mx-auto d-block" /></Col>
                                <Col className="marmlgt mt0" lg={{ span: 7, order: 'first' }} sm={{ span: 6, order: 'last' }} md="7" xs={{ span: 12, order: 'last' }}  >

                                    <h2 className="marmlgt"> Reasons to choose A&B for<strong> Marketing Automation</strong></h2> <br />

                                </Col>

                            </Row>
                            

                        </div>
                        <div>
                            <Row>

                                <Col className="offset-lg-1 offset-sm-1" lg={{ span: 5, order: 'first' }} sm={{ span: 6, order: 'first' }}  xs={{ span: 12, order: 'last' }} >

                                <h2 className="padt190"><span>A&B Commitment</span></h2>
                                    <h3 className=" padmsmb">  If we don't generate <strong>growth</strong> in 3 months, <strong><i>we fire ourselves.</i></strong><br /> There are 9 more weird things about us, <br />read them here.</h3>

                                </Col>
                                <Col  className="offset-lg-1 mgm90" lg={{ span: 4, order: 'first' }}  sm={{ span: 4, order: 'last' }} xs={{ span: 12, order: 'first' }}>
                                    <img src={`${process.env.PUBLIC_URL}/img/commitment.png`} className="custmt80  padslg125 mimgw img-fluid mx-auto d-block" />
                                </Col>

                            </Row>
                        </div>
                        <div>
                            <Row>

                            <Col className="offset-lg-1 offset-sm-1" lg={{ span: 5, order: 'first' }} sm={{ span: 5, order: 'first' }} xs={{ span: 12, order: 'last' }} >

                                    <h3 className="marblgt padmsmb padslg125 "> Specialised marketing automation agency trusted by global brands</h3>

                                </Col>
                                <Col className=" mgm90" lg={{ span: 5, order: 'first' }}  sm={{ span: 5, order: 'last' }} xs={{ span: 12, order: 'first' }}>
                                    <img src={`${process.env.PUBLIC_URL}/img/worldmap.png`} className="custmt80 padslg125 padbsmb img-fluid mx-auto d-block" />
                                    <img src={`${process.env.PUBLIC_URL}/img/clientslogo.png`} className=" img-fluid mx-auto d-block" />
                                </Col>
                            </Row>
                        </div>
                       
                        <div>
                        <Row> 
                                <Col className="offset-lg-1 offset-md-0" lg={{ span: 10, order: 'first' }}  md={{ span: 12, order: 'first' }}  sm={{ span: 8, order: 'last' }} xs={{ span: 12, order: 'last' }}>
                                <h3 className="text-center htitle"><span>Don't believe us?</span></h3>
                                    <p className="text-center custml ">  Hear it from our partners.</p>
                                    <div className="feedbackbox marmsmt padmsmt padmsmb">
                                     <img src={`${process.env.PUBLIC_URL}/img/leftcoma.png`}  className="mgt40 img-fluid mx-auto d-block"/>
                                     <br />
                                        {/* <img src={`${process.env.PUBLIC_URL}/img/paytminsiderlogo.png`} className="img-fluid mx-auto d-block padmsmt padmsmb" /> */}
                                        <h4 className="">We get hundreds of client inquiries weekly. Our automation helps us qualify a client basis size, budget, objective, service etc. Even to the extent that they get custom answers basis their inputs; all without manual intervention
                                        </h4>  
                                        {/* <img src={`${process.env.PUBLIC_URL}/img/1seagallery.png`} className="rounded-circle img-fluid mx-auto d-block" /> */}
                                        {/* <img src={`${process.env.PUBLIC_URL}/img/rightcoma.png`}  className="flr" /> */}
                                    </div>
                                    <div className="clientname">
                                    <img src={`${process.env.PUBLIC_URL}/img/clients/A&B_roundedlogo.png`} className="img-fluid mx-auto" width="160px" /> <br />
                                    <p className="text-center"> <i>- Lalit Patel, Founder, A&B</i></p>
                                    </div>
                                </Col>

                            </Row>

                             
                        </div>
                    </Slider>
                </Col>
            </Row>
        </Container>
    );
}