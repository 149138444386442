import { Nav, Container, Accordion, Card, Button, Col, Row, Tab, Carousel, Modal } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
function Services() {
    return (
        <div id="services" className="bgservices padbsmt padbsmb services">

            <Col lg="10" className="offset-lg-1" >
                <h3><span className="spund">Ou</span>r Services</h3>

                <Tab.Container defaultActiveKey="first"  >
                    <Row className="mbmgt50">
                        <Col lg="12">
                            <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                    <Nav.Link eventKey="first">Driving Sales</Nav.Link>
                                    <Nav.Link eventKey="second">Building Brand</Nav.Link>
                                </Nav.Item>

                            </Nav>
                        </Col>
                    </Row>
                    <Row className="servicesarrow">
                        <Col lg={12} sm={12} xs={12} className="padmsmt padmsmb">
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <Container>
                                        <Row>
                                            <Col lg="4" md="4" sm="6" xs="4" className="padsmdt mbpdl9">
                                                <Link to={`${process.env.PUBLIC_URL}/luxury-e-commerce-agency.html`} className="">
                                                    <Row>

                                                        <Col lg="4" md="6" className="mbppr0">
                                                             <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/e-commercesale.png`} width="auto" height="auto" alt="" className="bgbox img-fluid mx-auto d-block" />
                                                        </Col>
                                                        <Col lg="8" md="6" className="mbppr0">  <h4>E-Commerce<br />Sales Growth</h4></Col>
                                                    </Row>
                                                </Link>
                                            </Col>
                                            <Col lg="4" md="4" sm="6" xs="4" className="padsmdt mbpdl9">
                                                <Link to={`${process.env.PUBLIC_URL}/retail-digital-marketing-agency-luxury.html`} className="">
                                                    <Row>
                                                        <Col lg="4" md="6" className="mbppr0" >
                                                             <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/retail-store.png`} width="auto" height="auto" alt="" className="bgbox img-fluid mx-auto d-block" /></Col>
                                                        <Col lg="8" md="6" className="mbppr0">  <h4>Retail Store<br />Footfall Growth</h4></Col>
                                                    </Row>
                                                </Link>
                                            </Col>
                                            <Col lg="4" md="4" sm="6" xs="4" className="padsmdt mbpdl9">
                                                <Link to={`${process.env.PUBLIC_URL}/digital-lead-generation-agency-luxury.html`} className="">
                                                    <Row>
                                                        <Col lg="4" md="6" className="mbppr0">
                                                             <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/digitalleadgeneration.png`} width="auto" height="auto" alt="" className="bgbox img-fluid mx-auto d-block" /></Col>
                                                        <Col lg="8" md="6" className="mbppr0">  <h4>Digital Lead<br />   Generation</h4></Col>
                                                    </Row>
                                                </Link>
                                            </Col>
                                            <Col lg="4" md="4" sm="6" xs="4" className="padsmdt mbpdl9">
                                                <Link to={`${process.env.PUBLIC_URL}/media-buying-agency-luxury.html`} className="">
                                                    <Row>
                                                        <Col lg="4" md="6" className="mbppr0">
                                                             <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/digitalmediaplanning.png`} width="auto" height="auto" alt="" className=" bgbox img-fluid mx-auto d-block" /></Col>
                                                        <Col lg="8" md="6" className="mbppr0"><h4>Digital Media<br />  Planning & Buying</h4></Col>
                                                    </Row>
                                                </Link>
                                            </Col>

                                            <Col lg="4" md="4" sm="6" xs="4" className="padsmdt mbpdl9">
                                                <Link to={`${process.env.PUBLIC_URL}/conversion-rate-optimization-agency-luxury.html`} className="">
                                                    <Row>
                                                        <Col lg="4" md="6" className="mbppr0">
                                                             <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/conversionrate.png`} width="auto" height="auto" alt="" className="bgbox img-fluid mx-auto d-block" /></Col>
                                                        <Col lg="8" md="6" className="mbppr0">  <h4>Conversion Rate<br /> Optimization</h4></Col>
                                                    </Row>
                                                </Link>
                                            </Col>
                                            <Col lg="4" md="4" sm="6" xs="4" className="padsmdt mbpdl9">
                                                <Link to={`${process.env.PUBLIC_URL}/luxury-seo-agency.html`} className="">
                                                    <Row>
                                                        <Col lg="4" md="6" className="mbppr0">
                                                             <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/searchengine.png`} width="auto" height="auto" alt="" className="bgbox img-fluid mx-auto d-block" /></Col>
                                                        <Col lg="8" md="6" className="mbppr0">  <h4>Search Engine<br /> Optimisation</h4></Col>
                                                    </Row>
                                                </Link>
                                            </Col>
                                            <Col lg="4" md="4" sm="6" xs="4" className="padsmdt mbpdl9">
                                                <Link to={`${process.env.PUBLIC_URL}/marketing-automation-agency-luxury.html`} className="">
                                                    <Row>
                                                        <Col lg="4" md="6" className="mbppr0">
                                                             <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/MarketingAutomation.png`} width="auto" height="auto" alt="" className="bgbox img-fluid mx-auto d-block" /></Col>
                                                        <Col lg="8" md="6" className="mbppr0">  <h4>Marketing<br /> Automation</h4></Col>
                                                    </Row>
                                                </Link>
                                            </Col>
                                            { 
                                                <Col lg="4" md="4" sm="6" xs="4" className="padsmdt mbpdl9">
                                                <Link to={`${process.env.PUBLIC_URL}/facebook-feedback-score.html`} className="">
                                                    <Row>
                                                        <Col lg="4" md="6" className="mbppr0">
                                                             <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/facebook-score.png`} width="auto" height="auto" alt="" className="bgbox img-fluid mx-auto d-block" /></Col>
                                                        <Col lg="8" md="6" className="mbppr0">  <h4>Facebook<br /> Feedback Score</h4></Col>
                                                    </Row>
                                                </Link>
                                            </Col>
      }
                                            
                                            {/* <Col lg="4" md="4" sm="6" xs="4" className="padsmdt mbpdl9">
                                   
                                        <Row>
                                            <Col lg="4" md="6"  className="mbppr0">
                                                 <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/ConversationalMarketing.png`} width="auto" height="auto" alt="" className="bgbox img-fluid mx-auto d-block" /></Col>
                                            <Col lg="8" md="6"  className="mbppr0">  <h4>Conversational<br /> Marketing</h4></Col>
                                        </Row>
                                    
                                </Col> */}
                                            {/* <Col lg="4" md="4" sm="6" xs="4" className="padsmdt mbpdl9">
                                                <Link to={`${process.env.PUBLIC_URL}/oside`} className="">
                                                    <Row>
                                                        <Col lg="4" md="6" className="mbppr0">
                                                             <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/Oside.png`} width="auto" height="auto" alt="" className="bgbox img-fluid mx-auto d-block" /></Col>
                                                        <Col lg="8" md="6" className="mbppr0">  <h4><br />OSIDE</h4></Col>

                                                    </Row>
                                                </Link>

                                            </Col> */}
                                        </Row>
                                    </Container>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    {/* building brand */}

                                    <Container>
                                        <Row>
                                            <Col lg="4" md="4" sm="6" xs="4" className="padsmdt ">
                                                <Link to={`${process.env.PUBLIC_URL}/luxury-digital-branding-agency.html`}>
                                                    <Row>
                                                        <Col lg="4" md="6" className="mbppr0">
                                                             <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/digitalbrandbuilding.png`} width="auto" height="auto" alt="" className="bgbox img-fluid mx-auto d-block" />
                                                        </Col>
                                                        <Col lg="8" md="6" className="mbppr0">  <h4>Digital Brand<br /> Building</h4></Col>
                                                    </Row>
                                                </Link>
                                            </Col>
                                            <Col lg="4" md="4" sm="6" xs="4" className="padsmdt">
                                                <Link to={`${process.env.PUBLIC_URL}/media-buying-agency-luxury.html`}>
                                                    <Row>
                                                        <Col lg="4" md="6" className="mbppr0">
                                                             <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/digitalmediaplanning.png`} width="auto" height="auto" alt="" className="bgbox img-fluid mx-auto d-block" /></Col>
                                                        <Col lg="8" md="6" className="mbppr0">  <h4> Digital Media <br /> Planning & Buying</h4></Col>
                                                    </Row>
                                                </Link>
                                            </Col>
                                            <Col lg="4" md="4" sm="6" xs="4" className="padsmdt">
                                                <Link to={`${process.env.PUBLIC_URL}/luxury-social-media-marketing-agency.html`} >
                                                    <Row>
                                                        <Col lg="4" md="6" className="mbppr0">
                                                             <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/socialmediastrategy.png`} width="auto" height="auto" alt="" className="bgbox img-fluid mx-auto d-block" /></Col>
                                                        <Col lg="8" md="6" className="mbppr0">  <h4>Social Media <br />Strategy & Creative</h4></Col>
                                                    </Row>
                                                </Link>
                                            </Col>
                                            <Col lg="4" md="4" sm="6" xs="4" className="padsmdt">
                                                <Link to={`${process.env.PUBLIC_URL}/luxury-influencer-marketing-agency.html`} >
                                                    <Row>
                                                        <Col lg="4" md="6" className="mbppr0">
                                                             <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/influencermarketing.png`} width="auto" height="auto" alt="" className="bgbox img-fluid mx-auto d-block" /></Col>
                                                        <Col lg="8" md="6" className="mbppr0">  <h4>Influencer<br /> Marketing</h4></Col>
                                                    </Row>
                                                </Link>
                                            </Col>
                                            <Col lg="4" md="4" sm="6" xs="4" className="padsmdt">
                                                <Link to={`${process.env.PUBLIC_URL}/camera-effects-AR-lens-agency-luxury.html`}>
                                                    <Row>
                                                        <Col lg="4" md="6" className="mbppr0">
                                                             <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/cameraeffectslens.png`} width="auto" height="auto" alt="" className="bgbox img-fluid mx-auto d-block" /></Col>
                                                        <Col lg="8" md="6" className="mbppr0">  <h4>Camera Effects<br />& AR Lens</h4></Col>
                                                    </Row>
                                                </Link>
                                            </Col>
                                            <Col lg="4" md="4" sm="6" xs="4" className="padsmdt">
                                                <Row>
                                                    <Col lg="4" md="6" className="mbppr0">
                                                         <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/ConversationalMarketing.png`} width="auto" height="auto" alt="" className="bgbox img-fluid mx-auto d-block" /></Col>
                                                    <Col lg="8" md="6" className="mbppr0">  <h4>Conversational<br /> Marketing</h4></Col>
                                                </Row>

                                            </Col>
                                            {/* <Col lg="4" md="4" sm="6" xs="4" className="padsmdt">
                                    <Row>
                                        <Col lg="4" md="6" className="mbppr0">
                                             <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/videoproduction.png`} width="auto" height="auto" alt="" className="bgbox img-fluid mx-auto d-block" /></Col>
                                        <Col lg="8" md="6" className="mbppr0">  <h4>Video<br />  Production</h4></Col>
                                    </Row>
                                </Col> */}

                                            <Col lg="4" md="4" sm="6" xs="4" className="padsmdt mbpdl9">
                                                <Link to={`${process.env.PUBLIC_URL}/content-marketing-agency-luxury.html`} className="">
                                                    <Row>
                                                        <Col lg="4" md="6" className="mbppr0">
                                                             <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/contentmarketing.png`} width="auto" height="auto" alt="" className="bgbox img-fluid mx-auto d-block" /></Col>
                                                        <Col lg="8" md="6" className="mbppr0">  <h4>Content<br /> Marketing</h4></Col>
                                                    </Row>
                                                </Link>
                                            </Col>
                                            <Col lg="4" md="4" sm="6" xs="4" className="padsmdt mbpdl9">
                                                <Link to={`${process.env.PUBLIC_URL}/online-reputation-management-agency-luxury.html`} className="">
                                                    <Row>
                                                        <Col lg="4" md="6" className="mbppr0">
                                                             <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/ORM.png`} width="auto" height="auto" alt="" className="bgbox img-fluid mx-auto d-block" /></Col>
                                                        <Col lg="8" md="6" className="mbppr0">  <h4><br />ORM</h4></Col>
                                                    </Row>
                                                </Link>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </Col>
            <br />
        </div>
    )
}


export default Services;