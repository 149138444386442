import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container, Button, Col, Row } from 'react-bootstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Slider from "react-slick"; 
function Tirangadabba() {
    window.scrollTo(0, 0);
    return (
        <div>
            <Helmet>

                <title>Independence Day Campaign - Tiranga Dabba by A&B Digital</title>
                <meta name="description" content="" />
                <link rel="canonical" href="https://www.aandb.xyz/case-studies/independence-day-social-media-campaign-tiranga-dabba.html" />

            </Helmet>
            {/* first fold */}
            <div className="casestudy">
                <div className="box padmmdt padmmdb">

                    <Container>

                        <p className="text-left p1 pdl10"> <span class="spcase">Tiranga</span> dabba</p>
                    </Container>

                    <Container fluid className="padbsmt padbsmb">
                        <Row>
                            <Col lg="2"></Col>
                            <Col lg="8">
                                <div className="circleboxfirst  padssmb">
                                      <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="roundbx"  width="30%" alt="" />

                                    <div className="boxcentered tpfold">
                                        <h4 className="d-none d-sm-block ">
                                            Restoring the <br /> dignity of hundreds <br /> of <span>national flags</span>
                                            <br />
                                        </h4>
                                        <h4 className="d-block d-sm-none"> 
                                            Restoring the<br /> dignity of hundreds<br /> of <span>national flags</span>
                                        </h4>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="2"></Col>
                        </Row>
                    </Container>
                </div>
            </div>
            {/* The problem  and build fold */}
            <div className="lgbox  problem">
              <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/tirangadabba/1.png`} className="d-block d-sm-none img-fluid text-right" width="100%" height="100%" />
                <Container fluid className="tpfold pdr0 ">

                    <Row>

                        <Col lg="5" md="4" sm="6" xs="12" className="padmmdt mbpt0 casebox offset-lg-1 ">

                            <p className="text-left p1 mrg30 " > <span class="spcase">THE</span> Problem </p>
                            <p className="p2 marmsmt  mbt20 ">
                                <span>The tiranga that waves with vigour in the morning of 26th January gets soiled with the garbage by night.</span>
                            </p>
                            <p className="p2  ">
                                With millions of national flags being discarded every year post  Republic/Independence day celebrations, we knew we had to take matters in our hands. According to the <span>Flag Code of India, 2002, Section 3.25, “The Flag, when it is in a condition that it is no longer fitting allegory of a display; it should be destroyed in a respectful way, rather by burning.”</span>

                            </p>
                            <p className="p2">With the campaign <span>‘Tiranga Neeche Nahi Humara’</span> we did not want to just do an awareness campaign but wanted to drive action and change that reflected on the ground. We wanted to get keyboard activists to get off their chairs and take action.</p>
                        </Col>

                        <Col lg={{ span: 6, order: 'last' }} sm={{ span: 6, order: 'last' }} xs={{ span: 12, order: 'first' }} className="pdr0 mbppr0   mdpdt80 d-none d-sm-block">
                              <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/tirangadabba/1.png`} className="img-fluid text-right" width="100%" height="100%" />
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* Insights */}
            <Container fluid className=" box padbsmt padbsmb">
                <div className="seomatters ">
                    <Row>
                        <Col lg="11" className="casebox offset-lg-1">
                            <p className="text-left p1 marmsmb "> <span class="spcase">THE</span> BUILD</p>
                        </Col>
                    </Row>
                    <Container>
                        <Row className=" mbmt0 marketing ">
                            <Col lg="10" md="10" sm="10" xs="12" className="offset-lg-1">
                                <p className="p2 text-left">Having <strong>saved 250 flags</strong> in the first year of the campaign, we decided to set a higher target and take extra measures to beat the numbers from the previous year. </p>
                                <h3>The campaign</h3>
                                <p className="p2 text-left"><span>#TirangaNeecheNahiHumara2019</span> was initiated with an event created on Facebook.
                                </p>
                            </Col>
                        </Row>
                        <Row className="marbxst  mbmt0 marketing">

                            <Col lg="4" md="4" sm="6" xs="6" className="offset-lg-1">

                                  <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/tirangadabba/2.png`} className="img-fluid" alt="" />
                            </Col>

                            <Col lg="4" md="4" sm="6" className="offset-lg-1" >
                                  <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/tirangadabba/3.png`} className="img-fluid" alt="" />

                            </Col>

                        </Row>
                        <Row className=" mbpdt30 marketing marbxst">
                            <Col lg="10" md="10" sm="10" xs="12" className="offset-lg-1">
                                <p className="p2 text-left">Prior to creating the event, We reached out to popular influencers, NGO’s, Journalists and celebrities through Instagram DMs, facebook messenger, twitter and emails and we were surprised and excited to see unexpected replies from a few.</p>

                            </Col>
                        </Row>
                        <Row className="marbxst  mbmt0 marketing">

                            <Col lg="4" xs="6" className="offset-lg-1">

                                  <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/tirangadabba/4.png`} className="img-fluid" alt="" />
                            </Col>

                            <Col lg="4" xs="6" className="offset-lg-1" >
                                  <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/tirangadabba/5.png`} className="img-fluid" alt="" />

                            </Col>

                        </Row>
                        <Row className="marbxst mbpdt30 marketing">

                            <Col lg="4" md="4" sm="6" xs="12" className="offset-lg-1">

                                  <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/tirangadabba/6.png`} className="img-fluid" alt="" />
                            </Col>

                            <Col lg="4" md="4" sm="6" className="offset-lg-1" >
                                <p className="p2 marmlgt  mbt20 text-left">To ensure the word gets around and people know what to do, <strong>we created a video</strong> and <strong>a flyer</strong> with the <strong>correct steps to be taken by a person if he/she is interested</strong> in the campaign.
                                </p>
                            </Col>

                        </Row>
                        <Row className=" mbt20 marketing marbxst">
                            <Col lg="10" md="10" sm="10" xs="12" className="offset-lg-1">
                                <p className="p2 text-left">We asked the influencers to share these with the link leading to a landing page. Yes, we decided to have a landing page this year! </p>

                            </Col>
                        </Row>

                        <Row className="marbxst   marketing">

                            <Col lg="3" sm="6" xs="12" className="offset-lg-1 pdlr0">
                                <span className="influencebox">VISHAL DADLANI, <i>INDIAN SINGER AND MUSIC COMPOSER</i></span>
                            </Col>

                            <Col lg="7" sm="6" >
                                <span className="influencebox mx-auto d-none d-sm-block">YUVIKA ABROL, DIGITAL CREATOR</span>

                            </Col>
                        </Row>
                        <Row className="marbxst  marketing">

                            <Col lg="3" sm="3" xs="12" className="offset-lg-1 ">

                                  <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/tirangadabba/7.png`} className="img-fluid" alt="" />
                            </Col>
                            <Row className="d-block d-sm-none">
                                <Col xs="12" className="offset-lg-1 "><br />
                                    <span className="influencebox mx-auto d-block d-sm-none">YUVIKA ABROL, DIGITAL CREATOR</span><br />
                                </Col>
                            </Row>
                            <Col lg="2" sm="2" xs="6" className=" pdlr0">

                                  <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/tirangadabba/8.png`} className="img-fluid" alt="" />
                            </Col>
                            <Col lg="2" sm="2" xs="6" className=" pdlr0">
                                  <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/tirangadabba/9.png`} className="img-fluid" alt="" />
                            </Col>
                            <Col lg="2" sm="2" xs="6" className=" pdlr0">
                                  <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/tirangadabba/10.png`} className="img-fluid" alt="" />
                            </Col>
                            <Col lg="2" sm="2" xs="6" className=" pdlr0">
                                  <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/tirangadabba/11.png`} className="img-fluid" alt="" />
                            </Col>
                        </Row>
                        <Row className="marbxst  mbpdt30 marketing">


                            <Col lg="6" md="4" sm="6" className="offset-lg-1" >
                                <p className="p2 text-left marmlgt mbmt0">While we were brainstorming on what to do differently the year after that, We came up with an idea to make a landing page where people could enter the number of flags collected and see the total number of flags collected by everyone. This was our way of not only tracking the outcome of the campaign but to also make sure that all the promotions done by different people and on different mediums lead to a final common destination. Most importantly, people understood they actually need to pick flags, not limit themselves to click activism.
                                </p>
                            </Col>
                            <Col lg="4" md="4" sm="6" xs="12" >

                                  <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/tirangadabba/12.png`} className="img-fluid" alt="" />
                            </Col>


                        </Row>
                    </Container>

                </div>

            </Container>
            {/* The NUmbers */}
            <div className="lgbox build">
                <Container fluid className="lgbox mbpdt30">
                    <Row className="padssmt   ">

                        <Col lg="10" sm="6" xs="12" className=" pdtb20 casebox offset-lg-1 offset-sm-1">
                            <p className="text-left p1 marssmb  mbb50"> <span class="spcase">THE</span> NUMBERS </p>

                        </Col>
                    </Row>
                    <Row className="padssmt  mbpt0 ">

                        <Col lg="9" sm="6" xs="12" className=" pdtb20 casebox offset-lg-2 offset-sm-1">


                            <p className="p2 ">The metrics shown below is the data collected for the duration of the campaign that went on for 2 days (25th January evening - 27th afternoon)</p>

                            <h3 className=" ">
                                Facebook
                            </h3>
                        </Col>
                    </Row>
                    <Row className="padssmt mbpt0 ">

                        <Col lg={{ span: 2, order: 'last' }} sm={{ span: 3, order: 'last' }} xs="6" className="offset-lg-2 offset-sm-1">

                              <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/tirangadabba/13.png`} className=" mbmt0  mx-auto img-fluid text-right mgbtom30" />
                        </Col>
                        <Col lg={{ span: 2, order: 'last' }} xs="6" sm={{ span: 3, order: 'last' }}>

                              <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/tirangadabba/14.png`} className=" mbmt0  mx-auto img-fluid text-right mgbtom30" />
                        </Col>
                        <Col lg={{ span: 3, order: 'last' }} md="5" sm={{ span: 4, order: 'last' }} xs="12" className="padsmdt mbpt0 ">

                            <p className="p2">
                                Views : <span>11,031</span> views in 2 days <br />
                                Likes  : <span>2,793</span> likes <br />
                                Shares : <span>204</span> shares
                            </p>
                        </Col>

                    </Row>
                    <Row className="padssmt   ">

                        <Col lg="9" sm="6" xs="12" className=" pdtb20 casebox offset-lg-2 offset-sm-1">

                            <h3 className=" ">
                                Instagram
                            </h3>
                        </Col>

                    </Row>
                    <Row className="padssmt padmmdb pdtb20">

                        <Col lg={{ span: 2, order: 'last' }}  sm={{ span: 3, order: 'last' }} xs="6" className="offset-lg-2 offset-sm-1">

                              <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/tirangadabba/15.png`} className=" mbmt0  mx-auto img-fluid text-right mgbtom30" />
                        </Col>

                        <Col lg={{ span: 2, order: 'last' }}  sm={{ span: 2, order: 'last' }} className="d-none d-sm-block">
                        </Col>

                        <Col lg={{ span: 3, order: 'last' }}  sm={{ span: 4, order: 'last' }} xs="6" className="padsmdt">

                            <p className="p2">
                                Views : <span>7,243</span> <br />
                                Reach  : <span>69,744</span> <br />
                                Promotion links click : <span>306</span><br />
                                Likes : <span>171</span><br />
                                Saves :<span>20</span>
                            </p>
                        </Col>

                    </Row>
                    <Row className="padssmt padmmdb">

                        <Col lg={{ span: 8, order: 'last' }}  sm={{ span: 8, order: 'last' }} className="offset-lg-2 offset-sm-2 text-left">
                            <p className="p2">
                                By Linking all our posts, stories, tweet and emails to our landing page, We were able to drive a considerable amount of traffic to the page.
                            </p>
                            <h3 className="padssmt padssmb">The Landing Page Traffic    </h3>
                              <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/tirangadabba/16.png`} className=" mbmt0  mx-auto img-fluid text-right mgbtom30" />

                            <p className="padssmt padssmb p2">
                                While we have more engagement on Facebook, The traffic through Instagram dominates over every other platform.
                            </p>
                              <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/tirangadabba/17.png`} className=" mbmt0  mx-auto img-fluid text-right mgbtom30" />
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* website uptime */}
              <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/tirangadabba/18.png`} className="mx-auto img-fluid d-block d-sm-none" />

            <Container fluid className=" box pdr0 impact">
           
                <Row>

                    <Col lg="4" sm="6" xs="12" className="offset-lg-1">
                        <p className="text-left p1 marmsmb  padbmdt   "> <span className="spcase">THE</span> IMPACT</p>
                        <div className="circleboxfirst  padssmb tp">
                              <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/bgbox.png`} width="50%" alt="" />
                            <div className="boxcentered impact">
                                <h1 className="boxtitle"><span>Tiranga Neeche</span> <br />Nahi Humara <br /> </h1>

                            </div>
                        </div>
                        <p className="p2 text-left">
                            The end result (and what really matters) of this 2-day campaign -
                            <span>We managed to get 940 flags off the ground! </span>
                        </p>
                    </Col>

                    <Col lg="7"  sm="6" className="pdr0 d-none d-sm-block">
                          <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/tirangadabba/18.png`} className="mx-auto img-fluid text-right" />

                    </Col>
                </Row>
            </Container>
            <div className="lgbox build endbox">
                <Container fluid className="pdlr0">
                    <Row className="text-left  mglr0">

                        <Col lg="6" sm="6" xs="12">
                        <Link to={`${process.env.PUBLIC_URL}/case-studies/ecommerce-conversion-rate-case-study.html`}>
                            <Row className="marssmt marssmb mglr0">
                                <Col lg="3" sm="3" xs="3" >
                                      <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/footerlogo/saundh.png`} className="mx-auto img-fluid text-right" />
                                </Col>
                                <Col lg="5" sm="6" xs="7">
                                    {/* <p>Casestudy 7</p> */}
                                    <h4 className="tcgold">SAUNDH </h4>
                                    <p>5X Improvement in E-Commerce Conversion Rate.</p>
                                </Col>
                                <Col lg="2" sm="3" xs="2" className="marbxst">
                                      <img src={`${process.env.PUBLIC_URL}/img/righticon.png`} width="40%" className=" text-right" />
                                </Col>
                            </Row>
                            </Link>
                        </Col>
                        <Col lg="6" sm="6" xs="12" className="bgbl " >
                        <Link to={`${process.env.PUBLIC_URL}/case-studies/social-media-case-study-evok-furnitures.html`}>
                            <Row className="marssmt marssmb pdlr0 mglr0">
                                <Col lg="3" sm="3" xs="3" >
                                      <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/footerlogo/evok.png`} className="mx-auto img-fluid text-right" />
                                </Col>
                                <Col lg="4" sm="6" xs="7">
                                    {/* <p>Casestudy 9</p> */}
                                    <h4 className="tcgold">Evok Social Media Case Study</h4>
                                    <p> 1.47 Million Targeted Users Engaged</p>
                                </Col>
                                <Col lg="2" sm="3" xs="2" className="marbxst">
                                      <img src={`${process.env.PUBLIC_URL}/img/righticon.png`} width="40%" className=" text-right" />
                                </Col>
                            </Row>
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>

    )
}
export default Tirangadabba;