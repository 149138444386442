import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Nav, Container, Accordion, Card, Button, Col, Row, Tab, Carousel } from 'react-bootstrap';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Slider from "react-slick";
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";
import Services from './Services';
function Retailstoregrowth() {
    window.scrollTo(0, 0);
    const [focus, setFocus] = React.useState(false);
    return (
        <div>
            <Helmet>
                <title>Best Retail Digital Marketing Agency in India for *Luxury* Brands | A&B</title>
                <meta name="description" content="80% of all Luxury Sales</span> are<br /> influenced by Digital." /> 
                <link rel="canonical" href="https://www.aandb.xyz/retail-digital-marketing-agency-luxury.html" /> 

            </Helmet>
            <div className="firstfoldseo ">
                <div className="box padmmdb">
                    {/* first fold */}
                    <Container fluid className="padbsmt padbsmb">
                        <Row>
                            <Col lg="2"></Col>
                            <Col lg="8">
                                <div className="circleboxfirst  padsmdt padsmdb">
                                    <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="roundbx" width="30%" alt="" />

                                    <div className="boxcentered">
                                        <h4 className="d-none d-sm-block">
                                            <span className="cuspn"><CountUp start={focus ? 0 : null} end={80} redraw={true}>
                                                {({ countUpRef }) => (
                                                    <VisibilitySensor onChange={(isVisible) => {
                                                        if (isVisible) { setFocus(true); }
                                                    }}>
                                                        <span ref={countUpRef} />
                                                    </VisibilitySensor>
                                                )}
                                            </CountUp>%</span><span> of all Luxury Sales</span> are<br /> influenced by Digital.
                                            <br /> <br />
                                        </h4>
                                        <h4 className="d-block d-sm-none">
                                            <span className="cuspn"><CountUp start={focus ? 0 : null} end={80} redraw={true}>
                                                {({ countUpRef }) => (
                                                    <VisibilitySensor onChange={(isVisible) => {
                                                        if (isVisible) { setFocus(true); }
                                                    }}>
                                                        <span ref={countUpRef} />
                                                    </VisibilitySensor>
                                                )}
                                            </CountUp>%</span><br /> <span> of all Luxury Sales</span> are influenced by Digital.

                                        </h4>

                                    </div>

                                </div>
                                <AnchorLink href="#iwantto" className="btn btn-seo" >Let's Get More Walkins </AnchorLink>
                            </Col>
                            <Col lg="2"></Col>

                        </Row>
                    </Container>
                </div>
            </div>
            {/* Seo matters */}
            <div id="iwantto" className="seomatters  bgb">
                <Container className="padbmdt padbmdb">

                    <h3 className="text-center txtcolor  padsmdb">Should you advertise <span>Luxury Retail stores</span> on Digital ?</h3>
                    <Row className="padsmdb">
                        <Col lg="4" md="4" sm="4">
                            <div className="circleboxfirst  padssmb pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="servicescirbox" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1 className="d-none d-sm-block"><CountUp start={focus ? 0 : null} end={75} redraw={true}>
                                        {({ countUpRef }) => (
                                            <VisibilitySensor onChange={(isVisible) => {
                                                if (isVisible) { setFocus(true); }
                                            }}>
                                                <span ref={countUpRef} />
                                            </VisibilitySensor>
                                        )}
                                    </CountUp>% <br /><br /></h1>
                                    <h1 className="d-block d-sm-none"> <CountUp start={focus ? 0 : null} end={75} redraw={true}>
                                        {({ countUpRef }) => (
                                            <VisibilitySensor onChange={(isVisible) => {
                                                if (isVisible) { setFocus(true); }
                                            }}>
                                                <span ref={countUpRef} />
                                            </VisibilitySensor>
                                        )}
                                    </CountUp>% </h1>

                                </div>
                            </div>
                            <p>Consumers say that they have bought luxury brands that they discovered on Instagram.</p><br />
                        </Col>
                        <Col lg="4" md="4" sm="4">
                            <div className="circleboxfirst  padssmb pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="servicescirbox" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1  className="d-none d-sm-block"><CountUp start={focus ? 0 : null} end={61} redraw={true}>
                                        {({ countUpRef }) => (
                                            <VisibilitySensor onChange={(isVisible) => {
                                                if (isVisible) { setFocus(true); }
                                            }}>
                                                <span ref={countUpRef} />
                                            </VisibilitySensor>
                                        )}
                                    </CountUp>% <br /><br /></h1>
                                    <h1  className="d-block d-sm-none"><CountUp start={focus ? 0 : null} end={61} redraw={true}>
                                        {({ countUpRef }) => (
                                            <VisibilitySensor onChange={(isVisible) => {
                                                if (isVisible) { setFocus(true); }
                                            }}>
                                                <span ref={countUpRef} />
                                            </VisibilitySensor>
                                        )}
                                    </CountUp>% </h1>
                                </div>
                            </div>

                            <p>Luxury consumers depend on local searches to check the store in advance they will be shopping in.</p><br />

                        </Col>
                        <Col lg="4" md="4" sm="4">
                            <div className="circleboxfirst  padssmb pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="servicescirbox" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1 className="d-none d-sm-block"><CountUp start={focus ? 0 : null} end={90} redraw={true}>
                                        {({ countUpRef }) => (
                                            <VisibilitySensor onChange={(isVisible) => {
                                                if (isVisible) { setFocus(true); }
                                            }}>
                                                <span ref={countUpRef} />
                                            </VisibilitySensor>
                                        )}
                                    </CountUp>% <br /><br /></h1>
                                    <h1 className="d-block d-sm-none"><CountUp start={focus ? 0 : null} end={90} redraw={true}>
                                        {({ countUpRef }) => (
                                            <VisibilitySensor onChange={(isVisible) => {
                                                if (isVisible) { setFocus(true); }
                                            }}>
                                                <span ref={countUpRef} />
                                            </VisibilitySensor>
                                        )}
                                    </CountUp>%</h1>
                                </div>
                            </div>

                            <p>Luxury consumers want to know everything about a product on a website before they stepped in a store.</p><br />

                        </Col>
                    </Row>
                    <AnchorLink href="#approach" className="btn btn-seo" >Let's Get More Walkins </AnchorLink>
                </Container>
            </div>
            {/* our approach seo fold */}
            <div id="approach" className="seoapproach bgbl padmmdt padmmdb ">
                <Container>
                    <div className="appbox  padssmb d-none d-sm-block">
                        <img src={`${process.env.PUBLIC_URL}/img/approach2.png`} className="imgwidt img-fluid" alt="" />
                        <div className="top-left">
                            <h4><strong> Walkins,</strong><br /> <i>not likes </i></h4>
                            <p>Our aim is to drive walk-ins to the store, and not just get you likes on Instagram.</p>
                        </div>

                        <div className="top-right">
                            <h4><strong>Analytics,</strong><br /> <i> not guesswork</i></h4>
                            <p>We build data on marketing channel-wise walkins, you can stop guessing.</p>
                        </div>

                        <div className="bottom-left">
                            <h4><strong>Buyers,</strong><br /> <i>not window shoppers</i></h4>
                            <p>We target your customers in the right catchment with the right intent and purchasing power.</p>
                        </div>


                        <div className="bottom-right">
                            <h4><strong>Loyalty, </strong><br /> <i>not single Transactions</i></h4>
                            <p>Use data to reach to your store visitors to build relationships and repeat purchases.</p>
                        </div>
                        <div className="centered"><h1>OUR <span>APPROACH</span> <br />TO Retail Store Footfall Growth</h1></div>
                    </div>
                    <div className="appbox  padssmb d-block d-sm-none">
                        <h1>OUR <span>APPROACH</span> <br />Retail Store Footfall Growth</h1> <br />

                        <h4><strong> Walkins,</strong><br /> <i>not likes </i></h4>
                        <p>Our aim is to drive walk-ins to the store,<br /> and not just get you likes on Instagram.</p>
                        <img src={`${process.env.PUBLIC_URL}/img/straightline.png`} className="img-fluid" />
                        <h4><strong>Analytics,</strong><br /> <i> not guesswork</i></h4>
                        <p>We build data on marketing channel-wise<br /> walkins, you can stop guessing.</p>

                        <img src={`${process.env.PUBLIC_URL}/img/straightline.png`} className="img-fluid" />
                        <h4><strong>Buyers,</strong><br /> <i>not window shoppers</i></h4>
                        <p>We target your customers in the right<br /> catchment with the right<br /> intent and purchasing power.</p>
                        <img src={`${process.env.PUBLIC_URL}/img/straightline.png`} className="img-fluid" />
                        <h4><strong>Loyalty, </strong><br /> <i>not single Transactions</i></h4>
                        <p>Use data to reach to your store visitors<br /> to build relationships and repeat purchases.</p>
                    </div>

                    <Accordion defaultActiveKey="0" className="seoapproach1 marbxst">

                        <Accordion.Toggle as={Button} variant="read" eventKey="1">
                            Our approach in detail
                        </Accordion.Toggle>

                        <Accordion.Collapse eventKey="1">
                            <Card.Body>
                                <h3 className="text-center txtcolor  padsmdb"> <span>Retail Store Footfall Growth Services</span></h3>
                                <Row>
                                    <ol>
                                        <li>Content Marketing - <br /> <br />

                                            <ol type="a">
                                                <li>
                                                    In order to drive footfalls to the retail store via digital, A&B has a unique approach towards this. We use various digital channels such as Local SEO, hyper targeted ads in the catchment areas, social platforms, relevant email campaigns, etc.
                                                    Below are several services listed for driving foot traffic to your stores:
                                                    <br /><br />
                                                </li>
                                                <li>
                                                    Local SEO<br /><br />
                                                    <ol type="a">
                                                        <li> Why Local SEO is important:  Local search queries help in driving traffic to your offline store, it’s important to target the right set of keywords which have the offline intent of visiting the store.
                                                            <br /><br /></li>
                                                        <li> Our Detailed Approach:
                                                            <ol type="i">
                                                                <li>
                                                                    Objective - To drive footfalls from searches happening from local searches.

                                                                </li>
                                                                <li>
                                                                    Audit & Update- Unanswered reviews, old images, missing phone numbers, etc. are common issues with most of the local listings today, these small tasks have a huge impact on the reach and ranking of your listings.
                                                                </li>
                                                                <li>
                                                                    Execution - Analysing platforms & getting your local listings verified is the first step towards your local success. Populating correct data, updating them constantly with new features, and techniques to get reviews helps in your brand reputation and local rankings in search results.
                                                                </li>
                                                            </ol>
                                                        </li>
                                                        <li>Impact for our client:- All our clients having a physical presence have seen a 40-50% increase in high-value footfalls.
                                                            <br /><br /></li>
                                                    </ol>
                                                </li>
                                            </ol>
                                        </li>
                                        <br /> <br />
                                        <li>PPC Advertising - <br /> <br />

                                            <ol type="a">
                                                <li>
                                                    Why PPC Advertising is important:  Before making a luxury purchase, your audience goes through about 15 touchpoints half of which are digital and all of these can be advertised upon.  <br /><br />
                                                </li>

                                                <li>
                                                    Our Detailed Approach:
                                                    <ol type="i">
                                                        <li>
                                                            Objective - To be visible to all your potential customers on digital, across platforms which will drive store walkins
                                                        </li>
                                                        <li>
                                                            Audit - Before setting up any campaigns, we make sure that we not just know but understand your audience and their behaviour across platforms.
                                                        </li>
                                                        <li>
                                                            Execution - We don't go the traditional way of running ads to get immediate returns. With all your competitors running ads, we create long term plans that would not get you short-lived immediate returns but increase your ROAS overtime.
                                                        </li>
                                                    </ol>
                                                </li>
                                                <li>
                                                    The Impact of PPC Advertising for our client: One of our clients witnessed a 40% drop in their cost of acquisition through PPC ads run by our experts.

                                                </li>
                                            </ol>
                                        </li>
                                        <br /> <br />
                                        <li> SEO  <br /> <br />
                                            <p>Do you know what percentage of your website is indexed in Google?</p>
                                            <ol type="a">
                                                <li>
                                                    Why SEO is important: SEO helps your website to get discovered by Search Engines (Google, Bing, etc.)
                                                </li>
                                                <li>
                                                    Our Detailed Approach: <br /><br />
                                                    <ol type="i">
                                                        <li> Objective - We ensure that your website is crawlable, indexable in the search engines which will then make it rankable for relevant keywords. <br /><br /></li>
                                                        <li> Audit - We conduct an audit and look at Crawling & Indexing, Structured Data, Redirects, JS rendering, Canonicals, HREF tags & more.<br /><br /></li>
                                                        <li> Execution - We work with your technology team to fill the gaps identified in the audit. Low hanging fruits first for maximum impact in the shortest time!<br /><br /></li>
                                                    </ol>
                                                </li>
                                                <li>
                                                    Impact for our client: By just focusing on the technical & on-page aspects for a brand, we improved their organic traffic by 400% with the same authority. [link to Insider.in case study]

                                                </li>
                                            </ol>
                                        </li>



                                    </ol>

                                </Row>
                            </Card.Body>
                        </Accordion.Collapse>

                    </Accordion>
                </Container>
                {/* <img src={`${process.env.PUBLIC_URL}/img/approach.png`} className="img-fluid" /> */}
            </div>

            {/* case study */}

            <div className="bgfooter whyus padsmdt padbmdb">
                <Col lg="8" className="offset-lg-1" >
                    <h3 className=" tcwhite fwr"><span className="spund">CASE</span> STUDY</h3>

                </Col>
                <Casestudy />
            </div>

            {/* 3 Reasons to choose */}

            <div className="bgb casestudy">

                <Reasons />
            </div>

            {/* our services */}
            <Services />
        </div>
    );
}


export default Retailstoregrowth;

function Casestudy() {
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,

        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    adaptiveHeight: true,
                }
            },]
    };
    const next = () => {
        Slider.current.slickNext();
    }
    const previous = () => {
        Slider.current.slickPrev();
    }
    return (
        <Container fluid className="padmmdt casestudy ">
            <Row>
                <Col lg="10" className="offset-lg-1">
                    <Slider {...settings}>
                        <div>
                            <Row>
                                <Col className="offset-lg-1" lg={{ span: 4, order: 'first' }} md="6" sm={{ span: 6, order: 'last' }} xs={{ span: 12, order: 'first' }}><img src={`${process.env.PUBLIC_URL}/img/Isaia-casestudy.png`} width="100%" className="   mx-auto d-block" /></Col>
                                <Col lg={{ span: 6, order: 'first' }} sm={{ span: 6, order: 'first' }} md="6" xs={{ span: 12, order: 'last' }} className="mbtxtcenter  offset-lg-1 padbsmb">
                                    {/* <h1 className="padslgt padbxsb">&nbsp;</h1> */}
                                    <h2 className="padslgt padbxsb"><strong>ISAIA Store Footfalls <span className="prs">increased by 100% in 4 months.</span></strong></h2>
                                    {/* <a href="" className="btn btn-primary ">Read Case Study</a> */}
                                    <Link to={`${process.env.PUBLIC_URL}/case-studies/isaia-retail-store-digital-transformation.html`} className="btn btn-seo " >Read Case Study</Link>

                                </Col>

                            </Row>

                        </div>


                    </Slider>
                </Col>
            </Row>
        </Container>
    );
}

// reasons
function Reasons() {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        adaptiveHeight: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false,
                    adaptiveHeight: true,
                }
            },],
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    adaptiveHeight: false,
                }
            },]
    };
    const next = () => {
        Slider.current.slickNext();
    }
    const previous = () => {
        Slider.current.slickPrev();
    }
    return (
        <Container fluid className="reasons padslgt padslgb casestudy ">
            <Row>
                <Col lg="10" className="offset-lg-1">
                    <Slider {...settings}>

                        <div>
                            <Row>
                                <Col className="offset-lg-1 offset-sm-1" lg={{ span: 4, order: 'first' }} md="4" sm={{ span: 3, order: 'first' }} xs={{ span: 12, order: 'first' }}><img src={`${process.env.PUBLIC_URL}/img/reasons3.png`} className="custmt80 padt130 padmmdt mimgw padmsmb marbsmt img-fluid mx-auto d-block" /></Col>
                                <Col className="marmlgt mt0" lg={{ span: 7, order: 'first' }} sm={{ span: 6, order: 'last' }} md="7" xs={{ span: 12, order: 'last' }}  >

                                    <h2 className="marmlgt"> Reasons to choose A&B </h2> <br />

                                </Col>

                            </Row>

                        </div>
                        <div>
                            <Row>

                                <Col className="offset-lg-1 offset-sm-1" lg={{ span: 5, order: 'first' }} sm={{ span: 6, order: 'first' }} xs={{ span: 12, order: 'last' }} >

                                <h2 className="padt190"><span>A&B Commitment</span></h2>
                                    <h3 className=" padmsmb">  If we don't generate <strong>growth</strong> in 3 months, <strong><i>we fire ourselves.</i></strong><br /> There are 9 more weird things about us, <br />read them here.</h3>

                                </Col>
                                <Col className="offset-lg-1 mgm90" lg={{ span: 4, order: 'first' }} sm={{ span: 4, order: 'last' }} xs={{ span: 12, order: 'first' }}>
                                    <img src={`${process.env.PUBLIC_URL}/img/commitment.png`} className="custmt80  padslg125 mimgw img-fluid mx-auto d-block" />
                                </Col>

                            </Row>
                        </div>
                        <div>
                            <Row>

                                <Col className="offset-lg-1 offset-sm-1" lg={{ span: 5, order: 'first' }} sm={{ span: 5, order: 'first' }} xs={{ span: 12, order: 'last' }} >

                                    <h3 className="marblgt padmsmb padslg125"> Specialised retail agency trusted by global brands</h3>

                                </Col>
                                <Col className=" mgm90" lg={{ span: 5, order: 'first' }} sm={{ span: 5, order: 'last' }} xs={{ span: 12, order: 'first' }}>
                                    <img src={`${process.env.PUBLIC_URL}/img/worldmap.png`} className="custmt80 padslg125 padbsmb img-fluid mx-auto d-block" />
                                    <img src={`${process.env.PUBLIC_URL}/img/clientslogo.png`} className=" img-fluid mx-auto d-block" />
                                </Col>
                            </Row>
                        </div>
                       
                        <div>
                            <Row>
                                <Col className="offset-lg-1 offset-md-0" lg={{ span: 10, order: 'first' }} md={{ span: 12, order: 'first' }} sm={{ span: 8, order: 'last' }} xs={{ span: 12, order: 'last' }}>
                                    <h3 className="text-center htitle"><span>Don't believe us?</span></h3>
                                    <p className="text-center custml ">  Hear it from our partners.</p>
                                    <div className="feedbackbox marmsmt padmsmt padmsmb">
                                        <img src={`${process.env.PUBLIC_URL}/img/leftcoma.png`} className="mgt40 img-fluid mx-auto d-block" />
                                        <br />
                                        {/* <img src={`${process.env.PUBLIC_URL}/img/paytminsiderlogo.png`} className="img-fluid mx-auto d-block padmsmt padmsmb" /> */}
                                        <h4 className="">Very few agencies really understand the luxury category, A&B is one. Once we worked with A&B, they helped us to unlock digital growth for our ISAIA store at DLF Emporio. We saw a 100% jump in store walkins in 4 months!
                                        </h4>
                                        {/* <img src={`${process.env.PUBLIC_URL}/img/1seagallery.png`} className="rounded-circle img-fluid mx-auto d-block" /> */}
                                        {/* <img src={`${process.env.PUBLIC_URL}/img/rightcoma.png`}  className="flr" /> */}
                                    </div>
                                    <div className="clientname">
                                        <img src={`${process.env.PUBLIC_URL}/img/clients/Regalia_roundedlogo.png`} className="img-fluid mx-auto" width="160px" /> <br />
                                        <p className="text-center"> <i>- Pratik Dalmia, Founder, Regalia Luxury</i></p>
                                    </div>
                                </Col>

                            </Row>



                        </div>
                    </Slider>
                </Col>
            </Row>
        </Container>
    );
}