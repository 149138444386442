import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container, Button, Col, Row } from 'react-bootstrap';
import Slider from "react-slick";
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";
import { LazyLoadImage } from 'react-lazy-load-image-component';

function Parfait() {
    window.scrollTo(0, 0);
    return (
        <div>
            <Helmet>

                <title>Parfait - Snapchat Lens and Instagram Camera Effects Case Study </title>
                <meta name="description" content="" />
                <link rel="canonical" href="https://www.aandb.xyz/case-studies/parfait-snapchat-instagram-case-study.html" />

            </Helmet>
            {/* first fold */}
            <div className="casestudy">
                <div className="box padmmdt padmmdb">

                    <Container>

                        <p className="text-left p1 pdl10"> <span class="spcase">PA</span>RFAIT</p>
                    </Container>

                    <Container fluid className="padbsmt padbsmb">
                        <Row>
                            <Col lg="2"></Col>
                            <Col lg="8">
                                <div className="circleboxfirst  padssmb">
                                     <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="roundbx"  width="30%" alt="" />

                                    <div className="boxcentered tpfold">
                                        <h4 className="d-none d-sm-block "> Magazine covers became <br />  size inclusive with<br />   <span class="cuspn"> just a lens!</span> <br />
                                        </h4>
                                        <h4 className="d-block d-sm-none">  <br /> Magazine covers became <br />  size inclusive <br />  with <span>just a lens!</span>

                                        </h4>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="2"></Col>
                        </Row>
                    </Container>
                </div>
            </div>
            {/* The brand fold */}
            <div className="lgbox  problem">

                <Container fluid className="tpfold pdr0 mbpdr15">
                    <Row>

                        <Col lg="4" md="4" sm="6" xs="12" className="padmmdt mbpt0 casebox offset-lg-1 ">

                            <p className="text-left p1 mrg30 " > <span class="spcase">THE</span> <strong>BRAND</strong>  </p>
                            <p className="p2   marbmdt  mbt20 ">
                                <span>Parfait</span>  is a <span> size-inclusive lingerie brand</span> on a mission to help women find bras that fit well and feel good.

                            </p>
                            <p className="p2  ">
                                On <span>23 Feb 2019</span> Parfait organised the first of its kind, <span>The Parfait Exclusive Plus Size Fashion Show</span> in India at <span>JW Marriott, Mumbai!</span> This event was organised back when size-inclusive fashion shows were a rarity!

                            </p>
                        </Col>

                        <Col lg={{ span: 6, order: 'last' }} sm={{ span: 6, order: 'last' }} xs={{ span: 12, order: 'first' }} className="offset-lg-1 pdr0 mbpdr15">
                             <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/parfait/1.png`} width="100%" height="100%" className="img-fluid text-right" />
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* The opportunity */}
            <div className="box  problem padbxsb ">

                <Container fluid className="tpfold parfait">
                    <Row>

                        <Col lg="8" md="10" sm="10" xs="12" className="padmmdt mbpt0 casebox offset-lg-1 ">

                            <p className="text-left p1 mrg30 " > <span class="spcase">THE</span> <strong>OPPORTUNITY</strong> </p>
                            <p className="p2   marbsmt  mbt20 ">
                                When we went to the fashion show’s rehearsals, we were<br /> blown away by the plus-sized models’ panache. We thought:-
                            </p>
                            <h2 className="tcgold marbxst marbxsb"><strong>How can we get plus-sized women the same<br /> confidence as these plus-sized models?</strong></h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="5" md="6" sm="6" xs="12" className="mbpt0 casebox offset-lg-1 ">
                            <p className="p2  mbt20 ">
                                <strong><i>By putting them on the cover of a fashion magazine.</i></strong>
                            </p>
                            <p className="p2 ">
                                With plus-size models and communities rarely celebrated in the media, we thought we would give everyone a chance to be on the cover of a fashion magazine.
                            </p>
                            <p className="p2 ">
                                Our route - <strong>Augmented Reality via Snapchat Lens and Instagram Camera Effects.</strong>
                            </p>
                        </Col>

                        <Col lg={{ span: 4, order: 'last' }} sm={{ span: 6, order: 'last' }} xs={{ span: 12, order: 'first' }} className="offset-lg-1">
                             <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/parfait/2.png`} className="img-fluid text-right" />
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* Process */}
            <div className="lgbox  problem padbxsb parfait">

                <Container fluid className="tpfold ">
                    <Row>

                        <Col lg="9" md="10" sm="10" xs="12" className="padmmdt mbpt0 casebox offset-lg-1 ">

                            <p className="text-left p1 mrg30 " > <span class="spcase">THE</span><strong> PROCESS</strong> </p>
                          
                            <h2 className="tcgold marbxst marbxsb padssmt">What to build?</h2>
                            <p className="p2  mbt20 ">
                            The goal was to build an AR Effect that makes users feel that they are on the cover of a fashion magazine. We made sure to create a lens that is sharable and fun while sticking to the theme of body positivity and inclusivity. 
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="5" md="6" sm="6" xs="12" className="mbpt0 casebox offset-lg-1 ">
                        
                        <h2 className="tcgold marbxst marbxsb">How does the<br /> AR effect work?</h2>
                             <ul className="cuulb ">
                                 <li>
                                 A user hit a link on social media. It opens the camera on the phone. 
                                 </li>
                                 <li>
                                 The user clicks a photo with themselves in a setting of a cover of a fashion magazine and posts it.
                                 </li>
                             </ul>
                        </Col>

                        <Col lg={{ span: 4, order: 'last' }} sm={{ span: 6, order: 'last' }} xs={{ span: 12, order: 'first' }} className="offset-lg-1">
                             <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/parfait/3.png`} className="img-fluid text-right" />
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* the impact */}
            <div className="lgbox  problem padbxsb parfait">

                <Container fluid className="tpfold ">
                    <Row>

                        <Col lg="9" md="10" sm="10" xs="12" className="padmmdt mbpt0 casebox offset-lg-1 ">

                            <p className="text-left p1 mrg30 " > <span class="spcase">THE</span> <strong>IMPACT</strong> </p>
                           
                            <p className="p2  mbt20 ">
                            Users loved it, evident in their usage of the AR effect. Parfait was overjoyed when the numbers and ❤️ started pouring in. Have a look:-
                            </p>
                        </Col>
                    </Row>
                    <Row>
                       
                        <Col lg={{ span: 10, order: 'last' }} sm={{ span: 10, order: 'last' }} xs={{ span: 12, order: 'first' }} className="offset-lg-1">
                        <h2 className="tcgold marbxst marbxsb">Snapchat</h2>

                             <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/parfait/4.png`} className="img-fluid text-right" />
                        </Col>
                    </Row>
                </Container>
            </div>
              
            <div className="lgbox build endbox">
                <Container fluid className="pdlr0">
                    <Row className="text-left  mglr0">

                    <Col lg="6" sm="6" xs="12">
                            <Link to={`${process.env.PUBLIC_URL}/case-studies/isaia-retail-store-digital-transformation.html`}>
                                <Row className="marssmt marssmb mglr0">

                                    <Col lg="3" sm="3" xs="3" >
                                         <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/footerlogo/isaia.png`} className="mx-auto img-fluid text-right" />
                                    </Col>
                                    <Col lg="5" sm="6" xs="7">
                                    
                                    <h4 className="tcgold">ISAIA </h4>
                                    <p>Store Footfalls increased by 100% in 4 months </p>
                                    </Col>
                                    <Col lg="2" sm="3" xs="2" className="marbxst">
                                         <img src={`${process.env.PUBLIC_URL}/img/righticon.png`} width="40%" className=" text-right" />
                                    </Col>
                                </Row>
                            </Link>
                        </Col>

                        <Col lg="6" sm="6" xs="12" className="bgbl " >
                            <Link to={`${process.env.PUBLIC_URL}/case-studies/ecommerce-conversion-rate-case-study.html`}>
                                <Row className="marssmt marssmb mglr0">
                                    <Col lg="3" sm="3" xs="3" >
                                         <LazyLoadImage effect="blur" src={`${process.env.PUBLIC_URL}/img/casestudy/footerlogo/saundh.png`} className="mx-auto img-fluid text-right" />
                                    </Col>
                                    <Col lg="5" sm="6" xs="7">
                                      
                                        <h4 className="tcgold">Saundh </h4>
                                        <p>5X Improvement in E-Commerce Conversion Rate.</p>
                                    </Col>
                                    <Col lg="2" sm="3" xs="2" className="marbxst">
                                         <img src={`${process.env.PUBLIC_URL}/img/righticon.png`} width="40%" className=" text-right" />
                                    </Col>
                                </Row>
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>

    )
}
export default Parfait;