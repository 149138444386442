import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container } from 'react-bootstrap';

class Privacy extends Component {
    //window.scrollTo(0, 0);
    render() {

        return (
            <div>
                <Helmet>

                    <title>Privacy Policy</title>
                    <meta name="description" content="Privacy Policy" />
                    <link rel="canonical" href="https://www.aandb.xyz/privacy-policy.html" />

                </Helmet>

                <div className="firstfoldseo ">
                    <div className="box">
                        {/* first fold */}
                        <Container className="padbsmt padbsmb privacy-container">

                            <p>This page informs you of our policies regarding the collection, use and disclosure of Personal Information when you use our Service. We will not use or share your information with anyone except as described in this Privacy Policy.
                            </p>

                            <p>At A&B, we are committed to protecting the privacy and security of our clients and their customers. This privacy policy outlines our practices regarding the collection, use, and disclosure of personal information.</p>
                            <br></br>
                            <h3 className='text-center'>Collection of Personal Information</h3>
                            <br></br>
                            <p>We may collect personal information such as name, email address, phone number, and in order to provide our services. We may also collect information about customers of our clients, such as their IP address and browsing history, in order to deliver targeted digital marketing campaigns. <strong>By using the service, you agree to the collection and use of information in accordance with this policy.</strong></p>
                            <br></br>
                            <h3 className='text-center'>Use of Personal Information</h3>
                            <br></br>
                            <p>We may use personal information to provide our services to clients, to communicate with clients about their accounts, and to improve our services. We may also use personal information to deliver targeted digital marketing campaigns to customers of our clients.
                            </p>


                            <br></br>
                            <h3 className='text-center'>Disclosure of Personal Information
                            </h3>
                            <br></br>
                            <p>We may share personal information with third-party service providers who assist us in providing our services. We may also share personal information with our clients and their authorized representatives. We will not disclose personal information to third parties for their own marketing purposes without the consent of the individual concerned, except as required by law.
                            </p>


                            <br></br>
                            <h3 className='text-center'>International Transfer
                            </h3>
                            <br></br>
                            <p>Your information, including personal information, may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.
                            </p>
                            <p>If you are located outside India and choose to provide information to us, please note that we transfer the information, including Personal Information, to India and processes it there.
                            </p>
                            <p>Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.
                            </p>

                            <br></br>
                            <h3 className='text-center'>Security of Personal Information
                            </h3>
                            <br></br>
                            <p>We take appropriate measures to protect personal information from unauthorized access, disclosure, and misuse. We use industry-standard encryption to secure data in transit and at rest. We restrict access to personal information to employees who need to know in order to provide our services.
                            </p>

                            <br></br>
                            <h3 className='text-center'>Retention of Personal Information
                            </h3>
                            <br></br>
                            <p>We will retain personal information for as long as necessary to provide our services to clients and to comply with legal obligations. We may also retain personal information for as long as necessary for the purposes of fraud prevention, dispute resolution, and other legitimate business purposes.
                            </p>

                            <br></br>
                            <h3 className='text-center'>Changes to this Privacy Policy
                            </h3>
                            <br></br>
                            <p>We reserve the right to modify this privacy policy at any time. Changes will be posted on our website and will be effective immediately upon posting.
                            </p>

                            <br></br>
                            <h3 className='text-center'>Cookie Policy
                            </h3>
                            <br></br>
                            <p>A cookie is a small text file that is placed on your device when you visit a website. Cookies can be used to store information about your preferences and to track your browsing behavior.
                            </p>
                            <p>Types of cookies we use</p>
                            <p>We use both session cookies and persistent cookies on our website. Session cookies are temporary cookies that are deleted when you close your browser. Persistent cookies remain on your device until they expire or until you delete them.                            </p>
                            <p>We use the following types of cookies on our website:</p>
                            <ul>
                                <li>Strictly necessary cookies: These cookies are essential for the functioning of our website and cannot be turned off. They include cookies that enable basic functions such as page navigation and access to secure areas of the website.</li>
                                <li>Performance cookies: These cookies help us to improve the performance of our website by collecting information about how visitors use our website.
                                </li>
                                <li>Functional cookies: These cookies allow us to remember your preferences and to provide you with personalized content and features.
                                </li>
                                <li>Targeting cookies: These cookies are used to deliver targeted digital marketing campaigns to users who have visited our website or who have shown an interest in our services.
                                </li>
                            </ul>

                            <br></br>
                            <h3 className='text-center'>Third-party cookies
                            </h3>
                            <br></br>
                            <p>We may use third-party cookies on our website for the purpose of delivering targeted digital marketing campaigns. These cookies are placed by third-party service providers and are subject to the privacy policies of those providers.
                            </p>

                            <br></br>
                            <h3 className='text-center'>Managing cookies
                            </h3>
                            <br></br>
                            <p>You can control the use of cookies on our website by adjusting the settings in your browser. However, please note that disabling cookies may limit your ability to use certain features of our website.
                            </p>

                            <br></br>
                            <h3 className='text-center'>Changes to this Cookie Policy
                            </h3>
                            <br></br>
                            <p>We reserve the right to modify this cookie policy at any time. Changes will be posted on our website and will be effective immediately upon posting.
                            </p>

                            <br></br>
                            <h3 className='text-center'>Contact Information
                            </h3>
                            <br></br>
                            <p>If you have any questions or concerns about our privacy practices, please contact us at hello@aandb.xyz.
                            </p>

                        </Container>
                    </div>
                </div>
            </div>

        );

    }
}


export default withRouter(Privacy);