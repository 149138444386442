import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Nav, Container, Accordion, Card, Button, Col, Row, Tab, Carousel } from 'react-bootstrap';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Slider from "react-slick";
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";
import Services from './Services';
function Conversionoptimization() { 
    window.scrollTo(0, 0);
    const [focus, setFocus] = React.useState(false);

    return ( 
        <div>
            <Helmet>

                <title>Best Conversion Rate Optimization Agency for *Luxury* Brands | A&B</title>
                <meta name="description" content="More traffic does not generate revenue, more conversions do." />
                <link rel="canonical" href="https://www.aandb.xyz/conversion-rate-optimization-agency-luxury.html" /> 

            </Helmet>
            <div className="firstfoldseo ">
                <div className="box padmmdb">
                    {/* first fold */}
                    <Container fluid className="padbsmt padbsmb">
                        <Row>
                            <Col lg="2"></Col>
                            <Col lg="8">
                                <div className="circleboxfirst padsmdt padsmdb">
                                    <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="roundbx"  width="30%" alt="" />

                                    <div className="boxcentered">
                                        <h4 className="d-none d-sm-block">
                                        More traffic does not generate <span>revenue</span>,<br /> more <span>conversions do.</span>
                                            
                                     </h4>
                                     <h4 className="d-block d-sm-none">
                                        More traffic does not generate <span>revenue</span>, more <span>conversions do.</span>
                                            
                                     </h4>

                                    </div>

                                </div>
                                <AnchorLink href="#conservice" className="btn btn-seo" >Improve your conversions</AnchorLink>
                            </Col>
                            <Col lg="2"></Col>

                        </Row>
                    </Container>
                </div>
            </div>
            {/* Service */}
            <div id="conservice" className="seomatters  bgb">
                <Container className="padbmdt padbmdb">

                    <h3 className="text-center txtcolor  padsmdb">Why that <span>Service</span></h3>
                    <Row className="padsmdb">
                        <Col lg="4" md="4" sm="4">
                            <div className="circleboxfirst  padssmb">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="servicescirbox" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1>2.35%</h1>
                                </div>
                            </div>
                            <p>is the average conversion rate of a website.</p><br />
                        </Col>
                        <Col lg="4" md="4" sm="4">
                            <div className="circleboxfirst  padssmb">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="servicescirbox" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1>40%</h1>
                                </div>
                            </div>

                            <p>of the websites has a conversion rate of less than <span>0.5%</span> </p><br />

                        </Col>
                        <Col lg="4" md="4" sm="4">
                            <div className="circleboxfirst  padssmb">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="servicescirbox" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1>5% </h1>
                                </div>
                            </div>

                            <p>of the marketing budget is spent on CRO by top converting companies. </p> 

                        </Col>
                    </Row>
                    <AnchorLink href="#approach" className="btn btn-seo" >Let's do it!</AnchorLink>
                </Container>
            </div>
            {/* our approach seo fold */}
            <div id="approach" className="seoapproach bgbl padmmdt padmmdb ">
                <Container>
                    <div className="appbox  padssmb d-none d-sm-block">
                        <img src={`${process.env.PUBLIC_URL}/img/approach2.png`} className="imgwidt img-fluid" alt="" />
                        <div className="top-left">
                            <h4><strong>UX,</strong><br /> <i>NOT JUST UI</i></h4>
                            <p>Helping you develop a user experience that converts, generates $$ and makes your customers smile.</p><br />
                        </div>

                        <div className="top-right">
                            <h4><strong>PROBLEMS,</strong><br /> <i>NOT CHECKBOXES</i></h4>
                            <p>Load time, misplaced CTAs, poor contact forms, we get to the heart of your CRO pain points & fix.</p>
                        </div>

                        <div className="bottom-left">
                            <h4><strong>SOLUTIONS,</strong><br /> <i>NOT JARGON</i></h4>
                            <p>Funnels, Heatmaps, A/B Testing, User Flow and Analytics are part of the solution and not THE solution.</p>
                        </div>


                        <div className="bottom-right"> <h4><strong>GROWTH,</strong><br /> <i>NOT BS</i></h4>
                            <p>The improvement in traffic does not get us pumped, higher % conversions do. </p>
                        </div>
                        <div className="centered"><h1>OUR <span>APPROACH</span> <br />TO CRO</h1></div>
                    </div>
                    <div className="appbox  padssmb d-block d-sm-none">
                    <h1>OUR <span>APPROACH</span> <br />TO CRO</h1> <br />

                        <h4><strong>UX,</strong><br /> <i>NOT JUST UI</i></h4>
                        <p>Helping you develop a user experience that converts, generates $$ and makes your customers smile.</p>
                        <img src={`${process.env.PUBLIC_URL}/img/straightline.png`} className="img-fluid"/>
                        <h4><strong>PROBLEMS,</strong><br /> <i>NOT CHECKBOXES</i></h4>
                        <p>Load time, misplaced CTAs, poor contact forms, we get to the heart of your CRO pain points & fix.</p>

                        <img src={`${process.env.PUBLIC_URL}/img/straightline.png`} className="img-fluid"/>
                        <h4><strong>SOLUTIONS,</strong><br /> <i>NOT JARGON</i></h4>
                        <p>Funnels, Heatmaps, A/B Testing, User Flow and Analytics are part of the solution and not THE solution.</p>
                        <img src={`${process.env.PUBLIC_URL}/img/straightline.png`} className="img-fluid"/>
                        <h4><strong>GROWTH,</strong><br /> <i>NOT BS</i></h4>
                        <p>The improvement in traffic does not get us pumped, higher % conversions do.</p>


                    </div>

                    <Accordion defaultActiveKey="0"  className="seoapproach1 marbxst">

                        <Accordion.Toggle as={Button} variant="read" eventKey="1">
                            Our approach in detail
                        </Accordion.Toggle>

                        <Accordion.Collapse eventKey="1">
                            <Card.Body>
                                <h3 className="text-center txtcolor  padsmdb">Conversion Rate <span>Optimisation Agency</span></h3>
                                <p>Ecommerce Conversion Rate Optimisation Agency</p>
                                <p>Why eCommerce CRO is important: <br />
In eCommerce, Conversion Rate matters a lot because more website traffic does not improve your revenue and profits but more conversions do. That is why we do not only work to get visitors on the website but also work to convert these visitors into customers. </p>
                                <Row>
                                    <ol>
                                        <li>Our detailed approach: - <br /> <br />  
                                            <ol type="a">
                                                <li>
                                                Objective - To make sure the maximum number of visitors turns into our customers.<br /><br />
                                                </li>
                                                 
                                                <li>
                                                Audit - We dive deep into the common issues users face such as, load time, quality images, checkout process and fix them so that all the users get the best experience of our website with ease.
                                        </li><br /><br />
                                        <li>
                                        Execution - We use a mix of tools such as Hotjar, Google Analytics, MS Clarity, etc. to get deeper insights into what the users are actually going through. Based on these audits, we create a plan with the lowest hanging fruit having the most impact.
                                        </li>
                                        
                                            </ol><br /><br />
                                            <p>
                                            Impact on our clients: For a luxury fashion brand: through Analytics, we analyzed that the website wasn’t performing well on Safari browsers. On investigation further, we found that image extensions were not supported in Safari because of which users were not able to see the content. Resolving this increased our conversions massively.
                                            </p>
                                        </li>
                                        <br /> <br />
                                        <li>Lead Generation Conversion Rate Optimisation Agency<br /> <br />
 
                                            <ol type="a">
                                                <li>
                                                Why lead generation CRO is important:
                                                Lead generation CRO is important not only for improving the number of leads you are getting but also the quality of those leads. 
                                                </li>
                                                <li>
                                                    Our Detailed Approach: <br /><br />
                                                    <ol type="i">
                                                        <li> Objective - To make sure, we get not only a good amount of leads but also good quality leads.	 <br /><br /></li>
                                                        <li> Audit - We audit the website for the things which are limiting your leads such as unoptimized contact forms, misplaced Call to Action buttons, and page load time, and fix all these problems so that visitors feel comfortable submitting a lead. <br /><br /></li>
                                                        <li> Execution - We audit the website from a UI/UX perspective to find out and resolve the issues that are holding the leads back. <br /><br /></li>
                                                    </ol>
                                                </li>
                                               
                                            </ol>
                                        </li>
                                        <br /> <br />
                                        <li>Store visit Conversion Rate Optimisation Agency:<br /> <br />
 
                                            <ol type="a">
                                                <li>
                                                Why is it important:<br /><br />
                                                These days, before visiting the store actually, most people want to experience it virtually. That is why it is important that your store pages have the same experience for the online users
                                                </li><br /><br />
                                                <li>
                                                    Our Detailed Approach: <br /><br />
                                                    <ol type="i">
                                                        <li> Objective - To make sure, your website has Store pages and is optimized in a way that people would like to visit your offline store.
                                                            <br /><br /></li>
                                                        <li> Audit - We audit the store visit page and check for the points which might add value to your visitors such as store images, timings, top products from that store so that it would be easier for the visitors to make their mind. <br /><br /></li>
                                                        <li> Execution - We optimize the store pages in order to give your users a feel of actually visiting the store while they are visiting it virtually. <br /><br /></li>
                                                    </ol>
                                                </li>
                                                
                                            </ol>
                                        </li> 
                                    </ol>

                                </Row>
                            </Card.Body>
                        </Accordion.Collapse>

                    </Accordion>
                </Container>
                {/* <img src={`${process.env.PUBLIC_URL}/img/approach.png`} className="img-fluid" /> */}
            </div>
            
            {/* case study */}

            <div className="bgfooter whyus  padsmdt padbmdb">
                <Col lg="8" className="offset-lg-1" >
                    <h3 className=" txtcolor fwr"><span className="spund">CASE</span> STUDY</h3>
                </Col>
                <Casestudy />
            </div>

            {/* 3 Reasons to choose */}

            <div className="bgb casestudy ">

                <Reasons />
            </div>

            {/* our services */}
            <Services /> 
        </div>
    );
}


export default Conversionoptimization;

function Casestudy() {
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,

        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    adaptiveHeight: true,
                }
            },]
    };
    const next = () => {
        Slider.current.slickNext();
    }
    const previous = () => {
        Slider.current.slickPrev();
    }
    return (
        <Container fluid className="padmmdt casestudy ">
            <Row>
                <Col lg="10" className="offset-lg-1">
                    <Slider {...settings}>
                        <div>
                            <Row>
                                <Col className="offset-lg-1" lg={{ span: 4, order: 'first' }} md="6" sm={{ span: 6, order: 'last' }} xs={{ span: 12, order: 'first' }}><img src={`${process.env.PUBLIC_URL}/img/Saundh-casestudy.png`} width="100%" className=" mx-auto d-block mbtxtcenter " /></Col>
                                <Col lg={{ span: 6, order: 'first' }} sm={{ span: 6, order: 'first' }} md="6" xs={{ span: 12, order: 'last' }} className="offset-lg-1 padbsmb">
                                    <h1 className="padslgt padbxsb">5x </h1>
                                    <h2 className=" padbxsb text-left ">Improvement in E-Commerce Conversion Rate.</h2>  
                                    <Link to={`${process.env.PUBLIC_URL}/case-studies/ecommerce-conversion-rate-case-study.html`} className="btn btn-seo " >Read Case Study</Link> 

                                </Col> 
                            </Row> 
                        </div>  
                    </Slider>
                </Col>
            </Row>
        </Container>
    );
}

// reasons
function Reasons() {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        adaptiveHeight: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false,
                    adaptiveHeight: true,
                }
            },],
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    adaptiveHeight: false,
                }
            },]
    };
    const next = () => {
        Slider.current.slickNext();
    }
    const previous = () => {
        Slider.current.slickPrev();
    }
    return (
        <Container fluid className="reasons padslgt padslgb casestudy ">
            <Row>
                <Col lg="10" className="offset-lg-1">
                    <Slider {...settings}>
                        <div>
                        <Row>
                                <Col className="offset-lg-1 offset-sm-1" lg={{ span: 4, order: 'first' }} md="4" sm={{ span: 3, order: 'first' }} xs={{ span: 12, order: 'first' }}><img src={`${process.env.PUBLIC_URL}/img/reasons3.png`} className="custmt80 padt130 padmmdt mimgw padmsmb marbsmt img-fluid mx-auto d-block" /></Col>
                                <Col className="marmlgt mt0" lg={{ span: 7, order: 'first' }} sm={{ span: 6, order: 'last' }} md="7" xs={{ span: 12, order: 'last' }}  >

                                    <h2 className="marmlgt"> Reasons to choose A&B </h2> <br />

                                </Col>

                            </Row>
                           
                        </div>
                        <div> 
                            <Row>

                                <Col className="offset-lg-1 offset-sm-1" lg={{ span: 5, order: 'first' }} sm={{ span: 6, order: 'first' }}  xs={{ span: 12, order: 'last' }} >

                                <h2 className="padt190"><span>A&B Commitment</span></h2>
                                    <h3 className=" padmsmb">  If we don't generate <strong>growth</strong> in 3 months, <strong><i>we fire ourselves.</i></strong><br />There are 9 more weird things about us, <br />read them here.</h3>

                                </Col>
                                <Col  className="offset-lg-1 mgm90" lg={{ span: 4, order: 'first' }}  sm={{ span: 4, order: 'last' }} xs={{ span: 12, order: 'first' }}>
                                    <img src={`${process.env.PUBLIC_URL}/img/commitment.png`} className="custmt80  padslg125 mimgw img-fluid mx-auto d-block" />
                                </Col>

                            </Row>
                        </div>
                        <div>
                            <Row>

                                <Col className="offset-lg-1 offset-sm-1" lg={{ span: 5, order: 'first' }} sm={{ span: 5, order: 'first' }} xs={{ span: 12, order: 'last' }}>

                                    <h3 className="marblgt padmsmb padslg125 "> Specialised CRO agency trusted by global brands</h3>

                                </Col>
                                <Col className=" mgm90" lg={{ span: 5, order: 'first' }}  sm={{ span: 5, order: 'last' }} xs={{ span: 12, order: 'first' }}>
                                    <img src={`${process.env.PUBLIC_URL}/img/worldmap.png`} className="custmt80 padslg125 padbsmb img-fluid mx-auto d-block" />
                                    <img src={`${process.env.PUBLIC_URL}/img/clientslogo.png`} className=" img-fluid mx-auto d-block" />
                                </Col>
                            </Row>
                        </div>
                       
                        <div>
                        <Row> 
                                <Col className="offset-lg-1 offset-md-0" lg={{ span: 10, order: 'first' }}  md={{ span: 12, order: 'first' }}  sm={{ span: 8, order: 'last' }} xs={{ span: 12, order: 'last' }}>
                                <h3 className="text-center htitle"><span>Don't believe us?</span></h3>
                                    <p className="text-center custml ">  Hear it from our partners.</p>
                                    <div className="feedbackbox marmsmt padmsmt padmsmb">
                                     <img src={`${process.env.PUBLIC_URL}/img/leftcoma.png`}  className="mgt40 img-fluid mx-auto d-block"/>
                                     <br />
                                        {/* <img src={`${process.env.PUBLIC_URL}/img/paytminsiderlogo.png`} className="img-fluid mx-auto d-block padmsmt padmsmb" /> */}
                                        <h4 className="">When we went to A&B and asked them to increase our inquiries by getting more traffic, they refused. A&B focused on converting existing traffic, that improved our conversions by 176%
                                        </h4>  
                                        {/* <img src={`${process.env.PUBLIC_URL}/img/1seagallery.png`} className="rounded-circle img-fluid mx-auto d-block" /> */}
                                        {/* <img src={`${process.env.PUBLIC_URL}/img/rightcoma.png`}  className="flr" /> */}
                                    </div>
                                    <div className="clientname">
                                    <img src={`${process.env.PUBLIC_URL}/img/clients/Queo_roundedlogo.png`} className="img-fluid mx-auto" width="160px" /> <br />
                                    <p className="text-center"> <i>- Amit Chaudhary, Marketing Head, Queo Bathrooms</i></p>
                                    </div>
                                </Col>

                            </Row>
                            
                        </div>
                    </Slider>
                </Col>
            </Row>
        </Container>
    );
}