import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container, Button, Col, Row } from 'react-bootstrap';
import Slider from "react-slick";
import CountUp from 'react-countup';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import VisibilitySensor from "react-visibility-sensor";
function Insider() {
    window.scrollTo(0, 0);
    const [focus, setFocus] = React.useState(false);
    return (
        <div>
            <Helmet>

                <title>SEO Case Study for Event Management Company - 400% Increase in a year</title>
                <meta name="description" content="" />
                <link rel="canonical" href="https://www.aandb.xyz/case-studies/seo-case-study-for-event-ticketing-company.html" />

            </Helmet>
            {/* first fold */}
            <div className="casestudy">
                <div className="box padmmdt padmmdb">

                    <Container>

                        <p className="text-left p1 pdl10"> <span class="spcase">INS</span>IDER</p>
                    </Container>

                    <Container fluid className="padbsmt padbsmb">
                        <Row>
                            <Col lg="2"></Col>
                            <Col lg="8">
                                <div className="circleboxfirst  padssmb">
                                     <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="roundbx"  width="30%" alt="" />

                                    <div className="boxcentered">
                                        <h4 className="d-none d-sm-block">  <span className="cuspn">430%</span><br /> Increase in Organic Traffic
                                        </h4>
                                        <h4 className="d-block d-sm-none">  <span className="cuspn"> 430%</span><br /> Increase in <br />Organic Traffic
                                        </h4>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="2"></Col>
                        </Row>
                    </Container>
                </div>
            </div>
            {/* The problem  and build fold */}
            <div className="lgbox  problem">

                <Container fluid className=" pdr0 mbpdr15">
                    <Row>

                        <Col lg="4" md="4" sm="6" xs="12" className="padmmdt mbpt0 casebox offset-lg-1 ">

                            <p className="text-left p1 mrg30 " > <span class="spcase">THE</span> Problem </p>
                            <p className="p2   marbmdt  mbt20 ">
                                <span>Paytm Insider</span> is an <span>event & movie ticketing company.</span>
                            </p>
                            <p className="p2">
                                When we started working with them in 2016, Paytm Insider invested significant sums in gaining paid traffic, but their <span>organic traffic had remained stagnant</span> for a long time.
                            </p>
                            <p className="p2">
                                <span>Competitors were outranking them</span>, and they spent more to gain inorganic traffic.
                            </p>

                        </Col>

                        <Col lg={{ span: 6, order: 'last' }} sm={{ span: 6, order: 'last' }} xs={{ span: 12, order: 'first' }} className="offset-lg-1 pdr0 mbpdr15">
                             <LazyLoadImage effect="blur"  src={`${process.env.PUBLIC_URL}/img/casestudy/insider/1.png`} height="100%" width="100%" className="text-right" />
                        </Col>

                    </Row>
                </Container>
            </div>
            {/* Marketing Insights  */}

            <Container fluid className=" box padbmdt padbmdb">
                <div className="seomatters ">
                    <Row>
                        <Col lg="11" className="casebox offset-lg-1">
                            <p className="text-left p1 marmsmb "> <span class="spcase">THE</span> Insight</p>
                        </Col>
                    </Row>
                    <Container>
                        <div className="  mbpdt30 marketing">

                            <p className="p2 sc">
                                In the events and entertainment industry, users usually look for ideas/solutions online in the form of<br /> queries such as events near me, places to visit, best stand-ups to watch, music events, etc.
                            </p>
                             <LazyLoadImage effect="blur"  src={`${process.env.PUBLIC_URL}/img/casestudy/insider/2.png`} width="100%" className="padbxsb text-right mrg30" />
                            <p className="p2 sc">
                                These searches intend to get information first and then buy tickets. Insider had thousands of <br />event pages selling tickets but no page to give solutions to visitors.
                            </p>
                        </div>
                    </Container>

                </div>

            </Container>
            {/* The Build */}
            <div className="lgbox build">
                <Container fluid className="lgbox padmmdt padmmdb mbpdt30 tabpadt50">
                    <Row className=" ">
                        <Col lg="10" sm="12" className="offset-lg-1 ">
                            <p className="p2 ">
                                Since they cater to many categories of events such as music, comedy, workshops, etc., all of<br /> these had many untapped searches & opportunities because no pages were targeting them.
                            </p>
                            <span className="relevantbox d-none d-sm-block mx-auto">
                                This is how the old Insider website looked:
                            </span>
                            <span className="relevantboxmb d-block d-sm-none mx-auto">
                                This is how the old <br />Insider website looked:
                            </span>
                        </Col>
                        <Col lg="10" sm="12" className="offset-lg-1">
                        <LazyLoadImage effect="blur"  src={`${process.env.PUBLIC_URL}/img/casestudy/insider/3.png`} className="marssmt marssmb mx-auto img-fluid mgbtom30" />
                        
                            <p className="p2">
                                Also, many seasonal occasions/festivals such as New Years, Holi, Navratri, etc. also had an<br />  informational & transactional intent by the users.
                                We tapped these opportunities and created the<br />  right set of articles and categories to be present what the users are searching for.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* b fold */}
            <div className="lgbox build">
                <Container fluid className="pdlr0">
                    <Row className="text-left padmmdt  mpdt0">
                        <Col lg="10" md="10" sm="10" className="offset-lg-1 offset-sm-1">
                            <p className="text-left p1 "> <span class="spcase">THE</span> BUILD</p><br />
                        </Col>
                        <Col lg="8" md="10" sm="10" className="offset-lg-2 offset-sm-1">
                          
                            <p className="p2 sc">We use a different SEO strategy for each of our clients because every niche is different. <br />Our SEO campaign started with an initial detailed website audit and found that most of the issues were related to its technical aspects.
                            </p>
                            <h3 className="marbxst marbxsb mrg30  mbmt0">
                                Technical issues that were hurting their overall SEO:-
                            </h3>
                            <ul className="cuulb">
                                <li>Slow website loading speed</li>
                                <li>Distracting elements</li>
                                <li>404 pages (receiving traffic and links)</li>
                                <li>Broken links</li>
                                <li>Redirect chains</li>
                                <li>Thin content</li>
                                <li>Ineffective internal linking</li>
                            </ul>

                        </Col>
                    </Row>
                    <Row className="text-left    mbpdt30 mpdt0">
                        <Col lg="8" md="10" sm="10" className="offset-lg-2 offset-sm-1">
                            <h3 className="marbxst marbxsb mrg30  mbmt0">
                                Revamped Website:
                            </h3>

                            <p className="p2">
                                The website was redesigned in a way to structure the content and have a hierarchy of pages.
                                Right from the city landing to the category pages, and the event page, all were optimised to serve the best user experience and be used as a landing page with targeted keywords.
                            </p>
                            <p className="p2">
                                For example, each category has a main landing page and option to filter the events by time (today, tomorrow, or this weekend). We suggested creating individual pages for each of them to target queries such as “events in Mumbai today/tomorrow/this weekend” which helped to rank a more relevant page.
                            </p>

                        </Col>
                    </Row>
                     <LazyLoadImage effect="blur"  src={`${process.env.PUBLIC_URL}/img/casestudy/insider/4.png`} className="mx-auto mbpdtb0 marbxst marbxsb mrg30  mbmt0 img-fluid text-right mgbtom30" />
                    <Row className="text-left mbpt0  mbpdt30 mpdt0">
                        <Col lg="8" md="10" sm="10" className="offset-lg-2 offset-sm-1">

                            <h3 className="  marbxsb mrg30  mbmt0">
                                Round the year campaigns:
                            </h3>
                            <p className="p2 sc marmsmb">A couple of festivals/occasions that happen around the year surged the searches for users who want to explore their city. We targeted these queries by creating articles and linking the relevant events to be in front of the users. <br /><br />
                                For example: On New Year’s Eve each year there are a lot of searches looking for the best party in the city, places to visit near the city, things to do on NYE, etc. <br /><br />
                                To cater to these types of searches we suggested creating article pages with providing free + paid options to the users.<br /> These articles rank on top for a very competitive query “new year’s eve”.

                            </p>

                        </Col>
                    </Row>

                </Container>

            </div>
            <div className="box padmmdt problem">
                {/* first fold */}

                <Container fluid >
                    <Row>

                        <Col lg="10" md="10" sm="10" xs="12" className="casebox offset-lg-1 ">
                            <p className="text-left p1  "> <span class="spcase">THE</span> NUMBERS</p><br />
                        </Col>
                        <Col lg="8" md="10" sm="10" className="offset-lg-2 offset-sm-1 marmsmb">
                            <p className="p2 text-left ">Over the years the traffic has shot up significantly ( a 430% increase YOY) and resulted in Insider becoming the top-ranked website for events related searches.
                            </p>

                             <LazyLoadImage effect="blur"  src={`${process.env.PUBLIC_URL}/img/casestudy/insider/5.png`} width="100%" className="" />
                            <br />
                             <LazyLoadImage effect="blur"  src={`${process.env.PUBLIC_URL}/img/casestudy/insider/6.png`} width="100%" className="" />
                        </Col>



                    </Row>
                </Container>
                <br />
            </div>

            <div className="lgbox build endbox">
                <Container fluid className="pdlr0">
                    <Row className="text-left  mglr0">

                        <Col lg="6" sm="6" xs="12">
                            <Link to={`${process.env.PUBLIC_URL}/case-studies/scaling-evok-ecommerce-revenue.html`}>
                                <Row className="marssmt marssmb mglr0">
                                    <Col lg="3" sm="3" xs="3" >
                                         <LazyLoadImage effect="blur"  src={`${process.env.PUBLIC_URL}/img/casestudy/footerlogo/evok.png`} className="mx-auto img-fluid text-right" />
                                    </Col>
                                    <Col lg="5" sm="6" xs="7">

                                        <h4 className="tcgold">EVOK </h4>
                                        <p>Scaled Ecommerce Revenue by 2404%</p>
                                    </Col>
                                    <Col lg="2" sm="3" xs="2" className="marbxst">
                                         <img  src={`${process.env.PUBLIC_URL}/img/righticon.png`} width="40%" className=" text-right" />
                                    </Col>
                                </Row>
                            </Link>
                        </Col>
                        <Col lg="6" sm="6" xs="12" className="bgbl " >
                            <Link to={`${process.env.PUBLIC_URL}/case-studies/isaia-retail-store-digital-transformation.html`}>
                                <Row className="marssmt marssmb pdlr0 mglr0">
                                    <Col lg="3" sm="3" xs="3" >
                                         <LazyLoadImage effect="blur"  src={`${process.env.PUBLIC_URL}/img/casestudy/footerlogo/isaia.png`} className="mx-auto img-fluid text-right" />
                                    </Col>
                                    <Col lg="4" sm="6" xs="7">

                                        <h4 className="tcgold">ISAIA </h4>
                                        <p>Store Footfalls increased by 100% in 4 months </p>
                                    </Col>
                                    <Col lg="2" sm="3" xs="2" className="marbxst">
                                         <img  src={`${process.env.PUBLIC_URL}/img/righticon.png`} width="40%" className=" text-right" />
                                    </Col>
                                </Row>
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>

    )
}
export default Insider;