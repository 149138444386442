import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Nav, Container, Accordion, Card, Button, Col, Row, Tab, Carousel } from 'react-bootstrap';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Slider from "react-slick";
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";
import Services from './Services';
function Cameraeffectarlenses() {
    window.scrollTo(0, 0);
    const [focus, setFocus] = React.useState(false);
    return (
        <div>
            <Helmet>

                <title>Instagram Camera Effects & AR Filter Agency | A&B</title>
                <meta name="description" content="600 million people use AR effects across Facebook and Instagram every month! Are you on board yet?!" />
                <link rel="canonical" href="https://www.aandb.xyz/camera-effects-AR-lens-agency-luxury.html" />
            </Helmet>
            <div className="firstfoldseo ">
                <div className="box padmmdb">
                    {/* first fold */}
                    <Container fluid className="padbsmt padbsmb">
                        <Row>
                            <Col lg="1"></Col>
                            <Col lg="5">
                                <div className="circleboxfirst  padsmdt padsmdb">
                                    <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="roundbx" width="30%" alt="" />
                                    <br></br>
                                    <br></br>
                                    <br></br>

                                    <div className="boxcentered">

                                        <h4>
                                            <span className="cuspn">
                                                <CountUp start={focus ? 0 : null} end={600} redraw={true}>
                                                    {({ countUpRef }) => (
                                                        <VisibilitySensor onChange={(isVisible) => {
                                                            if (isVisible) { setFocus(true); }
                                                        }}>
                                                            <span ref={countUpRef} />
                                                        </VisibilitySensor>
                                                    )}
                                                </CountUp></span> million people use AR effects across Facebook and Instagram every month! Are you on board yet?!
                                        </h4>

                                    </div>

                                </div>
                                {/*<AnchorLink href="#gamechanger" className="btn btn-seo" >Be a game changer with AR </AnchorLink>*/}
                            </Col>
                            <Col lg="6">
                                <video alt="" width="100%" height="480" autoPlay loop playsInline>
                                    <source src="/video/camera-lens-video.mp4" type="video/mp4" /> </video>
                            </Col>

                        </Row>
                    </Container>
                </div>
            </div>
            <div className="bgfooter whyus   padsmdt padbmdb">
                <Col lg="8" className="offset-lg-1" >
                    <h3><span className="spund">OUR</span> WORK</h3>
                </Col>
                <SnapFilters />
            </div>
            {/* Seo matters */}
            <div id="gamechanger" className="seomatters  bgb">
                <Container className="padbmdt padbmdb">

                    <h3 className="text-center txtcolor  padsmdb">Why does your brand need <span> AR Camera Effects?</span></h3>
                    <Row className="padsmdb">
                        <Col lg="4" md="4" sm="4">
                            <div className="circleboxfirst  padssmb pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="servicescirbox" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1>86%</h1>
                                </div>
                            </div>
                            <p>Brand Managers feel AR will have a role to play in marketing</p><br />
                        </Col>
                        <Col lg="4" md="4" sm="4">
                            <div className="circleboxfirst  padssmb pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="servicescirbox" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1>70%</h1>

                                </div>
                            </div>

                            <p>Users feel AR lenses are fun & do not feel like advertisement </p><br />

                        </Col>
                        <Col lg="4" md="4" sm="4">
                            <div className="circleboxfirst  padssmb pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="servicescirbox" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1> 30%</h1>

                                </div>
                            </div>

                            <p>Brand lift achieved instantaneously after AR user interaction</p>

                        </Col>
                    </Row>
                    <AnchorLink href="#approach" className="btn btn-seo" >Let's do it!</AnchorLink>
                </Container>
            </div>
            {/* our approach seo fold */}
            <div id="approach" className="seoapproach bgbl padmmdt padmmdb ">
                <Container>
                    <div className="appbox  padssmb d-none d-sm-block">
                        <img src={`${process.env.PUBLIC_URL}/img/approach2.png`} className="imgwidt img-fluid" alt="" />
                        <div className="top-left">
                            <h4><strong>Right Trends,</strong><br /> <i>Not all of them</i></h4>
                            <p>We create lenses that are are relevant to your brand voice, not just another trendy lens.</p>
                        </div>

                        <div className="top-right">
                            <h4><strong>Value,</strong><br /> <i>not Volume</i></h4>
                            <p>We make lenses that resonate with your audience, not just the general public. </p>
                        </div>

                        <div className="bottom-left">
                            <h4><strong>SOLUTIONS,</strong><br /> <i>NOT JARGON</i></h4>
                            <p>We help your luxury brand reach your audience with customised lenses that work for your campaigns.  </p>
                        </div>


                        <div className="bottom-right"> <h4><strong>Real Metrics,</strong><br /> <i>NOT BS</i></h4>
                            <p>We track metrics like impressions, captures and shares, not BS metrics like organic reach, fans and likes.</p>
                        </div>
                        <div className="centered"><h1>OUR <span>APPROACH</span> <br />to AR Camera Effects</h1></div>
                    </div>
                    <div className="appbox  padssmb d-block d-sm-none">
                        <h1>OUR <span>APPROACH</span> <br />TO AR Camera Effects</h1> <br />

                        <h4><strong>Right Trends,</strong><br /> <i>Not all of them</i></h4>
                        <p>We create lenses that are are relevant to your brand voice, not just another trendy lens.</p>
                        <img src={`${process.env.PUBLIC_URL}/img/straightline.png`} className="img-fluid" />
                        <h4><strong>Value,</strong><br /> <i>not Volume</i></h4>
                        <p>We make lenses that resonate with your audience, not just the general public. </p>

                        <img src={`${process.env.PUBLIC_URL}/img/straightline.png`} className="img-fluid" />
                        <h4><strong>SOLUTIONS,</strong><br /> <i>NOT JARGON</i></h4>
                        <p>We help your luxury brand reach your audience with customised lenses that work for your campaigns.  </p>
                        <img src={`${process.env.PUBLIC_URL}/img/straightline.png`} className="img-fluid" />
                        <h4><strong>Real Metrics,</strong><br /> <i>NOT BS</i></h4>
                        <p>We track metrics like impressions, captures and shares, not BS metrics like organic reach, fans and likes.</p>


                    </div>


                </Container>
                {/* <img src={`${process.env.PUBLIC_URL}/img/approach.png`} className="img-fluid" /> */}
            </div>
            {/* seo services */}
            <div className="seoservices  bgb">
                <Container className="padmmdt  ">

                    <h3 className="text-center txtcolor  padsmdb mgb50">CAMERA EFFECTS AND AR LENSES <span>SERVICES</span></h3>
                    <Row className="padsmdb" xs={12}>
                        <Col lg="4" md="4" sm="4" xs="6" >
                            <div className="circleboxfirst  padssmb pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1><br />Snapchat</h1>

                                </div>
                                <p><br />Make your snaps stand out!</p>
                            </div>

                        </Col>
                        <Col lg="4" md="4" sm="4" xs="6" >
                            <div className="circleboxfirst  padssmb pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1><br />Instagram<br /> </h1>

                                </div>
                                <p><br />Make your gram unique with fun camera effects!</p>
                            </div>

                        </Col>
                        <Col lg="4" md="4" sm="4" xs="6" >
                            <div className="circleboxfirst  padssmb pdtb20">
                                <img src={`${process.env.PUBLIC_URL}/img/bgbox.png`} className="imgbw" width="40%" alt="" />
                                <div className="boxcentered">
                                    <h1><br />Facebook  <br /> </h1>

                                </div>
                                <p><br />Hook you customers with some exciting lenses!</p>
                            </div>


                        </Col>
                    </Row>


                </Container>
            </div>
            {/* case study */}

            <div className="bgfooter whyus   padsmdt padbmdb">
                <Col lg="8" className="offset-lg-1" >
                    <h3><span className="spund">CASE</span> STUDY</h3>
                </Col>
                <Casestudy />
            </div>

            <div className="whyus bgb padsmdt padbmdb">
                <Col lg="11" className="offset-lg-1" >
                    <h3 className='text-center'>WHOLE NEW POSSIBILITIES WITH SOCIAL AR</h3>
                </Col>
                <SnapFilters1 />
            </div>

            {/* 3 Reasons to choose */}

            <div className="bgbl  casestudy  ">

                <Reasons />
            </div>

            {/* our services */}
            <Services />
        </div>
    );
}


export default Cameraeffectarlenses;

function SnapFilters() {
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 2,
        arrows: false,

        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    adaptiveHeight: true,
                }
            },
            {
                breakpoint: 567,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    adaptiveHeight: true,
                }
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    adaptiveHeight: true,
                }
            },
        ]
    };
    const next = () => {
        Slider.current.slickNext();
    }
    const previous = () => {
        Slider.current.slickPrev();
    }
    return (
        <Container fluid className="padmmdt">
            <Row>
                <Col lg="10" className="offset-lg-1">
                    <Slider {...settings}>
                        <div className='item'>
                            <video alt="" width="100%" height="480" autoPlay loop playsInline >
                                <source src="/video/IPL.mp4" type="video/mp4" /> </video>
                            <p className='marsxst tcwhite'>Facebook / Instagram</p>
                            <h4 className='tcwhite'>IPL Celebration filter</h4>
                        </div>

                        <div className='item'>
                            <video alt="" width="100%" height="480" autoPlay={true} loop playsInline >
                                <source src="/video/Magzine_lens_AdobeExpress.mp4" type="video/mp4" /> </video>
                            <p className='marsxst tcwhite'>Facebook / Instagram / Snapchat</p>
                            <h4 className='tcwhite'>Parfait</h4>
                        </div>

                        <div className='item'>
                            <video alt="" width="100%" height="480" autoPlay loop playsInline>
                                <source src="/video/IIFW.mp4" type="video/mp4" /> </video>
                            <p className='marsxst tcwhite'>Facebook / Instagram / Snapchat</p>
                            <h4 className='tcwhite'>IIFW</h4>
                        </div>

                        <div className='item'>
                            <video alt="" width="100%" height="480" autoPlay loop playsInline>
                                <source src="/video/Emoji.mp4" type="video/mp4" /> </video>
                            <p className='marsxst tcwhite'>Facebook / Instagram</p>
                            <h4 className='tcwhite'>Emojis Come to Life</h4>
                        </div>
                        <div className='item'>
                            <video alt="" width="100%" height="480" autoPlay loop playsInline>
                                <source src="/video/Chocolate_day_AdobeExpress.mp4" type="video/mp4" /> </video>
                            <p className='marsxst tcwhite'>Facebook / Instagram</p>
                            <h4 className='tcwhite'>Chocolate Skies</h4>
                        </div>
                        <div className='item'>
                            <video alt="" width="100%" height="480" autoPlay loop playsInline>
                                <source src="/video/Pet_Lens.mp4" type="video/mp4" /> </video>
                            <p className='marsxst tcwhite'>Snapchat</p>
                            <h4 className='tcwhite'>Pet Lens</h4>
                        </div>



                    </Slider>
                </Col>
            </Row>
        </Container>
    );
}

function SnapFilters1() {
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 2,
        arrows: false,

        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    adaptiveHeight: true,
                }
            },
            {
                breakpoint: 567,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    adaptiveHeight: true,
                }
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    adaptiveHeight: true,
                }
            },
        ]
    };
    const next = () => {
        Slider.current.slickNext();
    }
    const previous = () => {
        Slider.current.slickPrev();
    }
    return (
        <Container fluid className="padmmdt">
            <Row>
                <Col lg="12">
                    <Slider {...settings}>
                        <div className='item'>
                            <video alt="" width="100%" height="480" autoPlay loop playsInline >
                                <source src="/video/3D-add-on-filter.mp4" type="video/mp4" /> </video>
                        </div>
                        <div className='item'>
                            <video alt="" width="100%" height="480" autoPlay loop playsInline >
                                <source src="/video/Face_tracking game_filter.mp4" type="video/mp4" /> </video>
                        </div>
                        <div className='item'>
                            <video alt="" width="100%" height="480" autoPlay loop playsInline >
                                <source src="/video/Object_Target_trackingSource-edhq.mp4" type="video/mp4" /> </video>
                        </div>
                        <div className='item'>
                            <video alt="" width="100%" height="480" autoPlay loop playsInline >
                                <source src="/video/Randomizer.mp4" type="video/mp4" /> </video>
                        </div>
                        <div className='item'>
                            <video alt="" width="100%" height="480" autoPlay loop playsInline >
                                <source src="/video/World_AR_Source.mp4" type="video/mp4" /> </video>
                        </div>

                    </Slider>
                </Col>
            </Row>
        </Container>
    );
}


function Casestudy() {
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,

        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    adaptiveHeight: true,
                }
            },]
    };
    const next = () => {
        Slider.current.slickNext();
    }
    const previous = () => {
        Slider.current.slickPrev();
    }
    return (
        <Container fluid className="padmmdt casestudy ">
            <Row>
                <Col lg="10" className="offset-lg-1">
                    <Slider {...settings}>
                        <div>
                            <Row>
                                <Col className="offset-lg-1" lg={{ span: 4, order: 'first' }} md="6" sm={{ span: 6, order: 'last' }} xs={{ span: 12, order: 'first' }}><img src={`${process.env.PUBLIC_URL}/img/IPL-Profile.png`} width="100%" className=" mx-auto d-block" /></Col>
                                <Col lg={{ span: 6, order: 'first' }} sm={{ span: 6, order: 'first' }} md="6" xs={{ span: 12, order: 'last' }} className="mbtxtcenter offset-lg-1 padbsmb">
                                    {/* <h1 className="padslgt padbxsb d-none d-sm-block">&nbsp;</h1> */}
                                    <h2 className="padslgt padbxsb">  Cheer virtually for your  <strong><span>team the right way</span></strong></h2>

                                    <Link to={`${process.env.PUBLIC_URL}/case-studies/parfait-snapchat-instagram-case-study.html`} className="btn btn-seo "  >Read Case Study</Link>
                                </Col>

                            </Row>


                        </div>
                        <div>
                            <Row>
                                <Col className="offset-lg-1" lg={{ span: 4, order: 'first' }} md="6" sm={{ span: 6, order: 'last' }} xs={{ span: 12, order: 'first' }}><img src={`${process.env.PUBLIC_URL}/img/parfait-casestudy.png`} width="100%" className=" mx-auto d-block" /></Col>
                                <Col lg={{ span: 6, order: 'first' }} sm={{ span: 6, order: 'first' }} md="6" xs={{ span: 12, order: 'last' }} className="mbtxtcenter offset-lg-1 padbsmb">
                                    {/* <h1 className="padslgt padbxsb d-none d-sm-block">&nbsp;</h1> */}
                                    <h2 className="padslgt padbxsb"> Magazine covers become <strong><span>size-inclusive with just a Camera Effect!</span></strong></h2>

                                    <Link to={`${process.env.PUBLIC_URL}/case-studies/parfait-snapchat-instagram-case-study.html`} className="btn btn-seo "  >Read Case Study</Link>
                                </Col>

                            </Row>


                        </div>


                    </Slider>
                </Col>
            </Row>
        </Container>
    );
}

// reasons
function Reasons() {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        adaptiveHeight: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false,
                    adaptiveHeight: true,
                }
            },],
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    adaptiveHeight: false,
                }
            },]
    };
    const next = () => {
        Slider.current.slickNext();
    }
    const previous = () => {
        Slider.current.slickPrev();
    }
    return (
        <Container fluid className="reasons padslgt padslgb casestudy ">
            <Row>
                <Col lg="10" className="offset-lg-1">
                    <Slider {...settings}>
                        <div>
                            <Row>
                                <Col className="offset-lg-1 offset-sm-1" lg={{ span: 4, order: 'first' }} md="4" sm={{ span: 3, order: 'first' }} xs={{ span: 12, order: 'first' }}><img src={`${process.env.PUBLIC_URL}/img/reasons3.png`} className="custmt80 padt130 padmmdt mimgw padmsmb marbsmt img-fluid mx-auto d-block" /></Col>
                                <Col className="marmlgt mt0" lg={{ span: 7, order: 'first' }} sm={{ span: 6, order: 'last' }} md="7" xs={{ span: 12, order: 'last' }}  >

                                    <h2 className="marmlgt"> Reasons to choose A&B <br />for<strong> Social</strong></h2> <br />

                                </Col>

                            </Row>

                        </div>
                        <div>
                            <Row>

                                <Col className="offset-lg-1 offset-sm-1" lg={{ span: 5, order: 'first' }} sm={{ span: 6, order: 'first' }} xs={{ span: 12, order: 'last' }} >

                                    <h2 className="padt190"><span>A&B Commitment</span></h2>
                                    <h3 className=" padmsmb">  If we don't generate <strong>growth</strong> in 3 months, <strong><i>we fire ourselves.</i></strong><br /> There are 9 more weird things about us, <br />read them here.</h3>

                                </Col>
                                <Col className="offset-lg-1 mgm90" lg={{ span: 4, order: 'first' }} sm={{ span: 4, order: 'last' }} xs={{ span: 12, order: 'first' }}>
                                    <img src={`${process.env.PUBLIC_URL}/img/commitment.png`} className="custmt80  padslg125 mimgw img-fluid mx-auto d-block" />
                                </Col>

                            </Row>

                        </div>
                        <div>
                            <Row>

                                <Col className="offset-lg-1 offset-sm-1" lg={{ span: 5, order: 'first' }} sm={{ span: 5, order: 'first' }} xs={{ span: 12, order: 'last' }} >

                                    <h3 className="marblgt padmsmb padslg125">Specialised AR Lens agency trusted by global brands</h3>

                                </Col>
                                <Col className=" mgm90" lg={{ span: 5, order: 'first' }} sm={{ span: 5, order: 'last' }} xs={{ span: 12, order: 'first' }}>
                                    <img src={`${process.env.PUBLIC_URL}/img/worldmap.png`} className="custmt80 padslg125 padbsmb img-fluid mx-auto d-block" />
                                    <img src={`${process.env.PUBLIC_URL}/img/clientslogo.png`} className=" img-fluid mx-auto d-block" />
                                </Col>

                            </Row>

                        </div>

                        <div>
                            <Row>
                                <Col className="offset-lg-1 offset-md-0" lg={{ span: 10, order: 'first' }} md={{ span: 12, order: 'first' }} sm={{ span: 8, order: 'last' }} xs={{ span: 12, order: 'last' }}>
                                    <h3 className="text-center htitle"><span>Don't believe us?</span></h3>
                                    <p className="text-center custml ">Hear it from our partners.</p>
                                    <div className="feedbackbox marmsmt padmsmt padmsmb">
                                        <img src={`${process.env.PUBLIC_URL}/img/leftcoma.png`} className="mgt40 img-fluid mx-auto d-block" />
                                        <br />

                                        <h4 className="">A&B made me realise that there is so much more to digital than social media and ads. With interactive and innovative camera effects, they took Parafit Plus Size Fashion Show to another level!
                                        </h4>

                                    </div>
                                    <div className="clientname">
                                        <img src={`${process.env.PUBLIC_URL}/img/clients/IIFW_roundedlogo.png`} className="img-fluid mx-auto" width="160px" /> <br />
                                        <p className="text-center"> <i>- Niraj, Founder, India Intimate Fashion Week</i></p>
                                    </div>
                                </Col>

                            </Row>

                        </div>
                    </Slider>
                </Col>
            </Row>
        </Container>
    );
}